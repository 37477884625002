import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoadingIndicator } from "./components/atoms/LoadingIndicator";
import { NotificationStatus } from "./components/molecules/NotificationStatus";
import { SelectAnalysis } from "./components/organisms/analysis/SelectAnalysis";
import { SelectAnalysisArea } from "./components/organisms/analysis/SelectAnalysisArea";
import { SelectAnalysisRoad } from "./components/organisms/analysis/SelectAnalysisRoad";
import { SelectAnalysisSubArea } from "./components/organisms/analysis/SelectAnalysisSubArea";
import { PopupModal } from "./components/organisms/modal/PopupModal";
import { NotFound } from "./components/pages/NotFound";
import { Analysis } from "./components/pages/analysis";
import { AnalysisArea } from "./components/pages/analysis_area";
import { AnalysisHistory } from "./components/pages/analysis_history";
import { ForgotPassword } from "./components/pages/forgot_password";
import { Login } from "./components/pages/login";
import { ResetPassword } from "./components/pages/reset_password";
import { RoadNetworkDefinition } from "./components/pages/road_network_definition";
import { SelectProject } from "./components/pages/select_project";
import { UserManagement } from "./components/pages/user_management";
import { AuthTemplate } from "./components/templates/AuthTemplate";
import { MainTemplate } from "./components/templates/MainTemplate";
import { MenuTemplate } from "./components/templates/MenuTemplate";
import { PageRoutes } from "./constants/routes";
import { appSelectors } from "./ducks/app";

export const RouteConfig = () => {
  const isLoading = useSelector(appSelectors.isLoading);

  const showModal = useSelector(appSelectors.showModal);
  const modalObj = useSelector(appSelectors.modalObject);

  const notification = useSelector(appSelectors.notification);
  const showNotification = useSelector(appSelectors.showNotification);

  return (
    <>
      <Routes>
        <Route path={PageRoutes.LOGIN} element={<MainTemplate />}>
          <Route
            index
            element={
              <AuthTemplate requiredLogout>
                <Login />
              </AuthTemplate>
            }
          />
        </Route>
        <Route path={PageRoutes.SELECT_PROJECT} element={<MainTemplate />}>
          <Route
            index
            element={
              <AuthTemplate requiredLogout>
                <SelectProject />
              </AuthTemplate>
            }
          />
        </Route>
        <Route path={PageRoutes.FORGOT_PASSWORD} element={<MainTemplate />}>
          <Route
            index
            element={
              <AuthTemplate requiredLogout>
                <ForgotPassword />
              </AuthTemplate>
            }
          />
        </Route>
        <Route path={PageRoutes.RESET_PASSWORD} element={<MainTemplate />}>
          <Route
            index
            element={
              <AuthTemplate requiredLogout>
                <ResetPassword />
              </AuthTemplate>
            }
          />
        </Route>
        <Route
          path={PageRoutes.INDEX}
          element={<Navigate to={PageRoutes.ANALYSIS_AREA} />}
        />
        <Route path={PageRoutes.ANALYSIS_AREA} element={<MenuTemplate />}>
          <Route
            index
            element={
              <AuthTemplate requiredLogin>
                <AnalysisArea />
              </AuthTemplate>
            }
          />
        </Route>
        <Route
          path={PageRoutes.ROAD_NETWORK_DEFINITION}
          element={<MenuTemplate />}
        >
          <Route
            index
            element={
              <AuthTemplate requiredLogin>
                <RoadNetworkDefinition />
              </AuthTemplate>
            }
          />
        </Route>
        <Route path={PageRoutes.ANALYSIS_EXECUTE} element={<MenuTemplate />}>
          <Route
            element={
              <AuthTemplate requiredLogin>
                <Analysis />
              </AuthTemplate>
            }
          >
            <Route index element={<SelectAnalysis />} />
            <Route
              path={PageRoutes.SELECT_ANALYSIS_AREA}
              element={<SelectAnalysisArea />}
            />
            <Route
              path={PageRoutes.SELECT_ANALYSIS_SUB_AREA}
              element={<SelectAnalysisSubArea />}
            />
            <Route
              path={PageRoutes.SELECT_ANALYSIS_ROAD}
              element={<SelectAnalysisRoad />}
            />
          </Route>
        </Route>
        <Route path={PageRoutes.ANALYSIS_HISTORY} element={<MenuTemplate />}>
          <Route
            index
            element={
              <AuthTemplate requiredLogin>
                <AnalysisHistory />
              </AuthTemplate>
            }
          />
        </Route>
        <Route path={PageRoutes.USER_MANAGEMENT} element={<MenuTemplate />}>
          <Route
            index
            element={
              <AuthTemplate requiredLogin>
                <UserManagement />
              </AuthTemplate>
            }
          />
        </Route>
        <Route path={"*"} element={<NotFound />} />
      </Routes>

      <PopupModal isOpen={showModal} {...modalObj!} />
      <LoadingIndicator isShown={isLoading} />

      {notification && (
        <NotificationStatus
          notification={notification}
          visible={showNotification}
        />
      )}
    </>
  );
};
