import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";
import { AnalysisPattern, AnalysisStep } from "./types";

const selector = (state: StoreState) => state.analysis;

export const analysisSelectors = {
  currentStep: createSelector(selector, (state) => state.currentStep),
  analysisItems: createSelector(selector, (state) => state.analysisItems),
  selectedAnalysisItem: createSelector(
    selector,
    (state) => state.selectedAnalysisItem,
  ),
  analysisSteps: createSelector(selector, (state) => {
    const selectedAnalysisItem = state.selectedAnalysisItem;
    if (!selectedAnalysisItem) return [];

    if (selectedAnalysisItem.pattern === AnalysisPattern.SelectAreaAndRoad) {
      return [
        AnalysisStep.SelectAnalysisType,
        AnalysisStep.SelectArea,
        AnalysisStep.SelectRoad,
      ];
    }

    if (selectedAnalysisItem.pattern === AnalysisPattern.SelectRoad) {
      return [AnalysisStep.SelectAnalysisType, AnalysisStep.SelectRoad];
    }

    return [AnalysisStep.SelectAnalysisType, AnalysisStep.SelectArea];
  }),
};
