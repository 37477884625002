import { ReactNode, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AppConfig } from "../../AppConfig";
import { PageRoutes } from "../../constants/routes";
import { LocalStorageManager } from "../../core/storage/LocalStorageManager";
import { assetActions } from "../../ducks/asset";
import { store } from "../../store";

type Props = {
  requiredLogin?: boolean;
  requiredLogout?: boolean;
  children?: ReactNode;
};

export const AuthTemplate: React.FC<Props> = ({
  requiredLogin,
  requiredLogout,
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<null | boolean>(null);
  const location = useLocation();

  useEffect(() => {
    const tenantId = LocalStorageManager.Instance.getObject<string>(
      AppConfig.Instance.LocalStorageKey["tenantId"],
    );

    setIsLoggedIn(Boolean(tenantId));
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      store.dispatch(assetActions.fetchStaticAssets.started({}));
      store.dispatch(assetActions.fetchAssets.started({}));
      // store.dispatch(appActions.fetchMenus.started({}));
      // store.dispatch(analysisActions.fetchAnalysisItems.started({}));
      // store.dispatch(authActions.fetchUsers.started({}));
      // store.dispatch(areaActions.fetchAreas.started({}));
      // store.dispatch(roadActions.fetchRoads.started({ area: [] }));
      // store.dispatch(datasetActions.fetchDatasets.started({}));
      // store.dispatch(historyActions.fetchHistories.started({}));
    }
  }, [isLoggedIn, location.pathname]);

  if (isLoggedIn === null) return null;

  if (requiredLogin && !isLoggedIn) {
    return <Navigate replace to={PageRoutes.LOGIN} />;
  }

  if (requiredLogout && isLoggedIn) {
    return <Navigate replace to={PageRoutes.INDEX} />;
  }

  if (!children) return <Outlet />;

  return <>{children}</>;
};
