import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { Area, AreaSelectCondition, CreateAreaRequestBody, DeleteAreaRequestBody, EditAreaRequestBody } from "./types";

const actionCreator = actionCreatorFactory("@@Area");

export const areaActions = {
  fetchAreas: actionCreator.async<{}, { areas: Area[] }>("FETCH_AREAS"),
  selectArea: actionCreator.async<AreaSelectCondition, {}>("SELECT_AREA"),
  removeArea: actionCreator.async<{ area: Area }, {}>("REMOVE_AREA"),
  selectSubArea: actionCreator.async<AreaSelectCondition, {}>("SELECT_SUB_AREA"),
  removeSubArea: actionCreator.async<{ area: Area }, {}>("REMOVE_SUB_AREA"),
  selectRoad: actionCreator.async<AreaSelectCondition, {}>("SELECT_ROAD"),
  removeRoad: actionCreator.async<{ area: Area }, {}>("REMOVE_ROAD"),
  resetSelectedAreas: actionCreator("RESET_SELECTED_AREAS"),
  resetSelectedSubAreas: actionCreator("RESET_SELECTED_SUB_AREAS"),
  resetSelectedRoads: actionCreator("RESET_SELECTED_ROADS"),
  createArea: actionCreator.async<CommonAsyncType & CreateAreaRequestBody & { resetSelectedArea?: boolean }, {}>("CREATE_AREA"),
  editArea: actionCreator.async<CommonAsyncType & EditAreaRequestBody, {}>("EDIT_AREA"),
  deleteArea: actionCreator.async<CommonAsyncType & DeleteAreaRequestBody, {}>("DELETE_AREA"),
  resetToInitialState: actionCreator("RESET_TO_INITIAL_STATE"),
};
