import { IconProps } from "./types";

type Props = IconProps & {
  open?: boolean;
};

export default function IconEye({ className, open }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 22"
      className={className}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d={
          open
            ? "M0 10.5C.833 8 4.2 3 11 3s10.167 5 11 7.5C21 13 17.4 18 11 18S1 13 0 10.5Zm16 0a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm-5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            : "M2.25 1.5 1 2.75l2.7 2.7C1.636 7.076.45 9.152 0 10.5 1 13 4.6 18 11 18c1.646 0 3.107-.33 4.386-.864L18.75 20.5 20 19.25l-2.966-2.966-2.376-2.376-1.415-1.415-4.236-4.236-1.415-1.415L5.2 4.45 2.25 1.5Zm4.272 6.772a5 5 0 0 0 6.705 6.705l-1.553-1.553a3 3 0 0 1-3.598-3.598L6.522 8.272Zm7.469 2.469L10.759 7.51a3 3 0 0 1 3.231 3.231ZM6.923 3.673l2.194 2.194a5 5 0 0 1 6.516 6.516l2.845 2.845C20.33 13.658 21.496 11.76 22 10.5 21.167 8 17.8 3 11 3c-1.53 0-2.885.253-4.077.673Z"
        }
        clipRule="evenodd"
      />
    </svg>
  );
}
