import { IconProps } from "../types";

export default function IconMapTypeControl({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        id="Path_707"
        data-name="Path 707"
        d="M9.333,14,4.667,12.367l-3.617,1.4a.7.7,0,0,1-.719-.088A.768.768,0,0,1,0,13.028V2.139a.726.726,0,0,1,.146-.447.872.872,0,0,1,.4-.292L4.667,0,9.333,1.633,12.95.233a.7.7,0,0,1,.719.088A.768.768,0,0,1,14,.972V11.861a.725.725,0,0,1-.145.447.874.874,0,0,1-.4.292Zm-.778-1.906v-9.1L5.444,1.906v9.1Zm1.556,0,2.333-.778V2.1l-2.333.894ZM1.556,11.9l2.333-.894v-9.1l-2.333.778Zm8.556-8.906v0ZM3.889,1.906v0Z"
      />
    </svg>
  );
}
