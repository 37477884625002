import { zodResolver } from "@hookform/resolvers/zod";
import { ReactNode, useEffect } from "react";
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { ZodSchema, output } from "zod";

type Props<ValidationSchema extends ZodSchema> = {
  schema?: ValidationSchema;
  defaultValues?: DeepPartial<Record<string, unknown>>;
  className?: string;
  onSubmit: SubmitHandler<output<ValidationSchema>>;
  children: ReactNode;
  setMethods?: (method: UseFormReturn<FieldValues, any, undefined>) => void;
};

export default function FormTemplate<ValidationSchema extends ZodSchema>({
  schema,
  defaultValues,
  className,
  onSubmit,
  children,
  setMethods,
}: Props<ValidationSchema>) {
  const methods = useForm({
    mode: "all",
    defaultValues,
    ...(schema ? { resolver: zodResolver(schema) } : {}),
  });

  useEffect(() => {
    if (setMethods) setMethods(methods);
  }, []);

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}
