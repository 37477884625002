import { IconProps } from "../types";

export default function IconRoadNetworkDefinition({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill="currentColor"
    >
      <path
        id="Path_856"
        data-name="Path 856"
        d="M1.67.835V15.212a.835.835,0,1,1-1.67,0V.835a.835.835,0,0,1,1.67,0M15.165,0a.834.834,0,0,0-.835.835V15.212a.835.835,0,1,0,1.67,0V.835A.834.834,0,0,0,15.165,0M8,11.831a.834.834,0,0,0-.835.835v2.546a.835.835,0,1,0,1.67,0V12.666A.834.834,0,0,0,8,11.831M8,0a.834.834,0,0,0-.835.835V3.381a.835.835,0,1,0,1.67,0V.835A.834.834,0,0,0,8,0M8,5.915a.834.834,0,0,0-.835.835V9.3a.835.835,0,0,0,1.67,0V6.75A.834.834,0,0,0,8,5.915"
        transform="translate(4 4)"
      />
    </svg>
  );
}
