export default function IconSpinner() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 108 109"
      className="text-gray-200 animate-spin dark:text-primary fill-primary m-auto"
    >
      <g
        id="Group_4169"
        data-name="Group 4169"
        transform="translate(-128.5 -314.5)"
      >
        <circle
          id="Ellipse_58"
          data-name="Ellipse 58"
          cx="50"
          cy="50"
          r="50"
          transform="translate(132.5 319.5)"
          fill="none"
          stroke="#f0f7fd"
          strokeWidth="8"
        />
        <path
          id="Path_359"
          data-name="Path 359"
          d="M100.5,50.5a50,50,0,1,1-50-50"
          transform="translate(132 318.001)"
          fill="none"
          stroke="#334A7C"
          strokeMiterlimit="10"
          strokeWidth="8"
        />
      </g>
    </svg>
  );
}
