type Props = {
  title: string;
  message: string;
  startDate: string;
  endDate: string;
  subText: string;
};

export const MaintenanceNotice: React.FC<Props> = ({
  title,
  message,
  startDate,
  endDate,
  subText,
}) => {
  return (
    <div className="flex flex-col justify-center items-center px-12 py-7 text-center text-primary border-2 border-primary rounded-md">
      <h1 className="text-[20px] mb-4">{title}</h1>
      <div className="flex flex-col justify-center items-center">
        <p className="max-w-[340px] text-[14px] whitespace-pre-wrap">
          {message}
        </p>
        <p className="text-[16px] my-4">
          {startDate}　〜　{endDate}
        </p>
      </div>
      <div className="text-[14px]">{subText}</div>
    </div>
  );
};
