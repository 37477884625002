import { IconProps } from "./types";

export default function IconX({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 12"
      className={className ?? "w-3 h-3"}
    >
      <path
        stroke="currentColor"
        d="m.334.334 11.333 11.333m0-11.333L.334 11.667"
      />
    </svg>
  );
}
