import { HttpStatusCode } from "axios";
import { call, put } from "typed-redux-saga";
import { ApiResponse } from "../../core/@types";
import { AnalyzeHistories, HistoryResponce } from "../../core/api";
import { historyApi } from "../../core/http/openAPIClient";
import { appActions } from "../app";
import { takeEveryFsa } from "../operations";
import { handleRefreshToken } from "../refreshToken";
import { historyActions } from "./actions";
import { HistoryItem, HistoryStatus } from "./types";

export function* historySaga() {
  yield takeEveryFsa(historyActions.fetchHistories.started, function* (action) {
    const { offset, limit, onComplete, onError, onSuccess } = action.payload;
    const { response, error }: ApiResponse<AnalyzeHistories> = yield call(
      () => {
        return historyApi
          .history(offset, limit)
          .then((response) => response)
          .catch((error) => error);
      },
    );

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          historyActions.fetchHistories.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }

    const histories: HistoryItem[] =
      response.data.histories?.map((history) => ({
        history_id: history.history_id ?? 0,
        status: (history.status as HistoryStatus) ?? HistoryStatus.Failure,
        type: history.type ?? 0,
        area:
          history.area_list?.map((area) => ({
            area_id: area.area_id ?? 0,
            checked: area.checked ?? false,
            is_subarea: area.is_subarea ?? false,
          })) ?? [],
        road:
          history.road_list?.map((road) => ({
            road_id: road.road_id ?? 0,
            checked: road.checked ?? false,
          })) ?? [],
        from: history.from ?? "",
        to: history.to ?? "",
        time: history.time ?? "",
        filter: history.filter ?? "",
        receptions:
          history.reception_ids?.map((reception) => ({
            reception_id: reception.reception_id ?? "",
            analyze_id: reception.analyze_id ?? 0,
          })) ?? [],
        user_name: history.user_name ?? "",
        created_at: history.created_at ?? "",
      })) ?? [];

    yield* put(
      historyActions.fetchHistories.done({
        params: action.payload,
        result: { totalHistories: response.data.total ?? 0, histories },
      }),
    );
    onSuccess?.(histories);
  });

  yield takeEveryFsa(historyActions.createHistory.started, function* (action) {
    const {
      type,
      areas,
      roads,
      from,
      to,
      time,
      filter,
      receptions,
      onComplete,
      onError,
      onSuccess,
    } = action.payload;
    const { response, error }: ApiResponse<HistoryResponce> = yield call(() => {
      return historyApi
        .historyadd({
          type,
          area_list: areas,
          road_list: roads,
          from,
          to,
          time,
          filter,
          reception_ids: receptions,
        })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          historyActions.createHistory.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }

    yield* put(
      historyActions.createHistory.done({
        params: action.payload,
        result: {
          history_id: response.data.history_id ?? null,
        },
      }),
    );

    onSuccess?.(response.data);
    yield* put(
      appActions.displayNotification({
        type: "success",
        text: "分析履歴へ保存しました",
      }),
    );
  });

  yield takeEveryFsa(
    historyActions.deleteHistories.started,
    function* (action) {
      const { historyIds, onComplete, onError, onSuccess } = action.payload;
      const { response, error }: ApiResponse<{}> = yield call(() => {
        return historyApi
          .historydelete(historyIds[0])
          .then((response) => response)
          .catch((error) => error);
      });

      if (response && historyIds.length === 1) {
        onComplete?.();

        yield* put(
          historyActions.deleteHistories.done({
            params: action.payload,
            result: { historyId: historyIds[0] },
          }),
        );

        yield* put(
          appActions.displayNotification({
            type: "success",
            text: "分析履歴を削除しました",
          }),
        );

        return onSuccess?.(response.data);
      }

      if (!response || error) {
        if (error?.status === HttpStatusCode.Unauthorized) {
          return yield* handleRefreshToken(
            historyActions.deleteHistories.started(action.payload),
          );
        } else {
          onComplete?.();
          return onError?.(error);
        }
      }

      yield* put(
        historyActions.deleteHistories.done({
          params: action.payload,
          result: { historyId: historyIds[0] },
        }),
      );

      yield* put(
        historyActions.deleteHistories.started({
          ...action.payload,
          historyIds: historyIds.slice(1, historyIds.length),
        }),
      );
    },
  );
}
