import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  URL_QUERY_PARAM_AREA,
  URL_QUERY_PARAM_ROAD,
} from "../../../constants/param";
import { PageRoutes } from "../../../constants/routes";
import { AnalysisPattern, analysisActions } from "../../../ducks/analysis";
import {
  Area,
  AreaType,
  areaActions,
  areaSelectors,
} from "../../../ducks/area";
import { getAnalysisItemIcon } from "../../../utils/analysis";
import { AnalysisItemCard } from "../../molecules/analysis/AnalysisItemCard";
import { useAnalysis } from "../../pages/analysis/hook";

export const SelectAnalysis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { analysisItems, selectedAnalysisItem, handleSelectAnalysisItem } =
    useAnalysis();

  const allAreas = useSelector(areaSelectors.allAreas);
  const roads = useSelector(areaSelectors.roads);
  const [selectedArea, setSelectedArea] = useState<Area | null>(null);

  const analysisItemsToDisplay = useMemo(() => {
    if (!selectedArea) return analysisItems;

    if (selectedArea.area.type === AreaType.Road) {
      return analysisItems.filter(
        (analysisItem) => analysisItem.pattern === AnalysisPattern.SelectRoad,
      );
    }

    if (selectedArea.for_multi) {
      return analysisItems.filter(
        (analysisItem) => analysisItem.pattern !== AnalysisPattern.SelectRoad,
      );
    } else {
      return analysisItems.filter(
        (analysisItem) =>
          analysisItem.pattern !== AnalysisPattern.SelectRoad &&
          !analysisItem.is_multi,
      );
    }
  }, [analysisItems, selectedArea]);

  useEffect(() => {
    dispatch(analysisActions.setCurrentStep(1));
    dispatch(areaActions.resetSelectedSubAreas());

    const query = new URLSearchParams(location.search);
    const area = query.get(URL_QUERY_PARAM_AREA);
    const road = query.get(URL_QUERY_PARAM_ROAD);

    if (area && !isNaN(Number(area))) {
      const targetArea = allAreas.find((a) => a.id === Number(area));

      if (targetArea) {
        setSelectedArea(targetArea);
        dispatch(
          areaActions.selectArea.started({
            area: targetArea,
            removeIfSelected: false,
          }),
        );
        return;
      }
    }

    if (road && !isNaN(Number(road))) {
      const targetArea = roads.find((a) => a.id === Number(road));

      if (targetArea) {
        setSelectedArea(targetArea);
        dispatch(
          areaActions.selectRoad.started({
            area: targetArea,
            removeIfSelected: false,
          }),
        );
        return;
      }
    }

    dispatch(areaActions.resetSelectedAreas());
    dispatch(areaActions.resetSelectedSubAreas());
    dispatch(areaActions.resetSelectedRoads());
    navigate(PageRoutes.ANALYSIS_EXECUTE, { replace: true });
  }, []);

  return (
    <div className="flex flex-col flex-1 gap-y-5 px-5 overflow-hidden">
      <span className="text-sm">
        分析する項目を選択し「次へ」を押下してください
      </span>
      {analysisItemsToDisplay.length > 0 ? (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(280px,1fr))] gap-4 overflow-y-auto">
          {analysisItemsToDisplay.map((item, i) => {
            return (
              <AnalysisItemCard
                key={`analysis-item-${item.type}-${i}`}
                isSelected={selectedAnalysisItem?.type === item.type}
                onClick={handleSelectAnalysisItem}
                item={item}
                icon={getAnalysisItemIcon(item.type)}
              />
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col flex-1 justify-center items-center w-full h-full text-lg text-[#666666] font-bold">
          分析項目がありません。
        </div>
      )}
    </div>
  );
};
