import { HISTORY_PER_PAGE } from "../../../constants/pagination";
import { HistoryItem, HistoryStatus } from "../../../ducks/history";
import { usePureApiPagination } from "../../../hooks/usePureApiPagination";
import { formatDateTime } from "../../../utils/date";
import { mergeClassNames } from "../../../utils/style";
import { CustomButton } from "../../atoms/CustomButton";
import { LabelCheckBox } from "../../atoms/LabelCheckBox";
import { PageHeader } from "../../organisms/PageHeader";
import { Pagination } from "../../organisms/Pagination";
import { useAnalysisHistory } from "./hook";

export const AnalysisHistory: React.FC = () => {
  const {
    totalHistories,
    selectedHistories,
    canDeleteHistory,
    fetchHistories,
    onPageChange,
    onDelete,
    onSelectAll,
    onSelectHistory,
    onShowHistoryResult,
    getHistoryUserName,
    getHistoryAnalysisTypeName,
    getHistoryAreaNames,
    getHistoryFilters,
  } = useAnalysisHistory();

  const {
    page,
    setPage,
    totalPage,
    onPrevious,
    onNext,
    onFirst,
    onLast,
    data,
    onFetchDataSuccess,
  } = usePureApiPagination<HistoryItem>({
    total: totalHistories,
    perPage: HISTORY_PER_PAGE,
    onPageChange,
    fetchData: fetchHistories,
  });

  const _renderActionButtons = () => {
    return (
      <div className="flex gap-x-2.5">
        <CustomButton
          type="button"
          buttonStyle="border"
          className="text-[14px] h-[36px] w-[72px] p-0"
          onClick={() => onDelete(page, onFetchDataSuccess)}
          disabled={!canDeleteHistory}
          text="削除"
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full box-border pr-2.5 pl-2.5 pb-2.5">
      <div>
        <PageHeader />
      </div>

      <div className="flex flex-col gap-y-5 p-5 overflow-hidden bg-white rounded">
        <div className="flex justify-between items-center">
          {_renderActionButtons()}

          <Pagination
            page={page}
            totalPage={totalPage}
            onSetPage={setPage}
            onPrevious={onPrevious}
            onNext={onNext}
            onFirst={onFirst}
            onLast={onLast}
          />
        </div>

        <div className="flex flex-col max-h-full max-w-full show-scrollbar overflow-auto border border-[#B5B5B5] rounded">
          <table className="table-fixed border-b border-gray-border">
            <thead>
              <tr className="h-[49px] divide-x divide-white sticky top-0 text-[11px]">
                <th
                  align="left"
                  className="px-3 w-16 text-white font-semibold bg-gray-light"
                >
                  <LabelCheckBox
                    label={""}
                    value={data}
                    indeterminate={
                      selectedHistories.length > 0 &&
                      selectedHistories.length < data.length
                    }
                    checked={
                      selectedHistories.length > 0 &&
                      selectedHistories.length === data.length
                    }
                    onChange={(event: any, value: any) => onSelectAll(value)}
                  />
                </th>
                {[
                  "保存日時",
                  "ステータス",
                  "分析結果",
                  "分析ユーザー名",
                  "分析項目",
                  "分析エリア·道路",
                  "分析結果の絞り込み",
                ].map((header, i) => (
                  <th
                    key={`analysis-history-table-header-${header}-${i}`}
                    align="left"
                    className={mergeClassNames(
                      "px-5 text-white font-semibold bg-gray-light whitespace-nowrap",
                    )}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-border">
              {data.map((history, i) => {
                const isSelected = selectedHistories.find(
                  (x) => x.history_id === history.history_id,
                );

                return (
                  <tr
                    key={`analysis-history-list-${history.history_id}-${i}`}
                    className="divide-x h-14 divide-gray-border text-[13px] break-words"
                  >
                    <td className="px-3">
                      <LabelCheckBox
                        checked={!!isSelected}
                        label={""}
                        value={history}
                        onChange={(event: any, value: any) =>
                          onSelectHistory(value)
                        }
                      />
                    </td>
                    <td className="px-5 whitespace-nowrap">
                      {formatDateTime(history.created_at)}
                    </td>
                    <td className="px-5">
                      {history.status === HistoryStatus.Success ? (
                        <span className="text-[11px] py-0.5 px-2.5 rounded-full bg-[#5FB955] text-white whitespace-nowrap">
                          完了
                        </span>
                      ) : (
                        <span className="text-[11px] py-0.5 px-2.5 rounded-full bg-[#FE9500] text-white whitespace-nowrap">
                          生成中
                        </span>
                      )}
                    </td>
                    <td className="px-5">
                      <button
                        disabled={history.status !== HistoryStatus.Success}
                        className={mergeClassNames(
                          "whitespace-nowrap",
                          history.status === HistoryStatus.Success
                            ? "text-primary"
                            : "text-gray-light",
                        )}
                        onClick={() => onShowHistoryResult(history)}
                      >
                        分析結果を見る
                      </button>
                    </td>
                    <td className="px-5 whitespace-nowrap">
                      {getHistoryUserName(history)}
                    </td>
                    <td className="px-5 whitespace-nowrap">
                      {getHistoryAnalysisTypeName(history)}
                    </td>
                    <td className="px-5 whitespace-nowrap">
                      {getHistoryAreaNames(history)}
                    </td>
                    <td className="px-5 py-2.5">
                      <div className="flex flex-wrap items-center gap-1 min-w-[512px]">
                        {getHistoryFilters(history).map(
                          (filter, filterIndex) => {
                            return (
                              <span
                                key={`analysis-history-filter-${filter.label}-${filterIndex}`}
                                className="bg-[#EFEFEF] rounded-lg py-0.5 px-2 text-[10px] whitespace-nowrap"
                              >
                                {filter.label} ： {filter.value}
                              </span>
                            );
                          },
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
