import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { CreateHistoryRequestBody, FetchHistoryRequestBody, HistoryItem } from "./types";

const actionCreator = actionCreatorFactory("@@History");

export const historyActions = {
  fetchHistories: actionCreator.async<CommonAsyncType<HistoryItem[]> & FetchHistoryRequestBody, { totalHistories: number; histories: HistoryItem[] }>(
    "FETCH_HISTORIES",
  ),
  createHistory: actionCreator.async<CommonAsyncType & CreateHistoryRequestBody, { history_id: number | null }>("CREATE_HISTORY"),
  deleteHistories: actionCreator.async<CommonAsyncType & { historyIds: number[] }, { historyId: number }>("DELETE_HISTORIES"),
  resetToInitialState: actionCreator("RESET_TO_INITIAL_STATE"),
};
