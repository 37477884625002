export const PageRoutes = {
  INDEX: "/",
  LOGIN: "/login",
  SELECT_PROJECT: "/select_project",
  FORGOT_PASSWORD: "/forgot_password",
  RESET_PASSWORD: "/reset-password/:secret_key",
  ANALYSIS_AREA: "/analysis_area",
  ROAD_NETWORK_DEFINITION: "/road_network_definition",
  ANALYSIS_EXECUTE: "/analysis_execute",
  SELECT_ANALYSIS_AREA: "/analysis_execute/select_area",
  SELECT_ANALYSIS_SUB_AREA: "/analysis_execute/select_subarea",
  SELECT_ANALYSIS_ROAD: "/analysis_execute/select_road",
  ANALYSIS_HISTORY: "/analysis_history",
  USER_MANAGEMENT: "/user_management",
  ANALYSIS_RESULT: "/analysis_result",
};
