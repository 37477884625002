import React from "react";
import { CustomButton } from "../../atoms/CustomButton";
import { PageHeader } from "../../organisms/PageHeader";
import { RoadNetworkMap } from "../../organisms/map/RoadNetworkMap";
import { EditRoadNetworkModal } from "../../organisms/modal/EditRoadNetworkModal";
import { useRoadNetworkDefinition } from "./hook";

export const RoadNetworkDefinition: React.FC = () => {
  const {
    createRoadNetworkModalOpen,
    canEditRoadNetwork,
    onClickAdd,
    onClose,
    isModalClose,
  } = useRoadNetworkDefinition();

  return (
    <div className="flex flex-col w-full h-full box-border px-2.5 pb-2.5">
      <div>
        <PageHeader />
      </div>

      <div className="flex flex-col w-full border-[#FFFFFF] border-8 h-[calc(100%_-_80px)] rounded-[4px]">
        <RoadNetworkMap isModalClose={isModalClose} />
        {canEditRoadNetwork && (
          <>
            <div className="relative h-0 -top-[99%] left-2">
              <CustomButton
                text="道路ネットワーク編集"
                className="px-6.25 py-2.5"
                onClick={onClickAdd}
              />
            </div>
            <EditRoadNetworkModal
              isOpen={createRoadNetworkModalOpen}
              handleClose={onClose}
            />
          </>
        )}
      </div>
    </div>
  );
};
