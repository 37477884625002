import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { appSelectors } from "../../ducks/app";
import { Header } from "../organisms/Header";
import { SideNavigation } from "../organisms/SideNavigation";

export const MenuTemplate: React.FC = () => {
  const menus = useSelector(appSelectors.menus);

  return (
    <main className="flex flex-col h-[100svh] bg-gray-background">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <SideNavigation menus={menus} />
        <div className="flex-1 overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </main>
  );
};
