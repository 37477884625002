import { User } from "../../../ducks/auth";
import { useLocalPagination } from "../../../hooks/useLocalPagination";
import { formatDateTime } from "../../../utils/date";
import { mergeClassNames } from "../../../utils/style";
import { CustomButton } from "../../atoms/CustomButton";
import { LabelCheckBox } from "../../atoms/LabelCheckBox";
import { PageHeader } from "../../organisms/PageHeader";
import { Pagination } from "../../organisms/Pagination";
import { UserManagementModal } from "../../organisms/modal/UserManagementModal";
import { useUserManagement } from "./hook";

export const UserManagement: React.FC = () => {
  const {
    users,
    selectedUsers,
    showUserModal,
    canAddUser,
    canEditUser,
    canDeleteUser,
    canEditTenant,
    onPageChange,
    onAdd,
    onEdit,
    onDelete,
    onClose,
    onSelectUser,
    onSelectAll,
    onSubmit,
    getProjectNames,
    getUserTypes,
  } = useUserManagement();

  const {
    page,
    setPage,
    totalPage,
    dataToDisplay,
    onPrevious,
    onNext,
    onFirst,
    onLast,
  } = useLocalPagination<User>({ data: users, onPageChange });

  const _renderActionButtons = () => {
    return (
      <div className="flex gap-x-2.5">
        <CustomButton
          type="button"
          className="text-[14px] h-[36px] w-[96px] p-0"
          onClick={onAdd}
          disabled={!canAddUser}
          text="新規追加"
        />

        <CustomButton
          type="button"
          buttonStyle="border"
          className="text-[14px] h-[36px] w-[72px] p-0"
          onClick={onEdit}
          disabled={!canEditUser}
          text="編集"
        />
        <CustomButton
          type="button"
          buttonStyle="border"
          className="text-[14px] h-[36px] w-[72px] p-0"
          onClick={onDelete}
          disabled={!canDeleteUser}
          text="削除"
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full box-border pr-2.5 pl-2.5 pb-2.5">
      <div>
        <PageHeader />
      </div>

      <div className="flex flex-col gap-y-5 p-5 overflow-hidden bg-white rounded">
        <div className="flex justify-between items-center">
          {_renderActionButtons()}

          <Pagination
            page={page}
            totalPage={totalPage}
            onSetPage={setPage}
            onPrevious={onPrevious}
            onNext={onNext}
            onFirst={onFirst}
            onLast={onLast}
          />
        </div>

        <div className="flex flex-col max-h-full max-w-full show-scrollbar overflow-auto border border-[#B5B5B5] rounded">
          <table className="table-fixed border-b border-gray-border">
            <thead>
              <tr className="h-[49px] divide-x divide-white sticky top-0 text-[11px]">
                <th
                  align="left"
                  className="px-3 w-16 font-semibold bg-gray-light"
                >
                  <LabelCheckBox
                    label={""}
                    value={dataToDisplay}
                    indeterminate={
                      selectedUsers.length > 0 &&
                      selectedUsers.length < dataToDisplay.length
                    }
                    checked={
                      selectedUsers.length > 0 &&
                      selectedUsers.length === dataToDisplay.length
                    }
                    onChange={(event: any, value: any) => onSelectAll(value)}
                  />
                </th>
                {[
                  "ID",
                  "ユーザー名",
                  "Eメール",
                  "所属プロジェクト",
                  "ユーザータイプ",
                  "登録日時",
                ].map((header, i) => (
                  <th
                    key={`user-management-table-header-${header}-${i}`}
                    align="left"
                    className={mergeClassNames(
                      i === 0 && "w-16",
                      "px-5 text-white font-semibold bg-gray-light whitespace-nowrap",
                    )}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-border">
              {dataToDisplay.map((user, i) => {
                const { id, name, email, create_date } = user;
                const isSelected = selectedUsers.find((x) => x.id === user.id);

                return (
                  <tr
                    key={`user-management-user-list-${user.id}-${i}`}
                    className="divide-x h-14 divide-gray-border text-[13px] break-words"
                  >
                    <td className="px-3">
                      <LabelCheckBox
                        checked={!!isSelected}
                        label={""}
                        value={user}
                        onChange={(event: any, value: any) => {
                          onSelectUser(value);
                        }}
                      />
                    </td>
                    <td className="px-5 whitespace-nowrap">{id}</td>
                    <td className="px-5 whitespace-nowrap">{name}</td>
                    <td className="px-5 whitespace-nowrap">{email}</td>
                    <td className="px-5 whitespace-nowrap">
                      {getProjectNames(user)}
                    </td>
                    <td className="px-5 whitespace-nowrap">
                      {getUserTypes(user)}
                    </td>
                    <td className="px-5 whitespace-nowrap">
                      {formatDateTime(create_date)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <UserManagementModal
          isOpen={showUserModal}
          selectedUser={selectedUsers[0]}
          canEditTenant={canEditTenant ?? false}
          handleClose={onClose}
          handleSubmit={onSubmit}
        />
      </div>
    </div>
  );
};
