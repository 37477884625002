import React from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert-icon.svg";
import { ModalDataItem, appActions } from "../../../ducks/app";
import IconX from "../../../icons/IconX";
import { CustomButton } from "../../atoms/CustomButton";

type Props = ModalDataItem & {
  isOpen: boolean;
};

export const PopupModal: React.FC<Props> = ({
  isOpen,
  title,
  subTitle,
  showXIcon,
  modalType,
  detailText,
  errorStatusCode,
  closeButtonLabel,
  nextButtonLabel,
  handleClose,
  handleNext,
}) => {
  const dispatch = useDispatch();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[999] bg-black/25 grid place-content-center">
      <div className="w-[577px] max-w-[90vw] relative py-8 rounded-xl bg-white px-[55px] flex items-center flex-col gap-y-11">
        {modalType === "alert" && <AlertIcon />}
        {showXIcon && (
          <div
            className="absolute cursor-pointer p-2.5 right-0 top-0"
            onClick={() => dispatch(appActions.closeModal())}
          >
            <IconX />
          </div>
        )}

        <div className="flex flex-col gap-y-6">
          <div className="text-base whitespace-pre-line text-center font-bold">
            {title}
          </div>

          {subTitle && (
            <div className="text-[14px] whitespace-pre-line text-center font-bold">
              {subTitle}
            </div>
          )}
          {(detailText || errorStatusCode) && (
            <div className="flex flex-col gap-y-5">
              {(detailText || errorStatusCode) && (
                <div className="w-[324px] border-b border-dotted" />
              )}
              {detailText && (
                <div className="whitespace-pre-wrap text-center text-[14px] text-[#666666]">
                  {detailText}
                </div>
              )}
              {errorStatusCode && (
                <div className="text-xs text-[#AAAAAA] text-center">
                  {errorStatusCode}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-wrap justify-center gap-x-3.75">
          {closeButtonLabel && (
            <CustomButton
              onClick={handleClose}
              text={closeButtonLabel}
              className="w-[226px] max-w-[80vw] bg-white border-gray-light border text-black"
            />
          )}
          {nextButtonLabel && (
            <CustomButton
              onClick={handleNext}
              text={nextButtonLabel}
              className="w-[226px] max-w-[80vw] text-white"
            />
          )}
        </div>
      </div>
    </div>
  );
};
