import { useEffect, useMemo, useState } from "react";

type Props<T> = {
  total: number;
  perPage?: number;
  onPageChange?: (page: number) => void;
  fetchData?: (page: number, onSuccess: (data: T[]) => void) => void;
};

export const usePureApiPagination = <T>({
  total,
  perPage = 10,
  onPageChange,
  fetchData,
}: Props<T>) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<T[]>([]);

  const totalPage = useMemo(() => {
    return Math.ceil(total / perPage);
  }, [total]);

  // useEffect(() => {
  //   if (dataToDisplay.length === 0 && page > 1) {
  //     setPage(page - 1);
  //   }
  // }, [dataToDisplay]);

  const onFetchDataSuccess = (data: T[]) => {
    setData(data);
    if (data.length === 0 && page > 1) setPage(page - 1);
  };

  useEffect(() => {
    onPageChange?.(page);

    fetchData?.(page, onFetchDataSuccess);
  }, [page]);

  const onPrevious = () => {
    if (page === 1) return;

    setPage(page - 1);
  };

  const onNext = () => setPage(page + 1);

  const onFirst = () => setPage(1);

  const onLast = () => setPage(totalPage);

  return {
    page,
    setPage,
    totalPage,
    onPrevious,
    onNext,
    onFirst,
    onLast,
    data,
    onFetchDataSuccess,
  };
};
