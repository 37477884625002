import actionCreatorFactory from "typescript-fsa";
import { ColorScale, HeatMapType } from "./types";

const actionCreator = actionCreatorFactory("@@Map");

export const mapActions = {
  setMapStyle: actionCreator<{ style: string }>("SET_MAP_STYLE"),
  setIsDisplayData: actionCreator<{ isDisplay: boolean }>("SET_IS_DISPLAY_DATA"),
  setIsDisplayRoadArea: actionCreator<{ isDisplay: boolean }>("SET_IS_DISPLAY_ROAD_AREA"),
  setMapCenter: actionCreator<[number, number]>("SET_MAP_CENTER"),
  setMapZoom: actionCreator<number>("SET_MAP_ZOOM"),
  resetMapBounds: actionCreator.async<{}, { center: [number, number] }>("RESET_MAP_BOUNDS"),
  setHeatMapDisplay: actionCreator<{ type: HeatMapType }>("SET_HEAT_MAP_DISPLAY"),
  setColorScale: actionCreator<{ scale: ColorScale[] }>("SET_COLOR_SCALE"),
  setMaxValue: actionCreator<{ maxValue: number }>("SET_MAX_VALUE"),
  resetToInitialState: actionCreator("RESET_TO_INITIAL_STATE"),
};
