import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { MAX_SUB_AREA_SELECTION } from "../../../constants/area";
import { PageRoutes } from "../../../constants/routes";
import { Area, AreaStatus } from "../../../ducks/area";
import { useArea } from "../../../hooks/useArea";
import { AnalysisAreaSelectionItem } from "../../molecules/analysis/AnalysisAreaSelectionItem";
import { SubAreaSelectionPopup } from "../../molecules/area/SubAreaSelectionPopup";
import { useAnalysis } from "../../pages/analysis/hook";
import { AreaSelectionMap } from "../map/AreaSelectionMap";

export const SelectAnalysisSubArea = () => {
  const { selectedAnalysisItem, SelectedAnalysisItemIcon } = useAnalysis();
  const {
    allAreas,
    selectedAreas,
    selectedSubAreas,
    newArea,
    selectSubArea,
    removeSubArea,
    handleSelectNewSubArea,
  } = useArea();

  const filteredAreas = useMemo(() => {
    if (selectedAnalysisItem && selectedAnalysisItem.is_multi)
      return allAreas.filter((area) => area.for_multi);
    else return allAreas;
  }, [allAreas, selectedAnalysisItem]);

  if (!selectedAnalysisItem)
    return <Navigate to={PageRoutes.SELECT_ANALYSIS_AREA} />;

  const _renderPopup = (area: Area) => {
    if (area.subAreas.length > 0) {
      const filteredSelectedAreas = selectedSubAreas.filter(
        (a) =>
          a.id !== area.id && !area.subAreas.map((x) => x.id).includes(a.id),
      );

      const selectedSubAreaIds = selectedSubAreas.map((a) => a.id);
      if (
        selectedSubAreaIds.includes(area.id) ||
        area.subAreas.some((a) => selectedSubAreaIds.includes(a.id))
      ) {
        removeSubArea(area);
        area.subAreas.forEach((a) => removeSubArea(a));
      }

      const checkDisabled = (a: Area) => {
        if (
          selectedAreas.length > 0 &&
          a.dataset.group_id !== selectedAreas[0].dataset.group_id
        )
          return true;

        if (selectedAnalysisItem && selectedAnalysisItem.is_multi) {
          return a.status === AreaStatus.Failure || !a.for_multi;
        }

        return (
          a.status === AreaStatus.Failure ||
          filteredSelectedAreas.length >= MAX_SUB_AREA_SELECTION
        );
      };

      return (
        <SubAreaSelectionPopup
          area={area}
          onSelect={(area, selected) => {
            if (selected) removeSubArea(area);
            else selectSubArea({ area });
          }}
          checkDisabled={checkDisabled}
          currentLength={filteredSelectedAreas.length}
          maxLength={MAX_SUB_AREA_SELECTION}
        />
      );
    }

    return <></>;
  };

  return (
    <div className="flex flex-col flex-grow gap-y-5 overflow-hidden">
      <div className="flex items-center rounded-[40px]">
        {SelectedAnalysisItemIcon && <SelectedAnalysisItemIcon />}

        <div className="ml-[5px] text-[16px] font-bold text-primary">
          {selectedAnalysisItem.name}
        </div>
      </div>

      <div className="text-sm">
        主エリアから移動する副エリアを選択してください（{MAX_SUB_AREA_SELECTION}
        個まで選択可）
      </div>
      <div className="flex gap-x-4 min-h-0 h-full">
        <div className="w-[40%] px-4 overflow-y-auto">
          {filteredAreas.map((area, i) => (
            <AnalysisAreaSelectionItem
              key={`select-analysis-item-${area.id}-${i}`}
              area={area}
              selectedAreas={selectedAreas}
              selectedSubAreas={selectedSubAreas}
              selectedAnalysisItem={selectedAnalysisItem}
              onSelectArea={handleSelectNewSubArea}
              isSelectSubArea
            />
          ))}
        </div>

        <AreaSelectionMap
          mode="subarea"
          newArea={newArea}
          renderPopup={_renderPopup}
        />
      </div>
    </div>
  );
};
