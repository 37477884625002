export const isTrue = <T>(value: T): value is NonNullable<T> => {
  return Boolean(value);
};

export function getEnumKeyByEnumValue(
  myEnum: any,
  enumValue: number | string,
): string {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : "";
}
