import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConfig } from "../AppConfig";
import { DEFAULT_ZOOM, ROAD_NETWORK_MAP_MIN_ZOOM } from "../constants/map";
import { LocalStorageManager } from "../core/storage/LocalStorageManager";
import { mapActions, mapSelectors } from "../ducks/map";
import { roadActions } from "../ducks/road";
import { tenantSelectors } from "../ducks/tenant";

export const useMapBounds = (map: any, isRoad?: boolean) => {
  const dispatch = useDispatch();
  const mapCenter = useSelector(mapSelectors.mapCenter);
  const mapZoom = useSelector(mapSelectors.mapZoom);

  const tenants = useSelector(tenantSelectors.tenants);

  const currentTenant = useMemo(() => {
    const tenantId = LocalStorageManager.Instance.getObject<string>(
      AppConfig.Instance.LocalStorageKey["tenantId"],
    );

    return tenants.find((tenant) => tenant.id === Number(tenantId));
  }, [tenants]);

  useEffect(() => {
    if (isRoad) dispatch(roadActions.setPreviousMapBounds(null));
  }, []);

  useEffect(() => {
    if (currentTenant && map.current) {
      const longitude = currentTenant.setting.default_location.longitude;
      const latitude = currentTenant.setting.default_location.latitude;

      dispatch(mapActions.setMapCenter([longitude, latitude]));
      map.current.flyTo({
        center: [longitude, latitude],
        zoom: isRoad ? ROAD_NETWORK_MAP_MIN_ZOOM : DEFAULT_ZOOM,
        maxZoom: isRoad ? ROAD_NETWORK_MAP_MIN_ZOOM : DEFAULT_ZOOM,
        minZoom: isRoad ? ROAD_NETWORK_MAP_MIN_ZOOM : DEFAULT_ZOOM,
        duration: 1000,
      });
    }
  }, [currentTenant]);

  const moveToMapBounds = () => {
    map.current.setCenter(mapCenter);
    if (!isRoad) {
      map.current.setZoom(mapZoom);
    }
  };

  const setOnMapMove = () => {
    map.current.on("move", () => {
      if (map.current) {
        const bounds: [number, number] = [
          map.current.getCenter().lng,
          map.current.getCenter().lat,
        ];

        dispatch(mapActions.setMapCenter(bounds));
        dispatch(mapActions.setMapZoom(map.current.getZoom()));
      }
    });
  };

  return {
    mapCenter,
    mapZoom,
    setOnMapMove,
    moveToMapBounds,
  };
};
