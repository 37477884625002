import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppConfig } from "../AppConfig";
import { PageRoutes } from "../constants/routes";
import { LocalStorageManager } from "../core/storage/LocalStorageManager";
import { appActions } from "../ducks/app";
import { assetActions } from "../ducks/asset";
import { LoginRequestBody, authActions } from "../ducks/auth";
import { tenantSelectors } from "../ducks/tenant";

export const useTenant = ({ emailAddress, password }: LoginRequestBody) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tenants = useSelector(tenantSelectors.tenants);

  useEffect(() => {
    if (emailAddress && password)
      dispatch(
        assetActions.fetchAssets.started({
          tenantOnly: true,
          onSuccess: (result) => {
            if (result && result.tenants && result.tenants.length === 0) {
              dispatch(
                appActions.showModal.started({
                  title: "利用できるプロジェクトがありません",
                  closeButtonLabel: "閉じる",
                  handleClose: () => {
                    navigate(PageRoutes.LOGIN, { replace: true });
                    dispatch(appActions.closeModal());
                  },
                }),
              );
            }
          },
        }),
      );
  }, []);

  useEffect(() => {
    if (tenants.length === 1) {
      onSelectTenant(tenants[0].id);
    }
  }, [tenants]);

  const onSelectTenant = (tenantId: number) => {
    if (!emailAddress && !password) return;

    dispatch(
      authActions.setToken.started({
        tenantId,
        emailAddress,
        password,
        onSuccess: () => {
          navigate(PageRoutes.INDEX, { replace: true });
          LocalStorageManager.Instance.saveObject(
            AppConfig.Instance.LocalStorageKey["tenantId"],
            tenantId,
          );
        },
      }),
    );
  };

  return { tenants, onSelectTenant };
};
