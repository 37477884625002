// @ts-ignore
import { MapboxOverlay } from "@deck.gl/mapbox";
//@ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useSelector } from "react-redux";
import { STANDARD_MAP_STYLE } from "../../../constants/map";
import { analysisSelectors } from "../../../ducks/analysis";
import {
  Area,
  AreaCoordinates,
  AreaType,
  CreateAreaMode,
} from "../../../ducks/area";
import { HeatMapType } from "../../../ducks/map";
import { useArea } from "../../../hooks/useArea";
import { useCarto } from "../../../hooks/useCarto";
import { useMapBounds } from "../../../hooks/useMapBounds";
import { useMapControl } from "../../../hooks/useMapControl";
import { useMapSetting } from "../../../hooks/useMapSetting";
import { useSideNavigation } from "../../../hooks/useSideNavigation";
import { getMaxLatLngBounds } from "../../../utils/geojson";
import {
  displayRoadLineStringOnMap,
  paintMapRoadLayers,
} from "../../../utils/linestring";
import {
  displayNewCircleOnMap,
  displayNewPolygonOnMap,
} from "../../../utils/polygon";
import { CustomButton } from "../../atoms/CustomButton";
import { CreateAreaModal } from "../modal/CreateAreaModal";
import { MapSettings } from "./MapSetting";

type Props = {
  newArea?: Area | null;
  callback?: (area: Area) => void;
  renderPopup?: (area: Area) => JSX.Element;
  renderRoadPopup?: (roads: Area[]) => JSX.Element;
  onClosePopup?: () => void;
};

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN ?? "";

export const RoadSelectionMap: React.FC<Props> = ({
  newArea,
  callback,
  renderPopup,
  renderRoadPopup,
  onClosePopup,
}) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const { mapCenter, mapZoom, setOnMapMove } = useMapBounds(map);

  const [openModal, setOpenModal] = useState(false);

  const {
    roadNetworks,
    selectedAreas,
    selectedRoads,
    selectRoad,
    selectRoadByRoadNetworkId,
    showRoadPopup,
    areaLngLat,
    currentRoadSelections,
    handleSetCurrentRoadSelections,
    onClickRoadLayer,
  } = useArea();

  const { sideMenuExpanded } = useSideNavigation();

  useEffect(() => {
    map.current?.resize();
  }, [sideMenuExpanded]);

  const {
    geocoderControl,
    navigationControl,
    languageControl,
    dataDisplayControl1,
    heatMapDisplayControl1,
    mapTypeControl1,
  } = useMapControl();

  const mapSetting = useMapSetting();
  const {
    isDisplayData,
    setIsDisplayData,
    isDisplayRoadArea,
    setIsDisplayRoadArea,
    heatMapDisplay,
    setHeatMapDisplay,
    mapStyle,
  } = mapSetting;

  const selectedAnalysisItem = useSelector(
    analysisSelectors.selectedAnalysisItem,
  );

  const { cartoLayer } = useCarto(
    selectedAnalysisItem?.is_multi ? "6_1" : "6_2",
    true,
    mapSetting,
  );

  const layers = useMemo(() => [cartoLayer], [cartoLayer]);

  const [mapboxOverlay, setMapboxOverlay] = useState<MapboxOverlay>(
    new MapboxOverlay({ layers, interleaved: true }),
  );

  const [hasAddedControls, setHasAddedControls] = useState<boolean>(false);

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: STANDARD_MAP_STYLE,
        center: mapCenter,
        zoom: mapZoom,
        attributionControl: false,
      });
    }

    map.current
      .addControl(new mapboxgl.AttributionControl({ compact: false }))
      .addControl(geocoderControl)
      .addControl(navigationControl, "bottom-right")
      .addControl(languageControl);

    map.current.addControl(mapTypeControl1, "top-right");
    map.current.addControl(dataDisplayControl1, "top-right");

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    setHasAddedControls(true);

    setOnMapMove();

    return () => {
      setIsDisplayData(true);
      setIsDisplayRoadArea(true);
      setHeatMapDisplay(HeatMapType.Both);
    };
  }, []);

  useEffect(() => {
    if (hasAddedControls) map.current.removeControl(heatMapDisplayControl1);

    map.current.addControl(heatMapDisplayControl1, "top-right");
  }, [heatMapDisplay]);

  const _renderRoadPopup = (roads: Area[]) => {
    if (!renderRoadPopup) return;

    try {
      const popup = document.createElement("div");
      const root = createRoot(popup);
      root.render(renderRoadPopup(roads));
      const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
        .setLngLat(areaLngLat)
        .setDOMContent(popup)
        .addTo(map.current);

      mapboxPopup.on("close", () => {
        onClosePopup?.();
      });
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    const roads = currentRoadSelections;
    handleSetCurrentRoadSelections([]);

    if (showRoadPopup) {
      if (map.current && renderRoadPopup) {
        if (roads.length === 1) {
          selectRoad({ area: roads[0], removeIfSelected: true });
        } else _renderRoadPopup(roads);
      }
    }
  }, [showRoadPopup]);

  useEffect(() => {
    if (!map.current) return;

    map.current.setStyle(mapStyle);
  }, [mapStyle]);

  useEffect(() => {
    const maxBounds = displayRoadLineStringOnMap(
      map,
      roadNetworks,
      undefined,
      onClickRoadLayer,
      selectRoadByRoadNetworkId,
      true,
      !isDisplayRoadArea,
    );

    const displaySelectedArea = () => {
      selectedAreas.forEach((area: Area, index: number) => {
        if (area.area.type === AreaType.Circle)
          // @ts-ignore
          displayNewCircleOnMap(map, area.area.data, index);
        else displayNewPolygonOnMap(map, area.area.data, index);
      });
    };

    try {
      displaySelectedArea();
    } catch (e) {
      map.current.on("load", () => displaySelectedArea());
    }

    if (maxBounds && !hasAddedControls) {
      const bounds = new mapboxgl.LngLatBounds(maxBounds);
      map.current.fitBounds(bounds);
    }

    map.current.on("style.load", () => {
      displayRoadLineStringOnMap(
        map,
        roadNetworks,
        undefined,
        onClickRoadLayer,
        selectRoadByRoadNetworkId,
        true,
        !isDisplayRoadArea,
      );

      displaySelectedArea();
    });
  }, [roadNetworks, isDisplayRoadArea]);

  useEffect(() => {
    setTimeout(() => {
      if (selectedAreas.length > 0) {
        let allLatitudes: number[] = [];
        let allLongitudes: number[] = [];

        selectedAreas.forEach((area) => {
          area.area.data.forEach((data) => {
            //@ts-ignore
            allLatitudes.push(data.latitude);
            //@ts-ignore
            allLongitudes.push(data.longitude);
            //@ts-ignore
            return [data.longitude, data.latitude];
          });
        });

        const bounds = getMaxLatLngBounds(allLatitudes, allLongitudes, 0.001);
        map.current.fitBounds(bounds);
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (!map.current) return;

    if (!isDisplayRoadArea) return;

    const selectedRoadNetworks = roadNetworks.filter((road) =>
      selectedRoads.flatMap((road) => road.roadIds).includes(road.id),
    );

    try {
      paintMapRoadLayers(map, roadNetworks, selectedRoadNetworks, true);
    } catch (e) {
      map.current.on("load", () => {
        paintMapRoadLayers(map, roadNetworks, selectedRoadNetworks, true);
      });
    }

    map.current.on("style.load", () => {
      paintMapRoadLayers(map, roadNetworks, selectedRoadNetworks, true);
    });
  }, [isDisplayRoadArea, roadNetworks, selectedRoads]);

  const updateMapboxOverlay = (callback?: (newMap: MapboxOverlay) => void) => {
    mapboxOverlay.finalize();
    const newMap = new MapboxOverlay({
      layers,
      interleaved: true,
    });
    setMapboxOverlay(newMap);

    callback?.(newMap);
    return newMap;
  };

  useEffect(() => {
    return () => updateMapboxOverlay();
  }, []);

  useEffect(() => {
    const newMap = updateMapboxOverlay();

    if (isDisplayData) map.current.addControl(newMap);
  }, [layers, isDisplayData, heatMapDisplay]);

  useEffect(() => {
    if (!newArea) return;

    const isMultiPolygon =
      // @ts-ignore
      !newArea.area.data[0].latitude && !newArea.area.data[0].longitude;

    let allLatitudes: number[] = [];
    let allLongitudes: number[] = [];

    if (isMultiPolygon) {
      const coordinates = newArea.area.data as AreaCoordinates[][];

      coordinates.forEach((coords) => {
        coords.forEach((coord) => {
          allLatitudes.push(coord.latitude);
          allLongitudes.push(coord.longitude);
        });
      });
    } else {
      const coordinates = newArea.area.data as AreaCoordinates[];
      coordinates.forEach((coords) => {
        allLatitudes.push(coords.latitude);
        allLongitudes.push(coords.longitude);
      });
    }

    const maxBounds = getMaxLatLngBounds(allLatitudes, allLongitudes, 0.001);
    if (!maxBounds) return;
  }, [newArea]);

  return (
    <div className="w-full">
      <div
        ref={mapContainer}
        className="w-full h-full"
        onContextMenu={(e) => e.preventDefault()}
      />
      <MapSettings index={1} />

      <div className="relative h-0 -top-[99%] left-2">
        <CustomButton
          text="分析道路を追加"
          className="px-6.25 py-[9px] text-sm"
          onClick={() => setOpenModal(true)}
        />
      </div>

      <CreateAreaModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        preSelectedMode={CreateAreaMode.RoadArea}
      />
    </div>
  );
};
