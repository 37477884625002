import { Feature } from "geojson";

export interface HtmlForFeature {
  title: string;
  feature: Feature;
}

export default function htmlForFeature({ title, feature }: HtmlForFeature) {
  if (!feature) {
    throw new Error(`htmlForFeature needs "info.object" information`);
  }

  //@ts-ignore
  const propertyNames: Array<string> = Object.keys(feature.properties);

  let html = "";

  if (title) {
    html = `<h3 style="margin: 0"><strong>${title}</strong></h3>`;
  }

  for (const name of propertyNames) {
    html = generateHtml(feature, name, html);
  }

  return html;
}

function generateHtml(
  feature: Feature,
  propertyName: string,
  html: string,
  formatterFunction = (v: number) => v.toString(),
): string {
  return html.concat(
    `<strong>${propertyName}</strong>: ${formatterFunction(
      feature.properties ? feature.properties[propertyName] : "",
    )}<br/>`,
  );
}
