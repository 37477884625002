type Props = {
  canGoBack: boolean;
  canGoNext: boolean;
  handleBack: () => void;
  handleNext: () => void;
};
export const AnalysisActionButton: React.FC<Props> = ({
  canGoBack,
  canGoNext,
  handleBack,
  handleNext,
}) => {
  return (
    <div className="flex justify-end items-center gap-x-2.5 mx-[-10px] px-5 py-5.5 bg-[#EEEEEE]">
      {canGoBack && (
        <button
          className="w-[200px] h-10 rounded border border-gray-light bg-white"
          onClick={handleBack}
        >
          戻る
        </button>
      )}

      <button
        disabled={!canGoNext}
        className="w-[200px] h-10 rounded text-white bg-primary disabled:bg-gray-border disabled:cursor-not-allowed"
        onClick={handleNext}
      >
        次へ
      </button>
    </div>
  );
};
