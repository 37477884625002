export const URL_QUERY_PARAM_TYPE = "type";
export const URL_QUERY_PARAM_AREA = "area";
export const URL_QUERY_PARAM_SUB_AREA = "subarea";
export const URL_QUERY_PARAM_ROAD = "road";
export const URL_QUERY_PARAM_FILTERED_AREA = "filtered_area";
export const URL_QUERY_PARAM_FILTERED_ROAD = "filtered_road";
export const URL_QUERY_PARAM_FROM = "from";
export const URL_QUERY_PARAM_TO = "to";
export const URL_QUERY_PARAM_TIME = "time";
export const URL_QUERY_PARAM_FILTER = "filter";
export const URL_QUERY_PARAM_ANALYZE_ID = "analyze_id";
export const URL_QUERY_PARAM_RECEPTION_ID = "reception_id";
export const URL_QUERY_PARAM_HISTORY_ID = "history_id";
export const URL_QUERY_PARAM_IS_VISIBLE = "is_visible";
export const URL_QUERY_PARAM_SEND_EMAIL = "send_email";
