import { Navigate } from "react-router-dom";
import { MAX_ROAD_SELECTION } from "../../../constants/area";
import { PageRoutes } from "../../../constants/routes";
import { AnalysisPattern } from "../../../ducks/analysis";
import { Area, AreaStatus } from "../../../ducks/area";
import { useArea } from "../../../hooks/useArea";
import { AnalysisAreaSelectionItem } from "../../molecules/analysis/AnalysisAreaSelectionItem";
import { RoadSelectionPopup } from "../../molecules/area/RoadSelectionPopup";
import { useAnalysis } from "../../pages/analysis/hook";
import { AreaSelectionMap } from "../map/AreaSelectionMap";
import { RoadSelectionMap } from "../map/RoadSelectionMap";

export const SelectAnalysisRoad = () => {
  const { selectedAnalysisItem, SelectedAnalysisItemIcon } = useAnalysis();
  const {
    roads,
    selectedSubAreas,
    selectedRoads,
    newArea,
    selectRoad,
    removeRoad,
    handleSelectNewArea,
  } = useArea();

  const _renderRoadPopup = (roads: Area[]) => {
    if (roads.length > 0) {
      const selectedRoadIds = selectedRoads.map((a) => a.id);
      roads.forEach((road) => {
        if (selectedRoadIds.includes(road.id)) {
          removeRoad(road);
        }
      });

      const checkDisabled = (road: Area) => {
        return (
          road.status === AreaStatus.Failure ||
          selectedRoads.length >= MAX_ROAD_SELECTION
        );
      };

      return (
        <RoadSelectionPopup
          roads={roads}
          onSelect={(area, selected) => {
            if (selected) removeRoad(area);
            else selectRoad({ area });
          }}
          checkDisabled={checkDisabled}
          currentLength={selectedRoads.length}
          maxLength={MAX_ROAD_SELECTION}
        />
      );
    }

    return <></>;
  };

  if (!selectedAnalysisItem)
    return <Navigate to={PageRoutes.ANALYSIS_EXECUTE} />;

  return (
    <div className="flex flex-col flex-grow gap-y-5 overflow-hidden">
      <div className="flex items-center rounded-[40px]">
        {SelectedAnalysisItemIcon && <SelectedAnalysisItemIcon />}

        <div className="ml-[5px] text-[16px] font-bold text-primary">
          {selectedAnalysisItem.name}
        </div>
      </div>

      <div className="text-sm">
        エリアからの移動経路を選択してください （{MAX_ROAD_SELECTION}
        個まで選択可）
      </div>

      <div className="flex gap-x-4 min-h-0 h-full">
        <div className="w-[40%] overflow-y-auto px-4">
          {roads.map((area, i) => (
            <AnalysisAreaSelectionItem
              key={`select-analysis-item-${area.id}-${i}`}
              area={area}
              selectedAreas={selectedRoads}
              selectedSubAreas={selectedSubAreas}
              selectedAnalysisItem={selectedAnalysisItem}
              onSelectArea={handleSelectNewArea}
              isRoad
            />
          ))}
        </div>

        {selectedAnalysisItem &&
          selectedAnalysisItem.pattern === AnalysisPattern.SelectRoad && (
            <AreaSelectionMap
              mode="road"
              newArea={newArea}
              renderRoadPopup={_renderRoadPopup}
            />
          )}

        {selectedAnalysisItem &&
          selectedAnalysisItem.pattern ===
            AnalysisPattern.SelectAreaAndRoad && (
            <RoadSelectionMap
              newArea={newArea}
              renderRoadPopup={_renderRoadPopup}
            />
          )}
      </div>
    </div>
  );
};
