import {
  FormControlLabel,
  Radio,
  ThemeProvider,
  createTheme,
} from "@mui/material";

type Props = {
  label: string;
  value: any;
  size?: "small" | "medium";
};

const { palette } = createTheme();
const theme = createTheme({
  palette: {
    primary: palette.augmentColor({ color: { main: "#334A7C" } }),
  },
});

export const LabelRadioButton: React.FC<Props> = ({
  label,
  value,
  size = "small",
}) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        classes={{
          label: "!text-[14px] !leading-[14px]",
        }}
        label={label}
        value={value}
        control={<Radio size={size} color="primary" />}
      />
    </ThemeProvider>
  );
};
