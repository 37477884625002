import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { Asset } from "../../core/api";

const actionCreator = actionCreatorFactory("@@Asset");

export const assetActions = {
  fetchStaticAssets: actionCreator.async<{}, {}>("FETCH_STATIC_ASSETS"),
  fetchAssets: actionCreator.async<CommonAsyncType<Asset> & { tenantOnly?: boolean }, { asset: any }>("FETCH_ASSETS"),
  fetchMessages: actionCreator.async<{}, {}>("FETCH_MESSAGES"),
  resetToInitialState: actionCreator("RESET_TO_INITIAL_STATE"),
};
