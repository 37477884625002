export interface MapState {
  mapStyle: string;
  isDisplayData: boolean;
  isDisplayRoadArea: boolean;
  mapCenter: [number, number];
  mapZoom: number;
  heatMapDisplay: HeatMapType;
  colorScale: ColorScale[];
  maxValue: number;
}

export interface ColorScale {
  from: number;
  to: number;
}

export enum HeatMapType {
  Hidden = "hidden",
  Walking = "walking",
  Car = "car",
  Both = "both",
}
