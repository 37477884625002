import { useState } from "react";
import { useDispatch } from "react-redux";
import { appActions } from "../ducks/app";
import { City, cityActions } from "../ducks/city";

export const useCity = () => {
  const dispatch = useDispatch();

  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const searchCities = (keyword: string) => {
    dispatch(appActions.showLoading());
    dispatch(
      cityActions.fetchTowns.started({
        keyword,
        onComplete: () => dispatch(appActions.hideLoading()),
        onSuccess: (res) => setCities(res),
      }),
    );
  };

  const onInput = (keyword: string) => {
    setSelectedCity(null);
    if (keyword.length === 0) setCities([]);
    else searchCities(keyword);
  };

  return { cities, selectedCity, setSelectedCity, searchCities, onInput };
};
