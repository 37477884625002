import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { tenantActions } from "./actions";
import { TenantState } from "./types";

const initialState: TenantState = {
  tenants: [],
};

export function TenantReducer(
  state = initialState,
  action: Action,
): TenantState {
  if (isType(action, tenantActions.fetchTenants.done)) {
    return {
      ...state,
      tenants: action.payload.result.tenants,
    };
  }

  if (isType(action, tenantActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
