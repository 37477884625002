import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { analysisActions } from "../../../ducks/analysis";
import { mapActions } from "../../../ducks/map";
import { AnalysisActionButton } from "../../molecules/analysis/AnalysisActionButton";
import { PageHeader } from "../../organisms/PageHeader";
import { AnalysisLoadingModal } from "../../organisms/modal/AnalysisLoadingModal";
import { SaveSelectionPopup } from "../../organisms/modal/SaveSelectionPopup";
import { useAnalysis } from "./hook";

export const Analysis: React.FC = () => {
  const dispatch = useDispatch();

  const {
    currentStep,
    canGoBack,
    canGoNext,
    handleNext,
    handleBack,
    analysisSteps,
    saveSelectionPopupOpen,
    analysisLoadingModal,
    isVisible,
    setIsVisible,
    handleClickSaveSelection,
    handleCloseAnalysisLoadingModal,
    closeSaveSelectionPopup,
    closeAnalysisLoadingModal,
  } = useAnalysis();

  useEffect(() => {
    dispatch(mapActions.resetMapBounds.started({}));

    dispatch(analysisActions.resetCurrentStep());
    dispatch(analysisActions.clearSelectedAnalysisItem());

    return () => {
      dispatch(analysisActions.resetCurrentStep());
      dispatch(analysisActions.clearSelectedAnalysisItem());
    };
  }, []);

  return (
    <div className="flex flex-col w-full h-full box-border px-2.5 pb-2.5">
      <div>
        <PageHeader currentStep={currentStep} analysisSteps={analysisSteps} />
      </div>

      <div className="flex flex-col flex-1 px-5 py-6 rounded bg-white overflow-hidden">
        <Outlet />
      </div>

      <AnalysisActionButton
        canGoBack={canGoBack}
        canGoNext={canGoNext}
        handleBack={handleBack}
        handleNext={handleNext}
      />

      <SaveSelectionPopup
        isOpen={saveSelectionPopupOpen}
        isSave={isVisible}
        setIsSave={setIsVisible}
        handleNext={handleClickSaveSelection}
        handleClose={closeSaveSelectionPopup}
      />

      <AnalysisLoadingModal
        isOpen={analysisLoadingModal}
        handleNext={handleCloseAnalysisLoadingModal}
        handleClose={closeAnalysisLoadingModal}
      />
    </div>
  );
};
