import React, { useEffect, useMemo, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import Modal from "react-modal";
import { z } from "zod";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { AreaCoordinates, CreateAreaMode } from "../../../ducks/area";
import { useCreateArea } from "../../../hooks/useCreateArea";
import { useMapDrawCircle } from "../../../hooks/useMapDrawCircle";
import { useRoad } from "../../../hooks/useRoad";
import { CustomButton } from "../../atoms/CustomButton";
import { Input } from "../../atoms/Input";
import { CitySearchBox } from "../../molecules/CitySearchBox";
import { CustomSelection } from "../../molecules/CustomSelection";
import FormTemplate from "../../templates/FormTemplate";
import { AreaMap } from "../map/AreaMap";
import { CircleMap } from "../map/CircleMap";
import { PolygonMap } from "../map/PolygonMap";
import { PolygonRoadMap } from "../map/PolygonRoadMap";
import { CreateAreaNameModal } from "./CreateAreaNameModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  preSelectedMode?: CreateAreaMode;
};

export const CreateAreaModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  preSelectedMode,
}) => {
  const {
    createAreaNameModalOpen,
    currentMode,
    coordinates,
    selectedArea,
    selectedCity,
    newPolygon,
    setSelectedCity,
    setNewPolygon,
    handleChangeMode,
    handleCallback,
    handleAreaCallback,
    handleRoadCallback,
    onChangeRadius,
    onClickNext,
    onClose,
    onSubmit,
    disableSubmitButton,
    helperText,
  } = useCreateArea(handleClose, preSelectedMode);

  const mapDrawCircleControls = useMapDrawCircle();
  const { radius, setRadius, resetAll } = mapDrawCircleControls;
  const roadControls = useRoad(handleRoadCallback, false);

  useEffect(() => onChangeRadius(radius * 1000), [radius]);

  const modalStyle = {
    content: {
      width: "80%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      zIndex: 20,
      backgroundColor: "transparent",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 120,
    },
  };

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const handleClickClose = () => {
    handleClose();
    handleChangeMode(
      preSelectedMode ?? CreateAreaMode.Polygon,
      preSelectedMode ?? CreateAreaMode.Polygon,
    );
    resetAll();
    roadControls.resetAll();
  };

  const [methods, setMethods] = useState<UseFormReturn<
    FieldValues,
    any,
    undefined
  > | null>(null);

  const schema = z
    .object({
      createAreaMode: z.string(),
      radius: z.string().or(z.number().positive()),
      city: z.string(),
      cityKeyword: z.string(),
    })
    .refine((obj) => {
      if (obj.createAreaMode === "Circle")
        return (
          obj.radius && !isNaN(Number(obj.radius)) && Number(obj.radius) > 0
        );

      if (obj.createAreaMode === "Address")
        return obj.city.length > 0 && obj.cityKeyword.length > 0;

      return true;
    });

  const extraInputs = useMemo(() => {
    switch (currentMode) {
      case CreateAreaMode.Circle:
        return (
          <>
            <div className="flex items-center text-[14px] mt-6">
              <div>円エリアのサイズを指定</div>
              <div className="ml-5">半径</div>
              <div className="w-[60px] mx-2">
                <Input
                  name="radius"
                  type="number"
                  size="sm"
                  step={0.01}
                  className="w-full"
                  hideErrorMessage
                />
              </div>
              km
            </div>
            <div className="flex justify-center items-center">
              <CustomButton
                type="submit"
                text="半径を指定"
                className="flex justify-center items-center w-[200px] h-[40px] text-white text-[14px] mt-6"
              />
            </div>
          </>
        );
      case CreateAreaMode.Address:
        return (
          <div className="mt-6 justify-between">
            <CitySearchBox
              onSelectCity={(city) => {
                methods?.setValue("city", city.city_name, {
                  shouldValidate: true,
                });
                methods?.setValue("cityKeyword", city.city_name, {
                  shouldValidate: true,
                });
                setSelectedCity(city);
                setNewPolygon(null);
                handleCallback([]);
              }}
              onCityInput={(keyword) => {
                if (keyword.length === 0)
                  methods?.setValue("city", "", { shouldValidate: true });
              }}
            />
          </div>
        );
      case CreateAreaMode.RoadArea:
        return (
          <div className="flex justify-center items-center">
            <CustomButton
              text="選択した範囲で道路を選択"
              className="flex justify-center items-center w-[250px] h-[40px] text-white text-[14px] mt-6"
              onClick={() =>
                roadControls.searchRoad(
                  null,
                  coordinates as AreaCoordinates[],
                  "select",
                )
              }
              disabled={coordinates.length === 0}
            />
          </div>
        );
      default:
        return <></>;
    }
  }, [currentMode, coordinates, radius, methods]);

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle}
      shouldReturnFocusAfterClose={true}
      portalClassName="alert-modal"
    >
      <div className="flex flex-col w-full h-[700px] bg-white rounded-lg pt-[25px]">
        <div className="flex-auto flex flex-col h-[548px] px-[40px]">
          <div className="flex justify-between items-center">
            <div className="font-bold">分析エリア・道路の新規追加</div>
            <div
              onClick={handleClickClose}
              className="w-[32px] h-[32px] flex justify-end items-center cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex flex-1 mt-[15px]">
            <div className="w-[30%] max-w-[300px] overflow-y-auto h-[548px]">
              <div>
                <FormTemplate
                  setMethods={setMethods}
                  defaultValues={{ radius, city: "", cityKeyword: "" }}
                  schema={schema}
                  onSubmit={(data) => {
                    if (data.createAreaMode === "Circle") {
                      setRadius(Number(Number(data.radius).toFixed(2)));
                    }

                    if (data.createAreaMode === "Address") {
                      if (selectedCity && selectedCity.data)
                        setNewPolygon(selectedCity.data);
                    }
                  }}
                  className="w-full"
                >
                  <CustomSelection
                    defaultValue={currentMode}
                    name="createAreaMode"
                    placeholder="追加方法を選択してください"
                    onChange={handleChangeMode}
                    options={Object.entries(CreateAreaMode).map(
                      ([type, placeholder], i) => ({
                        id: i + 1,
                        name: placeholder,
                        value: type,
                      }),
                    )}
                    disabled={!!preSelectedMode}
                  />
                  {helperText && typeof helperText === "string" && (
                    <div className="mt-[14px] bg-[#F4F4F4] rounded-[4px] text-[#334A7C] text-[13px] py-[10px] px-[13px]">
                      {helperText}
                    </div>
                  )}
                  {helperText && typeof helperText === "object" && (
                    <>
                      <div className="mt-[14px] bg-[#F4F4F4] rounded-[4px] text-[#334A7C] text-[13px] py-[10px] px-[13px]">
                        {helperText[0]}
                      </div>
                      <div className="mt-[14px] pt-[14px] border-t-2 border-dotted text-[12px]">
                        または、
                      </div>
                      <div className="mt-[14px] bg-[#F4F4F4] rounded-[4px] text-[#334A7C] text-[13px] py-[10px] px-[13px]">
                        {helperText[1]}
                      </div>
                    </>
                  )}
                  {extraInputs}
                </FormTemplate>
              </div>
            </div>

            <div className="flex-auto ml-[36px] h-[548px]">
              {currentMode === CreateAreaMode.Polygon && (
                <PolygonMap callback={handleCallback} />
              )}
              {currentMode === CreateAreaMode.Circle && (
                <CircleMap
                  mapDrawCircleControls={mapDrawCircleControls}
                  callback={handleCallback}
                />
              )}
              {currentMode === CreateAreaMode.Address && (
                <AreaMap
                  mode="display"
                  polygonCallback={handleCallback}
                  newPolygon={newPolygon}
                />
              )}
              {currentMode === CreateAreaMode.RoadArea && (
                <PolygonRoadMap
                  roadControls={roadControls}
                  callback={handleCallback}
                  roadCallback={handleRoadCallback}
                  showSelectedArea={!!preSelectedMode}
                />
              )}
              {currentMode === CreateAreaMode.MultiPolygon && (
                <AreaMap
                  mode="create"
                  callback={handleAreaCallback}
                  polygonCallback={handleCallback}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center w-full h-[88px]">
          <CustomButton
            buttonStyle="border"
            text="キャンセル"
            className="flex justify-center items-center w-[200px] h-[40px] mx-[8px] text-black border-gray-light"
            onClick={handleClickClose}
          />
          <CustomButton
            disabled={disableSubmitButton}
            text="エリア設定"
            className="flex justify-center items-center w-[200px] h-[40px] mx-[8px] font-medium"
            onClick={onClickNext}
          />
        </div>

        <CreateAreaNameModal
          isOpen={createAreaNameModalOpen}
          handleClose={onClose}
          handleSubmit={onSubmit}
          isEdit={false}
          isRoad={currentMode === CreateAreaMode.RoadArea}
          selectedArea={selectedArea}
        />
      </div>
    </Modal>
  );
};
