export interface FilterState {
  filters: FilterList[];
}

export interface FilterList {
  type: number;
  initial: string;
  filters: Filter[];
}

export interface Filter {
  name: string;
  type: string;
  showAll: boolean;
  values: {
    name: string;
    value: string;
    bit: number;
    checked?: boolean;
  }[];
}

export enum FilterType {
  Equal = "equal",
  Diff = "diff",
  Date = "date",
  Time = "time",
  Checkbox = "checkbox",
  Radio = "radio",
  Area = "area",
  SubArea = "subarea",
  Road = "road",
  CompArea = "comp_area",
}
