import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { MAP_DATA_DISPLAY_OPTIONS } from "../../../constants/map";
import { useMapSetting } from "../../../hooks/useMapSetting";
import IconDataDisplayControl from "../../../icons/Map/IconDataDisplayControl";
import { DATA_DISPLAY_CONTROL_ID } from "../../../utils/map";

type Props = {
  index: number;
};

export const DataDisplayControl: React.FC<Props> = ({ index }) => {
  const { isDisplayRoadArea, setIsDisplayRoadArea } = useMapSetting();

  const handleChange = (isDisplay: boolean) => {
    setIsDisplayRoadArea(isDisplay);

    const labelElement = document.getElementById(
      `${DATA_DISPLAY_CONTROL_ID}-${index}-label`,
    );

    if (labelElement)
      labelElement.innerText = isDisplay ? "道路表示" : "道路非表示";
  };

  return (
    <div id={`${DATA_DISPLAY_CONTROL_ID}-${index}`} style={{ display: "none" }}>
      <div
        id={`${DATA_DISPLAY_CONTROL_ID}-${index}-content`}
        className="flex items-center cursor-pointer bg-white rounded-[10px] shadow-md text-[12px]"
      >
        <Dropdown>
          <MenuButton
            id={`${DATA_DISPLAY_CONTROL_ID}-${index}-btn`}
            sx={{
              fontSize: "12px",
              color: "#000000",
              border: 0,
              padding: "0 12px",
              margin: 0,
            }}
          >
            <div className="flex items-center gap-x-2">
              <IconDataDisplayControl />
              <span
                id={`${DATA_DISPLAY_CONTROL_ID}-${index}-label`}
                className="font-normal text-sm"
              >
                {isDisplayRoadArea ? "道路表示" : "道路非表示"}
              </span>
            </div>
          </MenuButton>

          <Menu
            id={`${DATA_DISPLAY_CONTROL_ID}-menu`}
            anchorEl={document.getElementById(
              `${DATA_DISPLAY_CONTROL_ID}-${index}-content`,
            )}
            placement="bottom-end"
            size="sm"
            sx={{ padding: 0, margin: 0, borderRadius: "10px" }}
          >
            <MenuItem
              id={`${DATA_DISPLAY_CONTROL_ID}-${index}-menu-item`}
              style={{
                backgroundColor: "transparent",
                minHeight: 0,
                pointerEvents: "none",
                padding: 0,
                margin: 0,
              }}
            ></MenuItem>
            {MAP_DATA_DISPLAY_OPTIONS.map((option) => {
              return (
                <MenuItem
                  key={option.id}
                  sx={{ padding: 0, margin: 0, borderRadius: 0 }}
                  onClick={() => handleChange(option.value)}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#E8F0FE")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#ffffff")
                  }
                >
                  <span className="px-4.5">{option.name}</span>
                </MenuItem>
              );
            })}
          </Menu>
        </Dropdown>
      </div>
    </div>
  );
};
