import { IconProps } from "../types";

export default function IconResidenceMini({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16.59"
      viewBox="0 0 18 16.59"
    >
      <path
        id="Path_860"
        data-name="Path 860"
        d="M9.073,13.44H3.19V7.066H16.153a.833.833,0,0,0,.646-.3.782.782,0,0,0,.161-.679L15.682.625A.822.822,0,0,0,14.876,0H2.1A.822.822,0,0,0,1.3.625L.02,6.085a.788.788,0,0,0,.161.679.836.836,0,0,0,.646.3h.711v7.177a.814.814,0,0,0,.826.8h6.71a.8.8,0,1,0,0-1.606M2.763,1.605H14.216l.9,3.855H1.861ZM14.529,8.16a3.4,3.4,0,0,0-3.471,3.456q0,2.1,3.471,4.974Q18,13.719,18,11.616A3.4,3.4,0,0,0,14.529,8.16m0,4.612a1.3,1.3,0,1,1,1.337-1.3,1.32,1.32,0,0,1-1.337,1.3"
        transform="translate(0)"
        fill="#334a7c"
      />
    </svg>
  );
}
