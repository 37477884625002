import { mergeClassNames } from "../../../utils/style";

type Props = {
  label: string;
  step: number;
  currentStep: number;
};

export const AnalysisStepLabel: React.FC<Props> = ({
  label,
  step,
  currentStep,
}) => {
  return (
    <div className="flex flex-wrap items-center">
      {step > 1 && (
        <span className="inline-block align-top w-[15px] mr-[15px] border-t-[1px] border-[#999999]"></span>
      )}

      <p className="py-2 pr-[18px]">
        <span
          className={mergeClassNames(
            `inline-block w-[16px] h-[16px] mr-[6px] align-top rounded-full font-bold text-[#ffffff] text-[10px] text-center`,
            currentStep === step ? "bg-black" : "bg-[#999999]",
          )}
        >
          {step}
        </span>
        <span
          className={mergeClassNames(
            "text-[14px] align-top",
            currentStep === step ? "text-black" : "text-[#999999]",
          )}
        >
          {label}
        </span>
      </p>
    </div>
  );
};
