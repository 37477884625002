import { useCartoLayerProps } from "@carto/react-api";
import {
  addLayer,
  addSource,
  removeLayer,
  removeSource,
} from "@carto/react-redux";
// @ts-ignore
import { CartoLayer, MAP_TYPES } from "@deck.gl/carto";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROAD_HEAT_MAP_COLOR } from "../constants/carto";
import { FilterBits } from "../constants/filter";
import { appActions } from "../ducks/app";
import { areaSelectors } from "../ducks/area";
import { CartoData, cartoSelectors } from "../ducks/carto";
import { FilterType } from "../ducks/filter";
import { HeatMapType } from "../ducks/map";
import { hexToRgb } from "../utils/color";
import htmlForFeature from "../utils/html";
import { MapSetting } from "./useMapSetting";

let currentMaxValue = 0;

export const useCarto = (
  id: string,
  isRoad: boolean,
  mapSetting: MapSetting,
) => {
  const dispatch = useDispatch();

  const cartoData = useSelector(cartoSelectors.carto);
  const oldCurrentCartoData = useMemo(() => {
    return cartoData.find((data) => data.id === id && data.query !== undefined);
  }, [cartoData]);

  const [currentCartoData, setCurrentCartoData] = useState<CartoData | null>(
    null,
  );

  useEffect(() => {
    if (oldCurrentCartoData) {
      setTimeout(() => {
        setCurrentCartoData(oldCurrentCartoData);
      }, 250);
    }
  }, [oldCurrentCartoData]);

  const { isDisplayData, heatMapDisplay, colorScale, setMaxValue } = mapSetting;

  const selectedAreas = useSelector(areaSelectors.selectedAreas);

  const sqlQueryCondition = useMemo(() => {
    if (!currentCartoData || !currentCartoData.filterColumns) return "";

    const tableName = currentCartoData.table;

    const condition = currentCartoData.filterColumns
      .filter(
        (filterColumn) =>
          filterColumn.type === FilterType.Area ||
          filterColumn.type === FilterType.Diff ||
          filterColumn.filters.some(
            (filter) =>
              filter.bit === FilterBits.Walking ||
              filter.bit === FilterBits.Car,
          ),
      )
      .map((filterColumn) => {
        if (filterColumn.type === FilterType.Area) {
          const areaColumn = filterColumn.filters[0].columns[0].name;
          return (
            "(" +
            selectedAreas
              .map((area) => `(${tableName}.${areaColumn} = ${area.id})`)
              .join(" or ") +
            ")"
          );
        } else if (filterColumn.type === FilterType.Diff) {
          return (
            "(" +
            filterColumn.filters
              .map((filter) => {
                const column1 = filter.columns[0].name;
                const column2 = filter.columns[1].name;

                return `(${tableName}.${column1} != ${tableName}.${column2})`;
              })
              .join(" or ") +
            ")"
          );
        } else if (filterColumn.type === FilterType.CompArea) {
          const baseColumn = filterColumn.filters[0].columns[0].name;
          const compColumn = filterColumn.filters[0].columns[1].name;

          return (
            "(" +
            selectedAreas
              .map(
                (area) =>
                  `(${tableName}.${baseColumn} = ${area.id} and (${selectedAreas
                    .filter((a) => a.id !== area.id)
                    .map((a) => `(${tableName}.${compColumn} = ${a.id})`)
                    .join(" or ")}))`,
              )
              .join(" or ") +
            ")"
          );
        } else {
          const filteredColumns = filterColumn.filters.filter((filter) => {
            if (
              filter.bit === FilterBits.Train ||
              filter.bit === FilterBits.TransportationUnknown
            )
              return false;

            if (heatMapDisplay === HeatMapType.Both)
              return (
                filter.bit === FilterBits.Walking ||
                filter.bit === FilterBits.Car
              );
            if (heatMapDisplay === HeatMapType.Walking)
              return filter.bit === FilterBits.Walking;
            if (heatMapDisplay === HeatMapType.Car)
              return filter.bit === FilterBits.Car;

            return false;
          });

          return (
            "(" +
            filteredColumns
              .map((filter) =>
                filter.columns.map(
                  (col) =>
                    `${tableName}.${col.name} ` +
                    (Array.isArray(col.value)
                      ? `in ("${col.value.join('", "')}")`
                      : `= ${
                          typeof col.value === "string"
                            ? '"' + col.value + '"'
                            : col.value
                        }`),
                ),
              )
              .join(" or ") +
            ")"
          );
        }
      })
      .filter((x) => x.length > 0 && x !== "()")
      .join(" and ");

    return condition.length === 0 ? "" : " where " + condition;
  }, [currentCartoData, heatMapDisplay, selectedAreas]);

  useEffect(() => {
    if (isDisplayData) dispatch(appActions.showLoading());
  }, [sqlQueryCondition]);

  const cartoSource = useMemo(() => {
    if (!currentCartoData) return null;

    const query = currentCartoData.query.replaceAll(
      "{project_name}",
      process.env.REACT_APP_CARTO_BIGQUERY_PROJECT ?? "",
    );

    return {
      id: "carto-source",
      type: MAP_TYPES.QUERY,
      connection: process.env.REACT_APP_CARTO_CONNECTION ?? "",
      data:
        query.indexOf("{query}") === -1
          ? query + sqlQueryCondition
          : query.replace("{query}", sqlQueryCondition),
      geoColumn: currentCartoData.geoColumn,
      aggregationExp: currentCartoData.aggregationExp,
    };
  }, [currentCartoData, sqlQueryCondition]);

  // @ts-ignore
  const cartoLayerProps = useCartoLayerProps({ source: cartoSource });

  const cartoLayer = useMemo(() => {
    if (!currentCartoData) return null;

    currentMaxValue = 0;

    return new CartoLayer({
      ...cartoLayerProps,
      id: "carto-layer",
      beforeId: "admin-0-boundary-bg",
      extruded: false,
      pointRadiusMinPixels: 1,
      lineWidthMinPixels: 0,
      lineCapRounded: true,
      lineJointRounded: true,
      getLineWidth: isRoad ? 5 : 0,
      opacity: 1,
      getFillColor: (object: any) => {
        const sum = object.properties.f0_;

        if (!isRoad && sum > currentMaxValue) currentMaxValue = Math.round(sum);

        if (sum < colorScale[0].to) {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[0]);
        } else if (colorScale[1].from <= sum && sum < colorScale[1].to) {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[1]);
        } else if (colorScale[2].from <= sum && sum < colorScale[2].to) {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[2]);
        } else if (colorScale[3].from <= sum && sum < colorScale[3].to) {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[3]);
        } else if (colorScale[4].from <= sum && sum < colorScale[4].to) {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[4]);
        } else {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[5]);
        }
      },
      getLineColor: (object: any) => {
        const sum = object.properties.f0_;

        if (isRoad && sum > currentMaxValue) currentMaxValue = Math.round(sum);

        if (sum < colorScale[0].to) {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[0]);
        } else if (colorScale[1].from <= sum && sum < colorScale[1].to) {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[1]);
        } else if (colorScale[2].from <= sum && sum < colorScale[2].to) {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[2]);
        } else if (colorScale[3].from <= sum && sum < colorScale[3].to) {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[3]);
        } else if (colorScale[4].from <= sum && sum < colorScale[4].to) {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[4]);
        } else {
          return hexToRgb(ROAD_HEAT_MAP_COLOR[5]);
        }
      },
      pickable: true,
      onDataLoad: (obj: any) => dispatch(appActions.hideLoading()),
      onDataError: (obj: any) => dispatch(appActions.hideLoading()),
      onHover: (info: any) => {
        if (info?.object) {
          info.object = {
            // @ts-ignore
            html: htmlForFeature({ feature: info.object }),
            style: {},
          };
        }
      },
    });
  }, [currentCartoData, colorScale, isDisplayData, heatMapDisplay]);

  useEffect(() => {
    if (currentMaxValue > 0) setMaxValue(currentMaxValue);
  }, [currentMaxValue]);

  useEffect(() => {
    if (!cartoSource || !cartoLayer) return;

    dispatch(addSource(cartoSource));
    dispatch(addLayer({ id: cartoLayer.id, source: cartoSource.id }));

    return () => {
      dispatch(removeLayer(cartoLayer.id));
      dispatch(removeSource(cartoSource.id));
    };
  }, [cartoSource, cartoLayer]);

  return { cartoData, currentCartoData, cartoSource, cartoLayer };
};
