import React, { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ReactComponent as DownSelecionIcon } from "../../assets/icons/down-selection.svg";
import { mergeClassNames } from "../../utils/style";
import { RequiredBadge } from "../atoms/RequiredBadge";

export interface SelectOptionType {
  id: number;
  name: string;
  value: any;
}

type Props = {
  name?: string;
  label?: string;
  placeholder: string;
  required?: boolean;
  defaultValue?: string;
  size?: "xs" | "sm" | "md";
  options: SelectOptionType[];
  disabled?: boolean;
  onChange: (name: string, value: any) => void;
};

export const CustomSelection: React.FC<Props> = ({
  name,
  label,
  placeholder,
  required,
  defaultValue,
  size = "md",
  options,
  disabled,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentName, setCurrentName] = useState(defaultValue ?? "");

  const { setValue } = useFormContext();

  const handleOpenSelection = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = (optionName: string, value: string, event?: any) => {
    setIsOpen(false);
    setCurrentName(optionName);
    onChange(optionName, value);
    if (name) setValue(name, value, { shouldValidate: true });
  };

  return (
    <div className="relative">
      {(label || required) && (
        <div className="flex items-center mb-[11px] gap-x-2.5">
          {label && <div className="text-[14px] ">{label}</div>}
          {required && <RequiredBadge />}
        </div>
      )}
      <div
        onClick={handleOpenSelection}
        className={mergeClassNames(
          "flex justify-between items-center w-full pl-[16px]",
          size === "xs" ? "h-[24px] rounded" : "",
          size === "sm" ? "h-[32px] rounded" : "",
          size === "md" ? "h-[48px] rounded-lg" : "",
          disabled
            ? "cursor-not-allowed"
            : "border border-gray-light cursor-pointer",
        )}
      >
        <div className={size === "xs" ? "text-[12px]" : "text-[14px]"}>
          {currentName !== "" ? currentName : placeholder}
        </div>
        {!disabled && (
          <DownSelecionIcon
            className={size === "xs" ? "mr-[10px]" : "mr-[20px]"}
          />
        )}
      </div>

      {isOpen && !disabled && (
        <div
          className={mergeClassNames(
            "absolute w-full z-10 bg-white shadow-md rounded",
            size === "sm" ? "p-0" : "",
            size === "md" ? "mt-[14px] top-12 py-[10px]" : "",
          )}
        >
          {options?.map((option) => {
            return (
              <div
                onClick={() => handleChange(option.name, option.value)}
                key={option.id}
                className={mergeClassNames(
                  "cursor-pointer pl-[20px] flex items-center hover:bg-primary-light",
                  size === "xs"
                    ? "text-[12px] h-[30px]"
                    : "text-[14px] h-[40px]",
                )}
              >
                {option.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
