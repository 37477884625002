import { put } from "typed-redux-saga";
import { AppConfig } from "../AppConfig";
import { LocalStorageManager } from "../core/storage/LocalStorageManager";
import { appActions } from "./app";
import { authActions } from "./auth";

export function* handleRefreshToken(retryAction: any) {
  const refreshToken = LocalStorageManager.Instance.getObject<string>(
    AppConfig.Instance.LocalStorageKey["refreshToken"],
  );

  if (refreshToken) {
    yield* put(appActions.showLoading());
    yield* put(
      authActions.refreshToken.started({
        refreshToken,
        onComplete: function* () {},
        onError: function* () {
          yield* put(appActions.hideLoading());
          yield* put(authActions.logout.started({}));
        },
        onSuccess: function* (response) {
          yield* put(retryAction);
        },
      }),
    );
  }
}
