import { IconProps } from "../types";

export default function IconMovementMini({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19.35"
      viewBox="0 0 18 19.35"
    >
      <path
        id="Path_806"
        data-name="Path 806"
        d="M13.041,12.6V11.025L10.8,13.275l2.241,2.25V13.95H18V12.6Zm2.718,3.825H10.8v1.35h4.959V19.35L18,17.1l-2.241-2.25ZM6.75,3.6a1.8,1.8,0,1,0-1.8-1.8,1.805,1.805,0,0,0,1.8,1.8M3.375,6.66.9,19.35H2.79l1.575-7.2L6.3,13.95v5.4H8.1v-6.8L6.255,10.71l.54-2.7a6.28,6.28,0,0,0,4.9,2.34V8.55a4.614,4.614,0,0,1-3.915-2.2L6.93,4.905A1.755,1.755,0,0,0,5.4,4.05a1.806,1.806,0,0,0-.675.135L0,6.12v4.23H1.8V7.335L3.375,6.66"
        fill="#334a7c"
      />
    </svg>
  );
}
