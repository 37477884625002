import actionCreatorFactory from "typescript-fsa";
import { GlobalMenu, ModalDataItem, NotificationItem } from "./types";

const actionCreator = actionCreatorFactory("@@App");

export const appActions = {
  showLoading: actionCreator("SHOW_LOADING"),
  hideLoading: actionCreator("HIDE_LOADING"),
  showModal: actionCreator.async<ModalDataItem, {}>("SHOW_MODAL"),
  closeModal: actionCreator("CLOSE_MODAL"),
  displayNotification: actionCreator<NotificationItem>("DISPLAY_NOTIFICATION"),
  resetNotification: actionCreator("RESET_NOTIFICATION"),
  showNotification: actionCreator("SHOW_NOTIFICATION"),
  fetchMenus: actionCreator.async<{}, GlobalMenu[]>("FETCH_MENUS"),
  setSideMenuExpanded: actionCreator<boolean>("SET_SIDE_MENU_EXPANDED"),
  resetToInitialState: actionCreator("RESET_TO_INITIAL_STATE"),
};
