export interface AuthState {
  users: User[];
  isLoggedIn: boolean;
  loggedInUser: User | null;
}

export interface User {
  id: number;
  name: string;
  email: string;
  user_type: UserType;
  create_date: string;
  tenants: UserTenantAuthorization[];
  wheel: boolean;
}

export enum UserAuthorization {
  Editor = 0,
  User = 1,
}

export interface UserTenantAuthorization {
  tenant_id: number;
  authorization: UserAuthorization;
}

export interface UserTenantType {
  tenant_id: number;
  user_type: UserType;
}

export enum UserType {
  Admin = "管理者",
  Editor = "利用者(編集可能)",
  User = "利用者",
}

export interface LoginRequestBody {
  tenantId?: number;
  emailAddress: string;
  password: string;
}

export interface CreateUserRequestBody {
  name: string;
  email: string;
  password: string;
  tenants: UserTenantType[];
}

export interface EditUserRequestBody {
  userId: number;
  name: string;
  email: string;
  password: string;
  tenants: UserTenantType[];
}

export interface DeleteUserRequestBody {
  userIds: number[];
}
