import { Divider } from "@mui/material";
import { Dataset } from "../../../ducks/dataset";
import { LabelCheckBox } from "../../atoms/LabelCheckBox";

type Props = {
  datasets: Dataset[];
  selectedDatasets: Dataset[];
  onChange: (value: any, isSelected: boolean) => void;
};

export const DatasetSelection: React.FC<Props> = ({
  datasets,
  selectedDatasets,
  onChange,
}) => {
  return (
    <>
      <div className="mt-3 text-sm">追加するデータセットを選択してください</div>
      <div className="w-full max-h-[150px] rounded border border-[#B5B5B5] overflow-y-auto mt-[15px] text-sm">
        {datasets.map((dataset, i) => {
          const isSelected = !!selectedDatasets.find(
            (d) => d.dataset_id === dataset.dataset_id,
          );

          return (
            <div
              key={`dataset-checkbox-${dataset.dataset_id}-${i}`}
              className="mx-2"
            >
              {i > 0 && <Divider />}
              <div
                className="flex items-center py-2 cursor-pointer"
                onClick={() => onChange(dataset, isSelected)}
              >
                <LabelCheckBox
                  label={dataset.dataset_name}
                  value={dataset}
                  checked={isSelected}
                  onChange={(event, value) => onChange(value, isSelected)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
