import { Area, AreaType } from "../../../ducks/area";
import { useArea } from "../../../hooks/useArea";
import { CustomButton } from "../../atoms/CustomButton";
import { AreaPopupCard } from "../../molecules/area/AreaPopupCard";
import { SubAreaListPopupCard } from "../../molecules/area/SubAreaListPopupCard";
import { PageHeader } from "../../organisms/PageHeader";
import { AreaMap } from "../../organisms/map/AreaMap";
import { CreateAreaModal } from "../../organisms/modal/CreateAreaModal";
import { CreateAreaNameModal } from "../../organisms/modal/CreateAreaNameModal";
import { useAnalysisArea } from "./hook";

export const AnalysisArea: React.FC = () => {
  const {
    onAnalyze,
    onEdit,
    onDelete,
    onClose,
    onClickAdd,
    createAreaModalOpen,
    createAreaNameModalOpen,
    closeCreateAreaModal,
    closeCreateAreaNameModal,
    onSubmit,
    currentSelectedArea,
    setCurrentSelectedArea,
    isModalClose,
  } = useAnalysisArea();

  const { multipleAnalysisAreaCount } = useArea();

  const _renderPopup = (area: Area) => {
    if (area.subAreas.length === 0) {
      return (
        <AreaPopupCard
          area={area}
          multipleAnalysisAreaCount={multipleAnalysisAreaCount}
          onAnalyze={onAnalyze}
          onEdit={onEdit}
          onDelete={onDelete}
          onClose={onClose}
        />
      );
    } else {
      return (
        <SubAreaListPopupCard
          area={area}
          multipleAnalysisAreaCount={multipleAnalysisAreaCount}
          onAnalyze={onAnalyze}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      );
    }
  };

  return (
    <div className="flex flex-col w-full h-full box-border px-2.5 pb-2.5">
      <div>
        <PageHeader />
      </div>

      <div className="flex flex-col w-full border-[#FFFFFF] border-8 h-[calc(100%_-_80px)] rounded-[4px]">
        <AreaMap
          mode="all"
          renderPopup={_renderPopup}
          onClosePopup={() => setCurrentSelectedArea(null)}
          isModalClose={isModalClose}
        />
        <div className="relative h-0 -top-[99%] left-2">
          <CustomButton
            text="新規追加"
            className="px-6.25 py-2.5"
            onClick={onClickAdd}
          />
        </div>

        <CreateAreaModal
          isOpen={createAreaModalOpen}
          handleClose={closeCreateAreaModal}
        />

        <CreateAreaNameModal
          isOpen={createAreaNameModalOpen}
          handleClose={closeCreateAreaNameModal}
          handleSubmit={onSubmit}
          isEdit
          isRoad={
            currentSelectedArea !== null &&
            currentSelectedArea.area.type === AreaType.Road
          }
          selectedArea={currentSelectedArea}
        />
      </div>
    </div>
  );
};
