import { HttpStatusCode } from "axios";
import { call, put, select } from "typed-redux-saga";
import { ApiResponse } from "../../core/@types";
import { Roadnetwork } from "../../core/api";
import { roadNetworkApi } from "../../core/http/openAPIClient";
import { StoreState } from "../../store";
import { compareMapBounds } from "../../utils/geojson";
import { appActions } from "../app";
import { takeEveryFsa } from "../operations";
import { handleRefreshToken } from "../refreshToken";
import { roadActions } from "./actions";
import { RoadStatus } from "./types";

export function* roadSaga() {
  yield takeEveryFsa(roadActions.fetchRoads.started, function* (action) {
    const state: StoreState = yield* select();
    const { bounds, area, onComplete, onError, onSuccess } = action.payload;

    if (
      bounds &&
      state.road.previousMapBounds &&
      compareMapBounds(bounds, state.road.previousMapBounds)
    )
      return onComplete?.();

    yield* put(roadActions.setPreviousMapBounds(bounds));

    const { response, error }: ApiResponse<Roadnetwork[]> = yield call(() => {
      return roadNetworkApi
        .roadnetworksearch({ polygon: area })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          roadActions.fetchRoads.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }

    yield* put(
      roadActions.fetchRoads.done({
        params: action.payload,
        result: {
          roads: response.data.map((road) => ({
            id: road.id ?? 0,
            polygon:
              road.polygon?.map((polygon) => ({
                latitude: polygon.latitude ?? 0,
                longitude: polygon.longitude ?? 0,
              })) ?? [],
            status: (road.status as RoadStatus) ?? RoadStatus.Waiting,
          })),
        },
      }),
    );
    return onSuccess?.(response.data);
  });

  yield takeEveryFsa(roadActions.createRoad.started, function* (action) {
    const { area, onComplete, onError, onSuccess } = action.payload;
    const { response, error }: ApiResponse<{}> = yield call(() => {
      return roadNetworkApi
        .roadnetworkregister({
          area: {
            type: "line",
            data: area,
          },
        })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          roadActions.createRoad.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }

    yield* put(
      appActions.displayNotification({
        type: "success",
        text: "道路を保存しました",
      }),
    );
    yield* put(
      roadActions.createRoad.done({
        params: action.payload,
        result: {},
      }),
    );
    return onSuccess?.(response.data);
  });

  yield takeEveryFsa(roadActions.deleteRoads.started, function* (action) {
    const { roadIds, onComplete, onError, onSuccess } = action.payload;
    const { response, error }: ApiResponse<{}> = yield call(() => {
      return roadNetworkApi
        .roadnetworkdelete(roadIds[0])
        .then((response) => response)
        .catch((error) => error);
    });

    if (response && roadIds.length === 1) {
      onComplete?.();

      yield* put(
        roadActions.deleteRoads.done({
          params: action.payload,
          result: response.data,
        }),
      );

      yield* put(
        appActions.displayNotification({
          type: "success",
          text: "道路を削除しました",
        }),
      );
      return onSuccess?.(response.data);
    }

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          roadActions.deleteRoads.started(action.payload),
        );
      } else {
        onComplete?.();
        return onError?.(error);
      }
    }

    yield* put(
      roadActions.deleteRoads.done({
        params: action.payload,
        result: response.data,
      }),
    );

    yield* put(
      roadActions.deleteRoads.started({
        ...action.payload,
        roadIds: roadIds.slice(1, roadIds.length),
      }),
    );
  });
}
