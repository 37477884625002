export interface HistoryState {
  totalHistories: number;
  histories: HistoryItem[];
}

interface HistoryAreaRoad {
  area_id?: number;
  road_id?: number;
  checked: boolean;
  is_subarea?: boolean;
}

export interface HistoryReception {
  reception_id: string;
  analyze_id: number;
}

export interface CreateHistoryRequestBody {
  type: number;
  areas: HistoryAreaRoad[];
  roads: HistoryAreaRoad[];
  from: string;
  to: string;
  time: string;
  filter: string;
  receptions: HistoryReception[];
}

export interface HistoryItem {
  history_id: number;
  status: HistoryStatus;
  type: number;
  area: HistoryAreaRoad[];
  road: HistoryAreaRoad[];
  from: string;
  to: string;
  time: string;
  filter: string;
  receptions: {
    reception_id: string;
    analyze_id: number;
  }[];
  user_name: string;
  created_at: string;
}

export enum HistoryStatus {
  Success = "success",
  Approved = "approved",
  Processing = "processing",
  Failure = "failure",
}

export interface FetchHistoryRequestBody {
  offset?: number;
  limit?: number;
}
