import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { appActions, appSelectors } from "../ducks/app";

export const useSideNavigation = () => {
  const menus = useSelector(appSelectors.menus);
  const sideMenuExpanded = useSelector(appSelectors.sideMenuExpanded);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const title = useMemo(() => {
    return menus.filter((menu) => location.pathname.indexOf(menu.path) >= 0);
  }, [menus, location]);

  const currentMenu = useMemo(() => {
    return menus.find((menu) => location.pathname.indexOf(menu.path) >= 0);
  }, [menus, location]);

  const onClickSideMenu = useCallback((path: string) => {
    navigate(path);
  }, []);

  const setSideMenuExpanded = (expanded: boolean) => {
    dispatch(appActions.setSideMenuExpanded(expanded));
  };

  return {
    menus,
    title,
    currentMenu,
    onClickSideMenu,
    sideMenuExpanded,
    setSideMenuExpanded,
  };
};
