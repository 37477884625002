import { HttpStatusCode } from "axios";
import { call, put } from "typed-redux-saga";
import { ApiResponse, DataObj } from "../../core/@types";
import { Asset } from "../../core/api";
import {
  ErrorMessage,
  ErrorMessageManager,
} from "../../core/http/ErrorMessageManager";
import { assetsApi } from "../../core/http/openAPIClient";
import { Logger } from "../../core/logger";
import { AnalysisItem, analysisActions } from "../analysis";
import { GlobalMenu, appActions } from "../app";
import { AreaData, AreaStatus, AreaType, areaActions } from "../area";
import { UserAuthorization, UserType, authActions } from "../auth";
import { CartoData, cartoActions } from "../carto";
import { datasetActions } from "../dataset";
import { FilterList, filterActions } from "../filter";
import { takeEveryFsa } from "../operations";
import { handleRefreshToken } from "../refreshToken";
import { tenantActions } from "../tenant";
import { assetActions } from "./actions";

export function* assetSaga() {
  yield takeEveryFsa(
    assetActions.fetchStaticAssets.started,
    function* (action) {
      const fetchAsset = async <T>(
        filename: string,
      ): Promise<T | undefined> => {
        try {
          const result = await fetch(filename);
          const json: DataObj<T> = await result.json();

          return json.data;
        } catch (e) {
          Logger.getInstance().error(`Asset error: ${filename}`);
        }
      };

      // Menu
      const menus = yield* call(async () => {
        return await fetchAsset<GlobalMenu[]>("/data/menu.json");
      });
      if (menus) {
        yield* put(appActions.fetchMenus.done({ params: {}, result: menus }));
      }

      // Analysis
      const analysis = yield* call(async () => {
        return await fetchAsset<AnalysisItem[]>("/data/analysis.json");
      });
      if (analysis) {
        yield* put(
          analysisActions.fetchAnalysisItems.done({
            params: {},
            result: analysis,
          }),
        );
      }

      // Filter
      const filters = yield* call(async () => {
        return await fetchAsset<FilterList[]>("/data/filter.json");
      });
      if (filters) {
        yield* put(
          filterActions.fetchFilters.done({
            params: {},
            result: { filters },
          }),
        );
      }

      // Carto
      const carto = yield* call(async () => {
        return await fetchAsset<CartoData[]>("/data/carto.json");
      });
      if (carto) {
        yield* put(
          cartoActions.fetchCartoData.done({
            params: {},
            result: { carto },
          }),
        );
      }
    },
  );

  yield takeEveryFsa(assetActions.fetchAssets.started, function* (action) {
    yield* put(appActions.showLoading());

    const { onComplete, onError, onSuccess } = action.payload;
    const { response, error }: ApiResponse<Asset> = yield call(() => {
      return assetsApi
        .asset()
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          assetActions.fetchAssets.started(action.payload),
        );
      } else {
        onError?.();
        return yield* put(appActions.hideLoading());
      }
    }

    // Assets
    if (response && response.data) {
      yield* put(
        assetActions.fetchAssets.done({
          params: {},
          result: {
            asset: response.data,
          },
        }),
      );
    }

    // Spot
    if (response && response.data.spots && !action.payload.tenantOnly) {
      yield* put(
        areaActions.fetchAreas.done({
          params: {},
          result: {
            areas: response.data.spots.map((spot) => ({
              id: spot.id ?? 0,
              area_name: spot.spot_name ?? "",
              dataset: {
                group_id: spot.dataset?.group_id ?? 0,
                group_name: spot.dataset?.group_name ?? "",
                datasets:
                  spot.dataset?.datasets?.map((dataset) => ({
                    dataset_id: dataset.dataset_id ?? 0,
                    dataset_name: dataset.dataset_name ?? "",
                  })) ?? [],
              },
              area: {
                type: (spot.area?.type as AreaType) ?? AreaType.Polygon,
                data: (spot.area?.data as AreaData) ?? [],
              },
              create_date: spot.create_date ?? "",
              status: (spot.status as AreaStatus) ?? AreaStatus.Failure,
              for_multi: spot.for_multi ?? true,
              subAreas: [],
              roadIds:
                spot.area?.type === AreaType.Road
                  ? [
                      ...new Set(
                        spot.area?.data?.map((data) => {
                          // @ts-ignore
                          return data.road_id;
                        }),
                      ),
                    ] ?? []
                  : [],
            })),
          },
        }),
      );
    }

    // User
    if (response && response.data.users) {
      yield* put(
        authActions.fetchUsers.done({
          params: {},
          result: {
            users: response.data.users.map((user) => ({
              id: user.id ?? 0,
              name: user.name ?? "",
              email: user.email ?? "",
              create_date: user.create_date ?? "",
              user_type: UserType.User,
              tenants:
                user.tenants?.map((tenant) => ({
                  tenant_id: tenant.tenant_id ?? 0,
                  authorization: tenant.authorization ?? UserAuthorization.User,
                })) ?? [],
              wheel: user.wheel ?? false,
            })),
          },
        }),
      );
    }

    // Dataset
    if (response && response.data.datasets && !action.payload.tenantOnly) {
      yield* put(
        datasetActions.fetchDatasets.done({
          params: {},
          result: {
            datasets: response.data.datasets.map((dataset) => ({
              dataset_id: dataset.dataset_id ?? 0,
              dataset_name: dataset.dataset_name ?? "",
              group_id: dataset.group_id ?? 0,
              group_name: dataset.group_name ?? "",
              // @ts-ignore
              start_date: dataset.start_date ?? "",
              // @ts-ignore
              end_date: dataset.end_date ?? "",
            })),
          },
        }),
      );
    }

    // Tenant
    if (response && response.data.tenants) {
      yield* put(
        tenantActions.fetchTenants.done({
          params: {},
          result: {
            tenants: response.data.tenants.map((tenant) => ({
              id: tenant.id ?? 0,
              name: tenant.name ?? "",
              state: tenant.state ?? "",
              setting: {
                default_location: {
                  latitude: tenant.setting?.default_location?.latitude ?? 0,
                  longitude: tenant.setting?.default_location?.longitude ?? 0,
                  radius: tenant.setting?.default_location?.radius ?? 0,
                },
              },
              start_date: tenant.start_date ?? "",
              // @ts-ignore
              end_date: tenant.end_date ?? "",
              // @ts-ignore
              create_date: tenant.start_date ?? "",
              // @ts-ignore
              authority: tenant.authority ?? 0,
            })),
          },
        }),
      );
    }

    onSuccess?.(response.data);
    yield* put(appActions.hideLoading());
  });

  yield takeEveryFsa(assetActions.fetchMessages.started, function* (action) {
    const messages: Record<string, ErrorMessage> = yield call(async () => {
      try {
        const result = await fetch("/data/messages.json");
        const json: Record<string, ErrorMessage> = await result.json();

        return json;
      } catch (e) {
        Logger.getInstance().error(`Fetch message error`);
      }
    });

    if (messages) {
      ErrorMessageManager.Instance.setErrorMessage(messages);
    }
  });
}
