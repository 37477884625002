import { HttpStatusCode } from "axios";
import { call, put, select } from "typed-redux-saga";
import {
  MAX_ROAD_SELECTION,
  MAX_SUB_AREA_SELECTION,
} from "../../constants/area";
import { ApiResponse } from "../../core/@types";
import { spotApi } from "../../core/http/openAPIClient";
import { StoreState } from "../../store";
import { appActions } from "../app";
import { assetActions } from "../asset";
import { takeEveryFsa } from "../operations";
import { handleRefreshToken } from "../refreshToken";
import { areaActions } from "./actions";
import { AreaStatus, AreaType } from "./types";

export function* areaSaga() {
  yield takeEveryFsa(areaActions.selectArea.started, function* (action) {
    const state: StoreState = yield* select();

    const selectedAnalysisItem = state.analysis.selectedAnalysisItem;
    const alreadySelected = state.area.selectedAreas.find(
      (area) => area.id === action.payload.area.id,
    );

    if (
      !alreadySelected &&
      state.area.selectedAreas.length > 0 &&
      state.area.selectedAreas[0].dataset.group_id !==
        action.payload.area.dataset.group_id
    )
      return;

    if (
      selectedAnalysisItem &&
      selectedAnalysisItem.max &&
      state.area.selectedAreas.length >= selectedAnalysisItem.max &&
      !alreadySelected
    ) {
      return;
    }

    if (
      selectedAnalysisItem &&
      selectedAnalysisItem.is_multi &&
      (action.payload.area.status === AreaStatus.Failure ||
        !action.payload.area.for_multi)
    )
      return;

    yield* put(
      areaActions.selectArea.done({
        params: action.payload,
        result: {},
      }),
    );
  });

  yield takeEveryFsa(areaActions.removeArea.started, function* (action) {
    yield* put(
      areaActions.removeArea.done({
        params: action.payload,
        result: {},
      }),
    );
  });

  yield takeEveryFsa(areaActions.selectSubArea.started, function* (action) {
    const state: StoreState = yield* select();

    const alreadySelected = state.area.selectedSubAreas.find(
      (area) => area.id === action.payload.area.id,
    );

    if (
      !alreadySelected &&
      state.area.selectedSubAreas.length >= MAX_SUB_AREA_SELECTION
    )
      return;

    const selectedAnalysisItem = state.analysis.selectedAnalysisItem;
    if (
      selectedAnalysisItem &&
      selectedAnalysisItem.is_multi &&
      (action.payload.area.status === AreaStatus.Failure ||
        !action.payload.area.for_multi)
    )
      return;

    yield* put(
      areaActions.selectSubArea.done({
        params: action.payload,
        result: {},
      }),
    );
  });

  yield takeEveryFsa(areaActions.removeSubArea.started, function* (action) {
    yield* put(
      areaActions.removeSubArea.done({
        params: action.payload,
        result: {},
      }),
    );
  });

  yield takeEveryFsa(areaActions.selectRoad.started, function* (action) {
    const state: StoreState = yield* select();

    const alreadySelected = state.area.selectedRoads.find(
      (area) => area.id === action.payload.area.id,
    );

    if (
      !alreadySelected &&
      state.area.selectedRoads.length >= MAX_ROAD_SELECTION
    )
      return;

    yield* put(
      areaActions.selectRoad.done({
        params: action.payload,
        result: {},
      }),
    );
  });

  yield takeEveryFsa(areaActions.removeRoad.started, function* (action) {
    yield* put(
      areaActions.removeRoad.done({
        params: action.payload,
        result: {},
      }),
    );
  });

  yield takeEveryFsa(areaActions.createArea.started, function* (action) {
    const {
      areaName,
      datasetIds,
      forMulti,
      area,
      onComplete,
      onError,
      onSuccess,
    } = action.payload;
    const { response, error }: ApiResponse<{}> = yield call(() => {
      return spotApi
        .spotregist({
          spot_type: area.type === AreaType.Road ? "road" : "area",
          spot_name: areaName,
          dataset_id: datasetIds,
          area,
          for_multi: forMulti,
        })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          areaActions.createArea.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }

    yield* put(
      areaActions.createArea.done({
        params: action.payload,
        result: {},
      }),
    );

    onSuccess?.(response.data);
    yield* put(
      appActions.displayNotification({
        type: "success",
        text: "エリアを保存しました",
      }),
    );
    yield* put(assetActions.fetchAssets.started({}));
  });

  yield takeEveryFsa(areaActions.editArea.started, function* (action) {
    const { areaId, areaName, area, forMulti, onComplete, onError, onSuccess } =
      action.payload;
    const { response, error }: ApiResponse<{}> = yield call(() => {
      return spotApi
        .spotmodify({
          id: areaId,
          spot_name: areaName,
          for_multi: forMulti,
          spot_type: area.type === AreaType.Road ? "road" : "area",
          // @ts-ignore
          area,
        })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          areaActions.editArea.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }

    yield* put(
      areaActions.editArea.done({
        params: action.payload,
        result: {},
      }),
    );

    yield* put(
      appActions.displayNotification({
        type: "success",
        text: "エリアを編集しました",
      }),
    );

    onSuccess?.(response.data);

    yield* put(assetActions.fetchAssets.started({}));
  });

  yield takeEveryFsa(areaActions.deleteArea.started, function* (action) {
    const { areaId, type, onComplete, onError, onSuccess } = action.payload;
    const { response, error }: ApiResponse<{}> = yield call(() => {
      return spotApi
        .spotdelete(type, areaId)
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          areaActions.deleteArea.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }

    yield* put(
      areaActions.deleteArea.done({
        params: action.payload,
        result: {},
      }),
    );

    onSuccess?.(response.data);
    yield* put(
      appActions.displayNotification({
        type: "success",
        text: "エリアを削除しました",
      }),
    );

    yield* put(assetActions.fetchAssets.started({}));
  });
}
