import { Action } from "@reduxjs/toolkit";
import booleanEqual from "@turf/boolean-equal";
import { polygon } from "@turf/helpers";
import { isType } from "typescript-fsa";
import { Road, RoadStatus } from "../road";
import { areaActions } from "./actions";
import {
  Area,
  AreaCoordinates,
  AreaState,
  AreaStatus,
  AreaType,
} from "./types";

const initialState: AreaState = {
  areas: [],
  roads: [],
  roadAreas: [],
  roadCoordinates: {},
  roadNetworks: [],
  allAreas: [],
  selectedAreas: [],
  selectedSubAreas: [],
  selectedRoads: [],
  multipleAnalysisAreaCount: 0,
};

export function AreaReducer(state = initialState, action: Action): AreaState {
  if (isType(action, areaActions.fetchAreas.done)) {
    const areas: Record<number, Area> = {};
    const allAreas: Area[] = [];
    let multipleAnalysisAreaCount = 0;

    const filteredAreas = action.payload.result.areas.filter(
      (area) => area.area.type !== AreaType.Road,
    );

    filteredAreas.forEach((area) => {
      if (area.for_multi) multipleAnalysisAreaCount += 1;

      allAreas.push(area);

      if (Object.values(areas).length === 0) {
        areas[area.id] = area;
        return;
      }

      const isMultiPolygon =
        // @ts-ignore
        !area.area.data[0].latitude && !area.area.data[0].longitude;

      const samePolygonArea = Object.values(areas).find((a) => {
        if (a.area.type !== area.area.type) return false;

        // @ts-ignore
        if (!a.area.data[0].latitude && !a.area.data[0].longitude) {
          if (!isMultiPolygon) return false;

          const coordinatesA = area.area.data as AreaCoordinates[][];
          const coordinatesB = a.area.data as AreaCoordinates[][];

          if (coordinatesA.length !== coordinatesB.length) return false;

          return coordinatesA.every((coords, i) => {
            return booleanEqual(
              polygon([
                coords.map((coord) => [coord.longitude, coord.latitude]),
              ]),
              polygon([
                coordinatesB[i].map((coord) => [
                  coord.longitude,
                  coord.latitude,
                ]),
              ]),
            );
          });
        } else {
          if (isMultiPolygon) return false;

          const coordinatesA = area.area.data as AreaCoordinates[];
          const coordinatesB = a.area.data as AreaCoordinates[];
          try {
            // Polygon Comparison
            return booleanEqual(
              polygon([
                coordinatesA.map((coords) => [
                  coords.longitude,
                  coords.latitude,
                ]),
              ]),
              polygon([
                coordinatesB.map((coords) => [
                  coords.longitude,
                  coords.latitude,
                ]),
              ]),
            );
          } catch (e) {
            // Circle Comparison
            return (
              coordinatesA[0].latitude === coordinatesB[0].latitude &&
              coordinatesA[0].longitude === coordinatesB[0].longitude &&
              coordinatesA[0].radius === coordinatesB[0].radius
            );
          }
        }
      });

      if (!samePolygonArea) {
        areas[area.id] = area;
        return;
      }

      if (samePolygonArea) {
        areas[samePolygonArea.id] = {
          ...areas[samePolygonArea.id],
          subAreas: [...areas[samePolygonArea.id].subAreas, area],
        };
      }
    });

    const roads: Record<number, Area> = {};
    const filteredRoads = action.payload.result.areas.filter(
      (area) => area.area.type === AreaType.Road,
    );
    const roadIds: number[] = [];
    const roadCoordinates: Record<number, AreaCoordinates[]> = {};
    const roadAreaIds: Record<number, number[]> = {};

    filteredRoads.forEach((road) => {
      const road_ids: number[] = [];
      road.area.data.forEach((data) => {
        data = data as AreaCoordinates;
        if (!data.road_id) return;

        if (!roadAreaIds[data.road_id]) {
          roadAreaIds[data.road_id] = [road.id];
        } else if (!roadAreaIds[data.road_id].includes(road.id)) {
          roadAreaIds[data.road_id].push(road.id);
        }

        if (roadIds.includes(data.road_id)) return;
        if (!road_ids.includes(data.road_id)) road_ids.push(data.road_id);

        if (!roadCoordinates[data.road_id]) {
          roadCoordinates[data.road_id] = [data];
        } else {
          roadCoordinates[data.road_id].push(data);
        }
      });

      road_ids.forEach((id) => {
        if (!roadIds.includes(id)) roadIds.push(id);
      });

      roads[road.id] = road;
    });

    const roadAreas: Record<number, Area[]> = {};
    Object.entries(roadAreaIds).forEach(([id, area_ids]) => {
      const road_id = Number(id);
      if (!roadAreas[road_id]) {
        roadAreas[road_id] = area_ids.map((id) => roads[id]);
      }
    });

    const roadNetworks: Road[] = Object.entries(roadAreas).map(
      ([road_id, areas]) => {
        const id = Number(road_id);
        const status = areas.every((area) => area.status === AreaStatus.Success)
          ? RoadStatus.Created
          : RoadStatus.Waiting;

        return { id, polygon: roadCoordinates[id], status };
      },
    );

    return {
      ...state,
      areas: Object.values(areas),
      roads: Object.values(roads),
      roadAreas,
      roadCoordinates,
      roadNetworks,
      allAreas,
      multipleAnalysisAreaCount,
    };
  }

  if (isType(action, areaActions.selectArea.done)) {
    const alreadySelected = state.selectedAreas.find(
      (area) => area.id === action.payload.params.area.id,
    );

    if (action.payload.params.removeIfSelected && alreadySelected) {
      return {
        ...state,
        selectedAreas: state.selectedAreas.filter(
          (area) => area.id !== action.payload.params.area.id,
        ),
      };
    }

    if (alreadySelected) return state;

    return {
      ...state,
      selectedAreas: [...state.selectedAreas, action.payload.params.area],
    };
  }

  if (isType(action, areaActions.removeArea.done)) {
    return {
      ...state,
      selectedAreas: state.selectedAreas.filter(
        (area) => area.id !== action.payload.params.area.id,
      ),
    };
  }

  if (isType(action, areaActions.selectSubArea.done)) {
    const isMainArea = state.selectedAreas.find(
      (area) => area.id === action.payload.params.area.id,
    );
    const alreadySelected = state.selectedSubAreas.find(
      (area) => area.id === action.payload.params.area.id,
    );

    if (action.payload.params.removeIfSelected && alreadySelected) {
      return {
        ...state,
        selectedSubAreas: state.selectedSubAreas.filter(
          (area) => area.id !== action.payload.params.area.id,
        ),
      };
    }

    if (alreadySelected || isMainArea) return state;

    return {
      ...state,
      selectedSubAreas: [...state.selectedSubAreas, action.payload.params.area],
    };
  }

  if (isType(action, areaActions.removeSubArea.done)) {
    return {
      ...state,
      selectedSubAreas: state.selectedSubAreas.filter(
        (area) => area.id !== action.payload.params.area.id,
      ),
    };
  }

  if (isType(action, areaActions.selectRoad.done)) {
    const alreadySelected = state.selectedRoads.find(
      (area) => area.id === action.payload.params.area.id,
    );

    if (action.payload.params.removeIfSelected && alreadySelected) {
      return {
        ...state,
        selectedRoads: state.selectedRoads.filter(
          (area) => area.id !== action.payload.params.area.id,
        ),
      };
    }

    if (alreadySelected) return state;

    return {
      ...state,
      selectedRoads: [...state.selectedRoads, action.payload.params.area],
    };
  }

  if (isType(action, areaActions.removeRoad.done)) {
    return {
      ...state,
      selectedRoads: state.selectedRoads.filter(
        (area) => area.id !== action.payload.params.area.id,
      ),
    };
  }

  if (isType(action, areaActions.resetSelectedAreas)) {
    return {
      ...state,
      selectedAreas: [],
    };
  }

  if (isType(action, areaActions.resetSelectedSubAreas)) {
    return {
      ...state,
      selectedSubAreas: [],
    };
  }

  if (isType(action, areaActions.resetSelectedRoads)) {
    return {
      ...state,
      selectedRoads: [],
    };
  }

  if (isType(action, areaActions.createArea.done)) {
    return {
      ...state,
      selectedAreas: action.payload.params.resetSelectedArea
        ? []
        : state.selectedAreas,
    };
  }

  if (isType(action, areaActions.editArea.done)) {
    return {
      ...state,
      selectedAreas: [],
    };
  }

  if (isType(action, areaActions.deleteArea.done)) {
    return {
      ...state,
      selectedAreas: [],
    };
  }

  if (isType(action, areaActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
