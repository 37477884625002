import { Checkbox as MuiCheckbox } from "@mui/material";
import React, { useCallback } from "react";
import { mergeClassNames } from "../../utils/style";
import Checkbox from "./Checkbox";

export const LabelCheckBox: React.FC<{
  label: string;
  value: any;
  onChange: (event: any, value: any) => void;
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  secondary?: boolean;
  type?: "mui" | "icon";
}> = ({
  label,
  onChange,
  value,
  checked,
  indeterminate,
  disabled,
  secondary,
  type = "icon",
}) => {
  const onChangeHandler = useCallback(
    (event: any) => {
      if (disabled) return;

      event.stopPropagation();
      onChange(event, value);
    },
    [onChange, value, disabled],
  );

  return (
    <div
      className={mergeClassNames(
        "flex items-center px-[6px] my-[8px]",
        disabled ? "cursor-not-allowed" : "cursor-pointer",
        type === "mui" && "bg-[#F4F4F4]",
      )}
      onClick={onChangeHandler}
    >
      {type === "mui" && (
        <MuiCheckbox
          sx={{
            margin: 0,
            padding: 0,
            width: "18px",
            height: "18px",
            color: "#334A7C",
            "&.Mui-checked": {
              color: "#334A7C",
            },
            "&.MuiCheckbox-indeterminate": {
              color: "#334A7C",
            },
          }}
          checked={checked}
          indeterminate={indeterminate}
        />
      )}

      {type === "icon" && (
        <Checkbox
          disabled={disabled}
          selected={checked ?? false}
          indeterminate={indeterminate}
          secondary={secondary}
        />
      )}
      {label.length > 0 && (
        <p className="ml-[8px] text-[12px] leading-[12px] whitespace-nowrap">
          {label}
        </p>
      )}
    </div>
  );
};
