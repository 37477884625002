import React from "react";
import { GlobalMenu } from "../../ducks/app";
import { useSideNavigation } from "../../hooks/useSideNavigation";
import IconResizeNav from "../../icons/SideNav/IconResizeNav";
import IconResizeNavCollapse from "../../icons/SideNav/IconResizeNavCollapse";
import { getSideNavigationIcon } from "../../utils/menu";
import { mergeClassNames } from "../../utils/style";
import { SideNavItem } from "../molecules/SIdeNavItem";

type SideNavigateProps = {
  menus: GlobalMenu[];
};

export const SideNavigation: React.FC<SideNavigateProps> = ({ menus }) => {
  const { sideMenuExpanded, setSideMenuExpanded } = useSideNavigation();

  return (
    <aside
      aria-label="Sidebar"
      className={mergeClassNames(
        "flex flex-col pb-3 bg-white",
        sideMenuExpanded ? "w-60" : "w-auto",
      )}
    >
      {menus.map((menu) => {
        return (
          <SideNavItem
            key={menu.id}
            shrink={!sideMenuExpanded}
            icon={getSideNavigationIcon(menu.path)}
            path={menu.path}
            title={menu.name}
          />
        );
      })}
      <div
        className={mergeClassNames(
          "flex-1 flex flex-col justify-end",
          sideMenuExpanded ? "items-end" : "items-center",
        )}
      >
        <button
          type="button"
          className="px-6 py-4.5"
          onClick={() => setSideMenuExpanded(!sideMenuExpanded)}
        >
          {sideMenuExpanded ? (
            <IconResizeNav className="h-3" />
          ) : (
            <IconResizeNavCollapse className="h-3" />
          )}
        </button>
      </div>
    </aside>
  );
};
