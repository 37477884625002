import { RadioGroup } from "@mui/material";
import React, { useEffect } from "react";
import Modal from "react-modal";
import { CustomButton } from "../../atoms/CustomButton";
import { LabelRadioButton } from "../../atoms/LabelRadioButton";

type Props = {
  isOpen: boolean;
  isSave: boolean;
  setIsSave: (isSave: boolean) => void;
  handleNext: (isSave: boolean) => void;
  handleClose: () => void;
};

export const SaveSelectionPopup: React.FC<Props> = ({
  isOpen,
  isSave,
  setIsSave,
  handleNext,
  handleClose,
}) => {
  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 20,
      backgroundColor: "transparent",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 120,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={handleClose}
      shouldReturnFocusAfterClose={true}
    >
      <div className="w-[500px] h-[300px] bg-white rounded-lg pt-[25px]">
        <div className="flex justify-center items-center font-bold pt-6 text-[20px]">
          分析実行時の選択内容を保存しますか?
        </div>
        <div className="flex justify-center items-center w-full h-[50px] text-sm">
          保存した分析は「分析履歴」から確認できます。
        </div>
        <div className="flex justify-center items-center w-full h-[60px]">
          <RadioGroup
            row
            name="save-selection-popup-radio-group"
            defaultValue={isSave ? "1" : "0"}
            onChange={(e) => setIsSave(e.target.value === "1")}
          >
            <LabelRadioButton label="保存する" value={"1"} />
            <LabelRadioButton label="保存しない" value={"0"} />
          </RadioGroup>
        </div>
        <div className="flex justify-center items-center w-full h-[88px] text-sm">
          <CustomButton
            text="決定"
            onClick={() => handleNext(isSave)}
            className="flex justify-center items-center w-[200px] h-[40px] text-white text-sm bg-primary rounded"
          />
        </div>
      </div>
    </Modal>
  );
};
