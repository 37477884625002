//@ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React, { useEffect, useMemo, useRef } from "react";
import { createRoot } from "react-dom/client";
import { useSelector } from "react-redux";
import { STANDARD_MAP_STYLE } from "../../../constants/map";
import { analysisSelectors } from "../../../ducks/analysis";
import { Area, AreaCoordinates, AreaStatus } from "../../../ducks/area";
import { useArea } from "../../../hooks/useArea";
import { useMapBounds } from "../../../hooks/useMapBounds";
import { useMapControl } from "../../../hooks/useMapControl";
import { useMapSetting } from "../../../hooks/useMapSetting";
import { useSideNavigation } from "../../../hooks/useSideNavigation";
import { getMaxLatLngBounds } from "../../../utils/geojson";
import {
  displayRoadLineStringOnMap,
  paintMapRoadLayers,
} from "../../../utils/linestring";
import {
  displayAreaPolygonOnMap,
  paintMapLayers,
} from "../../../utils/polygon";
import { MapTypeControl } from "../../molecules/map/MapTypeControl";

type Props = {
  mode: "area" | "subarea" | "road";
  newArea?: Area | null;
  callback?: (area: Area) => void;
  renderPopup?: (area: Area) => JSX.Element;
  renderRoadPopup?: (roads: Area[]) => JSX.Element;
  onClosePopup?: () => void;
};

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN ?? "";

export const AreaSelectionMap: React.FC<Props> = ({
  mode,
  newArea,
  callback,
  renderPopup,
  renderRoadPopup,
  onClosePopup,
}) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const { mapCenter, mapZoom, setOnMapMove } = useMapBounds(map);
  const { mapStyle } = useMapSetting();

  const {
    areas,
    roads,
    roadNetworks,
    selectedAreas,
    selectedSubAreas,
    selectedRoads,
    selectArea,
    selectSubArea,
    selectRoad,
    selectRoadByRoadNetworkId,
    showAreaPopup,
    showRoadPopup,
    areaLngLat,
    currentSelection,
    handleSetCurrentSelection,
    currentRoadSelections,
    handleSetCurrentRoadSelections,
    onClickLayer,
    onClickRoadLayer,
  } = useArea();

  const { sideMenuExpanded } = useSideNavigation();

  const selectedAnalysisItem = useSelector(
    analysisSelectors.selectedAnalysisItem,
  );

  useEffect(() => {
    map.current?.resize();
  }, [sideMenuExpanded]);

  const filteredAreas = useMemo(() => {
    if (mode === "road") return [];
    if (selectedAnalysisItem && selectedAnalysisItem.is_multi)
      return areas.filter((area) => area.for_multi);

    return areas;
  }, [areas, selectedAnalysisItem]);

  const {
    geocoderControl,
    navigationControl,
    languageControl,
    mapTypeControl1,
  } = useMapControl();

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: STANDARD_MAP_STYLE,
        center: mapCenter,
        zoom: mapZoom,
        attributionControl: false,
      });
    }

    map.current
      .addControl(new mapboxgl.AttributionControl({ compact: false }))
      .addControl(geocoderControl)
      .addControl(navigationControl, "bottom-right")
      .addControl(mapTypeControl1, "top-right")
      .addControl(languageControl);

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    setOnMapMove();
  }, []);

  const _renderPopup = (area: Area) => {
    if (!renderPopup) return;

    try {
      const popup = document.createElement("div");
      const root = createRoot(popup);
      root.render(renderPopup(area));
      const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
        .setLngLat(areaLngLat)
        .setDOMContent(popup)
        .addTo(map.current);

      mapboxPopup.on("close", () => {
        onClosePopup?.();
      });
    } catch (e) {
      console.warn(e);
    }
  };

  const _renderRoadPopup = (roads: Area[]) => {
    if (!renderRoadPopup) return;

    try {
      const popup = document.createElement("div");
      const root = createRoot(popup);
      root.render(renderRoadPopup(roads));
      const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
        .setLngLat(areaLngLat)
        .setDOMContent(popup)
        .addTo(map.current);

      mapboxPopup.on("close", () => {
        onClosePopup?.();
      });
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    const area = currentSelection ? { ...currentSelection } : null;
    handleSetCurrentSelection(null);

    if (showAreaPopup && area) {
      if (area.subAreas.length === 0) return;

      if (map.current && renderPopup) {
        _renderPopup(area);
      } else if (callback) {
        callback(area);
      }
    }
  }, [showAreaPopup]);

  useEffect(() => {
    const roads = currentRoadSelections;
    handleSetCurrentRoadSelections([]);

    if (showRoadPopup) {
      if (map.current && renderRoadPopup) {
        if (roads.length === 1) {
          selectRoad({ area: roads[0], removeIfSelected: true });
        } else _renderRoadPopup(roads);
      }
    }
  }, [showRoadPopup]);

  useEffect(() => {
    if (mode === "road" || filteredAreas.length === 0) return;

    const showAreaOnMap = () => {
      return displayAreaPolygonOnMap(
        map,
        filteredAreas,
        onClickLayer,
        (area) => {
          if (area.status === AreaStatus.Failure && area.subAreas.length === 0)
            return;

          if (
            area.status === AreaStatus.Failure &&
            area.subAreas.length > 0 &&
            area.subAreas.every((a) => a.status === AreaStatus.Failure)
          )
            return;

          if (
            mode === "subarea" &&
            selectedAreas.length > 0 &&
            (selectedAreas[0].id === area.id ||
              area.subAreas.map((a) => a.id).includes(selectedAreas[0].id))
          )
            return;

          if (area.subAreas.length === 0) {
            if (mode === "area") selectArea({ area, removeIfSelected: true });

            if (mode === "subarea")
              selectSubArea({ area, removeIfSelected: true });
          } else handleSetCurrentSelection(area);
        },
      );
    };

    const maxBounds = showAreaOnMap();

    const bounds = new mapboxgl.LngLatBounds(maxBounds);
    map.current.fitBounds(bounds);

    map.current.on("style.load", () => {
      showAreaOnMap();
    });
  }, [filteredAreas]);

  useEffect(() => {
    if (mode !== "road") return;

    const maxBounds = displayRoadLineStringOnMap(
      map,
      roadNetworks,
      undefined,
      onClickRoadLayer,
      selectRoadByRoadNetworkId,
      true,
    );

    if (maxBounds) {
      const bounds = new mapboxgl.LngLatBounds(maxBounds);
      map.current.fitBounds(bounds);
    }

    map.current.on("style.load", () => {
      displayRoadLineStringOnMap(
        map,
        roadNetworks,
        undefined,
        onClickRoadLayer,
        selectRoadByRoadNetworkId,
        true,
      );
    });
  }, [roadNetworks]);

  useEffect(() => {
    if (!map.current || mode === "road") return;

    try {
      paintMapLayers(map, filteredAreas, selectedAreas, selectedSubAreas);
    } catch (e) {
      map.current.on("load", () => {
        paintMapLayers(map, filteredAreas, selectedAreas, selectedSubAreas);
      });
    }

    map.current.on("style.load", () => {
      paintMapLayers(map, filteredAreas, selectedAreas, selectedSubAreas);
    });
  }, [filteredAreas, selectedAreas, selectedSubAreas]);

  useEffect(() => {
    if (!map.current || mode !== "road") return;

    const selectedRoadNetworks = roadNetworks.filter((road) =>
      selectedRoads.flatMap((road) => road.roadIds).includes(road.id),
    );

    try {
      paintMapRoadLayers(map, roadNetworks, selectedRoadNetworks, true);
    } catch (e) {
      map.current.on("load", () => {
        paintMapRoadLayers(map, roadNetworks, selectedRoadNetworks, true);
      });
    }

    map.current.on("style.load", () => {
      paintMapRoadLayers(map, roadNetworks, selectedRoadNetworks, true);
    });
  }, [roadNetworks, selectedRoads]);

  useEffect(() => {
    if (!newArea) return;

    const isMultiPolygon =
      // @ts-ignore
      !newArea.area.data[0].latitude && !newArea.area.data[0].longitude;

    let allLatitudes: number[] = [];
    let allLongitudes: number[] = [];

    if (isMultiPolygon) {
      const coordinates = newArea.area.data as AreaCoordinates[][];

      coordinates.forEach((coords) => {
        coords.forEach((coord) => {
          allLatitudes.push(coord.latitude);
          allLongitudes.push(coord.longitude);
        });
      });
    } else {
      const coordinates = newArea.area.data as AreaCoordinates[];
      coordinates.forEach((coords) => {
        allLatitudes.push(coords.latitude);
        allLongitudes.push(coords.longitude);
      });
    }

    const maxBounds = getMaxLatLngBounds(allLatitudes, allLongitudes, 0.001);
    if (!maxBounds) return;
  }, [newArea]);

  useEffect(() => {
    if (!map.current) return;

    map.current.setStyle(mapStyle);
  }, [mapStyle]);

  return (
    <>
      <div
        ref={mapContainer}
        className="w-full h-full"
        onContextMenu={(e) => e.preventDefault()}
      />
      <MapTypeControl index={1} />
    </>
  );
};
