import dayjs from "dayjs";

const minMax = require("dayjs/plugin/minMax");

dayjs.extend(minMax);

export const dateText = ["日", "月", "火", "水", "木", "金", "土"];

export const formatDate = (
  date: string | Date,
  withDay?: boolean,
  format?: string,
) => {
  const formattedDate = dayjs(date).format(format ?? "YYYY/MM/DD");
  return withDay
    ? `${formattedDate}（${dateText[dayjs(date).day()]}）`
    : formattedDate;
};

export const formatDateTime = (dateTime: string | Date) => {
  return dayjs(dateTime).format("YYYY/MM/DD HH:mm:ss");
};

export const getCurrentDate = () => {
  return formatDate(
    new Date(new Date().setHours(0, 0, 0, 0)),
    false,
    "YYYY-MM-DD",
  );
};

export const getDatesInRange = (
  startDate: string,
  endDate: string,
): string[] => {
  const dateList = [];
  let currentDate = dayjs(startDate);

  // Iterate through the dates
  while (
    currentDate.isSame(dayjs(endDate)) ||
    currentDate.isBefore(dayjs(endDate))
  ) {
    const formattedDate = currentDate.format("YYYY-MM-DD");
    dateList.push(formattedDate);

    // Move to the next day
    currentDate = currentDate.add(1, "day");
  }

  return dateList;
};

export const getMinAndMaxDate = (dates: Date[]) => {
  const dayjsDates = dates.map((date) => dayjs(date));

  // @ts-ignore
  const minDate: dayjs.Dayjs = dayjs.min(dayjsDates);
  // @ts-ignore
  const maxDate: dayjs.Dayjs = dayjs.max(dayjsDates);

  return [
    minDate && formatDate(minDate.toDate(), false, "YYYY-MM-DD"),
    maxDate && formatDate(maxDate.toDate(), false, "YYYY-MM-DD"),
  ];
};
