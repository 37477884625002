import { MAP_RESET_BUTTON_ID } from "../../../utils/map";

type Props = {
  onClick: (data?: any) => void;
  data?: any;
};

export const MapResetButton: React.FC<Props> = ({ onClick, data }) => {
  return (
    <div id={MAP_RESET_BUTTON_ID} style={{ display: "none" }}>
      <div
        id={`${MAP_RESET_BUTTON_ID}-content`}
        className="flex justify-end items-center p-0 h-0 w-[300px]"
      >
        <button
          id={`${MAP_RESET_BUTTON_ID}-btn`}
          className="w-[80px] h-[32px] mt-[32px] rounded bg-primary hover:!bg-primary shadow-md text-white"
          onClick={() => onClick(data)}
        >
          リセット
        </button>
      </div>
    </div>
  );
};
