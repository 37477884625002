import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { appActions } from "../ducks/app";
import {
  Area,
  AreaData,
  AreaType,
  CreateAreaMode,
  areaActions,
} from "../ducks/area";
import { City } from "../ducks/city";
import { Road } from "../ducks/road";

let lastClick = 0;

export const useCreateArea = (
  handleClose: () => void,
  preSelectedMode?: CreateAreaMode,
) => {
  const dispatch = useDispatch();
  const [createAreaNameModalOpen, setCreateAreaNameModalOpen] =
    useState<boolean>(false);

  const [currentMode, setCurrentMode] = useState<CreateAreaMode>(
    preSelectedMode ?? CreateAreaMode.Polygon,
  );
  const [currentType, setCurrentType] = useState<AreaType>(
    preSelectedMode && preSelectedMode === CreateAreaMode.RoadArea
      ? AreaType.Road
      : preSelectedMode && preSelectedMode === CreateAreaMode.Circle
        ? AreaType.Circle
        : AreaType.Polygon,
  );
  const [coordinates, setCoordinates] = useState<AreaData>([]);
  const [radius, setRadius] = useState<number>(0);
  const [selectedArea, setSelectedArea] = useState<Area | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [newPolygon, setNewPolygon] = useState<AreaData | null>(null);
  const [selectedRoads, setSelectedRoads] = useState<Road[]>([]);

  const disableSubmitButton = useMemo(() => {
    if (currentType === AreaType.Road) return selectedRoads.length === 0;

    return coordinates.length === 0 && selectedArea === null;
  }, [currentType, coordinates, selectedArea, selectedRoads]);

  const handleChangeMode = (name: string, value: string) => {
    // @ts-ignore
    setCurrentMode(name);
    setCoordinates([]);
    setSelectedArea(null);
    setSelectedCity(null);
    setNewPolygon(null);
    setSelectedRoads([]);
    if (!preSelectedMode) dispatch(areaActions.resetSelectedAreas());

    switch (name) {
      case CreateAreaMode.Polygon:
      case CreateAreaMode.Address:
      case CreateAreaMode.MultiPolygon:
        setCurrentType(AreaType.Polygon);
        break;
      case CreateAreaMode.Circle:
        setCurrentType(AreaType.Circle);
        break;
      case CreateAreaMode.RoadArea:
        setCurrentType(AreaType.Road);
        break;
      default:
        break;
    }
  };

  const handleCallback = (coords: AreaData) => {
    // Avoiding from adding multiple area to a selection when spot are layering on top of each other
    if (lastClick < Date.now() - 200) {
      lastClick = Date.now();
      setNewPolygon(null);
      setCoordinates(coords);
    }
  };

  const handleAreaCallback = (area: Area) => {
    setSelectedArea(area);
  };

  const handleRoadCallback = (roads: Road[]) => {
    setSelectedRoads(roads);
  };

  const onChangeRadius = (value: number) => setRadius(value);

  const onClickNext = () => setCreateAreaNameModalOpen(true);
  const onClose = () => setCreateAreaNameModalOpen(false);
  const onSubmit = (
    selectedArea: Area | null,
    areaName: string,
    datasetIds: number[],
    forMulti: boolean,
  ) => {
    dispatch(
      areaActions.createArea.started({
        resetSelectedArea: !preSelectedMode,
        areaName,
        datasetIds,
        forMulti,
        area: {
          type: selectedArea?.area.type ?? currentType,
          // @ts-ignore
          data:
            currentType === AreaType.Circle
              ? coordinates.map((coords) => ({ ...coords, radius }))
              : selectedArea
                ? selectedArea.area.data
                : selectedRoads.length > 0
                  ? selectedRoads.flatMap((road) =>
                      road.polygon.flatMap((polygon) => ({
                        ...polygon,
                        road_id: road.id,
                      })),
                    )
                  : coordinates,
        },
        onComplete: () => dispatch(appActions.hideLoading()),
        onSuccess: () => {
          onClose();
          handleClose();
        },
      }),
    );
  };

  const helperText = useMemo(() => {
    switch (currentMode) {
      case CreateAreaMode.Polygon:
        return "地図上をクリックし、多角形で囲むことで分析したいエリアを選択することができます。";
      case CreateAreaMode.Circle:
        return "地図上をクリックし、分析したいエリアを円で選択してください。";
      case CreateAreaMode.Address:
        return "住所からエリアを選択してください。選択後のエリアは編集可能です。";
      case CreateAreaMode.RoadArea:
        return [
          "道路をクリックし、エリア設定する道路を選択・解除してください。",
          "地図上をクリックし、多角形で範囲を選択してから、道路を選択・解除してください。",
        ];
      case CreateAreaMode.MultiPolygon:
        return "既存のポリゴンを1点選択ください。";
      default:
        return null;
    }
  }, [currentMode]);

  return {
    createAreaNameModalOpen,
    currentMode,
    coordinates,
    selectedArea,
    selectedCity,
    newPolygon,
    setSelectedCity,
    setNewPolygon,
    handleChangeMode,
    handleCallback,
    handleAreaCallback,
    handleRoadCallback,
    onChangeRadius,
    onClickNext,
    onClose,
    onSubmit,
    disableSubmitButton,
    helperText,
  };
};
