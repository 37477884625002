import { HttpStatusCode } from "axios";
import { call, put } from "typed-redux-saga";
import { AppConfig } from "../../AppConfig";
import { PageRoutes } from "../../constants/routes";
import { ApiResponse } from "../../core/@types";
import { Auth, AuthAccess } from "../../core/api";
import { authApi, userApi } from "../../core/http/openAPIClient";
import { LocalStorageManager } from "../../core/storage/LocalStorageManager";
import { getUserTenantAuthorization } from "../../utils/user";
import { appActions } from "../app";
import { assetActions } from "../asset";
import { takeEveryFsa } from "../operations";
import { handleRefreshToken } from "../refreshToken";
import { resetToInitialState } from "../resetToInitialState";
import { authActions } from "./actions";
import { UserType } from "./types";

export function* authSaga() {
  yield takeEveryFsa(authActions.login.started, function* (action) {
    const { emailAddress, password, onComplete, onError, onSuccess } =
      action.payload;
    const { response, error }: ApiResponse<Auth> = yield call(() => {
      return authApi
        .login({ user_name: emailAddress, password })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) return onError?.(error);

    yield* put(
      authActions.login.done({
        params: action.payload,
        result: { userId: response.data.iam?.user_id ?? 0 },
      }),
    );

    LocalStorageManager.Instance.saveObject(
      AppConfig.Instance.LocalStorageKey["accessToken"],
      response.data.access_token,
    );
    LocalStorageManager.Instance.saveObject(
      AppConfig.Instance.LocalStorageKey["refreshToken"],
      response.data.refresh_token,
    );
    LocalStorageManager.Instance.saveObject(
      AppConfig.Instance.LocalStorageKey["loggedInUser"],
      response.data.iam?.user_id,
    );

    onSuccess?.(response.data);
  });

  yield takeEveryFsa(authActions.setToken.started, function* (action) {
    const { tenantId, emailAddress, password, onComplete, onError, onSuccess } =
      action.payload;
    const { response, error }: ApiResponse<Auth> = yield call(() => {
      return authApi
        .logintoken({
          tenant_id: tenantId ?? 0,
          user_name: emailAddress,
          password,
        })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) return onError?.(error);

    LocalStorageManager.Instance.saveObject(
      AppConfig.Instance.LocalStorageKey["accessToken"],
      response.data.access_token,
    );
    LocalStorageManager.Instance.saveObject(
      AppConfig.Instance.LocalStorageKey["refreshToken"],
      response.data.refresh_token,
    );
    LocalStorageManager.Instance.saveObject(
      AppConfig.Instance.LocalStorageKey["loggedInUser"],
      response.data.iam?.user_id,
    );
    LocalStorageManager.Instance.saveObject(
      AppConfig.Instance.LocalStorageKey["tenantId"],
      response.data.iam?.tenant_id,
    );

    yield* put(
      authActions.login.done({
        params: action.payload,
        result: { userId: response.data.iam?.user_id ?? 0 },
      }),
    );

    onSuccess?.(response.data);
  });

  yield takeEveryFsa(authActions.logout.started, function* (action) {
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["accessToken"],
    );
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["refreshToken"],
    );
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["loggedInUser"],
    );
    LocalStorageManager.Instance.removeObject(
      AppConfig.Instance.LocalStorageKey["tenantId"],
    );

    yield* put(
      authActions.logout.done({
        params: action.payload,
        result: {},
      }),
    );
    yield* resetToInitialState();

    action.payload.onSuccess?.({});
    const index = window.location.href.indexOf("/#/");
    const baseUrl = window.location.href.slice(0, index);

    window.location.href = `${baseUrl}/#${PageRoutes.LOGIN}`;
  });

  yield takeEveryFsa(authActions.forgetPassword.started, function* (action) {
    const { emailAddress, onComplete, onError, onSuccess } = action.payload;
    const { response, error }: ApiResponse<AuthAccess> = yield call(() => {
      return authApi
        .sendresetmail({ user_name: emailAddress })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) return onError?.(error);

    yield* put(
      authActions.forgetPassword.done({
        params: action.payload,
        result: response.data,
      }),
    );

    onSuccess?.(response.data);
  });

  yield takeEveryFsa(authActions.resetPassword.started, function* (action) {
    const { password, secret_key, onComplete, onError, onSuccess } =
      action.payload;
    const { response, error }: ApiResponse<AuthAccess> = yield call(() => {
      return authApi
        .passwordreset({ secret_key, password })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) return onError?.(error);

    yield* put(
      authActions.resetPassword.done({
        params: action.payload,
        result: response.data,
      }),
    );

    onSuccess?.(response.data);
  });

  yield takeEveryFsa(authActions.refreshToken.started, function* (action) {
    const { refreshToken, onComplete, onError, onSuccess } = action.payload;
    const { response, error }: ApiResponse<AuthAccess> = yield call(() => {
      return authApi
        .authupdate({ refresh_token: refreshToken })
        .then((response) => response)
        .catch((error) => error);
    });

    yield* onComplete?.();

    // @ts-ignore
    if (!response || error) return yield* onError?.(error);

    LocalStorageManager.Instance.saveObject(
      AppConfig.Instance.LocalStorageKey["accessToken"],
      response.data.access_token ?? "",
    );

    yield* put(
      authActions.refreshToken.done({
        params: action.payload,
        result: { accessToken: response.data.access_token ?? "" },
      }),
    );
    // @ts-ignore
    yield* onSuccess?.(response.data);
  });

  yield takeEveryFsa(authActions.createUser.started, function* (action) {
    const { name, email, password, tenants, onComplete, onError, onSuccess } =
      action.payload;
    const { response, error }: ApiResponse<{}> = yield call(() => {
      const wheel = !!tenants.find(
        (tenant) => tenant.user_type === UserType.Admin,
      );

      return userApi
        .userregist({
          name,
          email,
          password,
          wheel,
          tenants: getUserTenantAuthorization(tenants),
        })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          authActions.createUser.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }

    yield* put(
      authActions.createUser.done({
        params: action.payload,
        result: response.data,
      }),
    );

    onSuccess?.(response.data);
    yield* put(
      appActions.displayNotification({
        type: "success",
        text: "ユーザーを保存しました",
      }),
    );

    yield* put(assetActions.fetchAssets.started({}));
  });

  yield takeEveryFsa(authActions.editUser.started, function* (action) {
    const {
      userId,
      name,
      email,
      password,
      tenants,
      onComplete,
      onError,
      onSuccess,
    } = action.payload;
    const { response, error }: ApiResponse<{}> = yield call(() => {
      const wheel = !!tenants.find(
        (tenant) => tenant.user_type === UserType.Admin,
      );

      return userApi
        .usermodify({
          id: userId,
          name,
          email,
          // @ts-ignore
          password: password.length === 0 ? undefined : password,
          wheel,
          tenants: getUserTenantAuthorization(tenants),
        })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          authActions.editUser.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }

    yield* put(
      authActions.editUser.done({
        params: action.payload,
        result: response.data,
      }),
    );

    onSuccess?.(response.data);
    yield* put(
      appActions.displayNotification({
        type: "success",
        text: "ユーザーを編集しました",
      }),
    );

    yield* put(assetActions.fetchAssets.started({}));
  });

  yield takeEveryFsa(authActions.deleteUsers.started, function* (action) {
    const { userIds, onComplete, onError, onSuccess } = action.payload;
    const { response, error }: ApiResponse<{}> = yield call(() => {
      return userApi
        .userdelete(userIds[0])
        .then((response) => response)
        .catch((error) => error);
    });

    if (response && userIds.length === 1) {
      onComplete?.();

      yield* put(
        authActions.deleteUsers.done({
          params: action.payload,
          result: response.data,
        }),
      );

      yield* put(
        appActions.displayNotification({
          type: "success",
          text: "ユーザーを削除しました",
        }),
      );
      yield* put(assetActions.fetchAssets.started({}));

      return onSuccess?.(response.data);
    }

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          authActions.deleteUsers.started(action.payload),
        );
      } else {
        onComplete?.();
        return onError?.(error);
      }
    }

    yield* put(
      authActions.deleteUsers.done({
        params: action.payload,
        result: response.data,
      }),
    );

    yield* put(
      authActions.deleteUsers.started({
        ...action.payload,
        userIds: userIds.slice(1, userIds.length),
      }),
    );
  });
}
