// @ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import React, { useCallback, useEffect, useRef } from "react";
import {
  DEFAULT_ZOOM,
  ROAD_NETWORK_MAP_MIN_ZOOM,
  STANDARD_MAP_STYLE,
} from "../../../constants/map";
import { AreaCoordinates } from "../../../ducks/area";
import { Road } from "../../../ducks/road";
import { useArea } from "../../../hooks/useArea";
import { useMapBounds } from "../../../hooks/useMapBounds";
import { useMapControl } from "../../../hooks/useMapControl";
import { useMapDraw } from "../../../hooks/useMapDraw";
import { useMapSetting } from "../../../hooks/useMapSetting";
import { RoadControls } from "../../../hooks/useRoad";
import { debounceFunction } from "../../../utils/debounce";
import { MapBounds, getMapBounds } from "../../../utils/geojson";
import {
  displayRoadLineStringOnMap,
  paintMapRoadLayers,
} from "../../../utils/linestring";
import { displayAreaPolygonOnMap } from "../../../utils/polygon";
import { MapResetButton } from "../../molecules/map/MapResetButton";
import { MapSettings } from "./MapSetting";

type Props = {
  roadControls: RoadControls;
  callback: (coordinates: AreaCoordinates[]) => void;
  roadCallback: (roads: Road[]) => void;
  showSelectedArea?: boolean;
};

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN;

export const PolygonRoadMap: React.FC<Props> = ({
  roadControls,
  callback,
  roadCallback,
  showSelectedArea,
}) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const { mapCenter, mapZoom, setOnMapMove } = useMapBounds(map, true);
  const { mapStyle } = useMapSetting();

  const { selectedAreas } = useArea();

  const {
    roads,
    selectedRoads,
    searchRoad,
    selectOrRemoveRoad,
    removeSelectedRoad,
    resetSelectedRoads,
  } = roadControls;

  const { draw, onUpdate, onDelete, onReset } = useMapDraw({
    style: "secondary",
  });

  const handleOnUpdate = (e: any) => {
    onUpdate(e);

    callback(
      e.features[0].geometry.coordinates[0]?.map(
        (coords: [number, number]) => ({
          latitude: coords[1],
          longitude: coords[0],
        }),
      ) ?? [],
    );
  };

  const handleOnReset = (resetPolygonOnly?: boolean) => {
    onReset();
    callback([]);
    roadCallback([]);
    if (!resetPolygonOnly) resetSelectedRoads();
  };

  const {
    geocoderControl,
    navigationControl,
    languageControl,
    mapResetButton,
    dataDisplayControl2,
    mapTypeControl2,
    heatMapDisplayControl2,
  } = useMapControl(true);

  useEffect(() => {
    geocoderControl.on("result", (e) => {
      setTimeout(() => {
        handleOnReset();
        draw.changeMode(MapboxDraw.constants.modes.DRAW_POLYGON);
      }, 100);
    });
  }, []);

  const searchRoadFn = debounceFunction((bounds, mapBounds) => {
    searchRoad(bounds, mapBounds);
  }, 500);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: STANDARD_MAP_STYLE,
      center: mapCenter,
      zoom: mapZoom === DEFAULT_ZOOM ? ROAD_NETWORK_MAP_MIN_ZOOM : mapZoom,
      minZoom: ROAD_NETWORK_MAP_MIN_ZOOM,
      attributionControl: false,
    })
      .addControl(new mapboxgl.AttributionControl({ compact: false }))
      .addControl(geocoderControl)
      .addControl(navigationControl, "bottom-right")
      .addControl(languageControl)
      .addControl(mapTypeControl2, "top-right")
      .addControl(draw);

    if (showSelectedArea) {
      map.current
        .addControl(dataDisplayControl2, "top-right")
        .addControl(heatMapDisplayControl2, "top-right");
    }

    map.current.addControl(mapResetButton, "top-right");

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    map.current.on(MapboxDraw.constants.events.CREATE, handleOnUpdate);
    map.current.on(MapboxDraw.constants.events.UPDATE, handleOnUpdate);
    map.current.on(MapboxDraw.constants.events.DELETE, onDelete);
    map.current.on(MapboxDraw.constants.events.MODE_CHANGE, (e: any) => {
      if (draw.getAll().features.length === 0) {
        draw.changeMode(MapboxDraw.constants.modes.DRAW_POLYGON);
      }
    });
    draw.changeMode(MapboxDraw.constants.modes.DRAW_POLYGON);

    map.current.on("load", () => {
      const { _ne, _sw } = map.current.getBounds();
      const bounds: MapBounds = {
        northeast: [_ne.lng, _ne.lat],
        southwest: [_sw.lng, _sw.lat],
      };
      const mapBounds = getMapBounds(bounds);

      searchRoadFn(bounds, mapBounds);
    });

    setOnMapMove();

    map.current.on("move", onMapMove);

    return () => {
      resetSelectedRoads();
    };
  }, []);

  const onMapMove = useCallback(() => {
    if (!mapContainer.current) return;

    if (map.current.getZoom() < ROAD_NETWORK_MAP_MIN_ZOOM) return;

    const { _ne, _sw } = map.current.getBounds();
    const bounds: MapBounds = {
      northeast: [_ne.lng, _ne.lat],
      southwest: [_sw.lng, _sw.lat],
    };
    const mapBounds = getMapBounds(bounds);

    searchRoadFn(bounds, mapBounds);
  }, []);

  useEffect(() => {
    handleOnReset(true);
    draw.changeMode(MapboxDraw.constants.modes.DRAW_POLYGON);
  }, [selectedRoads]);

  useEffect(() => {
    displayRoadLineStringOnMap(map, roads, (road, coordinates) => {
      if (!callback) return;

      selectOrRemoveRoad(road);
    });

    map.current.on("style.load", () => {
      displayRoadLineStringOnMap(map, roads, (road, coordinates) => {
        if (!callback) return;

        selectOrRemoveRoad(road);
      });
    });
  }, [roads]);

  useEffect(() => {
    if (!map.current) return;

    try {
      paintMapRoadLayers(map, roads, selectedRoads);
      if (showSelectedArea) displayAreaPolygonOnMap(map, selectedAreas);
    } catch (e) {
      map.current.on("load", () => {
        paintMapRoadLayers(map, roads, selectedRoads);
      });
      if (showSelectedArea) displayAreaPolygonOnMap(map, selectedAreas);
    }

    map.current.on("style.load", () => {
      paintMapRoadLayers(map, roads, selectedRoads);
      if (showSelectedArea) displayAreaPolygonOnMap(map, selectedAreas);
    });
  }, [roads, selectedRoads]);

  useEffect(() => {
    if (!map.current) return;

    map.current.setStyle(mapStyle);
  }, [mapStyle]);

  return (
    <>
      <div className="flex flex-1 w-full h-full">
        <div
          ref={mapContainer}
          className="w-full h-full"
          onContextMenu={(e) => e.preventDefault()}
        />
        <MapSettings index={2} />
        <MapResetButton onClick={handleOnReset} />
      </div>
    </>
  );
};
