import { IconProps } from "../types";

export default function IconAnalysisHistory({ className }: IconProps) {
  return (
    <svg
      id="icon_history_off"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="currentColor"
    >
      <rect
        id="Rectangle_893"
        data-name="Rectangle 893"
        width="24"
        height="24"
        fill="#f0f0f0"
        opacity="0"
      />
      <path
        id="Path_637"
        data-name="Path 637"
        d="M11.971,19.792a.75.75,0,0,1-.75.75H6.931a2.178,2.178,0,0,1-2.175-2.175V6.2A2.178,2.178,0,0,1,6.931,4.02H18.707A2.178,2.178,0,0,1,20.882,6.2v4.56a.75.75,0,0,1-1.5,0V6.2a.676.676,0,0,0-.675-.675H6.931a.676.676,0,0,0-.675.675V18.367a.676.676,0,0,0,.675.675h4.29a.75.75,0,0,1,.75.75m5.4-11.036a.75.75,0,0,0-.75-.75H9.013a.75.75,0,0,0,0,1.5h7.612a.75.75,0,0,0,.75-.75m-3.626,3.526a.75.75,0,0,0-.75-.75H9.013a.75.75,0,0,0,0,1.5H13a.75.75,0,0,0,.75-.75M9.013,15.056a.75.75,0,1,0,0,1.5H10.47a.75.75,0,1,0,0-1.5ZM22.3,14.748a4.828,4.828,0,0,0-8.83,2.317h-.547a.375.375,0,0,0-.265.641l1.35,1.347a.375.375,0,0,0,.529,0l1.35-1.347a.375.375,0,0,0-.265-.641h-.656a3.335,3.335,0,1,1,1.372,3.088.75.75,0,1,0-.873,1.219A4.837,4.837,0,0,0,22.3,14.748m-4.015.114h0a.75.75,0,0,0-.75.749l0,1.828a.75.75,0,0,0,.75.751h1.282a.75.75,0,0,0,0-1.5h-.532V15.613a.749.749,0,0,0-.749-.751"
        transform="translate(-1.189 -1.006)"
      />
    </svg>
  );
}
