import { IconProps } from "../types";

export default function IconResizeNav({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.281 12"
      className={className}
    >
      <g>
        <path d="M12.281 12h-2V0h2Z" />
        <path d="M4.5 11.626 0 6.001 4.5.376l1.562 1.249L2.562 6l3.5 4.375Z" />
      </g>
    </svg>
  );
}
