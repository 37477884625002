import { useState } from "react";
import { Area } from "../../../ducks/area";
import { mergeClassNames } from "../../../utils/style";
import { AreaPopupCard } from "./AreaPopupCard";

type Props = {
  area: Area;
  multipleAnalysisAreaCount: number;
  onAnalyze: (area: Area) => void;
  onEdit: (area: Area) => void;
  onDelete: (area: Area) => void;
};

export const SubAreaListPopupCard: React.FC<Props> = ({
  area,
  multipleAnalysisAreaCount,
  onAnalyze,
  onEdit,
  onDelete,
}) => {
  const [selectedArea, setSelectedArea] = useState<Area | null>(null);

  const _renderAreaItem = (a: Area, border: boolean, key: string) => {
    return (
      <div
        key={key}
        className={mergeClassNames(
          "flex flex-1 w-[220px] justify-between items-center py-1 text-[13px]",
          border && "border-t-2",
        )}
      >
        <div className="max-w-[150px] overflow-ellipsis">
          <span className="text-[13px]">{a.area_name}</span>
          <div className="flex-1 text-[12px] text-[#999999]">
            <span className="whitespace-nowrap text-[#999999]">
              データセット：
            </span>
            {a.dataset.datasets
              .map((dataset) => dataset.dataset_name)
              .join(" / ")}
          </div>
        </div>

        <button className="text-primary" onClick={() => setSelectedArea(a)}>
          詳細確認
        </button>
      </div>
    );
  };

  if (selectedArea) {
    return (
      <AreaPopupCard
        area={{ ...selectedArea, subAreas: area.subAreas }}
        multipleAnalysisAreaCount={multipleAnalysisAreaCount}
        onAnalyze={onAnalyze}
        onEdit={onEdit}
        onDelete={onDelete}
        onClose={() => setSelectedArea(null)}
      />
    );
  }

  return (
    <div className="flex flex-col max-h-[80vh] overflow-y-auto px-3 py-1 rounded-lg bg-white shadow-lg">
      {_renderAreaItem(area, false, `sub-area-list-${area.id}`)}

      {area.subAreas.map((a, i) =>
        _renderAreaItem(a, true, `sub-area-list-${a.id}-${i}`),
      )}
    </div>
  );
};
