import { useEffect } from "react";
import { Provider } from "react-redux";
import { CartoProvider } from "./CartoProvider";
import { MaintenanceProvider } from "./MaintenanceProvider";
import { RouteConfig } from "./RouteConfig";
import {
  axiosInstance,
  requestFailure,
  requestSuccess,
} from "./core/http/openAPIClient";
import { assetActions } from "./ducks/asset";
import { store } from "./store";

function App() {
  useEffect(() => {
    store.dispatch(assetActions.fetchMessages.started({}));

    axiosInstance.interceptors.response.use(
      (config) => requestSuccess(config),
      (config) => requestFailure(config, store),
    );
  }, []);

  return (
    <Provider store={store}>
      <MaintenanceProvider>
        <CartoProvider>
          <RouteConfig />
        </CartoProvider>
      </MaintenanceProvider>
    </Provider>
  );
}

export default App;
