/* tslint:disable */
/* eslint-disable */
/**
 * UR Common API - OpenAPI 3.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.11
 * Contact: tetsuya.kunita@quad.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
import type { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base";
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setBearerAuthToObject,
  setSearchParams,
  toPathString,
} from "./common";
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, operationServerMap } from "./base";

/**
 *
 * @export
 * @interface AnalyzeHistories
 */
export interface AnalyzeHistories {
  /**
   *
   * @type {number}
   * @memberof AnalyzeHistories
   */
  total?: number;
  /**
   *
   * @type {Array<AnalyzeHistory>}
   * @memberof AnalyzeHistories
   */
  histories?: Array<AnalyzeHistory>;
}
/**
 *
 * @export
 * @interface AnalyzeHistoriesAlia
 */
export interface AnalyzeHistoriesAlia {
  /**
   *
   * @type {number}
   * @memberof AnalyzeHistoriesAlia
   */
  area_id?: number;
  /**
   *
   * @type {boolean}
   * @memberof AnalyzeHistoriesAlia
   */
  checked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalyzeHistoriesAlia
   */
  is_subarea?: boolean;
}
/**
 *
 * @export
 * @interface AnalyzeHistoriesRoad
 */
export interface AnalyzeHistoriesRoad {
  /**
   *
   * @type {number}
   * @memberof AnalyzeHistoriesRoad
   */
  road_id?: number;
  /**
   *
   * @type {boolean}
   * @memberof AnalyzeHistoriesRoad
   */
  checked?: boolean;
}
/**
 *
 * @export
 * @interface AnalyzeHistory
 */
export interface AnalyzeHistory {
  /**
   *
   * @type {number}
   * @memberof AnalyzeHistory
   */
  history_id?: number;
  /**
   *
   * @type {number}
   * @memberof AnalyzeHistory
   */
  type?: number;
  /**
   *
   * @type {Array<AnalyzeHistoriesAlia>}
   * @memberof AnalyzeHistory
   */
  area_list?: Array<AnalyzeHistoriesAlia>;
  /**
   *
   * @type {Array<AnalyzeHistoriesRoad>}
   * @memberof AnalyzeHistory
   */
  road_list?: Array<AnalyzeHistoriesRoad>;
  /**
   *
   * @type {string}
   * @memberof AnalyzeHistory
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof AnalyzeHistory
   */
  to?: string;
  /**
   *
   * @type {string}
   * @memberof AnalyzeHistory
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof AnalyzeHistory
   */
  filter?: string;
  /**
   *
   * @type {Array<ReceptionIds>}
   * @memberof AnalyzeHistory
   */
  reception_ids?: Array<ReceptionIds>;
  /**
   *
   * @type {string}
   * @memberof AnalyzeHistory
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof AnalyzeHistory
   */
  user_name?: string;
  /**
   *
   * @type {string}
   * @memberof AnalyzeHistory
   */
  created_at?: string;
}
/**
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   *
   * @type {Array<Service>}
   * @memberof Asset
   */
  services?: Array<Service>;
  /**
   *
   * @type {Array<Spot>}
   * @memberof Asset
   */
  spots?: Array<Spot>;
  /**
   *
   * @type {Array<Tenant>}
   * @memberof Asset
   */
  tenants?: Array<Tenant>;
  /**
   *
   * @type {Array<User>}
   * @memberof Asset
   */
  users?: Array<User>;
  /**
   *
   * @type {Array<Dataset>}
   * @memberof Asset
   */
  datasets?: Array<Dataset>;
}
/**
 *
 * @export
 * @interface Auth
 */
export interface Auth {
  /**
   *
   * @type {string}
   * @memberof Auth
   */
  access_token?: string;
  /**
   *
   * @type {string}
   * @memberof Auth
   */
  refresh_token?: string;
  /**
   *
   * @type {AuthIam}
   * @memberof Auth
   */
  iam?: AuthIam;
}
/**
 *
 * @export
 * @interface AuthAccess
 */
export interface AuthAccess {
  /**
   *
   * @type {string}
   * @memberof AuthAccess
   */
  access_token?: string;
}
/**
 *
 * @export
 * @interface AuthIam
 */
export interface AuthIam {
  /**
   *
   * @type {number}
   * @memberof AuthIam
   */
  user_id?: number;
  /**
   * In API-1, It is always null.
   * @type {number}
   * @memberof AuthIam
   */
  tenant_id?: number;
  /**
   * In API-1, It is always null.
   * @type {number}
   * @memberof AuthIam
   */
  authority?: number;
}
/**
 *
 * @export
 * @interface AuthRefresh
 */
export interface AuthRefresh {
  /**
   *
   * @type {string}
   * @memberof AuthRefresh
   */
  refresh_token?: string;
}
/**
 *
 * @export
 * @interface City
 */
export interface City {
  /**
   *
   * @type {number}
   * @memberof City
   */
  city_id?: number;
  /**
   *
   * @type {string}
   * @memberof City
   */
  city_name?: string;
  /**
   * Each data is entered according to type.
   * @type {Array<Polygon2>}
   * @memberof City
   */
  data?: Array<Polygon2>;
}
/**
 *
 * @export
 * @interface CitySearchRequest
 */
export interface CitySearchRequest {
  /**
   * city_id array
   * @type {Array<number>}
   * @memberof CitySearchRequest
   */
  city_id?: Array<number>;
  /**
   * search word
   * @type {string}
   * @memberof CitySearchRequest
   */
  keyword?: string;
}
/**
 *
 * @export
 * @interface CsvDownloadRequest
 */
export interface CsvDownloadRequest {
  /**
   *
   * @type {number}
   * @memberof CsvDownloadRequest
   */
  csv_id?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CsvDownloadRequest
   */
  conditions?: Array<string>;
  /**
   * csv_idに応じて異なるフォーマットのデータがPOSTされます。データがない場合はnullをセットする
   * @type {object}
   * @memberof CsvDownloadRequest
   */
  summary?: object;
  /**
   * csv_idに応じて異なるフォーマットのデータがPOSTされます
   * @type {object}
   * @memberof CsvDownloadRequest
   */
  data?: object;
}
/**
 *
 * @export
 * @interface Dataset
 */
export interface Dataset {
  /**
   *
   * @type {number}
   * @memberof Dataset
   */
  dataset_id?: number;
  /**
   *
   * @type {string}
   * @memberof Dataset
   */
  dataset_name?: string;
  /**
   *
   * @type {number}
   * @memberof Dataset
   */
  group_id?: number;
  /**
   *
   * @type {string}
   * @memberof Dataset
   */
  group_name?: string;
}
/**
 *
 * @export
 * @interface GlobalMenu
 */
export interface GlobalMenu {
  /**
   *
   * @type {number}
   * @memberof GlobalMenu
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GlobalMenu
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GlobalMenu
   */
  path?: string;
}
/**
 *
 * @export
 * @interface HistoryCondition
 */
export interface HistoryCondition {
  /**
   *
   * @type {number}
   * @memberof HistoryCondition
   */
  type?: number;
  /**
   *
   * @type {Array<AnalyzeHistoriesAlia>}
   * @memberof HistoryCondition
   */
  area_list?: Array<AnalyzeHistoriesAlia>;
  /**
   *
   * @type {Array<AnalyzeHistoriesRoad>}
   * @memberof HistoryCondition
   */
  road_list?: Array<AnalyzeHistoriesRoad>;
  /**
   *
   * @type {string}
   * @memberof HistoryCondition
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryCondition
   */
  to?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryCondition
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof HistoryCondition
   */
  filter?: string;
  /**
   *
   * @type {Array<ReceptionIds>}
   * @memberof HistoryCondition
   */
  reception_ids?: Array<ReceptionIds>;
}
/**
 *
 * @export
 * @interface HistoryResponce
 */
export interface HistoryResponce {
  /**
   *
   * @type {number}
   * @memberof HistoryResponce
   */
  history_id?: number;
}
/**
 *
 * @export
 * @interface Login
 */
export interface Login {
  /**
   *
   * @type {string}
   * @memberof Login
   */
  user_name?: string;
  /**
   *
   * @type {string}
   * @memberof Login
   */
  password?: string;
}
/**
 *
 * @export
 * @interface LoginToken
 */
export interface LoginToken {
  /**
   *
   * @type {number}
   * @memberof LoginToken
   */
  tenant_id?: number;
  /**
   *
   * @type {string}
   * @memberof LoginToken
   */
  user_name?: string;
  /**
   *
   * @type {string}
   * @memberof LoginToken
   */
  password?: string;
}
/**
 *
 * @export
 * @interface Menu
 */
export interface Menu {
  /**
   *
   * @type {Array<GlobalMenu>}
   * @memberof Menu
   */
  global_menu?: Array<GlobalMenu>;
  /**
   *
   * @type {Array<SpotMenu>}
   * @memberof Menu
   */
  spot_menu?: Array<SpotMenu>;
  /**
   *
   * @type {MenuDisplayItems}
   * @memberof Menu
   */
  display_items?: MenuDisplayItems;
}
/**
 *
 * @export
 * @interface MenuDisplayItems
 */
export interface MenuDisplayItems {
  /**
   *
   * @type {boolean}
   * @memberof MenuDisplayItems
   */
  spot_create?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MenuDisplayItems
   */
  analysis_item_selection?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MenuDisplayItems
   */
  spot_selection?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MenuDisplayItems
   */
  period_selection?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MenuDisplayItems
   */
  data_set_name?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MenuDisplayItems
   */
  data_gen_status?: boolean;
  /**
   *
   * @type {MenuDisplayItemsSpotSreateMethods}
   * @memberof MenuDisplayItems
   */
  spot_sreate_methods?: MenuDisplayItemsSpotSreateMethods;
}
/**
 *
 * @export
 * @interface MenuDisplayItemsSpotSreateMethods
 */
export interface MenuDisplayItemsSpotSreateMethods {
  /**
   *
   * @type {boolean}
   * @memberof MenuDisplayItemsSpotSreateMethods
   */
  poi?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MenuDisplayItemsSpotSreateMethods
   */
  rectangle?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MenuDisplayItemsSpotSreateMethods
   */
  circle?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MenuDisplayItemsSpotSreateMethods
   */
  polygon?: boolean;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {number}
   * @memberof ModelError
   */
  error_id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  summary?: string;
}
/**
 *
 * @export
 * @interface PasswordReset1
 */
export interface PasswordReset1 {
  /**
   *
   * @type {string}
   * @memberof PasswordReset1
   */
  user_name?: string;
}
/**
 *
 * @export
 * @interface PasswordReset2
 */
export interface PasswordReset2 {
  /**
   *
   * @type {string}
   * @memberof PasswordReset2
   */
  secret_key?: string;
  /**
   *
   * @type {string}
   * @memberof PasswordReset2
   */
  password?: string;
}
/**
 *
 * @export
 * @interface Poi
 */
export interface Poi {
  /**
   *
   * @type {number}
   * @memberof Poi
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Poi
   */
  name?: string;
  /**
   *
   * @type {Array<Polygon>}
   * @memberof Poi
   */
  polygon?: Array<Polygon>;
  /**
   *
   * @type {string}
   * @memberof Poi
   */
  create_date?: string;
  /**
   * Create status
   * @type {string}
   * @memberof Poi
   */
  status?: PoiStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof Poi
   */
  complete?: boolean;
}

export const PoiStatusEnum = {
  Approved: "approved",
  Processing: "processing",
  Success: "success",
  Failure: "failure",
} as const;

export type PoiStatusEnum = (typeof PoiStatusEnum)[keyof typeof PoiStatusEnum];

/**
 *
 * @export
 * @interface Polygon
 */
export interface Polygon {
  /**
   *
   * @type {number}
   * @memberof Polygon
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof Polygon
   */
  longitude?: number;
  /**
   *
   * @type {number}
   * @memberof Polygon
   */
  radius?: number;
}
/**
 *
 * @export
 * @interface Polygon2
 */
export interface Polygon2 {
  /**
   *
   * @type {number}
   * @memberof Polygon2
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof Polygon2
   */
  longitude?: number;
}
/**
 *
 * @export
 * @interface Reception
 */
export interface Reception {
  /**
   *
   * @type {string}
   * @memberof Reception
   */
  reception_id?: string;
}
/**
 *
 * @export
 * @interface ReceptionIds
 */
export interface ReceptionIds {
  /**
   *
   * @type {string}
   * @memberof ReceptionIds
   */
  reception_id?: string;
  /**
   *
   * @type {number}
   * @memberof ReceptionIds
   */
  analyze_id?: number;
}
/**
 *
 * @export
 * @interface ReceptionResponse
 */
export interface ReceptionResponse {
  /**
   *
   * @type {number}
   * @memberof ReceptionResponse
   */
  spot_id?: number;
  /**
   *
   * @type {Array<ReceptionResponseDaily>}
   * @memberof ReceptionResponse
   */
  daily?: Array<ReceptionResponseDaily>;
  /**
   *
   * @type {Array<ReceptionResponseHourly>}
   * @memberof ReceptionResponse
   */
  hourly?: Array<ReceptionResponseHourly>;
  /**
   * Create status
   * @type {string}
   * @memberof ReceptionResponse
   */
  status?: ReceptionResponseStatusEnum;
}

export const ReceptionResponseStatusEnum = {
  Approved: "approved",
  Processing: "processing",
  Success: "success",
  Failure: "failure",
} as const;

export type ReceptionResponseStatusEnum =
  (typeof ReceptionResponseStatusEnum)[keyof typeof ReceptionResponseStatusEnum];

/**
 *
 * @export
 * @interface ReceptionResponseDaily
 */
export interface ReceptionResponseDaily {
  /**
   *
   * @type {string}
   * @memberof ReceptionResponseDaily
   */
  date?: string;
  /**
   *
   * @type {ReceptionResponseDailyData}
   * @memberof ReceptionResponseDaily
   */
  data?: ReceptionResponseDailyData;
}
/**
 *
 * @export
 * @interface ReceptionResponseDailyData
 */
export interface ReceptionResponseDailyData {
  /**
   *
   * @type {number}
   * @memberof ReceptionResponseDailyData
   */
  "100010000001000010010001000001"?: number;
}
/**
 *
 * @export
 * @interface ReceptionResponseHourly
 */
export interface ReceptionResponseHourly {
  /**
   *
   * @type {string}
   * @memberof ReceptionResponseHourly
   */
  date?: string;
  /**
   *
   * @type {ReceptionResponseDailyData}
   * @memberof ReceptionResponseHourly
   */
  data?: ReceptionResponseDailyData;
}
/**
 *
 * @export
 * @interface Road
 */
export interface Road {
  /**
   *
   * @type {number}
   * @memberof Road
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Road
   */
  name?: string;
  /**
   * 0:Road or 1:Road network
   * @type {number}
   * @memberof Road
   */
  data_type?: RoadDataTypeEnum;
  /**
   *
   * @type {Array<Polygon>}
   * @memberof Road
   */
  polygon?: Array<Polygon>;
  /**
   *
   * @type {string}
   * @memberof Road
   */
  create_date?: string;
}

export const RoadDataTypeEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type RoadDataTypeEnum =
  (typeof RoadDataTypeEnum)[keyof typeof RoadDataTypeEnum];

/**
 * 指定したポリゴン内に含まれる道路を返す
 * @export
 * @interface RoadListRequest
 */
export interface RoadListRequest {
  /**
   *
   * @type {Array<Polygon>}
   * @memberof RoadListRequest
   */
  polygon?: Array<Polygon>;
}
/**
 *
 * @export
 * @interface RoadNetworkCreateRequest
 */
export interface RoadNetworkCreateRequest {
  /**
   *
   * @type {RoadNetworkCreateRequestArea}
   * @memberof RoadNetworkCreateRequest
   */
  area?: RoadNetworkCreateRequestArea;
}
/**
 *
 * @export
 * @interface RoadNetworkCreateRequestArea
 */
export interface RoadNetworkCreateRequestArea {
  /**
   * GEOの線データ
   * @type {string}
   * @memberof RoadNetworkCreateRequestArea
   */
  type?: RoadNetworkCreateRequestAreaTypeEnum;
  /**
   * Each data is entered according to type.
   * @type {Array<Polygon2>}
   * @memberof RoadNetworkCreateRequestArea
   */
  data?: Array<Polygon2>;
}

export const RoadNetworkCreateRequestAreaTypeEnum = {
  Line: "line",
} as const;

export type RoadNetworkCreateRequestAreaTypeEnum =
  (typeof RoadNetworkCreateRequestAreaTypeEnum)[keyof typeof RoadNetworkCreateRequestAreaTypeEnum];

/**
 *
 * @export
 * @interface Roadnetwork
 */
export interface Roadnetwork {
  /**
   *
   * @type {number}
   * @memberof Roadnetwork
   */
  id?: number;
  /**
   *
   * @type {Array<Polygon2>}
   * @memberof Roadnetwork
   */
  polygon?: Array<Polygon2>;
  /**
   *
   * @type {string}
   * @memberof Roadnetwork
   */
  status?: RoadnetworkStatusEnum;
}

export const RoadnetworkStatusEnum = {
  Waiting: "waiting",
  Created: "created",
} as const;

export type RoadnetworkStatusEnum =
  (typeof RoadnetworkStatusEnum)[keyof typeof RoadnetworkStatusEnum];

/**
 *
 * @export
 * @interface RoadnetworkSearchRequest
 */
export interface RoadnetworkSearchRequest {
  /**
   * Each data is entered according to type.
   * @type {Array<Polygon>}
   * @memberof RoadnetworkSearchRequest
   */
  area?: Array<Polygon>;
}
/**
 *
 * @export
 * @interface SaveAnalysisHistoryCondition
 */
export interface SaveAnalysisHistoryCondition {
  /**
   *
   * @type {Array<AnalyzeHistoriesAlia>}
   * @memberof SaveAnalysisHistoryCondition
   */
  area_list?: Array<AnalyzeHistoriesAlia>;
  /**
   *
   * @type {Array<AnalyzeHistoriesRoad>}
   * @memberof SaveAnalysisHistoryCondition
   */
  road_list?: Array<AnalyzeHistoriesRoad>;
  /**
   *
   * @type {string}
   * @memberof SaveAnalysisHistoryCondition
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof SaveAnalysisHistoryCondition
   */
  filter?: string;
}
/**
 *
 * @export
 * @interface Service
 */
export interface Service {
  /**
   *
   * @type {number}
   * @memberof Service
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Service
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Service
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof Service
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Service
   */
  pubsub?: string;
  /**
   *
   * @type {Menu}
   * @memberof Service
   */
  menu?: Menu;
}
/**
 *
 * @export
 * @interface Spot
 */
export interface Spot {
  /**
   * エリアID
   * @type {number}
   * @memberof Spot
   */
  id?: number;
  /**
   * タイプ
   * @type {string}
   * @memberof Spot
   */
  spot_type?: SpotSpotTypeEnum;
  /**
   * エリア名
   * @type {string}
   * @memberof Spot
   */
  spot_name?: string;
  /**
   *
   * @type {SpotDataset}
   * @memberof Spot
   */
  dataset?: SpotDataset;
  /**
   *
   * @type {SpotArea}
   * @memberof Spot
   */
  area?: SpotArea;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  create_date?: string;
  /**
   * Create status
   * @type {string}
   * @memberof Spot
   */
  status?: SpotStatusEnum;
  /**
   * 複数分析の利用有無
   * @type {boolean}
   * @memberof Spot
   */
  for_multi?: boolean;
}

export const SpotSpotTypeEnum = {
  Area: "area",
  Road: "road",
} as const;

export type SpotSpotTypeEnum =
  (typeof SpotSpotTypeEnum)[keyof typeof SpotSpotTypeEnum];
export const SpotStatusEnum = {
  Approved: "approved",
  Processing: "processing",
  Success: "success",
  Failure: "failure",
} as const;

export type SpotStatusEnum =
  (typeof SpotStatusEnum)[keyof typeof SpotStatusEnum];

/**
 *
 * @export
 * @interface SpotAnalysisRequest
 */
export interface SpotAnalysisRequest {
  /**
   * spot_id array
   * @type {Array<number>}
   * @memberof SpotAnalysisRequest
   */
  spot_id: Array<number>;
  /**
   *
   * @type {number}
   * @memberof SpotAnalysisRequest
   */
  analyze_id: number;
  /**
   *
   * @type {string}
   * @memberof SpotAnalysisRequest
   */
  filter_mask: string;
  /**
   *
   * @type {string}
   * @memberof SpotAnalysisRequest
   */
  start_date: string;
  /**
   *
   * @type {string}
   * @memberof SpotAnalysisRequest
   */
  end_date: string;
  /**
   *
   * @type {boolean}
   * @memberof SpotAnalysisRequest
   */
  send_email: boolean;
  /**
   * If not specified, set null
   * @type {string}
   * @memberof SpotAnalysisRequest
   */
  url?: string;
  /**
   * Specify whether or not to save in the analysis history
   * @type {boolean}
   * @memberof SpotAnalysisRequest
   */
  is_visible?: boolean;
}
/**
 *
 * @export
 * @interface SpotArea
 */
export interface SpotArea {
  /**
   * エリア定義の方法
   * @type {string}
   * @memberof SpotArea
   */
  type?: SpotAreaTypeEnum;
  /**
   * Each data is entered according to type.
   * @type {Array<Polygon>}
   * @memberof SpotArea
   */
  data?: Array<Polygon>;
  /**
   * sub data
   * @type {Array<Polygon>}
   * @memberof SpotArea
   */
  sub_data?: Array<Polygon>;
  /**
   * Circle radius
   * @type {Array<number>}
   * @memberof SpotArea
   */
  radius?: Array<number>;
  /**
   * road_id array
   * @type {Array<number>}
   * @memberof SpotArea
   */
  road_id?: Array<number>;
}

export const SpotAreaTypeEnum = {
  Polygon: "polygon",
  Circle: "circle",
  Rectangle: "rectangle",
  Road: "road",
  Poi: "poi",
} as const;

export type SpotAreaTypeEnum =
  (typeof SpotAreaTypeEnum)[keyof typeof SpotAreaTypeEnum];

/**
 *
 * @export
 * @interface SpotCreateRequest
 */
export interface SpotCreateRequest {
  /**
   * タイプ
   * @type {string}
   * @memberof SpotCreateRequest
   */
  spot_type?: SpotCreateRequestSpotTypeEnum;
  /**
   * エリア名
   * @type {string}
   * @memberof SpotCreateRequest
   */
  spot_name?: string;
  /**
   * データセットID
   * @type {Array<number>}
   * @memberof SpotCreateRequest
   */
  dataset_id?: Array<number>;
  /**
   *
   * @type {SpotCreateRequestArea}
   * @memberof SpotCreateRequest
   */
  area?: SpotCreateRequestArea;
  /**
   * 複数エリア分析での利用有無
   * @type {boolean}
   * @memberof SpotCreateRequest
   */
  for_multi?: boolean;
}

export const SpotCreateRequestSpotTypeEnum = {
  Area: "area",
  Road: "road",
} as const;

export type SpotCreateRequestSpotTypeEnum =
  (typeof SpotCreateRequestSpotTypeEnum)[keyof typeof SpotCreateRequestSpotTypeEnum];

/**
 *
 * @export
 * @interface SpotCreateRequestArea
 */
export interface SpotCreateRequestArea {
  /**
   * polygon, circle, rectangle, etc...
   * @type {string}
   * @memberof SpotCreateRequestArea
   */
  type?: SpotCreateRequestAreaTypeEnum;
  /**
   * Each data is entered according to type.
   * @type {Array<Polygon>}
   * @memberof SpotCreateRequestArea
   */
  data?: Array<Polygon>;
  /**
   * sub data
   * @type {Array<Polygon>}
   * @memberof SpotCreateRequestArea
   */
  sub_data?: Array<Polygon>;
  /**
   * Circle radius
   * @type {Array<number>}
   * @memberof SpotCreateRequestArea
   */
  radius?: Array<number>;
  /**
   * road_id array
   * @type {Array<number>}
   * @memberof SpotCreateRequestArea
   */
  road_id?: Array<number>;
}

export const SpotCreateRequestAreaTypeEnum = {
  Polygon: "polygon",
  Circle: "circle",
  Rectangle: "rectangle",
  Road: "road",
  Poi: "poi",
} as const;

export type SpotCreateRequestAreaTypeEnum =
  (typeof SpotCreateRequestAreaTypeEnum)[keyof typeof SpotCreateRequestAreaTypeEnum];

/**
 *
 * @export
 * @interface SpotDataset
 */
export interface SpotDataset {
  /**
   *
   * @type {number}
   * @memberof SpotDataset
   */
  group_id?: number;
  /**
   *
   * @type {string}
   * @memberof SpotDataset
   */
  group_name?: string;
  /**
   *
   * @type {Array<SpotDatasetDatasetsInner>}
   * @memberof SpotDataset
   */
  datasets?: Array<SpotDatasetDatasetsInner>;
}
/**
 *
 * @export
 * @interface SpotDatasetDatasetsInner
 */
export interface SpotDatasetDatasetsInner {
  /**
   *
   * @type {number}
   * @memberof SpotDatasetDatasetsInner
   */
  dataset_id?: number;
  /**
   *
   * @type {string}
   * @memberof SpotDatasetDatasetsInner
   */
  dataset_name?: string;
}
/**
 *
 * @export
 * @interface SpotMenu
 */
export interface SpotMenu {
  /**
   *
   * @type {number}
   * @memberof SpotMenu
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpotMenu
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SpotMenu
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SpotMenu
   */
  usecase_description?: string;
  /**
   *
   * @type {string}
   * @memberof SpotMenu
   */
  image_url?: string;
  /**
   *
   * @type {number}
   * @memberof SpotMenu
   */
  min?: number;
  /**
   *
   * @type {number}
   * @memberof SpotMenu
   */
  max?: number;
  /**
   *
   * @type {number}
   * @memberof SpotMenu
   */
  retroactiveDate?: number;
  /**
   *
   * @type {string}
   * @memberof SpotMenu
   */
  type?: string;
}
/**
 *
 * @export
 * @interface SpotModifyRequest
 */
export interface SpotModifyRequest {
  /**
   *
   * @type {number}
   * @memberof SpotModifyRequest
   */
  id?: number;
  /**
   * タイプ
   * @type {string}
   * @memberof SpotModifyRequest
   */
  spot_type?: SpotModifyRequestSpotTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpotModifyRequest
   */
  spot_name?: string;
  /**
   *
   * @type {string}
   * @memberof SpotModifyRequest
   */
  dataset_name?: string;
  /**
   *
   * @type {SpotCreateRequestArea}
   * @memberof SpotModifyRequest
   */
  area?: SpotCreateRequestArea;
}

export const SpotModifyRequestSpotTypeEnum = {
  Area: "area",
  Road: "road",
} as const;

export type SpotModifyRequestSpotTypeEnum =
  (typeof SpotModifyRequestSpotTypeEnum)[keyof typeof SpotModifyRequestSpotTypeEnum];

/**
 *
 * @export
 * @interface Tenant
 */
export interface Tenant {
  /**
   *
   * @type {number}
   * @memberof Tenant
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Tenant
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Tenant
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof Tenant
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof Tenant
   */
  create_date?: string;
  /**
   *
   * @type {TenantSetting}
   * @memberof Tenant
   */
  setting?: TenantSetting;
  /**
   * User permissions for tenant. 0:admin / 1:
   * @type {number}
   * @memberof Tenant
   */
  ahtority?: TenantAhtorityEnum;
}

export const TenantAhtorityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type TenantAhtorityEnum =
  (typeof TenantAhtorityEnum)[keyof typeof TenantAhtorityEnum];

/**
 *
 * @export
 * @interface TenantModifyRequestBody
 */
export interface TenantModifyRequestBody {
  /**
   *
   * @type {number}
   * @memberof TenantModifyRequestBody
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TenantModifyRequestBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TenantModifyRequestBody
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof TenantModifyRequestBody
   */
  start_at: string;
  /**
   *
   * @type {TenantSetting}
   * @memberof TenantModifyRequestBody
   */
  setting: TenantSetting;
}
/**
 *
 * @export
 * @interface TenantRequestBody
 */
export interface TenantRequestBody {
  /**
   *
   * @type {string}
   * @memberof TenantRequestBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TenantRequestBody
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof TenantRequestBody
   */
  start_at: string;
  /**
   *
   * @type {TenantSetting}
   * @memberof TenantRequestBody
   */
  setting: TenantSetting;
}
/**
 *
 * @export
 * @interface TenantResponse
 */
export interface TenantResponse {
  /**
   *
   * @type {string}
   * @memberof TenantResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface TenantSetting
 */
export interface TenantSetting {
  /**
   *
   * @type {Polygon}
   * @memberof TenantSetting
   */
  default_location?: Polygon;
}
/**
 *
 * @export
 * @interface Town
 */
export interface Town {
  /**
   *
   * @type {string}
   * @memberof Town
   */
  town_id?: string;
  /**
   *
   * @type {string}
   * @memberof Town
   */
  town_name?: string;
  /**
   * Each data is entered according to type.
   * @type {Array<Polygon2>}
   * @memberof Town
   */
  data?: Array<Polygon2>;
}
/**
 *
 * @export
 * @interface TownSearchRequest
 */
export interface TownSearchRequest {
  /**
   * town_id array
   * @type {Array<string>}
   * @memberof TownSearchRequest
   */
  town_id?: Array<string>;
  /**
   * search word
   * @type {string}
   * @memberof TownSearchRequest
   */
  keyword?: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  wheel?: boolean;
  /**
   *
   * @type {Array<UserTenant>}
   * @memberof User
   */
  tenants?: Array<UserTenant>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  create_date?: string;
}
/**
 *
 * @export
 * @interface UserModifyRequestBody
 */
export interface UserModifyRequestBody {
  /**
   *
   * @type {number}
   * @memberof UserModifyRequestBody
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserModifyRequestBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserModifyRequestBody
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserModifyRequestBody
   */
  password: string;
  /**
   *
   * @type {boolean}
   * @memberof UserModifyRequestBody
   */
  wheel: boolean;
  /**
   *
   * @type {Array<UserTenant>}
   * @memberof UserModifyRequestBody
   */
  tenants: Array<UserTenant>;
}
/**
 *
 * @export
 * @interface UserRegistRequestBody
 */
export interface UserRegistRequestBody {
  /**
   *
   * @type {string}
   * @memberof UserRegistRequestBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistRequestBody
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistRequestBody
   */
  password: string;
  /**
   *
   * @type {boolean}
   * @memberof UserRegistRequestBody
   */
  wheel: boolean;
  /**
   *
   * @type {Array<UserTenant>}
   * @memberof UserRegistRequestBody
   */
  tenants: Array<UserTenant>;
}
/**
 *
 * @export
 * @interface UserTenant
 */
export interface UserTenant {
  /**
   *
   * @type {number}
   * @memberof UserTenant
   */
  tenant_id?: number;
  /**
   *
   * @type {number}
   * @memberof UserTenant
   */
  authorization?: number;
}

/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Access to asset information
     * @summary API-6: Access to asset information API
     * @param {AssetSchemaEnum} [schema] asset schemas. Narrow down the output to the data specified here.
     * @param {number} [id] asset schemas id. Narrow down the output to the data specified here. Valid only when schema is specified.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    asset: async (
      schema?: AssetSchemaEnum,
      id?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/assets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (schema !== undefined) {
        localVarQueryParameter["schema"] = schema;
      }

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration);
  return {
    /**
     * Access to asset information
     * @summary API-6: Access to asset information API
     * @param {AssetSchemaEnum} [schema] asset schemas. Narrow down the output to the data specified here.
     * @param {number} [id] asset schemas id. Narrow down the output to the data specified here. Valid only when schema is specified.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async asset(
      schema?: AssetSchemaEnum,
      id?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.asset(
        schema,
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AssetsApi.asset"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AssetsApiFp(configuration);
  return {
    /**
     * Access to asset information
     * @summary API-6: Access to asset information API
     * @param {AssetSchemaEnum} [schema] asset schemas. Narrow down the output to the data specified here.
     * @param {number} [id] asset schemas id. Narrow down the output to the data specified here. Valid only when schema is specified.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    asset(
      schema?: AssetSchemaEnum,
      id?: number,
      options?: any,
    ): AxiosPromise<Asset> {
      return localVarFp
        .asset(schema, id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
  /**
   * Access to asset information
   * @summary API-6: Access to asset information API
   * @param {AssetSchemaEnum} [schema] asset schemas. Narrow down the output to the data specified here.
   * @param {number} [id] asset schemas id. Narrow down the output to the data specified here. Valid only when schema is specified.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public asset(
    schema?: AssetSchemaEnum,
    id?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return AssetsApiFp(this.configuration)
      .asset(schema, id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const AssetSchemaEnum = {
  Services: "services",
  Spots: "spots",
  Tenants: "tenants",
  Users: "users",
  Datasets: "datasets",
} as const;
export type AssetSchemaEnum =
  (typeof AssetSchemaEnum)[keyof typeof AssetSchemaEnum];

/**
 * CitiesApi - axios parameter creator
 * @export
 */
export const CitiesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Search city
     * @summary API-17: Search city
     * @param {CitySearchRequest} citySearchRequest Search city
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchcity: async (
      citySearchRequest: CitySearchRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'citySearchRequest' is not null or undefined
      assertParamExists("searchcity", "citySearchRequest", citySearchRequest);
      const localVarPath = `/cities/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        citySearchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Search town
     * @summary API-30: Search town
     * @param {TownSearchRequest} townSearchRequest Search town
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchtown: async (
      townSearchRequest: TownSearchRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'townSearchRequest' is not null or undefined
      assertParamExists("searchtown", "townSearchRequest", townSearchRequest);
      const localVarPath = `/towns/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        townSearchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CitiesApi - functional programming interface
 * @export
 */
export const CitiesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CitiesApiAxiosParamCreator(configuration);
  return {
    /**
     * Search city
     * @summary API-17: Search city
     * @param {CitySearchRequest} citySearchRequest Search city
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchcity(
      citySearchRequest: CitySearchRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<City>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchcity(
        citySearchRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CitiesApi.searchcity"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Search town
     * @summary API-30: Search town
     * @param {TownSearchRequest} townSearchRequest Search town
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchtown(
      townSearchRequest: TownSearchRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Town>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchtown(
        townSearchRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CitiesApi.searchtown"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * CitiesApi - factory interface
 * @export
 */
export const CitiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CitiesApiFp(configuration);
  return {
    /**
     * Search city
     * @summary API-17: Search city
     * @param {CitySearchRequest} citySearchRequest Search city
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchcity(
      citySearchRequest: CitySearchRequest,
      options?: any,
    ): AxiosPromise<Array<City>> {
      return localVarFp
        .searchcity(citySearchRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Search town
     * @summary API-30: Search town
     * @param {TownSearchRequest} townSearchRequest Search town
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchtown(
      townSearchRequest: TownSearchRequest,
      options?: any,
    ): AxiosPromise<Array<Town>> {
      return localVarFp
        .searchtown(townSearchRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CitiesApi - object-oriented interface
 * @export
 * @class CitiesApi
 * @extends {BaseAPI}
 */
export class CitiesApi extends BaseAPI {
  /**
   * Search city
   * @summary API-17: Search city
   * @param {CitySearchRequest} citySearchRequest Search city
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CitiesApi
   */
  public searchcity(
    citySearchRequest: CitySearchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CitiesApiFp(this.configuration)
      .searchcity(citySearchRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Search town
   * @summary API-30: Search town
   * @param {TownSearchRequest} townSearchRequest Search town
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CitiesApi
   */
  public searchtown(
    townSearchRequest: TownSearchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CitiesApiFp(this.configuration)
      .searchtown(townSearchRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HistoriesApi - axios parameter creator
 * @export
 */
export const HistoriesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get analysis history
     * @summary API-18: Get analysis history
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    history: async (
      offset?: number,
      limit?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add analysis history
     * @summary API-20: Add analysis history
     * @param {HistoryCondition} [historyCondition] Save your analysis options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    historyadd: async (
      historyCondition?: HistoryCondition,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/history/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        historyCondition,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API for deleting analysis history.
     * @summary API-21: Delete analysis history
     * @param {number} id History id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    historydelete: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("historydelete", "id", id);
      const localVarPath = `/history/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API for storing analysis history.
     * @summary API-19: Save analysis history
     * @param {number} id History id
     * @param {SaveAnalysisHistoryCondition} saveAnalysisHistoryCondition Conditions specified when performing the analysis.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    historymodify: async (
      id: number,
      saveAnalysisHistoryCondition: SaveAnalysisHistoryCondition,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("historymodify", "id", id);
      // verify required parameter 'saveAnalysisHistoryCondition' is not null or undefined
      assertParamExists(
        "historymodify",
        "saveAnalysisHistoryCondition",
        saveAnalysisHistoryCondition,
      );
      const localVarPath = `/history/{id}/modify`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveAnalysisHistoryCondition,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HistoriesApi - functional programming interface
 * @export
 */
export const HistoriesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    HistoriesApiAxiosParamCreator(configuration);
  return {
    /**
     * Get analysis history
     * @summary API-18: Get analysis history
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async history(
      offset?: number,
      limit?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AnalyzeHistories>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.history(
        offset,
        limit,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["HistoriesApi.history"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Add analysis history
     * @summary API-20: Add analysis history
     * @param {HistoryCondition} [historyCondition] Save your analysis options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async historyadd(
      historyCondition?: HistoryCondition,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HistoryResponce>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.historyadd(
        historyCondition,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["HistoriesApi.historyadd"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * API for deleting analysis history.
     * @summary API-21: Delete analysis history
     * @param {number} id History id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async historydelete(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.historydelete(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["HistoriesApi.historydelete"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * API for storing analysis history.
     * @summary API-19: Save analysis history
     * @param {number} id History id
     * @param {SaveAnalysisHistoryCondition} saveAnalysisHistoryCondition Conditions specified when performing the analysis.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async historymodify(
      id: number,
      saveAnalysisHistoryCondition: SaveAnalysisHistoryCondition,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.historymodify(
        id,
        saveAnalysisHistoryCondition,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["HistoriesApi.historymodify"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * HistoriesApi - factory interface
 * @export
 */
export const HistoriesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HistoriesApiFp(configuration);
  return {
    /**
     * Get analysis history
     * @summary API-18: Get analysis history
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    history(
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<AnalyzeHistories> {
      return localVarFp
        .history(offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add analysis history
     * @summary API-20: Add analysis history
     * @param {HistoryCondition} [historyCondition] Save your analysis options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    historyadd(
      historyCondition?: HistoryCondition,
      options?: any,
    ): AxiosPromise<HistoryResponce> {
      return localVarFp
        .historyadd(historyCondition, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API for deleting analysis history.
     * @summary API-21: Delete analysis history
     * @param {number} id History id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    historydelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .historydelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * API for storing analysis history.
     * @summary API-19: Save analysis history
     * @param {number} id History id
     * @param {SaveAnalysisHistoryCondition} saveAnalysisHistoryCondition Conditions specified when performing the analysis.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    historymodify(
      id: number,
      saveAnalysisHistoryCondition: SaveAnalysisHistoryCondition,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .historymodify(id, saveAnalysisHistoryCondition, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HistoriesApi - object-oriented interface
 * @export
 * @class HistoriesApi
 * @extends {BaseAPI}
 */
export class HistoriesApi extends BaseAPI {
  /**
   * Get analysis history
   * @summary API-18: Get analysis history
   * @param {number} [offset] The number of items to skip before starting to collect the result set
   * @param {number} [limit] The numbers of items to return
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HistoriesApi
   */
  public history(
    offset?: number,
    limit?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return HistoriesApiFp(this.configuration)
      .history(offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add analysis history
   * @summary API-20: Add analysis history
   * @param {HistoryCondition} [historyCondition] Save your analysis options
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HistoriesApi
   */
  public historyadd(
    historyCondition?: HistoryCondition,
    options?: RawAxiosRequestConfig,
  ) {
    return HistoriesApiFp(this.configuration)
      .historyadd(historyCondition, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API for deleting analysis history.
   * @summary API-21: Delete analysis history
   * @param {number} id History id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HistoriesApi
   */
  public historydelete(id: number, options?: RawAxiosRequestConfig) {
    return HistoriesApiFp(this.configuration)
      .historydelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API for storing analysis history.
   * @summary API-19: Save analysis history
   * @param {number} id History id
   * @param {SaveAnalysisHistoryCondition} saveAnalysisHistoryCondition Conditions specified when performing the analysis.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HistoriesApi
   */
  public historymodify(
    id: number,
    saveAnalysisHistoryCondition: SaveAnalysisHistoryCondition,
    options?: RawAxiosRequestConfig,
  ) {
    return HistoriesApiFp(this.configuration)
      .historymodify(id, saveAnalysisHistoryCondition, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OauthApi - axios parameter creator
 * @export
 */
export const OauthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Refrash authorization API
     * @summary API-3: Refrash authorization API
     * @param {AuthRefresh} authRefresh Token for access token refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authupdate: async (
      authRefresh: AuthRefresh,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authRefresh' is not null or undefined
      assertParamExists("authupdate", "authRefresh", authRefresh);
      const localVarPath = `/oauth/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        authRefresh,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Authentication based on user ID and password
     * @summary API-1: Authentication API
     * @param {Login} login Authentication based on user ID and password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      login: Login,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'login' is not null or undefined
      assertParamExists("login", "login", login);
      const localVarPath = `/oauth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        login,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Authentication based on tenant ID, user ID, and password
     * @summary API-29: Regenerate Auth token with tenant ID.
     * @param {LoginToken} loginToken Authentication based on tenant ID, user ID, and password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logintoken: async (
      loginToken: LoginToken,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'loginToken' is not null or undefined
      assertParamExists("logintoken", "loginToken", loginToken);
      const localVarPath = `/oauth/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginToken,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Log out and destroy the current token
     * @summary API-2: Logout API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oauth/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Reset Password
     * @summary API-5: Password reset
     * @param {PasswordReset2} [passwordReset2] secret_key, new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordreset: async (
      passwordReset2?: PasswordReset2,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oauth/reset`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordReset2,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Email sending API for password reset. The email contains a temporary URL, and the temporary URL contains a secret key. URL example: https://ebpm-dashboard.com/password_reset/{secret_key}
     * @summary API-4: Send password reset mail
     * @param {PasswordReset1} [passwordReset1] user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendresetmail: async (
      passwordReset1?: PasswordReset1,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oauth/password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordReset1,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OauthApi - functional programming interface
 * @export
 */
export const OauthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OauthApiAxiosParamCreator(configuration);
  return {
    /**
     * Refrash authorization API
     * @summary API-3: Refrash authorization API
     * @param {AuthRefresh} authRefresh Token for access token refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authupdate(
      authRefresh: AuthRefresh,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthAccess>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authupdate(
        authRefresh,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OauthApi.authupdate"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Authentication based on user ID and password
     * @summary API-1: Authentication API
     * @param {Login} login Authentication based on user ID and password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      login: Login,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Auth>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(
        login,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OauthApi.login"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Authentication based on tenant ID, user ID, and password
     * @summary API-29: Regenerate Auth token with tenant ID.
     * @param {LoginToken} loginToken Authentication based on tenant ID, user ID, and password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logintoken(
      loginToken: LoginToken,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Auth>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logintoken(
        loginToken,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OauthApi.logintoken"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Log out and destroy the current token
     * @summary API-2: Logout API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OauthApi.logout"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Reset Password
     * @summary API-5: Password reset
     * @param {PasswordReset2} [passwordReset2] secret_key, new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async passwordreset(
      passwordReset2?: PasswordReset2,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.passwordreset(
        passwordReset2,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OauthApi.passwordreset"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Email sending API for password reset. The email contains a temporary URL, and the temporary URL contains a secret key. URL example: https://ebpm-dashboard.com/password_reset/{secret_key}
     * @summary API-4: Send password reset mail
     * @param {PasswordReset1} [passwordReset1] user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendresetmail(
      passwordReset1?: PasswordReset1,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendresetmail(
        passwordReset1,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OauthApi.sendresetmail"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * OauthApi - factory interface
 * @export
 */
export const OauthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OauthApiFp(configuration);
  return {
    /**
     * Refrash authorization API
     * @summary API-3: Refrash authorization API
     * @param {AuthRefresh} authRefresh Token for access token refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authupdate(
      authRefresh: AuthRefresh,
      options?: any,
    ): AxiosPromise<AuthAccess> {
      return localVarFp
        .authupdate(authRefresh, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Authentication based on user ID and password
     * @summary API-1: Authentication API
     * @param {Login} login Authentication based on user ID and password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(login: Login, options?: any): AxiosPromise<Auth> {
      return localVarFp
        .login(login, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Authentication based on tenant ID, user ID, and password
     * @summary API-29: Regenerate Auth token with tenant ID.
     * @param {LoginToken} loginToken Authentication based on tenant ID, user ID, and password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logintoken(loginToken: LoginToken, options?: any): AxiosPromise<Auth> {
      return localVarFp
        .logintoken(loginToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Log out and destroy the current token
     * @summary API-2: Logout API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(options?: any): AxiosPromise<void> {
      return localVarFp
        .logout(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Reset Password
     * @summary API-5: Password reset
     * @param {PasswordReset2} [passwordReset2] secret_key, new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordreset(
      passwordReset2?: PasswordReset2,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .passwordreset(passwordReset2, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Email sending API for password reset. The email contains a temporary URL, and the temporary URL contains a secret key. URL example: https://ebpm-dashboard.com/password_reset/{secret_key}
     * @summary API-4: Send password reset mail
     * @param {PasswordReset1} [passwordReset1] user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendresetmail(
      passwordReset1?: PasswordReset1,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .sendresetmail(passwordReset1, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OauthApi - object-oriented interface
 * @export
 * @class OauthApi
 * @extends {BaseAPI}
 */
export class OauthApi extends BaseAPI {
  /**
   * Refrash authorization API
   * @summary API-3: Refrash authorization API
   * @param {AuthRefresh} authRefresh Token for access token refresh
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public authupdate(authRefresh: AuthRefresh, options?: RawAxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .authupdate(authRefresh, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Authentication based on user ID and password
   * @summary API-1: Authentication API
   * @param {Login} login Authentication based on user ID and password
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public login(login: Login, options?: RawAxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .login(login, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Authentication based on tenant ID, user ID, and password
   * @summary API-29: Regenerate Auth token with tenant ID.
   * @param {LoginToken} loginToken Authentication based on tenant ID, user ID, and password
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public logintoken(loginToken: LoginToken, options?: RawAxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .logintoken(loginToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Log out and destroy the current token
   * @summary API-2: Logout API
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public logout(options?: RawAxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .logout(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Reset Password
   * @summary API-5: Password reset
   * @param {PasswordReset2} [passwordReset2] secret_key, new password
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public passwordreset(
    passwordReset2?: PasswordReset2,
    options?: RawAxiosRequestConfig,
  ) {
    return OauthApiFp(this.configuration)
      .passwordreset(passwordReset2, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Email sending API for password reset. The email contains a temporary URL, and the temporary URL contains a secret key. URL example: https://ebpm-dashboard.com/password_reset/{secret_key}
   * @summary API-4: Send password reset mail
   * @param {PasswordReset1} [passwordReset1] user ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public sendresetmail(
    passwordReset1?: PasswordReset1,
    options?: RawAxiosRequestConfig,
  ) {
    return OauthApiFp(this.configuration)
      .sendresetmail(passwordReset1, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PoisApi - axios parameter creator
 * @export
 */
export const PoisApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get information poi
     * @summary TO BE DELETED: Get information on poi
     * @param {number} id Poi id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    poiinfo: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("poiinfo", "id", id);
      const localVarPath = `/pois/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list on pois
     * @summary TO BE DELETED: Get list on pois
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    poilist: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/pois/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PoisApi - functional programming interface
 * @export
 */
export const PoisApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PoisApiAxiosParamCreator(configuration);
  return {
    /**
     * Get information poi
     * @summary TO BE DELETED: Get information on poi
     * @param {number} id Poi id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async poiinfo(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Poi>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.poiinfo(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["PoisApi.poiinfo"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list on pois
     * @summary TO BE DELETED: Get list on pois
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async poilist(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Poi>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.poilist(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["PoisApi.poilist"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * PoisApi - factory interface
 * @export
 */
export const PoisApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PoisApiFp(configuration);
  return {
    /**
     * Get information poi
     * @summary TO BE DELETED: Get information on poi
     * @param {number} id Poi id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    poiinfo(id: number, options?: any): AxiosPromise<Poi> {
      return localVarFp
        .poiinfo(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list on pois
     * @summary TO BE DELETED: Get list on pois
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    poilist(options?: any): AxiosPromise<Array<Poi>> {
      return localVarFp
        .poilist(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PoisApi - object-oriented interface
 * @export
 * @class PoisApi
 * @extends {BaseAPI}
 */
export class PoisApi extends BaseAPI {
  /**
   * Get information poi
   * @summary TO BE DELETED: Get information on poi
   * @param {number} id Poi id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PoisApi
   */
  public poiinfo(id: number, options?: RawAxiosRequestConfig) {
    return PoisApiFp(this.configuration)
      .poiinfo(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list on pois
   * @summary TO BE DELETED: Get list on pois
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PoisApi
   */
  public poilist(options?: RawAxiosRequestConfig) {
    return PoisApiFp(this.configuration)
      .poilist(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReceptionsApi - axios parameter creator
 * @export
 */
export const ReceptionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Obtaining reception results
     * @summary API-11: Obtaining reception results
     * @param {string} id Reception id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    result: async (
      id: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("result", "id", id);
      const localVarPath = `/receptions/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReceptionsApi - functional programming interface
 * @export
 */
export const ReceptionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ReceptionsApiAxiosParamCreator(configuration);
  return {
    /**
     * Obtaining reception results
     * @summary API-11: Obtaining reception results
     * @param {string} id Reception id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async result(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ReceptionResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.result(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ReceptionsApi.result"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * ReceptionsApi - factory interface
 * @export
 */
export const ReceptionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReceptionsApiFp(configuration);
  return {
    /**
     * Obtaining reception results
     * @summary API-11: Obtaining reception results
     * @param {string} id Reception id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    result(id: string, options?: any): AxiosPromise<Array<ReceptionResponse>> {
      return localVarFp
        .result(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReceptionsApi - object-oriented interface
 * @export
 * @class ReceptionsApi
 * @extends {BaseAPI}
 */
export class ReceptionsApi extends BaseAPI {
  /**
   * Obtaining reception results
   * @summary API-11: Obtaining reception results
   * @param {string} id Reception id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReceptionsApi
   */
  public result(id: string, options?: RawAxiosRequestConfig) {
    return ReceptionsApiFp(this.configuration)
      .result(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RoadNetworksApi - axios parameter creator
 * @export
 */
export const RoadNetworksApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Delete road network
     * @summary API-14: Delete road network
     * @param {number} id Road network id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roadnetworkdelete: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("roadnetworkdelete", "id", id);
      const localVarPath = `/road_networks/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add a road network
     * @summary API-12: Add a road network
     * @param {RoadNetworkCreateRequest} roadNetworkCreateRequest A road network
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roadnetworkregister: async (
      roadNetworkCreateRequest: RoadNetworkCreateRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'roadNetworkCreateRequest' is not null or undefined
      assertParamExists(
        "roadnetworkregister",
        "roadNetworkCreateRequest",
        roadNetworkCreateRequest,
      );
      const localVarPath = `/road_networks/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        roadNetworkCreateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Search road network
     * @summary API-15: Search road network
     * @param {Roadnetwork} roadnetwork Polygon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roadnetworksearch: async (
      roadnetwork: Roadnetwork,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'roadnetwork' is not null or undefined
      assertParamExists("roadnetworksearch", "roadnetwork", roadnetwork);
      const localVarPath = `/road_networks/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        roadnetwork,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RoadNetworksApi - functional programming interface
 * @export
 */
export const RoadNetworksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    RoadNetworksApiAxiosParamCreator(configuration);
  return {
    /**
     * Delete road network
     * @summary API-14: Delete road network
     * @param {number} id Road network id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async roadnetworkdelete(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.roadnetworkdelete(id, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoadNetworksApi.roadnetworkdelete"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Add a road network
     * @summary API-12: Add a road network
     * @param {RoadNetworkCreateRequest} roadNetworkCreateRequest A road network
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async roadnetworkregister(
      roadNetworkCreateRequest: RoadNetworkCreateRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reception>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.roadnetworkregister(
          roadNetworkCreateRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoadNetworksApi.roadnetworkregister"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Search road network
     * @summary API-15: Search road network
     * @param {Roadnetwork} roadnetwork Polygon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async roadnetworksearch(
      roadnetwork: Roadnetwork,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<Roadnetwork>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.roadnetworksearch(roadnetwork, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoadNetworksApi.roadnetworksearch"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * RoadNetworksApi - factory interface
 * @export
 */
export const RoadNetworksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RoadNetworksApiFp(configuration);
  return {
    /**
     * Delete road network
     * @summary API-14: Delete road network
     * @param {number} id Road network id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roadnetworkdelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .roadnetworkdelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add a road network
     * @summary API-12: Add a road network
     * @param {RoadNetworkCreateRequest} roadNetworkCreateRequest A road network
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roadnetworkregister(
      roadNetworkCreateRequest: RoadNetworkCreateRequest,
      options?: any,
    ): AxiosPromise<Reception> {
      return localVarFp
        .roadnetworkregister(roadNetworkCreateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Search road network
     * @summary API-15: Search road network
     * @param {Roadnetwork} roadnetwork Polygon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roadnetworksearch(
      roadnetwork: Roadnetwork,
      options?: any,
    ): AxiosPromise<Array<Roadnetwork>> {
      return localVarFp
        .roadnetworksearch(roadnetwork, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RoadNetworksApi - object-oriented interface
 * @export
 * @class RoadNetworksApi
 * @extends {BaseAPI}
 */
export class RoadNetworksApi extends BaseAPI {
  /**
   * Delete road network
   * @summary API-14: Delete road network
   * @param {number} id Road network id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoadNetworksApi
   */
  public roadnetworkdelete(id: number, options?: RawAxiosRequestConfig) {
    return RoadNetworksApiFp(this.configuration)
      .roadnetworkdelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add a road network
   * @summary API-12: Add a road network
   * @param {RoadNetworkCreateRequest} roadNetworkCreateRequest A road network
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoadNetworksApi
   */
  public roadnetworkregister(
    roadNetworkCreateRequest: RoadNetworkCreateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return RoadNetworksApiFp(this.configuration)
      .roadnetworkregister(roadNetworkCreateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Search road network
   * @summary API-15: Search road network
   * @param {Roadnetwork} roadnetwork Polygon
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoadNetworksApi
   */
  public roadnetworksearch(
    roadnetwork: Roadnetwork,
    options?: RawAxiosRequestConfig,
  ) {
    return RoadNetworksApiFp(this.configuration)
      .roadnetworksearch(roadnetwork, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RoadsApi - axios parameter creator
 * @export
 */
export const RoadsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get information road
     * @summary TO BE DELETED: Get information on road
     * @param {number} id road id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roadinfo: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("roadinfo", "id", id);
      const localVarPath = `/roads/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtain road master and road network data contained within the polygon specified by the parameter.
     * @summary TO BE DELETED: Get list on roads
     * @param {RoadListRequest} [roadListRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roadlist: async (
      roadListRequest?: RoadListRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/roads/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        roadListRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RoadsApi - functional programming interface
 * @export
 */
export const RoadsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RoadsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get information road
     * @summary TO BE DELETED: Get information on road
     * @param {number} id road id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async roadinfo(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Road>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.roadinfo(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoadsApi.roadinfo"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Obtain road master and road network data contained within the polygon specified by the parameter.
     * @summary TO BE DELETED: Get list on roads
     * @param {RoadListRequest} [roadListRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async roadlist(
      roadListRequest?: RoadListRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Road>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.roadlist(
        roadListRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoadsApi.roadlist"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * RoadsApi - factory interface
 * @export
 */
export const RoadsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RoadsApiFp(configuration);
  return {
    /**
     * Get information road
     * @summary TO BE DELETED: Get information on road
     * @param {number} id road id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roadinfo(id: number, options?: any): AxiosPromise<Road> {
      return localVarFp
        .roadinfo(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Obtain road master and road network data contained within the polygon specified by the parameter.
     * @summary TO BE DELETED: Get list on roads
     * @param {RoadListRequest} [roadListRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roadlist(
      roadListRequest?: RoadListRequest,
      options?: any,
    ): AxiosPromise<Array<Road>> {
      return localVarFp
        .roadlist(roadListRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RoadsApi - object-oriented interface
 * @export
 * @class RoadsApi
 * @extends {BaseAPI}
 */
export class RoadsApi extends BaseAPI {
  /**
   * Get information road
   * @summary TO BE DELETED: Get information on road
   * @param {number} id road id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoadsApi
   */
  public roadinfo(id: number, options?: RawAxiosRequestConfig) {
    return RoadsApiFp(this.configuration)
      .roadinfo(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Obtain road master and road network data contained within the polygon specified by the parameter.
   * @summary TO BE DELETED: Get list on roads
   * @param {RoadListRequest} [roadListRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoadsApi
   */
  public roadlist(
    roadListRequest?: RoadListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return RoadsApiFp(this.configuration)
      .roadlist(roadListRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SpotsApi - axios parameter creator
 * @export
 */
export const SpotsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Spot Analysis
     * @summary API-10: Spot Analysis
     * @param {SpotAnalysisRequest} spotAnalysisRequest Analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotanalyze: async (
      spotAnalysisRequest: SpotAnalysisRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spotAnalysisRequest' is not null or undefined
      assertParamExists(
        "spotanalyze",
        "spotAnalysisRequest",
        spotAnalysisRequest,
      );
      const localVarPath = `/spots/analyze`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spotAnalysisRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get analysis history
     * @summary API-18: Get analysis history
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotanalyzehistory: async (
      offset?: number,
      limit?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/spots/analyze/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete spot
     * @summary API-9: Delete spot
     * @param {SpotdeleteSpotTypeEnum} spotType Spot type
     * @param {number} id Spot id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotdelete: async (
      spotType: SpotdeleteSpotTypeEnum,
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spotType' is not null or undefined
      assertParamExists("spotdelete", "spotType", spotType);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("spotdelete", "id", id);
      const localVarPath = `/spots/delete/{spot_type}/{id}`
        .replace(`{${"spot_type"}}`, encodeURIComponent(String(spotType)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get information spot
     * @summary Get information on spot
     * @param {number} id Spot id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotinfo: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("spotinfo", "id", id);
      const localVarPath = `/spots/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Modify spots
     * @summary API-8: Modify spots
     * @param {SpotModifyRequest} spotModifyRequest Modify analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotmodify: async (
      spotModifyRequest: SpotModifyRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spotModifyRequest' is not null or undefined
      assertParamExists("spotmodify", "spotModifyRequest", spotModifyRequest);
      const localVarPath = `/spots/modify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spotModifyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add spots
     * @summary API-7: Add spots
     * @param {SpotCreateRequest} spotCreateRequest Add analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotregist: async (
      spotCreateRequest: SpotCreateRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spotCreateRequest' is not null or undefined
      assertParamExists("spotregist", "spotCreateRequest", spotCreateRequest);
      const localVarPath = `/spots/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spotCreateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This API creates a CSV file and response it to the frontend.
     * @summary API-16: donwload csv
     * @param {CsvDownloadRequest} csvDownloadRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotsanalyzedownload: async (
      csvDownloadRequest: CsvDownloadRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'csvDownloadRequest' is not null or undefined
      assertParamExists(
        "spotsanalyzedownload",
        "csvDownloadRequest",
        csvDownloadRequest,
      );
      const localVarPath = `/spots/analyze/download`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        csvDownloadRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SpotsApi - functional programming interface
 * @export
 */
export const SpotsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SpotsApiAxiosParamCreator(configuration);
  return {
    /**
     * Spot Analysis
     * @summary API-10: Spot Analysis
     * @param {SpotAnalysisRequest} spotAnalysisRequest Analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spotanalyze(
      spotAnalysisRequest: SpotAnalysisRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reception>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spotanalyze(
        spotAnalysisRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SpotsApi.spotanalyze"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get analysis history
     * @summary API-18: Get analysis history
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spotanalyzehistory(
      offset?: number,
      limit?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AnalyzeHistories>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.spotanalyzehistory(
          offset,
          limit,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SpotsApi.spotanalyzehistory"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Delete spot
     * @summary API-9: Delete spot
     * @param {SpotdeleteSpotTypeEnum} spotType Spot type
     * @param {number} id Spot id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spotdelete(
      spotType: SpotdeleteSpotTypeEnum,
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spotdelete(
        spotType,
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SpotsApi.spotdelete"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get information spot
     * @summary Get information on spot
     * @param {number} id Spot id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spotinfo(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Spot>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spotinfo(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SpotsApi.spotinfo"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Modify spots
     * @summary API-8: Modify spots
     * @param {SpotModifyRequest} spotModifyRequest Modify analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spotmodify(
      spotModifyRequest: SpotModifyRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reception>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spotmodify(
        spotModifyRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SpotsApi.spotmodify"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Add spots
     * @summary API-7: Add spots
     * @param {SpotCreateRequest} spotCreateRequest Add analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spotregist(
      spotCreateRequest: SpotCreateRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reception>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spotregist(
        spotCreateRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SpotsApi.spotregist"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * This API creates a CSV file and response it to the frontend.
     * @summary API-16: donwload csv
     * @param {CsvDownloadRequest} csvDownloadRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spotsanalyzedownload(
      csvDownloadRequest: CsvDownloadRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reception>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.spotsanalyzedownload(
          csvDownloadRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SpotsApi.spotsanalyzedownload"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * SpotsApi - factory interface
 * @export
 */
export const SpotsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SpotsApiFp(configuration);
  return {
    /**
     * Spot Analysis
     * @summary API-10: Spot Analysis
     * @param {SpotAnalysisRequest} spotAnalysisRequest Analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotanalyze(
      spotAnalysisRequest: SpotAnalysisRequest,
      options?: any,
    ): AxiosPromise<Reception> {
      return localVarFp
        .spotanalyze(spotAnalysisRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get analysis history
     * @summary API-18: Get analysis history
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotanalyzehistory(
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<AnalyzeHistories> {
      return localVarFp
        .spotanalyzehistory(offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete spot
     * @summary API-9: Delete spot
     * @param {SpotdeleteSpotTypeEnum} spotType Spot type
     * @param {number} id Spot id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotdelete(
      spotType: SpotdeleteSpotTypeEnum,
      id: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .spotdelete(spotType, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get information spot
     * @summary Get information on spot
     * @param {number} id Spot id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotinfo(id: number, options?: any): AxiosPromise<Spot> {
      return localVarFp
        .spotinfo(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Modify spots
     * @summary API-8: Modify spots
     * @param {SpotModifyRequest} spotModifyRequest Modify analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotmodify(
      spotModifyRequest: SpotModifyRequest,
      options?: any,
    ): AxiosPromise<Reception> {
      return localVarFp
        .spotmodify(spotModifyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add spots
     * @summary API-7: Add spots
     * @param {SpotCreateRequest} spotCreateRequest Add analysis spots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotregist(
      spotCreateRequest: SpotCreateRequest,
      options?: any,
    ): AxiosPromise<Reception> {
      return localVarFp
        .spotregist(spotCreateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This API creates a CSV file and response it to the frontend.
     * @summary API-16: donwload csv
     * @param {CsvDownloadRequest} csvDownloadRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spotsanalyzedownload(
      csvDownloadRequest: CsvDownloadRequest,
      options?: any,
    ): AxiosPromise<Reception> {
      return localVarFp
        .spotsanalyzedownload(csvDownloadRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SpotsApi - object-oriented interface
 * @export
 * @class SpotsApi
 * @extends {BaseAPI}
 */
export class SpotsApi extends BaseAPI {
  /**
   * Spot Analysis
   * @summary API-10: Spot Analysis
   * @param {SpotAnalysisRequest} spotAnalysisRequest Analysis spots
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpotsApi
   */
  public spotanalyze(
    spotAnalysisRequest: SpotAnalysisRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SpotsApiFp(this.configuration)
      .spotanalyze(spotAnalysisRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get analysis history
   * @summary API-18: Get analysis history
   * @param {number} [offset] The number of items to skip before starting to collect the result set
   * @param {number} [limit] The numbers of items to return
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpotsApi
   */
  public spotanalyzehistory(
    offset?: number,
    limit?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return SpotsApiFp(this.configuration)
      .spotanalyzehistory(offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete spot
   * @summary API-9: Delete spot
   * @param {SpotdeleteSpotTypeEnum} spotType Spot type
   * @param {number} id Spot id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpotsApi
   */
  public spotdelete(
    spotType: SpotdeleteSpotTypeEnum,
    id: number,
    options?: RawAxiosRequestConfig,
  ) {
    return SpotsApiFp(this.configuration)
      .spotdelete(spotType, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get information spot
   * @summary Get information on spot
   * @param {number} id Spot id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpotsApi
   */
  public spotinfo(id: number, options?: RawAxiosRequestConfig) {
    return SpotsApiFp(this.configuration)
      .spotinfo(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Modify spots
   * @summary API-8: Modify spots
   * @param {SpotModifyRequest} spotModifyRequest Modify analysis spots
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpotsApi
   */
  public spotmodify(
    spotModifyRequest: SpotModifyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SpotsApiFp(this.configuration)
      .spotmodify(spotModifyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add spots
   * @summary API-7: Add spots
   * @param {SpotCreateRequest} spotCreateRequest Add analysis spots
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpotsApi
   */
  public spotregist(
    spotCreateRequest: SpotCreateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SpotsApiFp(this.configuration)
      .spotregist(spotCreateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This API creates a CSV file and response it to the frontend.
   * @summary API-16: donwload csv
   * @param {CsvDownloadRequest} csvDownloadRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpotsApi
   */
  public spotsanalyzedownload(
    csvDownloadRequest: CsvDownloadRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SpotsApiFp(this.configuration)
      .spotsanalyzedownload(csvDownloadRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const SpotdeleteSpotTypeEnum = {
  Area: "area",
  Road: "road",
} as const;
export type SpotdeleteSpotTypeEnum =
  (typeof SpotdeleteSpotTypeEnum)[keyof typeof SpotdeleteSpotTypeEnum];

/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Delete tenant
     * @summary API-28: Delete tenant
     * @param {number} id tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantdelete: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("tenantdelete", "id", id);
      const localVarPath = `/tenants/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Modify tenant
     * @summary API-27: Modify tenant
     * @param {TenantModifyRequestBody} tenantModifyRequestBody Modify tenants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantmodify: async (
      tenantModifyRequestBody: TenantModifyRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tenantModifyRequestBody' is not null or undefined
      assertParamExists(
        "tenantmodify",
        "tenantModifyRequestBody",
        tenantModifyRequestBody,
      );
      const localVarPath = `/tenants/modify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantModifyRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add tenant
     * @summary API-22: Add tenant
     * @param {TenantRequestBody} tenantRequestBody Add tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantregist: async (
      tenantRequestBody: TenantRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tenantRequestBody' is not null or undefined
      assertParamExists("tenantregist", "tenantRequestBody", tenantRequestBody);
      const localVarPath = `/tenants/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration);
  return {
    /**
     * Delete tenant
     * @summary API-28: Delete tenant
     * @param {number} id tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantdelete(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantdelete(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["TenantsApi.tenantdelete"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Modify tenant
     * @summary API-27: Modify tenant
     * @param {TenantModifyRequestBody} tenantModifyRequestBody Modify tenants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantmodify(
      tenantModifyRequestBody: TenantModifyRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantmodify(
        tenantModifyRequestBody,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["TenantsApi.tenantmodify"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Add tenant
     * @summary API-22: Add tenant
     * @param {TenantRequestBody} tenantRequestBody Add tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantregist(
      tenantRequestBody: TenantRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantregist(
        tenantRequestBody,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["TenantsApi.tenantregist"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TenantsApiFp(configuration);
  return {
    /**
     * Delete tenant
     * @summary API-28: Delete tenant
     * @param {number} id tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantdelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .tenantdelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Modify tenant
     * @summary API-27: Modify tenant
     * @param {TenantModifyRequestBody} tenantModifyRequestBody Modify tenants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantmodify(
      tenantModifyRequestBody: TenantModifyRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .tenantmodify(tenantModifyRequestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add tenant
     * @summary API-22: Add tenant
     * @param {TenantRequestBody} tenantRequestBody Add tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantregist(
      tenantRequestBody: TenantRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .tenantregist(tenantRequestBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
  /**
   * Delete tenant
   * @summary API-28: Delete tenant
   * @param {number} id tenant id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantdelete(id: number, options?: RawAxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .tenantdelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Modify tenant
   * @summary API-27: Modify tenant
   * @param {TenantModifyRequestBody} tenantModifyRequestBody Modify tenants
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantmodify(
    tenantModifyRequestBody: TenantModifyRequestBody,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantsApiFp(this.configuration)
      .tenantmodify(tenantModifyRequestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add tenant
   * @summary API-22: Add tenant
   * @param {TenantRequestBody} tenantRequestBody Add tenant
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantregist(
    tenantRequestBody: TenantRequestBody,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantsApiFp(this.configuration)
      .tenantregist(tenantRequestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Delete user
     * @summary API-26: Delete user
     * @param {number} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userdelete: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userdelete", "id", id);
      const localVarPath = `/users/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Modify user
     * @summary API-25: Modify user
     * @param {UserModifyRequestBody} userModifyRequestBody Modify users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usermodify: async (
      userModifyRequestBody: UserModifyRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userModifyRequestBody' is not null or undefined
      assertParamExists(
        "usermodify",
        "userModifyRequestBody",
        userModifyRequestBody,
      );
      const localVarPath = `/users/modify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userModifyRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add user
     * @summary API-24: Add user
     * @param {UserRegistRequestBody} userRegistRequestBody Add user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userregist: async (
      userRegistRequestBody: UserRegistRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userRegistRequestBody' is not null or undefined
      assertParamExists(
        "userregist",
        "userRegistRequestBody",
        userRegistRequestBody,
      );
      const localVarPath = `/users/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userRegistRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * Delete user
     * @summary API-26: Delete user
     * @param {number} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userdelete(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userdelete(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UsersApi.userdelete"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Modify user
     * @summary API-25: Modify user
     * @param {UserModifyRequestBody} userModifyRequestBody Modify users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usermodify(
      userModifyRequestBody: UserModifyRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usermodify(
        userModifyRequestBody,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UsersApi.usermodify"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Add user
     * @summary API-24: Add user
     * @param {UserRegistRequestBody} userRegistRequestBody Add user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userregist(
      userRegistRequestBody: UserRegistRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userregist(
        userRegistRequestBody,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UsersApi.userregist"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * Delete user
     * @summary API-26: Delete user
     * @param {number} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userdelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .userdelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Modify user
     * @summary API-25: Modify user
     * @param {UserModifyRequestBody} userModifyRequestBody Modify users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usermodify(
      userModifyRequestBody: UserModifyRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .usermodify(userModifyRequestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add user
     * @summary API-24: Add user
     * @param {UserRegistRequestBody} userRegistRequestBody Add user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userregist(
      userRegistRequestBody: UserRegistRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .userregist(userRegistRequestBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * Delete user
   * @summary API-26: Delete user
   * @param {number} id user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userdelete(id: number, options?: RawAxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .userdelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Modify user
   * @summary API-25: Modify user
   * @param {UserModifyRequestBody} userModifyRequestBody Modify users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usermodify(
    userModifyRequestBody: UserModifyRequestBody,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usermodify(userModifyRequestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add user
   * @summary API-24: Add user
   * @param {UserRegistRequestBody} userRegistRequestBody Add user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userregist(
    userRegistRequestBody: UserRegistRequestBody,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .userregist(userRegistRequestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
