import { IconProps } from "../types";

export default function IconVisitorMini({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15.324"
      viewBox="0 0 18 15.324"
    >
      <path
        id="Path_805"
        data-name="Path 805"
        d="M10.99,14.628c-2.329,0-6.893,1.152-6.893,3.814v1.54a.733.733,0,0,0,.733.733h12.32a.733.733,0,0,0,.733-.733v-1.54c0-2.662-4.565-3.814-6.894-3.814m5.427,4.62H5.564v-.807c0-1.3,3.283-2.347,5.426-2.347s5.427,1.052,5.427,2.347ZM10.99,13.816A4.212,4.212,0,1,0,6.778,9.6a4.218,4.218,0,0,0,4.212,4.213m0-6.958A2.746,2.746,0,1,1,8.245,9.6,2.748,2.748,0,0,1,10.99,6.858m3.556-1.411a4.213,4.213,0,1,1,0,8.312,5.458,5.458,0,0,0,0-8.312M22.1,18.441v1.54a.734.734,0,0,1-.733.733H18.91a1.9,1.9,0,0,0,.147-.733v-1.54c0-1.758-1.234-2.961-2.8-3.747,2.429.276,5.838,1.446,5.838,3.747"
        transform="translate(-4.097 -5.391)"
        fill="#334a7c"
      />
    </svg>
  );
}
