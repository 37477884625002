import { IconProps } from "../types";

export default function IconAreaMovement2({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        id="Path_859"
        data-name="Path 859"
        d="M30.33,7.165H21.252V1.67A1.668,1.668,0,0,0,19.582,0H1.67A1.668,1.668,0,0,0,0,1.67V19.582a1.668,1.668,0,0,0,1.67,1.67h5.5V30.33A1.668,1.668,0,0,0,8.835,32h21.5A1.668,1.668,0,0,0,32,30.33V8.835a1.668,1.668,0,0,0-1.67-1.67M3.34,17.912V3.34H17.912V7.165H8.835a1.668,1.668,0,0,0-1.67,1.67v9.077Zm14.572,0H10.5V10.5h7.409ZM28.66,28.66H10.5V21.252h9.077a1.668,1.668,0,0,0,1.67-1.67V10.5H28.66Z"
      />
    </svg>
  );
}
