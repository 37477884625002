import { IconProps } from "../types";

export default function IconAreaDefinitionList({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill="currentColor"
    >
      <path d="M14.677 9.444A2.678 2.678 0 1 0 12 12.121a2.681 2.681 0 0 0 2.677-2.677m-3.856 0a1.178 1.178 0 1 1 1.178 1.177 1.18 1.18 0 0 1-1.178-1.177m11.069 9.989-2.978-4.386a.748.748 0 0 0-.62-.328h-2.3a8.283 8.283 0 0 0 2.17-5.045 6.166 6.166 0 1 0-12.33 0 8.285 8.285 0 0 0 2.17 5.045h-2.3a.748.748 0 0 0-.621.328l-2.972 4.386a.75.75 0 0 0 .621 1.171h18.539a.75.75 0 0 0 .621-1.171M7.334 9.675a4.667 4.667 0 1 1 9.33 0c0 1.493-.952 3.25-2.836 5.243a1.441 1.441 0 0 0-.066.07q-.779.816-1.762 1.682-.984-.866-1.762-1.682a.739.739 0 0 0-.067-.07c-1.885-1.993-2.837-3.75-2.837-5.243m-3.189 9.429 1.959-2.886H9.34q.955.978 2.174 2.014a.751.751 0 0 0 .972 0q1.219-1.036 2.174-2.014h3.236l1.959 2.886Z" />
    </svg>
  );
}
