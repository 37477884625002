import { useEffect, useMemo, useState } from "react";

type Props<T> = {
  data: T[];
  perPage?: number;
  onPageChange?: (page: number) => void;
};

export const useLocalPagination = <T>({
  data,
  perPage = 10,
  onPageChange,
}: Props<T>) => {
  const [page, setPage] = useState(1);

  const totalPage = useMemo(() => {
    if (data.length === 0) return 1;

    return Math.ceil(data.length / perPage);
  }, [data]);

  const dataToDisplay = useMemo(() => {
    return data.slice(perPage * (page - 1), perPage * page);
  }, [data, page]);

  const onPrevious = () => {
    if (page === 1) return;

    setPage(page - 1);
  };

  const onNext = () => {
    if (page === totalPage) return;

    setPage(page + 1);
  };

  const onFirst = () => setPage(1);

  const onLast = () => setPage(totalPage);

  useEffect(() => {
    if (dataToDisplay.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [dataToDisplay]);

  useEffect(() => {
    onPageChange?.(page);
  }, [page]);

  return {
    page,
    setPage,
    totalPage,
    dataToDisplay,
    onPrevious,
    onNext,
    onFirst,
    onLast,
  };
};
