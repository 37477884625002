import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../constants/routes";
import { appActions } from "../../../ducks/app";
import { assetSelectors } from "../../../ducks/asset";
import {
  LoginRequestBody,
  authActions,
  authSelectors,
} from "../../../ducks/auth";

export const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector(authSelectors.loggedInUser);
  const services = useSelector(assetSelectors.services);

  const handleLogin = useCallback((body: LoginRequestBody) => {
    const { emailAddress, password } = body;
    dispatch(appActions.showLoading());
    dispatch(
      authActions.login.started({
        emailAddress,
        password,
        onComplete: () => dispatch(appActions.hideLoading()),
        onSuccess: () =>
          navigate(PageRoutes.SELECT_PROJECT, {
            state: { body },
            replace: true,
          }),
      }),
    );
  }, []);

  const serviceName = useMemo(() => {
    return services && services.length > 0 ? services[0].name : "";
  }, [services]);

  useEffect(() => {
    document.title = serviceName;
  }, [serviceName]);

  const handleLogout = useCallback(() => {
    dispatch(
      appActions.showModal.started({
        title: "ログアウトして良いですか。",
        showXIcon: true,
        closeButtonLabel: "キャンセル",
        nextButtonLabel: "ログアウト",
        handleClose: () => dispatch(appActions.closeModal()),
        handleNext: () => {
          dispatch(authActions.logout.started({}));
          dispatch(appActions.closeModal());
        },
      }),
    );
  }, []);

  return {
    serviceName,
    loggedInUser,
    handleLogin,
    handleLogout,
  };
};
