import { IconProps } from "../types";

export default function IconVisitor2({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="32.737"
      viewBox="0 0 36 32.737"
    >
      <path
        id="Path_860"
        data-name="Path 860"
        d="M.972,12.14a4.2,4.2,0,1,1,4.2,4.2,4.2,4.2,0,0,1-4.2-4.2m9.369,10.541v8.663a1.392,1.392,0,0,1-1.393,1.393H1.393A1.392,1.392,0,0,1,0,31.344V22.681a5.171,5.171,0,0,1,10.342,0m-2.786,0a2.384,2.384,0,0,0-4.769,0v7.27H7.555ZM18,12.366a4.2,4.2,0,1,0-4.2-4.2,4.2,4.2,0,0,0,4.2,4.2m5.172,6.544V31.146a1.393,1.393,0,0,1-1.393,1.393H14.222a1.393,1.393,0,0,1-1.393-1.393V18.91a5.172,5.172,0,1,1,10.343,0m-2.786,0a2.385,2.385,0,1,0-4.77,0V29.753h4.77ZM30.83,8.4a4.2,4.2,0,1,0-4.2-4.2,4.2,4.2,0,0,0,4.2,4.2M36,15.137v15.81a1.393,1.393,0,0,1-1.393,1.393H27.052a1.393,1.393,0,0,1-1.393-1.393V15.137a5.171,5.171,0,0,1,10.342,0m-2.786,0a2.384,2.384,0,0,0-4.769,0V29.554h4.769Z"
      />
    </svg>
  );
}
