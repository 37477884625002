import { IconProps } from "../types";

export default function IconMovement({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.152"
      height="36.114"
      viewBox="0 0 34.152 36.114"
    >
      <path
        id="Path_708"
        data-name="Path 708"
        d="M24.743,23.516V20.576l-4.252,4.2,4.252,4.2V26.035h9.409v-2.52ZM29.9,30.655H20.491v2.52H29.9v2.939l4.252-4.2-4.252-4.2ZM12.807,6.719a3.4,3.4,0,0,0,3.415-3.359,3.416,3.416,0,0,0-6.83,0,3.4,3.4,0,0,0,3.415,3.359M6.4,12.43l-4.7,23.684H5.294L8.282,22.676l3.671,3.359V36.114h3.415V23.432l-3.5-3.443,1.025-5.039A11.991,11.991,0,0,0,22.2,19.317V15.957a8.785,8.785,0,0,1-7.428-4.115L13.149,9.154a3.343,3.343,0,0,0-2.9-1.6,3.475,3.475,0,0,0-1.281.252L0,11.422v7.895H3.415V13.69L6.4,12.43"
      />
    </svg>
  );
}
