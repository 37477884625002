import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { assetActions } from "./actions";
import { AssetState } from "./types";

const initialState: AssetState = {
  services: [],
};

export function AssetReducer(state = initialState, action: Action): AssetState {
  if (isType(action, assetActions.resetToInitialState)) {
    return initialState;
  }

  if (isType(action, assetActions.fetchAssets.done)) {
    return {
      ...state,
      services: action.payload.result.asset.services,
    };
  }

  return state;
}
