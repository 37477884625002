import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import circularAnimation from "../../../assets/icons/circularAnimation.json";
import { CustomButton } from "../../atoms/CustomButton";
import { LabelCheckBox } from "../../atoms/LabelCheckBox";

type Props = {
  isOpen: boolean;
  handleNext: (sendEmail: boolean) => void;
  handleClose: () => void;
};

export const AnalysisLoadingModal: React.FC<Props> = ({
  isOpen,
  handleNext,
  handleClose,
}) => {
  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const [checked, setChecked] = useState(false);

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 20,
      backgroundColor: "transparent",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 120,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={handleClose}
      shouldReturnFocusAfterClose={true}
    >
      <div className="w-[550px] h-[400px] bg-white rounded-lg pt-[25px]">
        <div className="flex justify-center items-center w-full h-[50px] text-lg text-primary">
          分析データ生成中
        </div>
        <div className="flex justify-center items-center h-[120px]">
          <Lottie
            animationData={circularAnimation}
            loop={true}
            autoplay={true}
            style={{ width: 90 }}
          />
        </div>
        <div className="flex items-center justify-center mt-[10px]">
          <LabelCheckBox
            label=""
            value={""}
            checked={checked}
            onChange={() => setChecked((prev) => !prev)}
          />
          <div className="ml-2">
            <div className="text-[13px]">完了時にメールで通知する</div>
          </div>
        </div>
        <div className="flex justify-center items-center w-full h-[50px] text-xs mt-4">
          <div className="text-center">
            <p>上記にチェックいただくと</p>
            <p className="mt-2">
              生成完了時にアカウントのメールアドレスへ通知します
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center w-full h-[88px] text-sm">
          <CustomButton
            text="分析項目の選択へ"
            onClick={() => handleNext(checked)}
            className="flex justify-center items-center w-[200px] h-[40px] text-white text-sm bg-primary rounded"
          />
        </div>
      </div>
    </Modal>
  );
};
