import IconSelectMultiple from "../../../icons/IconSelectMultiple";

type Props = {
  count: number;
};

export const AreaPolygonCount: React.FC<Props> = ({ count }) => {
  return (
    <div className="flex justify-center items-center py-[2px] px-2 bg-primary-light rounded-full">
      <IconSelectMultiple />
      <span className="text-[12px] mx-1 text-primary">{count}/100</span>
    </div>
  );
};
