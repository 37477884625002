import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FILTER_BIT_SIZE } from "../../../constants/filter";
import { HISTORY_PER_PAGE } from "../../../constants/pagination";
import {
  URL_QUERY_PARAM_AREA,
  URL_QUERY_PARAM_FILTER,
  URL_QUERY_PARAM_FILTERED_AREA,
  URL_QUERY_PARAM_FILTERED_ROAD,
  URL_QUERY_PARAM_FROM,
  URL_QUERY_PARAM_HISTORY_ID,
  URL_QUERY_PARAM_ROAD,
  URL_QUERY_PARAM_SUB_AREA,
  URL_QUERY_PARAM_TIME,
  URL_QUERY_PARAM_TO,
  URL_QUERY_PARAM_TYPE,
} from "../../../constants/param";
import { PageRoutes } from "../../../constants/routes";
import { AnalysisPattern, analysisSelectors } from "../../../ducks/analysis";
import { appActions } from "../../../ducks/app";
import { areaSelectors } from "../../../ducks/area";
import { FilterType, filterSelectors } from "../../../ducks/filter";
import {
  HistoryItem,
  historyActions,
  historySelectors,
} from "../../../ducks/history";
import { formatDate } from "../../../utils/date";

export const useAnalysisHistory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const totalHistories = useSelector(historySelectors.totalHistories);
  const histories = useSelector(historySelectors.histories);
  const analysisItems = useSelector(analysisSelectors.analysisItems);
  const areas = useSelector(areaSelectors.allAreas);
  const roads = useSelector(areaSelectors.roads);
  const filters = useSelector(filterSelectors.filters);

  const [selectedHistories, setSelectedHistories] = useState<HistoryItem[]>([]);

  const canDeleteHistory = useMemo(() => {
    return selectedHistories.length > 0;
  }, [selectedHistories]);

  const onPageChange = () => {
    setSelectedHistories([]);
  };

  const fetchHistories = (
    page: number,
    onSuccess: (data: HistoryItem[]) => void,
  ) => {
    dispatch(appActions.showLoading());
    dispatch(
      historyActions.fetchHistories.started({
        limit: HISTORY_PER_PAGE,
        offset: HISTORY_PER_PAGE * (page - 1),
        onSuccess,
        onComplete: () => dispatch(appActions.hideLoading()),
      }),
    );
  };

  const onDelete = (
    page: number,
    onFetchDataSuccess: (data: HistoryItem[]) => void,
  ) => {
    if (canDeleteHistory)
      dispatch(
        appActions.showModal.started({
          title: "分析履歴を\n削除して良いですか？",
          closeButtonLabel: "キャンセル",
          nextButtonLabel: "削除",
          handleClose: () => dispatch(appActions.closeModal()),
          handleNext: () => {
            dispatch(appActions.showLoading());
            dispatch(
              historyActions.deleteHistories.started({
                historyIds: selectedHistories.map(
                  (history) => history.history_id,
                ),
                onComplete: () => dispatch(appActions.hideLoading()),
                onSuccess: () => {
                  dispatch(appActions.closeModal());
                  setSelectedHistories([]);
                  fetchHistories(page, onFetchDataSuccess);
                },
              }),
            );
          },
        }),
      );
  };

  const onSelectHistory = (history: HistoryItem) => {
    setSelectedHistories((prev) => {
      const isSelected = prev.find((x) => x.history_id === history.history_id);
      return isSelected
        ? prev.filter((x) => x.history_id !== history.history_id)
        : [...prev, history];
    });
  };

  const onSelectAll = (histories: HistoryItem[]) => {
    setSelectedHistories((prev) => {
      const isSelected = prev.length > 0 && prev.length === histories.length;
      return isSelected ? [] : histories;
    });
  };

  const onShowHistoryResult = (history: HistoryItem) => {
    if (process.env.NODE_ENV === "development") return;

    const index = window.location.href.indexOf("/#/");
    const baseUrl = window.location.href.slice(0, index);

    const areaIds = history.area
      .filter((area) => !area.is_subarea)
      .map((area) => area.area_id);
    const subAreaIds = history.area
      .filter((area) => area.is_subarea)
      .map((area) => area.area_id);
    const roadIds = history.road.map((road) => road.road_id);
    const filteredAreaIds = history.area
      .filter((area) => !area.checked)
      .map((area) => area.area_id);
    const filteredRoadIds = history.road
      .filter((road) => !road.checked)
      .map((road) => road.road_id);

    query.set(URL_QUERY_PARAM_TYPE, history.type.toString());
    query.set(URL_QUERY_PARAM_AREA, areaIds.join(","));
    query.set(URL_QUERY_PARAM_SUB_AREA, subAreaIds.join(","));
    query.set(URL_QUERY_PARAM_ROAD, roadIds.join(","));
    query.set(URL_QUERY_PARAM_FILTERED_AREA, filteredAreaIds.join(","));
    query.set(URL_QUERY_PARAM_FILTERED_ROAD, filteredRoadIds.join(","));
    query.set(URL_QUERY_PARAM_FROM, history.from);
    query.set(URL_QUERY_PARAM_TO, history.to);
    query.set(URL_QUERY_PARAM_TIME, history.time);
    query.set(URL_QUERY_PARAM_FILTER, history.filter);
    // query.set(
    //   URL_QUERY_PARAM_ANALYZE_ID,
    //   history.receptions.map((reception) => reception.analyze_id).join(","),
    // );
    // query.set(
    //   URL_QUERY_PARAM_RECEPTION_ID,
    //   history.receptions.map((reception) => reception.reception_id).join(","),
    // );
    query.set(URL_QUERY_PARAM_HISTORY_ID, history.history_id.toString());

    window.location.href = `${baseUrl}/analyze/#${PageRoutes.ANALYSIS_RESULT}?${query}`;
  };

  const getHistoryUserName = (history: HistoryItem) => {
    return history.user_name;
  };

  const getHistoryAnalysisTypeName = (history: HistoryItem) => {
    return analysisItems.find((item) => item.type === history.type)?.name ?? "";
  };

  const getHistoryAreaNames = (history: HistoryItem) => {
    if (
      analysisItems.find((item) => item.type === history.type)?.pattern ===
      AnalysisPattern.SelectRoad
    ) {
      return roads
        .filter((a) => history.road.map((road) => road.road_id).includes(a.id))
        .sort((a, b) => a.id - b.id)
        .map((area) => area.area_name)
        .join(" / ");
    }

    return areas
      .filter((a) => history.area.map((area) => area.area_id).includes(a.id))
      .sort((a, b) => a.id - b.id)
      .map((area) => area.area_name)
      .join(" / ");
  };

  const getHistoryFilters = (history: HistoryItem) => {
    try {
      const filterMask = history.filter;

      if (
        !filterMask ||
        filterMask.split("").filter((c) => c === "0" || c === "1").length !==
          FILTER_BIT_SIZE
      ) {
        return [];
      }

      const type = history.type;
      const historyFilters = filters.find((f) => f.type === type)?.filters;

      if (!historyFilters?.length) return [];

      return historyFilters
        .map((filter) => {
          if (filter.type === FilterType.Date) {
            const from = history.from;
            const to = history.to;
            return {
              label: filter.name,
              value:
                from && to
                  ? `${formatDate(from, true)} 〜 ${formatDate(to, true)}`
                  : "",
            };
          }

          if (filter.type === FilterType.Time) {
            const [from, to] = history.time.split("-") ?? [];

            return {
              label: filter.name,
              value: from && to ? `${from}:00 〜 ${to}:00` : "",
            };
          }

          const selectedValues = filter.values
            .filter((f) => filterMask[filterMask.length - 1 - f.bit] === "1")
            .map((f) => f.value)
            .filter(Boolean);

          return {
            label: filter.name,
            value: selectedValues.length
              ? selectedValues.length === filter.values.length
                ? "全て"
                : selectedValues.join(", ")
              : null,
          };
        })
        .filter((f) => f.value);
    } catch (e) {
      return [];
    }
  };

  return {
    totalHistories,
    histories,
    canDeleteHistory,
    selectedHistories,
    fetchHistories,
    onPageChange,
    onDelete,
    onSelectAll,
    onSelectHistory,
    onShowHistoryResult,
    getHistoryUserName,
    getHistoryAnalysisTypeName,
    getHistoryAreaNames,
    getHistoryFilters,
  };
};
