import { IconProps } from "../types";

export default function IconVisitor2Mini({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17.424"
      viewBox="0 0 20 17.424"
    >
      <path
        id="Path_642"
        data-name="Path 642"
        d="M6,13.077A2.2,2.2,0,1,0,6,8.683a2.2,2.2,0,1,0,0,4.394m0-3a.807.807,0,1,1-.842.806A.826.826,0,0,1,6,10.074M6,13.4a2.923,2.923,0,0,0-2.982,2.853v5.038a.672.672,0,0,0,.686.656H8.3a.672.672,0,0,0,.687-.656V16.255A2.923,2.923,0,0,0,6,13.4m1.61,7.235H4.39V16.255A1.579,1.579,0,0,1,6,14.715a1.578,1.578,0,0,1,1.61,1.541ZM13.018,11a2.251,2.251,0,0,0,2.3-2.2,2.3,2.3,0,0,0-4.592,0,2.251,2.251,0,0,0,2.3,2.2m0-3a.807.807,0,1,1-.842.806A.825.825,0,0,1,13.018,8m0,3.328a2.923,2.923,0,0,0-2.982,2.853v7.116a.672.672,0,0,0,.686.656h4.592A.672.672,0,0,0,16,21.294V14.177a2.923,2.923,0,0,0-2.982-2.853m1.61,9.314h-3.22v-6.46a1.611,1.611,0,0,1,3.22,0ZM20.036,8.92a2.25,2.25,0,0,0,2.3-2.2,2.3,2.3,0,0,0-4.592,0,2.251,2.251,0,0,0,2.3,2.2m0-3a.807.807,0,1,1-.843.806.825.825,0,0,1,.843-.806m0,3.328A2.923,2.923,0,0,0,17.054,12.1v9.195a.672.672,0,0,0,.686.656h4.592a.672.672,0,0,0,.687-.656V12.1a2.924,2.924,0,0,0-2.982-2.853m1.61,11.392h-3.22V12.1a1.611,1.611,0,0,1,3.22,0Z"
        transform="translate(-3.018 -4.526)"
        fill="#334a7c"
      />
    </svg>
  );
}
