import { IconProps } from "../types";

export default function IconAreaMovement({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32.094"
      viewBox="0 0 32 32.094"
    >
      <path
        id="Path_857"
        data-name="Path 857"
        d="M16,0H1.67A1.669,1.669,0,0,0,0,1.67V16a1.668,1.668,0,0,0,1.67,1.67H16A1.668,1.668,0,0,0,17.67,16V1.67A1.669,1.669,0,0,0,16,0M14.33,14.33H3.34V3.34H14.33ZM32,1.67V16a1.668,1.668,0,0,1-1.67,1.67H22.679A1.668,1.668,0,0,1,21.009,16V1.67A1.669,1.669,0,0,1,22.679,0H30.33A1.669,1.669,0,0,1,32,1.67m0,21.1v7.651a1.668,1.668,0,0,1-1.67,1.67H22.679a1.668,1.668,0,0,1-1.67-1.67V22.773a1.668,1.668,0,0,1,1.67-1.67H30.33A1.668,1.668,0,0,1,32,22.773m-14.33,0v7.651A1.668,1.668,0,0,1,16,32.094H1.67A1.668,1.668,0,0,1,0,30.424V22.773A1.668,1.668,0,0,1,1.67,21.1H16a1.668,1.668,0,0,1,1.67,1.67"
      />
    </svg>
  );
}
