import React, { useEffect } from "react";
import Modal from "react-modal";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { Area, AreaType } from "../../../ducks/area";
import { CreateAreaNameForm } from "../form/CreateAreaNameForm";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (
    selectedArea: Area | null,
    areaName: string,
    datasetIds: number[],
    forMulti: boolean,
  ) => void;
  isEdit: boolean;
  isRoad: boolean;
  selectedArea: Area | null;
};

export const CreateAreaNameModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  handleSubmit,
  isEdit,
  isRoad,
  selectedArea,
}) => {
  const modalStyle = {
    content: {
      width: "600px",
      maxWidth: "60%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      zIndex: 50,
      backgroundColor: "transparent",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 140,
    },
  };

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  return (
    <Modal style={modalStyle} portalClassName="alert-modal" isOpen={isOpen}>
      <div className="flex flex-col items-center w-full rounded-lg bg-white px-[40px]">
        <div className="flex justify-between w-full pt-[17px] font-bold">
          {isEdit
            ? selectedArea?.area.type === AreaType.Road
              ? "分析道路の編集"
              : "分析エリアの編集"
            : "分析エリア・道路の新規追加"}
          <div
            onClick={handleClose}
            className="w-[32px] h-[32px] flex justify-end items-center cursor-pointer"
          >
            <CloseIcon />
          </div>
        </div>
        <div className="flex flex-col items-center max-w-[400px]">
          <CreateAreaNameForm
            defaultAreaName={isEdit ? selectedArea?.area_name ?? "" : undefined}
            defaultDatasetIds={isEdit ? [] : undefined}
            isEdit={isEdit}
            isRoad={isRoad}
            handleClose={handleClose}
            handleSubmit={(areaName, datasetIds, forMulti) => {
              handleSubmit(
                selectedArea ?? null,
                areaName,
                datasetIds,
                forMulti,
              );
            }}
            selectedArea={selectedArea}
          />
        </div>
      </div>
    </Modal>
  );
};
