import { OutlineIconProps } from "./types";

export default function IconCheckboxCheck({
  strokeWidth,
  className,
}: OutlineIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 11 9"
      className={className ?? "h-2 text-white"}
    >
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth ?? 2}
        d="m.718 3.413 3.183 3.283 5.817-6"
      />
    </svg>
  );
}
