import { Road } from "../road";

export interface AreaState {
  areas: Area[];
  roads: Area[];
  roadAreas: Record<number, Area[]>;
  roadCoordinates: Record<number, AreaCoordinates[]>;
  roadNetworks: Road[];
  allAreas: Area[];
  selectedAreas: Area[];
  selectedSubAreas: Area[];
  selectedRoads: Area[];
  multipleAnalysisAreaCount: number;
}

export enum CreateAreaMode {
  Polygon = "地図上でエリアを追加（多角形）",
  Circle = "地図上でエリアを追加（円）",
  Address = "住所からエリアを追加",
  MultiPolygon = "ポリゴンを複製し分析エリアを追加",
  RoadArea = "地図上で分析道路を追加",
}

export enum AreaStatus {
  Success = "success",
  Approved = "approved",
  Processing = "processing",
  Failure = "failure",
}

export enum AreaType {
  Polygon = "polygon",
  Circle = "circle",
  Rectangle = "rectangle",
  Road = "road",
}

export interface AreaDataset {
  group_id: number;
  group_name: string;
  datasets: {
    dataset_id: number;
    dataset_name: string;
  }[];
}

export interface Area {
  id: number;
  area_name: string;
  dataset: AreaDataset;
  area: {
    type: AreaType;
    data: AreaData;
  };
  create_date: string;
  status: AreaStatus;
  for_multi: boolean;
  subAreas: Area[];
  roadIds: number[];
}

export interface AreaCoordinates {
  latitude: number;
  longitude: number;
  radius?: number;
  road_id?: number;
}

export type AreaData = AreaCoordinates[] | AreaCoordinates[][];

export interface AreaSelectCondition {
  area: Area;
  removeIfSelected?: boolean;
}

export interface CreateAreaRequestBody {
  areaName: string;
  datasetIds: number[];
  forMulti: boolean;
  area: {
    type: AreaType;
    data: AreaCoordinates[];
  };
}

export interface EditAreaRequestBody {
  areaId: number;
  areaName: string;
  forMulti: boolean;
  area: {
    type: AreaType;
    data: AreaData;
  };
}

export interface DeleteAreaRequestBody {
  areaId: number;
  type: "area" | "road";
}
