import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { z } from "zod";
import { useMaintenanceContext } from "../../../MaintenanceProvider";
import { PageRoutes } from "../../../constants/routes";
import { authActions } from "../../../ducks/auth";
import { mergeClassNames } from "../../../utils/style";
import { CustomButton } from "../../atoms/CustomButton";
import { Input } from "../../atoms/Input";
import { MaintenanceNotice } from "../../organisms/MaintenanceNotice";
import FormTemplate from "../../templates/FormTemplate";
import { useLogin } from "./hook";

export const Login: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.logout.started({}));
  }, []);

  const { showMaintenanceNotice, ...maintenanceContext } =
    useMaintenanceContext();
  const { handleLogin } = useLogin();

  const schema = z.object({
    emailAddress: z.string().email({ message: "Eメールを入力してください" }),
    password: z.string().min(1, { message: "パスワードを入力してください" }),
  });

  return (
    <div
      className={mergeClassNames(
        "grid place-items-center w-full",
        showMaintenanceNotice ? "mt-6" : "mt-24",
      )}
    >
      {showMaintenanceNotice && <MaintenanceNotice {...maintenanceContext} />}

      <FormTemplate
        schema={schema}
        className={mergeClassNames(
          "flex flex-col w-full max-w-100 gap-y-14",
          showMaintenanceNotice && "mt-6",
        )}
        onSubmit={handleLogin}
      >
        <span className="text-3xl text-gray-dark text-center">ログイン</span>
        <div className="flex flex-col gap-y-7.5">
          <Input
            name="emailAddress"
            placeholder="メールアドレス"
            className="w-full"
          />
          <Input
            name="password"
            type="password"
            placeholder="パスワード"
            maxLength={64}
            className="w-full"
          />
          <Link
            to={PageRoutes.FORGOT_PASSWORD}
            className="mt-[15px] mx-auto hover:underline text-primary text-sm"
          >
            パスワードをお忘れの方
          </Link>
        </div>
        <CustomButton type="submit" text="ログイン" />
      </FormTemplate>
    </div>
  );
};
