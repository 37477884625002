import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { filterActions } from "./actions";
import { FilterState } from "./types";

const initialState: FilterState = {
  filters: [],
};

export function FilterReducer(
  state = initialState,
  action: Action,
): FilterState {
  if (isType(action, filterActions.fetchFilters.done)) {
    return {
      ...state,
      filters: action.payload.result.filters,
    };
  }

  if (isType(action, filterActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
