import {
  fetchAndActivate,
  getBoolean,
  getRemoteConfig,
  getString,
} from "@firebase/remote-config";
import { initializeApp } from "firebase/app";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Maintenance } from "./components/pages/maintenance";

export type MaintenanceContextType = {
  isMaintenance: boolean;
  showMaintenanceNotice: boolean;
  title: string;
  message: string;
  startDate: string;
  endDate: string;
  subText: string;
};

const MaintenanceContext = createContext<MaintenanceContextType>(
  {} as MaintenanceContextType,
);
export const useMaintenanceContext = (): MaintenanceContextType => {
  return useContext<MaintenanceContextType>(MaintenanceContext);
};

type Props = {
  children: React.ReactNode;
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 30 * 1000; // 30 secs

export const MaintenanceProvider = (props: Props) => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [showMaintenanceNotice, setShowMaintenanceNotice] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [subText, setSubText] = useState("");

  useEffect(() => {
    fetchAndActivate(remoteConfig).then(() => {
      const is_maintenance = getBoolean(remoteConfig, "is_maintenance");
      const show_maintenance_notice = getBoolean(
        remoteConfig,
        "show_maintenance_notice",
      );
      const maintenance_title = getString(remoteConfig, "maintenance_title");
      const maintenance_message = getString(
        remoteConfig,
        "maintenance_message",
      );
      const maintenance_start_date = getString(
        remoteConfig,
        "maintenance_start_date",
      );
      const maintenance_end_date = getString(
        remoteConfig,
        "maintenance_end_date",
      );
      const maintenance_subtext = getString(
        remoteConfig,
        "maintenance_subtext",
      );

      setIsMaintenance(is_maintenance);
      setShowMaintenanceNotice(show_maintenance_notice);
      setTitle(maintenance_title);
      setMessage(maintenance_message);
      setStartDate(maintenance_start_date);
      setEndDate(maintenance_end_date);
      setSubText(maintenance_subtext);
    });
  }, [remoteConfig]);

  const value: MaintenanceContextType = {
    isMaintenance: useMemo(() => isMaintenance, [isMaintenance]),
    showMaintenanceNotice: useMemo(
      () => showMaintenanceNotice,
      [showMaintenanceNotice],
    ),
    title: useMemo(() => title, [title]),
    message: useMemo(() => message, [message]),
    startDate: useMemo(() => startDate, [startDate]),
    endDate: useMemo(() => endDate, [endDate]),
    subText: useMemo(() => subText, [subText]),
  };

  return (
    <MaintenanceContext.Provider value={value}>
      {value.isMaintenance ? (
        <Maintenance
          title={title}
          message={message}
          startDate={startDate}
          endDate={endDate}
          subText={subText}
        />
      ) : (
        props.children
      )}
    </MaintenanceContext.Provider>
  );
};
