// @ts-ignore
import { setDefaultCredentials } from "@deck.gl/carto";
import { createContext, useEffect } from "react";
import { cartoInitialState } from "./ducks/carto";

type Props = {
  children: React.ReactNode;
};

export const CartoProvider: React.FC<Props> = ({ children }) => {
  const CartoContext = createContext({});

  useEffect(() => {
    setDefaultCredentials({ ...cartoInitialState.credentials });
  }, []);

  return <CartoContext.Provider value={{}}>{children}</CartoContext.Provider>;
};
