import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { z } from "zod";
import { PageRoutes } from "../../../constants/routes";
import { appActions } from "../../../ducks/app";
import { authActions } from "../../../ducks/auth";
import { CustomButton } from "../../atoms/CustomButton";
import { Input } from "../../atoms/Input";
import FormTemplate from "../../templates/FormTemplate";

export const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div className="grid place-items-center w-full mt-24">
      <FormTemplate
        schema={z.object({
          emailAddress: z
            .string()
            .email({ message: "Eメールを入力してください" }),
        })}
        className="flex flex-col w-full max-w-100 gap-y-14"
        onSubmit={({ emailAddress }) => {
          dispatch(appActions.showLoading());
          dispatch(
            authActions.forgetPassword.started({
              emailAddress,
              onComplete: () => dispatch(appActions.hideLoading()),
              onSuccess: () => {
                dispatch(
                  appActions.showModal.started({
                    title: "メールを送信しました。",
                    showXIcon: true,
                    closeButtonLabel: "閉じる",
                    handleClose: () => dispatch(appActions.closeModal()),
                  }),
                );
              },
            }),
          );
        }}
      >
        <span className="text-3xl text-gray-dark text-center">
          パスワードをお忘れの方
        </span>
        <div className="flex flex-col gap-y-2.5">
          <div className="mb-[22px]">
            <Input
              name="emailAddress"
              placeholder="メールアドレス"
              className="w-full"
            />
          </div>
          <span className="text-gray-light text-xs">
            本人確認用URLをお送りします。Eメールを入力していただき、
            「送信」をタップしてください。
          </span>
        </div>
        <div className="flex flex-col gap-y-2.5 mt-[30px]">
          <CustomButton type="submit" text="送信" />
          <Link
            to={PageRoutes.LOGIN}
            className="mt-[20px] mx-auto hover:underline text-primary text-sm"
          >
            ログインページへ戻る
          </Link>
        </div>
      </FormTemplate>
    </div>
  );
};
