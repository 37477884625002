import IconCheckboxCheck from "../../icons/IconCheckboxCheck";
import IconCheckboxDash from "../../icons/IconCheckboxDash";
import { mergeClassNames } from "../../utils/style";
import { DisableCheckbox } from "./DisableCheckbox";

type Props = {
  selected: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  secondary?: boolean;
};

export default function Checkbox({
  selected,
  indeterminate,
  disabled,
  className,
  onClick,
  secondary,
}: Props) {
  const handleOnClick = () => {
    if (disabled) return;

    if (onClick) onClick();
  };

  if (disabled && !selected) return <DisableCheckbox />;

  return (
    <button
      type="button"
      className={mergeClassNames(
        "w-6 h-6 border rounded-sm focus:outline-none grid place-items-center text-black",
        selected || indeterminate
          ? secondary
            ? "bg-black border-black"
            : "bg-primary border-primary"
          : "bg-white border-gray-light",
        disabled && "opacity-50 cursor-not-allowed",
        className,
      )}
      onClick={handleOnClick}
    >
      {indeterminate ? (
        <IconCheckboxDash />
      ) : selected ? (
        <IconCheckboxCheck />
      ) : (
        <></>
      )}
    </button>
  );
}
