export enum FilterBits {
  GenderMale = 0,
  GenderFemale = 1,
  Age10 = 2,
  Age20 = 3,
  Age30 = 4,
  Age40 = 5,
  Age50 = 6,
  Age60 = 7,
  Age70 = 8,
  AgeUnknown = 9,
  Weekday = 10,
  WeekendHoliday = 11,
  Resident = 12,
  Worker = 13,
  Visitor = 14,
  VisitorTypeUnknown = 15,
  SameDay = 16,
  Between30Minutes = 17,
  Between60Minutes = 18,
  LessThan15Minutes = 19,
  Between15And30Minutes = 20,
  Between30And60Minutes = 21,
  MoreThan1Hour = 22,
  Walking = 23,
  Car = 24,
  Train = 25,
  TransportationUnknown = 26,
}

export const FILTER_BIT_SIZE = Object.values(FilterBits).length / 2;
