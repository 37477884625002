import { useState } from "react";
import { Area } from "../../../ducks/area";
import { mergeClassNames } from "../../../utils/style";
import { LabelCheckBox } from "../../atoms/LabelCheckBox";

type Props = {
  area: Area;
  onSelect: (area: Area, selected: boolean) => void;
  checkDisabled: (area: Area) => boolean;
  currentLength: number;
  maxLength?: number;
};

export const SubAreaSelectionPopup: React.FC<Props> = ({
  area,
  onSelect,
  checkDisabled,
  currentLength,
  maxLength,
}) => {
  const [selectedAreas, setSelectedAreas] = useState<Area[]>([]);

  const handleOnChange = (a: Area) => {
    const selected = selectedAreas.map((x) => x.id).includes(a.id);

    onSelect(a, selected);

    if (selected) {
      setSelectedAreas(selectedAreas.filter((x) => x.id !== a.id));
    } else {
      setSelectedAreas([...selectedAreas, a]);
    }
  };

  const _renderAreaItem = (a: Area, border: boolean, key: string) => {
    return (
      <div
        key={key}
        className={mergeClassNames(
          "flex flex-1 w-[220px] justify-between items-center py-1 text-[13px]",
          border && "border-t-2",
        )}
      >
        <div className="max-w-[150px] overflow-ellipsis">
          <span className="text-[13px]">{a.area_name}</span>
          <div className="flex-1 text-[12px] text-[#999999]">
            <span className="whitespace-nowrap text-[#999999]">
              データセット：
            </span>
            {a.dataset.datasets
              .map((dataset) => dataset.dataset_name)
              .join(" / ")}
          </div>
        </div>

        <LabelCheckBox
          label=""
          value={a}
          checked={selectedAreas.map((x) => x.id).includes(a.id)}
          disabled={
            (maxLength &&
              currentLength + selectedAreas.length >= maxLength &&
              !selectedAreas.map((x) => x.id).includes(a.id)) ||
            (selectedAreas.length > 0 &&
              a.dataset.group_id !== selectedAreas[0].dataset.group_id) ||
            checkDisabled(a)
          }
          onChange={(event, value) => handleOnChange(value)}
        />
      </div>
    );
  };

  if (checkDisabled(area) && area.subAreas.every((sub) => checkDisabled(sub)))
    return <></>;

  return (
    <div className="flex flex-col max-h-[80vh] overflow-y-auto px-3 py-1 rounded-lg bg-white shadow-lg">
      {_renderAreaItem(area, false, `sub-area-list-${area.id}`)}

      {area.subAreas.map((a, i) =>
        _renderAreaItem(a, true, `sub-area-list-${a.id}-${i}`),
      )}
    </div>
  );
};
