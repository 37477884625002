import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { roadActions } from "./actions";
import { RoadState } from "./types";

const initialState: RoadState = {
  previousMapBounds: null,
};

export function RoadReducer(state = initialState, action: Action): RoadState {
  if (isType(action, roadActions.fetchRoads.done)) {
    return {
      ...state,
    };
  }

  if (isType(action, roadActions.createRoad.done)) {
    return {
      ...state,
    };
  }

  if (isType(action, roadActions.deleteRoads.done)) {
    return {
      ...state,
    };
  }

  if (isType(action, roadActions.setPreviousMapBounds)) {
    return {
      ...state,
      previousMapBounds: action.payload,
    };
  }

  if (isType(action, roadActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
