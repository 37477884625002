//@ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React, { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { STANDARD_MAP_STYLE } from "../../../constants/map";
import { Area, AreaData, AreaType } from "../../../ducks/area";
import { useArea } from "../../../hooks/useArea";
import { useMapBounds } from "../../../hooks/useMapBounds";
import { useMapControl } from "../../../hooks/useMapControl";
import { useMapDraw } from "../../../hooks/useMapDraw";
import { useMapSetting } from "../../../hooks/useMapSetting";
import { useSideNavigation } from "../../../hooks/useSideNavigation";
import {
  averageGeolocation,
  checkSelfIntersection,
  getClosestPolygonEdgeToPoint,
  getMaxLatLngBounds,
  getMidPoint,
} from "../../../utils/geojson";
import {
  displayAreaPolygonOnMap,
  displayNewLineOnPolygon,
  displayNewPolygonOnMap,
  displayRoadLineStringOnMap,
  hasNewLine,
  newLineCoords,
  paintMapLayers,
  paintMapRoadLayers,
} from "../../../utils/polygon";
import { MapTypeControl } from "../../molecules/map/MapTypeControl";

type Props = {
  mode: "all" | "create" | "display";
  callback?: (area: Area) => void;
  renderPopup?: (area: Area) => JSX.Element;
  onClosePopup?: () => void;
  newPolygon?: AreaData | null;
  polygonCallback?: (coords: AreaData) => void;
  isModalClose?: boolean;
};

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN ?? "";

export const AreaMap: React.FC<Props> = ({
  mode = "all",
  callback,
  renderPopup,
  onClosePopup,
  newPolygon,
  polygonCallback,
  isModalClose,
}) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const { mapCenter, mapZoom, setOnMapMove, moveToMapBounds } =
    useMapBounds(map);
  const { mapStyle } = useMapSetting();

  const {
    draw,
    modes,
    features,
    onUpdate,
    onDelete,
    onReset,
    onInvalidDrawing,
    removeVertex,
    closePopup,
    setDrawPolygon,
  } = useMapDraw({});

  const handleOnReset = () => {
    onReset();
    polygonCallback?.([]);
    displayNewLineOnPolygon(map, null);
  };

  const handleOnUpdate = (e: any) => {
    displayNewLineOnPolygon(map, null);

    const coordinates = e.features[0].geometry.coordinates[0];

    if (checkSelfIntersection(coordinates)) {
      return onInvalidDrawing(handleOnReset);
    }

    onUpdate(e);

    polygonCallback?.(
      coordinates?.map((coords: [number, number]) => ({
        latitude: coords[1],
        longitude: coords[0],
      })) ?? [],
    );
  };

  const {
    areas,
    roads,
    roadAreas,
    roadCoordinates,
    selectedAreas,
    selectedSubAreas,
    selectedRoads,
    selectArea,
    selectRoad,
    removeArea,
    removeRoad,
    showAreaPopup,
    areaLngLat,
    currentSelection,
    onClickLayer,
  } = useArea();

  const { sideMenuExpanded } = useSideNavigation();

  useEffect(() => {
    map.current?.resize();
  }, [sideMenuExpanded]);

  const {
    geocoderControl,
    navigationControl,
    mapTypeControl1,
    mapTypeControl2,
    languageControl,
  } = useMapControl();

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: STANDARD_MAP_STYLE,
        center: mapCenter,
        zoom: mapZoom,
        attributionControl: false,
      });
    }

    map.current
      .addControl(new mapboxgl.AttributionControl({ compact: false }))
      .addControl(geocoderControl)
      .addControl(navigationControl, "bottom-right")
      .addControl(
        mode === "all" ? mapTypeControl1 : mapTypeControl2,
        "top-right",
      )
      .addControl(languageControl)
      .addControl(draw);

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    map.current.on(MapboxDraw.constants.events.CREATE, handleOnUpdate);
    map.current.on(MapboxDraw.constants.events.UPDATE, handleOnUpdate);
    map.current.on(MapboxDraw.constants.events.DELETE, onDelete);
    map.current.on(MapboxDraw.constants.events.MODE_CHANGE, (e: any) => {
      if (draw.getAll().features.length === 0) {
        draw.changeMode(MapboxDraw.constants.modes.DRAW_POLYGON);
      }
    });

    setOnMapMove();
  }, []);

  const _renderPopup = (area: Area) => {
    if (!renderPopup) return;

    try {
      const popup = document.createElement("div");
      const root = createRoot(popup);
      root.render(renderPopup(area));
      const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
        .setLngLat(areaLngLat)
        .setDOMContent(popup)
        .addTo(map.current);

      mapboxPopup.on("close", () => {
        onClosePopup?.();
        if (area.area.type === AreaType.Road) removeRoad(area);
        else removeArea(area);
      });
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    const area = selectedAreas[0] ?? selectedRoads[0] ?? currentSelection;

    if (showAreaPopup && area) {
      if (mode !== "all" && area.subAreas.length === 0) return;

      if (map.current && renderPopup) {
        _renderPopup(area);
      }
    }
  }, [showAreaPopup]);

  useEffect(() => {
    if (mode === "all") return;

    // const originalOnDrag = modes.direct_select.onDrag?.bind(
    //   // @ts-ignore
    //   modes.direct_select,
    // );
    const originalOnClick = modes.direct_select.onClick?.bind(
      //@ts-ignore
      modes.direct_select,
    );
    // modes.direct_select.onDrag = (state, event) => {
    //   if (state.selectedCoordPaths.length === 0) return;
    //   displayNewLineOnPolygon(map, null);
    //   originalOnDrag?.(state, event);
    // };

    modes.direct_select.onClick = (state, event) => {
      try {
        if (state.selectedCoordPaths.length > 0)
          originalOnClick?.(state, event);
      } catch (e) {}

      if (!event.featureTarget) return;

      closePopup();
      const newLineCoordinates = getClosestPolygonEdgeToPoint(
        state.feature.coordinates[0],
        [event.lngLat.lng, event.lngLat.lat],
      );
      if (event.originalEvent.button === 0) {
        displayNewLineOnPolygon(map, newLineCoordinates);
      }

      if (state.selectedCoordPaths.length > 0) {
        displayNewLineOnPolygon(map, null);
      }

      if (event.originalEvent.button === 2) {
        if (state.selectedCoordPaths.length === 0 && !hasNewLine) return;

        const popup = document.createElement("div");
        const root = createRoot(popup);
        root.render(
          <div className="py-1 flex flex-col gap-y-6 rounded-lg bg-white shadow-lg">
            <div
              onClick={() => {
                closePopup();

                if (state.selectedCoordPaths.length > 0) removeVertex(1);
                else {
                  const newFeature = setDrawPolygon(
                    state.featureId,
                    state.feature.coordinates,
                    newLineCoords,
                  );

                  polygonCallback?.(
                    newFeature.geometry.coordinates[0].map(
                      ([longitude, latitude]: [number, number]) => ({
                        latitude,
                        longitude,
                      }),
                    ),
                  );
                }

                displayNewLineOnPolygon(map, null);
              }}
              className="flex items-center px-5 py-1 text-[14px] cursor-pointer hover:bg-primary-light"
            >
              {state.selectedCoordPaths.length > 0
                ? "頂点の削除"
                : "頂点を追加"}
            </div>
          </div>,
        );

        const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
          .setLngLat(
            newLineCoords
              ? getMidPoint(newLineCoords)
              : draw.getSelectedPoints().features.length > 0
                ? // @ts-ignore
                  draw.getSelectedPoints().features[0].geometry.coordinates
                : event.lngLat,
          )
          .setDOMContent(popup)
          .addTo(map.current);

        mapboxPopup.on("close", () => {});
      }
    };

    // modes.simple_select.onDrag = () => {};
    modes.simple_select.onClick = (state, event) => {
      closePopup();

      if (!event.featureTarget) return;

      if (event.originalEvent.button === 2) {
        const popup = document.createElement("div");
        const root = createRoot(popup);
        root.render(
          <div className="py-1 flex flex-col gap-y-6 rounded-lg bg-white shadow-lg">
            <div
              onClick={() => {
                closePopup();
                const id = features?.id ?? state.initiallySelectedFeatureIds[0];
                draw.setFeatureProperty(id, "selected", true);

                draw.changeMode(MapboxDraw.constants.modes.DIRECT_SELECT, {
                  featureId: id,
                });
              }}
              className="flex items-center px-5 py-1 text-[14px] cursor-pointer hover:bg-primary-light"
            >
              頂点の編集
            </div>
          </div>,
        );
        // @ts-ignore
        const coordinates = draw.getAll().features[0].geometry.coordinates[0];
        const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
          .setLngLat(averageGeolocation(coordinates))
          .setDOMContent(popup)
          .addTo(map.current);

        mapboxPopup.on("close", () => {});
      } else {
        draw.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
          featureIds: [features?.id ?? state.initiallySelectedFeatureIds[0]],
        });
      }
    };
  }, [features]);

  useEffect(() => {
    // if (areas.length === 0 && roads.length === 0) return;

    const filteredAreas =
      mode === "create"
        ? areas.filter((area) => area.area.type !== AreaType.Circle)
        : areas;

    let maxBounds;

    if (mode === "all") {
      maxBounds = displayRoadLineStringOnMap(
        map,
        roadCoordinates,
        roadAreas,
        onClickLayer,
        (area) => selectRoad({ area }),
      );
    }

    const areaMaxBounds = displayAreaPolygonOnMap(
      map,
      filteredAreas,
      onClickLayer,
      (area) => {
        if (mode === "display") return;

        // if (mode === "create") selectOneArea(area);
        if (mode === "create" && !features) {
          setDrawPolygon(
            "new-poly",
            [
              area.area.data.map((coords) => [
                // @ts-ignore
                coords.longitude,
                // @ts-ignore
                coords.latitude,
              ]),
            ],
            null,
          );
          polygonCallback?.(area.area.data);
        }

        if (mode === "all") {
          if (area.area.type === AreaType.Road) selectRoad({ area });
          else selectArea({ area });
        }

        // if (callback) callback(area);
      },
    );

    if (mode === "all" && maxBounds) {
      if (areaMaxBounds) {
        maxBounds = [...maxBounds, ...areaMaxBounds];
      }
    } else {
      maxBounds = areaMaxBounds;
    }

    if (mode === "all" && maxBounds) {
      const bounds = new mapboxgl.LngLatBounds(
        getMaxLatLngBounds(
          maxBounds.map((bound) => bound[1]),
          maxBounds.map((bound) => bound[0]),
          0,
        ),
      );
      map.current.fitBounds(bounds);
    }

    map.current.on("style.load", () => {
      displayRoadLineStringOnMap(
        map,
        roadCoordinates,
        roadAreas,
        onClickLayer,
        (area) => selectRoad({ area }),
      );

      displayAreaPolygonOnMap(map, filteredAreas, onClickLayer, (area) => {
        if (mode === "display") return;

        // if (mode === "create") selectOneArea(area);
        if (mode === "create" && !features) {
          setDrawPolygon(
            "new-poly",
            [
              area.area.data.map((coords) => [
                // @ts-ignore
                coords.longitude,
                // @ts-ignore
                coords.latitude,
              ]),
            ],
            null,
          );
          polygonCallback?.(area.area.data);
        }

        if (mode === "all") {
          if (area.area.type === AreaType.Road) selectRoad({ area });
          else selectArea({ area });
        }

        // if (callback) callback(area);
      });
    });
  }, [areas, roads, roadCoordinates, roadAreas, features]);

  useEffect(() => {
    if (!map.current) return;

    map.current.setStyle(mapStyle);
  }, [mapStyle]);

  useEffect(() => {
    if (isModalClose) moveToMapBounds();
  }, [isModalClose]);

  useEffect(() => {
    if (!map.current) return;

    const targetArea =
      mode === "create"
        ? areas.filter((area) => area.area.type !== AreaType.Circle)
        : areas;

    try {
      paintMapLayers(map, targetArea, selectedAreas, selectedSubAreas);
      if (mode === "all") paintMapRoadLayers(map, roads, selectedRoads);
    } catch (e) {
      map.current.on("load", () => {
        paintMapLayers(map, targetArea, selectedAreas, selectedSubAreas);
        if (mode === "all") paintMapRoadLayers(map, roads, selectedRoads);
      });
    }

    map.current.on("style.load", () => {
      paintMapLayers(map, targetArea, selectedAreas, selectedSubAreas);
      if (mode === "all") paintMapRoadLayers(map, roads, selectedRoads);
    });
  }, [areas, roads, selectedAreas, selectedSubAreas, selectedRoads]);

  useEffect(() => {
    if (newPolygon === undefined) return;

    const maxBounds = displayNewPolygonOnMap(map, newPolygon, 0, true);
    map.current.on("style.load", () =>
      displayNewPolygonOnMap(map, newPolygon, 0, true),
    );

    if (!maxBounds) return;

    const bounds = new mapboxgl.LngLatBounds(maxBounds);
    map.current.fitBounds(bounds);

    if (newPolygon) {
      setDrawPolygon(
        "new-poly",
        [
          newPolygon.map((coords) => [
            // @ts-ignore
            coords.longitude,
            // @ts-ignore
            coords.latitude,
          ]),
        ],
        null,
      );
      polygonCallback?.(newPolygon);
    }
  }, [newPolygon]);

  return (
    <div className="flex flex-1 w-full h-full">
      <div
        ref={mapContainer}
        className="w-full h-full"
        onContextMenu={(e) => e.preventDefault()}
      />
      <MapTypeControl index={mode === "all" ? 1 : 2} />
    </div>
  );
};
