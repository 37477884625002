import { useMemo } from "react";
import { City } from "../../ducks/city";
import { useCity } from "../../hooks/useCity";
import IconMapMarker from "../../icons/IconMapMarker";
import IconSearch from "../../icons/IconSearch";
import { debounceFunction } from "../../utils/debounce";
import { CustomButton } from "../atoms/CustomButton";
import { Input } from "../atoms/Input";

type Props = {
  onSelectCity: (city: City) => void;
  onCityInput: (keyword: string) => void;
};

export const CitySearchBox: React.FC<Props> = ({
  onSelectCity,
  onCityInput,
}) => {
  const { cities, selectedCity, setSelectedCity, onInput } = useCity();

  const searchCitiesDebounce = useMemo(() => {
    return debounceFunction((keyword: string) => {
      onCityInput(keyword);
      onInput(keyword);
    }, 750);
  }, []);

  const _renderCityItem = (city: City, i: number) => {
    return (
      <div
        key={`cities-${city.city_id}-${i}`}
        className="flex items-center py-2 px-4 hover:bg-primary-light cursor-pointer"
        onClick={() => {
          onSelectCity(city);
          setSelectedCity(city);
        }}
      >
        <IconMapMarker />
        <div className="ml-2 text-[14px] leading-[14px]">{city.city_name}</div>
      </div>
    );
  };

  return (
    <div className="relative">
      <Input
        name="cityKeyword"
        size="md"
        placeholder="住所を検索"
        hideErrorMessage
        icon={<IconSearch />}
        onInput={(e) => searchCitiesDebounce(e.currentTarget.value)}
      />

      {cities.length > 0 && !selectedCity && (
        <div className="absolute top-12 w-full max-h-[180px] mt-[14px] z-10 bg-white border border-gray-border overflow-y-auto shadow-md rounded">
          {cities.map((city, i) => _renderCityItem(city, i))}
        </div>
      )}

      <div className="flex justify-center items-center">
        <CustomButton
          type="submit"
          text="エリアを表示"
          className="flex justify-center items-center w-[200px] h-[40px] text-white text-[14px] mt-6"
        />
      </div>
    </div>
  );
};
