import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";

const selector = (state: StoreState) => state.area;

export const areaSelectors = {
  areas: createSelector(selector, (state) => state.areas),
  roads: createSelector(selector, (state) => state.roads),
  roadAreas: createSelector(selector, (state) => state.roadAreas),
  roadCoordinates: createSelector(selector, (state) => state.roadCoordinates),
  roadNetworks: createSelector(selector, (state) => state.roadNetworks),
  allAreas: createSelector(selector, (state) => state.allAreas),
  selectedAreas: createSelector(selector, (state) => state.selectedAreas),
  selectedSubAreas: createSelector(selector, (state) => state.selectedSubAreas),
  selectedRoads: createSelector(selector, (state) => state.selectedRoads),
  multipleAnalysisAreaCount: createSelector(
    selector,
    (state) => state.multipleAnalysisAreaCount,
  ),
};
