import { AnalysisStep } from "../../ducks/analysis";
import { useSideNavigation } from "../../hooks/useSideNavigation";
import { AnalysisStepLabel } from "../molecules/analysis/AnalysisStepLabel";

type Props = {
  analysisSteps?: AnalysisStep[];
  currentStep?: number;
};

export const PageHeader: React.FC<Props> = ({ analysisSteps, currentStep }) => {
  const { title } = useSideNavigation();

  return (
    <div className="bg-white mx-[-10px]">
      <div className="flex flex-wrap ml-[30px] py-[12px] mb-[14px] text-[#334A7C] text-[16px] leading-4 font-bold">
        <p className="mr-[40px] my-3">
          {title && title[0] ? title[0].name : ""}
        </p>

        {analysisSteps && analysisSteps.length > 0 && (
          <div className="flex flex-wrap items-center pl-[16px] border-l-[1px] border-[#999999]">
            {analysisSteps.map((step, i) => (
              <AnalysisStepLabel
                key={`analysis-step-${i}`}
                label={step}
                step={i + 1}
                currentStep={currentStep ?? 1}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
