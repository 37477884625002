import { HistoryReception } from "../history";

export interface AnalysisState {
  currentStep: number;
  analysisItems: AnalysisItem[];
  selectedAnalysisItem: AnalysisItem | null;
}

export enum AnalysisPattern {
  SelectArea = 1,
  SelectRoad = 2,
  SelectAreaAndRoad = 3,
  SelectMainAndSubArea = 4,
}

export enum AnalysisStep {
  SelectAnalysisType = "分析項目の選択",
  SelectArea = "分析エリアの選択",
  SelectRoad = "分析道路の選択",
}

export interface AnalysisItem {
  type: number;
  name: string;
  description: string;
  pattern: AnalysisPattern;
  analyze_id: number[];
  aggregation_type: AnalysisAggregationType[];
  min?: number;
  max?: number;
  is_multi?: boolean;
}

export enum AnalysisAggregationType {
  All = 0,
  Gender = 1,
  Age = 2,
  VisitorType = 3,
  StayMinute = 4,
  Transportation = 5,
  Road = 6,
  Area = 7,
  City = 8,
  RoadTransportation = 9,
}

export interface AnalysisRequestBody {
  areaIds: Array<number>;
  filterMask: string;
  batchIds: number[];
  startDate: string;
  endDate: string;
  sendEmail: boolean;
  isVisible: boolean;
  reception_ids: HistoryReception[];
}
