//@ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { STANDARD_MAP_STYLE } from "../../../constants/map";
import { AreaCoordinates } from "../../../ducks/area";
import { useArea } from "../../../hooks/useArea";
import { useMapBounds } from "../../../hooks/useMapBounds";
import { useMapControl } from "../../../hooks/useMapControl";
import { useMapDraw } from "../../../hooks/useMapDraw";
import { useMapSetting } from "../../../hooks/useMapSetting";
import {
  averageGeolocation,
  checkSelfIntersection,
  getClosestPolygonEdgeToPoint,
  getMidPoint,
} from "../../../utils/geojson";
import {
  displayAreaPolygonOnMap,
  displayNewLineOnPolygon,
  hasNewLine,
  newLineCoords,
} from "../../../utils/polygon";
import { MapResetButton } from "../../molecules/map/MapResetButton";
import { MapTypeControl } from "../../molecules/map/MapTypeControl";

type Props = {
  callback: (coordinates: AreaCoordinates[]) => void;
};

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN;

export const PolygonMap: React.FC<Props> = ({ callback }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const { mapCenter, mapZoom, setOnMapMove } = useMapBounds(map);

  const {
    draw,
    modes,
    features,
    onUpdate,
    onDelete,
    onReset,
    onInvalidDrawing,
    removeVertex,
    closePopup,
    setDrawPolygon,
  } = useMapDraw({});

  const { areas, roads } = useArea();

  const handleOnReset = () => {
    onReset();
    callback([]);
    displayNewLineOnPolygon(map, null);
  };

  const handleOnUpdate = (e: any) => {
    displayNewLineOnPolygon(map, null);

    const coordinates = e.features[0].geometry.coordinates[0];

    if (checkSelfIntersection(coordinates)) {
      return onInvalidDrawing(handleOnReset);
    }

    onUpdate(e);

    callback(
      coordinates?.map((coords: [number, number]) => ({
        latitude: coords[1],
        longitude: coords[0],
      })) ?? [],
    );
  };

  const {
    geocoderControl,
    navigationControl,
    languageControl,
    mapResetButton,
    mapTypeControl2,
  } = useMapControl();

  const { mapStyle } = useMapSetting();

  useEffect(() => {
    geocoderControl.on("result", (e) => {
      setTimeout(() => {
        handleOnReset();
        draw.changeMode(MapboxDraw.constants.modes.DRAW_POLYGON);
      }, 100);
    });
  }, []);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: STANDARD_MAP_STYLE,
      center: mapCenter,
      zoom: mapZoom,
      attributionControl: false,
    })
      .addControl(new mapboxgl.AttributionControl({ compact: false }))
      .addControl(geocoderControl)
      .addControl(navigationControl, "bottom-right")
      .addControl(mapTypeControl2, "top-right")
      .addControl(languageControl)
      .addControl(mapResetButton, "top-right")
      .addControl(draw);

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    map.current.on(MapboxDraw.constants.events.CREATE, handleOnUpdate);
    map.current.on(MapboxDraw.constants.events.UPDATE, handleOnUpdate);
    map.current.on(MapboxDraw.constants.events.DELETE, onDelete);
    map.current.on(MapboxDraw.constants.events.MODE_CHANGE, (e: any) => {
      if (draw.getAll().features.length === 0) {
        draw.changeMode(MapboxDraw.constants.modes.DRAW_POLYGON);
      }
    });
    draw.changeMode(MapboxDraw.constants.modes.DRAW_POLYGON);

    setOnMapMove();
  }, []);

  useEffect(() => {
    // const originalOnDrag = modes.direct_select.onDrag?.bind(
    //   // @ts-ignore
    //   modes.direct_select,
    // );
    const originalOnClick = modes.direct_select.onClick?.bind(
      //@ts-ignore
      modes.direct_select,
    );
    // modes.direct_select.onDrag = (state, event) => {
    //   if (state.selectedCoordPaths.length === 0) return;
    //   displayNewLineOnPolygon(map, null);
    //   originalOnDrag?.(state, event);
    // };

    modes.direct_select.onClick = (state, event) => {
      try {
        if (state.selectedCoordPaths.length > 0)
          originalOnClick?.(state, event);
      } catch (e) {}

      if (!event.featureTarget) return;

      closePopup();
      const newLineCoordinates = getClosestPolygonEdgeToPoint(
        state.feature.coordinates[0],
        [event.lngLat.lng, event.lngLat.lat],
      );
      if (event.originalEvent.button === 0) {
        displayNewLineOnPolygon(map, newLineCoordinates);
      }

      if (state.selectedCoordPaths.length > 0) {
        displayNewLineOnPolygon(map, null);
      }

      if (event.originalEvent.button === 2) {
        if (state.selectedCoordPaths.length === 0 && !hasNewLine) return;

        const popup = document.createElement("div");
        const root = createRoot(popup);
        root.render(
          <div className="py-1 flex flex-col gap-y-6 rounded-lg bg-white shadow-lg">
            <div
              onClick={() => {
                closePopup();

                if (state.selectedCoordPaths.length > 0) removeVertex(1);
                else {
                  const newFeature = setDrawPolygon(
                    state.featureId,
                    state.feature.coordinates,
                    newLineCoords,
                  );

                  callback(
                    newFeature.geometry.coordinates[0].map(
                      ([longitude, latitude]: [number, number]) => ({
                        latitude,
                        longitude,
                      }),
                    ),
                  );
                }

                displayNewLineOnPolygon(map, null);
              }}
              className="flex items-center px-5 py-1 text-[14px] cursor-pointer hover:bg-primary-light"
            >
              {state.selectedCoordPaths.length > 0
                ? "頂点の削除"
                : "頂点を追加"}
            </div>
          </div>,
        );

        const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
          .setLngLat(
            newLineCoords
              ? getMidPoint(newLineCoords)
              : draw.getSelectedPoints().features.length > 0
                ? // @ts-ignore
                  draw.getSelectedPoints().features[0].geometry.coordinates
                : event.lngLat,
          )
          .setDOMContent(popup)
          .addTo(map.current);

        mapboxPopup.on("close", () => {});
      }
    };

    // modes.simple_select.onDrag = () => {};
    modes.simple_select.onClick = (state, event) => {
      closePopup();

      if (!event.featureTarget) return;

      if (event.originalEvent.button === 2) {
        const popup = document.createElement("div");
        const root = createRoot(popup);
        root.render(
          <div className="py-1 flex flex-col gap-y-6 rounded-lg bg-white shadow-lg">
            <div
              onClick={() => {
                closePopup();
                const id = features?.id ?? state.initiallySelectedFeatureIds[0];
                draw.setFeatureProperty(id, "selected", true);

                draw.changeMode(MapboxDraw.constants.modes.DIRECT_SELECT, {
                  featureId: id,
                });
              }}
              className="flex items-center px-5 py-1 text-[14px] cursor-pointer hover:bg-primary-light"
            >
              頂点の編集
            </div>
          </div>,
        );
        // @ts-ignore
        const coordinates = draw.getAll().features[0].geometry.coordinates[0];
        const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
          .setLngLat(averageGeolocation(coordinates))
          .setDOMContent(popup)
          .addTo(map.current);

        mapboxPopup.on("close", () => {});
      } else {
        draw.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
          featureIds: [features?.id ?? state.initiallySelectedFeatureIds[0]],
        });
      }
    };
  }, [features]);

  useEffect(() => {
    if (!map.current) return;

    map.current.setStyle(mapStyle);
  }, [mapStyle]);

  useEffect(() => {
    displayAreaPolygonOnMap(map, areas);
    displayAreaPolygonOnMap(map, roads);

    map.current.on("style.load", () => {
      displayAreaPolygonOnMap(map, areas);
      displayAreaPolygonOnMap(map, roads);
    });
  }, [areas, roads]);

  return (
    <div className="flex flex-1 w-full h-full">
      <div
        ref={mapContainer}
        className="w-full h-full"
        onContextMenu={(e) => e.preventDefault()}
      ></div>
      <MapResetButton onClick={handleOnReset} />
      <MapTypeControl index={2} />
    </div>
  );
};
