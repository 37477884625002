import { Divider, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { AppConfig } from "../../../AppConfig";
import { PageRoutes } from "../../../constants/routes";
import { LocalStorageManager } from "../../../core/storage/LocalStorageManager";
import { useTenant } from "../../../hooks/useTenant";
import { CustomButton } from "../../atoms/CustomButton";
import { LabelRadioButton } from "../../atoms/LabelRadioButton";
import FormTemplate from "../../templates/FormTemplate";

export const SelectProject: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state || !location.state.body) {
      navigate(PageRoutes.LOGIN, { replace: true });
    }
  }, [location.state]);

  const { tenants, onSelectTenant } = useTenant(
    location.state?.body ?? { emailAddress: "", password: "" },
  );

  const schema = z.object({ tenantId: z.number().positive() });

  const [methods, setMethods] = useState<UseFormReturn<
    FieldValues,
    any,
    undefined
  > | null>(null);

  const tenantId = LocalStorageManager.Instance.getObject<string>(
    AppConfig.Instance.LocalStorageKey["tenantId"],
  );

  if (tenantId) return <Navigate replace to={PageRoutes.INDEX} />;

  return (
    <div className="grid place-items-center w-full mt-24">
      <FormTemplate
        schema={schema}
        setMethods={setMethods}
        className="flex flex-col w-full max-w-100 gap-y-14"
        onSubmit={({ tenantId }) => onSelectTenant(tenantId)}
      >
        <span className="text-3xl text-gray-dark text-center">
          プロジェクト選択
        </span>
        <span className="text-[14px] text-gray-dark text-center">
          分析するプロジェクトを選択してください
        </span>
        <div className="flex flex-col max-h-[250px] overflow-y-auto bg-white border border-gray-border rounded-lg">
          <RadioGroup
            name="project-selection-radio-group"
            onChange={(e) => {
              methods?.setValue("tenantId", Number(e.target.value), {
                shouldValidate: true,
              });
            }}
          >
            {tenants.map((tenant, i) => {
              return (
                <div key={`project-selection-${i}`} className="mx-2">
                  {i > 0 && <Divider />}
                  <div className="flex items-center p-2 cursor-pointer">
                    <LabelRadioButton
                      label={tenant.name}
                      value={tenant.id}
                      size="medium"
                    />
                  </div>
                </div>
              );
            })}
          </RadioGroup>
        </div>
        <CustomButton type="submit" text="次へ" />
      </FormTemplate>
    </div>
  );
};
