import { IconProps } from "../types";

export default function IconVisitor({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g id="Group_5434" data-name="Group 5434">
        <path
          id="Path_685"
          data-name="Path 685"
          d="M15.867,20.625c-3.976,0-11.77,1.9-11.77,6.29v2.54a1.231,1.231,0,0,0,1.252,1.21H26.387a1.231,1.231,0,0,0,1.252-1.21v-2.54c0-4.39-7.794-6.29-11.772-6.29m9.267,7.62H6.6V26.915c0-2.136,5.607-3.871,9.266-3.871s9.267,1.735,9.267,3.871Zm-9.267-8.96a7.082,7.082,0,0,0,7.193-6.948,7.081,7.081,0,0,0-7.193-6.947,7.08,7.08,0,0,0-7.192,6.947,7.081,7.081,0,0,0,7.192,6.948m0-11.475a4.615,4.615,0,0,1,4.689,4.528,4.691,4.691,0,0,1-9.376,0A4.615,4.615,0,0,1,15.867,7.81M21.94,5.483a7.447,7.447,0,0,1,1.121-.092,7.081,7.081,0,0,1,7.193,6.947,7.082,7.082,0,0,1-7.193,6.948,7.447,7.447,0,0,1-1.121-.093,8.805,8.805,0,0,0,0-13.71M34.832,26.915v2.54a1.232,1.232,0,0,1-1.252,1.21H29.391a3.033,3.033,0,0,0,.252-1.21v-2.54c0-2.9-2.106-4.883-4.779-6.18,4.148.455,9.968,2.385,9.968,6.18"
        />
        <rect
          id="Rectangle_1024"
          data-name="Rectangle 1024"
          width="42"
          height="42"
          fill="none"
        />
      </g>
    </svg>
  );
}
