import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { Auth, AuthAccess } from "../../core/api";
import { CreateUserRequestBody, DeleteUserRequestBody, EditUserRequestBody, LoginRequestBody, User } from "./types";

const actionCreator = actionCreatorFactory("@@Auth");

export const authActions = {
  fetchUsers: actionCreator.async<{}, { users: User[] }>("FETCH_USERS"),
  login: actionCreator.async<CommonAsyncType<Auth> & LoginRequestBody, { userId: number }>("LOGIN"),
  setToken: actionCreator.async<CommonAsyncType<Auth> & LoginRequestBody, { userId: number }>("SET_TOKEN"),
  logout: actionCreator.async<CommonAsyncType, {}>("LOGOUT"),
  forgetPassword: actionCreator.async<CommonAsyncType & { emailAddress: string }, {}>("FORGET_PASSWORD"),
  resetPassword: actionCreator.async<CommonAsyncType & { secret_key: string; password: string }, {}>("RESET_PASSWORD"),
  refreshToken: actionCreator.async<{ refreshToken: string } & CommonAsyncType<AuthAccess>, { accessToken: string }>("REFRESH_TOKEN"),
  createUser: actionCreator.async<CommonAsyncType & CreateUserRequestBody, {}>("CREATE_USER"),
  editUser: actionCreator.async<CommonAsyncType & EditUserRequestBody, {}>("EDIT_USER"),
  deleteUsers: actionCreator.async<CommonAsyncType & DeleteUserRequestBody, {}>("DELETE_USERS"),
  resetToInitialState: actionCreator("RESET_TO_INITIAL_STATE"),
};
