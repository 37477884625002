import { IconProps } from "../types";

export default function IconUserManagement({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill="currentColor"
    >
      <path d="M20.82 16.578v-.356a1.117 1.117 0 0 0-.021-.181l.576-.41a.451.451 0 0 0 .131-.589l-.377-.664a.449.449 0 0 0-.578-.187l-.615.281a1.415 1.415 0 0 0-.306-.214 2.7 2.7 0 0 0-.341-.155l-.107-.678a.451.451 0 0 0-.445-.379h-.776a.451.451 0 0 0-.445.38l-.107.686a1.773 1.773 0 0 0-.336.151 2.305 2.305 0 0 0-.31.21l-.615-.281a.45.45 0 0 0-.579.187l-.379.669a.45.45 0 0 0 .126.585l.583.426a.952.952 0 0 0-.021.173v.344a.944.944 0 0 0 .021.172l-.583.426a.45.45 0 0 0-.126.585l.379.669a.45.45 0 0 0 .579.187l.615-.281a2.305 2.305 0 0 0 .31.21 2.359 2.359 0 0 0 .336.16l.107.677a.449.449 0 0 0 .444.379h.775a.45.45 0 0 0 .445-.379l.107-.677a1.471 1.471 0 0 0 .341-.155 2.98 2.98 0 0 0 .306-.215l.615.281a.449.449 0 0 0 .578-.187l.378-.667a.449.449 0 0 0-.127-.587l-.58-.418a1.1 1.1 0 0 0 .021-.176m-1.7.6a1.094 1.094 0 1 1 .319-.772 1.051 1.051 0 0 1-.319.772m-7.15-4.721a4.308 4.308 0 1 0-4.308-4.308 4.313 4.313 0 0 0 4.307 4.308m0-7.116a2.808 2.808 0 1 1-2.807 2.808 2.811 2.811 0 0 1 2.807-2.808M14.6 19.519H5.669a.75.75 0 0 1-.75-.75v-1.58c0-2.722 4.669-3.9 7.05-3.9a11.2 11.2 0 0 1 2.012.211c.118.023.267.054.431.091l-.716 1.382a9.63 9.63 0 0 0-1.727-.184c-2.191 0-5.55 1.076-5.55 2.4v.83h7.358Z" />
    </svg>
  );
}
