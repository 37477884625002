import { IconProps } from "../types";

export default function IconRoad({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32.094"
      viewBox="0 0 32 32.094"
    >
      <path
        id="Path_856"
        data-name="Path 856"
        d="M3.34,1.67V30.424a1.67,1.67,0,1,1-3.34,0V1.67a1.67,1.67,0,0,1,3.34,0M30.33,0a1.668,1.668,0,0,0-1.67,1.67V30.424a1.67,1.67,0,1,0,3.34,0V1.67A1.668,1.668,0,0,0,30.33,0M16,23.663a1.668,1.668,0,0,0-1.67,1.67v5.091a1.67,1.67,0,1,0,3.34,0V25.332A1.668,1.668,0,0,0,16,23.663M16,0a1.668,1.668,0,0,0-1.67,1.67V6.761a1.67,1.67,0,1,0,3.34,0V1.67A1.668,1.668,0,0,0,16,0m0,11.831a1.668,1.668,0,0,0-1.67,1.67v5.091a1.67,1.67,0,1,0,3.34,0V13.5A1.668,1.668,0,0,0,16,11.831"
      />
    </svg>
  );
}
