import React, { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import Modal from "react-modal";
import { z } from "zod";
import { User, UserTenantType, UserType } from "../../../ducks/auth";
import IconX from "../../../icons/IconX";
import { getUserTenantType } from "../../../utils/user";
import { CustomButton } from "../../atoms/CustomButton";
import { Input } from "../../atoms/Input";
import { TenantSelection } from "../../molecules/TenantSelection";
import FormTemplate from "../../templates/FormTemplate";

type Props = {
  isOpen: boolean;
  selectedUser?: User;
  canEditTenant: boolean;
  handleSubmit: (
    selectedUser: User | null,
    name: string,
    email: string,
    password: string,
    tenants: UserTenantType[],
  ) => void;
  handleClose: () => void;
};
export const UserManagementModal: React.FC<Props> = ({
  isOpen,
  selectedUser,
  canEditTenant,
  handleSubmit,
  handleClose,
}) => {
  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      zIndex: 50,
      backgroundColor: "transparent",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 140,
    },
  };

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const schema = z
    .object({
      name: z
        .string()
        .min(1, { message: "アカウント名は1文字以上入力してください" }),
      email: z.string().email({ message: "Eメールを入力してください" }),
      password: z.string(),
      tenants: z
        .array(
          z.object({
            tenant_id: z.number().positive(),
            user_type: z.nativeEnum(UserType),
          }),
        )
        .min(1),
    })
    .refine(
      (obj) => {
        if (selectedUser && (!obj.password || obj.password.length === 0))
          return true;

        return (
          obj.password &&
          obj.password.length > 0 &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#%*,\-./;<=>?@^_|]).{8,64}$/.test(
            obj.password,
          )
        );
      },
      (obj) => {
        return {
          path: ["password"],
          message:
            "パスワードは大文字、小文字、数字、記号の組合せの8桁以上64桁以下で入力して下さい。 記号は ! # % * , - . / ; < = > ? @ ^ _ | をお使いいただけます。",
        };
      },
    );

  const [methods, setMethods] = useState<UseFormReturn<
    FieldValues,
    any,
    undefined
  > | null>(null);

  const onChangeTenants = (tenants: UserTenantType[]) => {
    methods?.setValue("tenants", tenants, { shouldValidate: true });
  };

  return (
    <Modal portalClassName="alert-modal" isOpen={isOpen} style={modalStyle}>
      <div className="fixed inset-0 mx-auto py-12 bg-grey grid place-items-center">
        <div className="w-full h-full max-w-4xl px-12 overflow-hidden">
          <FormTemplate
            schema={schema}
            defaultValues={{
              name: selectedUser?.name,
              email: selectedUser?.email,
              tenants: selectedUser ? getUserTenantType(selectedUser) : [],
            }}
            setMethods={setMethods}
            className="flex flex-col h-full bg-white rounded-lg px-4 py-3"
            onSubmit={({
              name,
              email,
              password,
              tenants,
            }: {
              name: string;
              email: string;
              password: string;
              tenants: UserTenantType[];
            }) =>
              handleSubmit(selectedUser ?? null, name, email, password, tenants)
            }
          >
            <div className="flex justify-between pb-11">
              <span className="pl-3.5 pt-4.5 font-semibold">
                {selectedUser ? "アカウントの編集" : "アカウントの新規作成"}
              </span>
              <button type="button" className="p-2.5" onClick={handleClose}>
                <IconX />
              </button>
            </div>
            <div className="flex flex-col flex-1 overflow-y-auto px-16">
              <div className="flex flex-col h-full gap-y-8">
                <Input
                  required
                  name="name"
                  label="ユーザー名"
                  placeholder="アカウント名を入力してください"
                />
                <Input
                  required
                  name="email"
                  label="Eメール"
                  placeholder="Eメールを入力してください"
                />
                <Input
                  name="password"
                  type="password"
                  label="パスワード"
                  required={!selectedUser}
                  placeholder={"パスワードを入力してください"}
                />

                <TenantSelection
                  selectedUser={selectedUser}
                  canEditTenant={canEditTenant}
                  onChangeTenants={onChangeTenants}
                />
              </div>
            </div>
            <div className="flex gap-x-4 justify-center pb-5 pt-12">
              <CustomButton
                text="キャンセル"
                className="flex justify-center items-center rounded w-[200px] h-[40px] border border-gray-light bg-white text-black"
                onClick={handleClose}
              />
              <CustomButton
                type="submit"
                text="保存"
                className="flex justify-center items-center rounded w-[200px] h-[40px] py-2.5 border border-primary bg-primary text-white disabled:border-gray-border disabled:bg-gray-border focus:outline-none"
              />
            </div>
          </FormTemplate>
        </div>
      </div>
    </Modal>
  );
};
