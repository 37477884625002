import React from "react";
import { Area, AreaStatus, AreaType } from "../../../ducks/area";
import IconX from "../../../icons/IconX";
import { formatDate } from "../../../utils/date";
import { CustomButton } from "../../atoms/CustomButton";
import { StatusLabel } from "../../atoms/StatusLabel";
import { AreaPolygonCount } from "./AreaPolygonCount";

type Props = {
  area: Area;
  multipleAnalysisAreaCount: number;
  onAnalyze: (area: Area) => void;
  onEdit: (area: Area) => void;
  onDelete: (area: Area) => void;
  onClose: () => void;
};

export const AreaPopupCard: React.FC<Props> = ({
  area,
  multipleAnalysisAreaCount,
  onAnalyze,
  onEdit,
  onDelete,
  onClose,
}) => {
  return (
    <div className="p-5 flex flex-col w-[300px] gap-y-6 rounded-lg bg-white shadow-lg">
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-1.25 items-center">
          <span className="text-[13px] leading-[13px] whitespace-nowrap text-[#666666]">
            データセット名：
          </span>
          <span className="text-sm">
            {area.dataset.datasets
              .map((dataset) => dataset.dataset_name)
              .join(" / ")}
          </span>
        </div>

        <div className="flex gap-x-1.25 items-center">
          <span className="text-[13px] leading-[13px] whitespace-nowrap text-[#666666]">
            エリア名：
          </span>
          <span className="text-sm">{area.area_name}</span>
        </div>

        <div className="flex gap-x-1.25 items-center">
          <span className="text-[13px] leading-[13px] whitespace-nowrap text-[#666666]">
            データ生成ステータス：
          </span>
          <StatusLabel
            status={area.status}
            isRoad={area.area.type === AreaType.Road}
          />
        </div>

        <div className="flex gap-x-1.25 items-center">
          <span className="text-[13px] leading-[13px] whitespace-nowrap text-[#666666]">
            登録日：
          </span>
          <span className="text-sm">{formatDate(area.create_date)}</span>
        </div>

        {area.for_multi && area.area.type !== AreaType.Road && (
          <div className="flex gap-x-1.25 items-center">
            <span className="text-[13px] leading-[13px] whitespace-nowrap text-[#666666]">
              複数分析の利用：
            </span>
            <span className="text-sm">利用する</span>
            <AreaPolygonCount count={multipleAnalysisAreaCount} />
          </div>
        )}
      </div>
      <div className="flex gap-x-2.5">
        <CustomButton
          text="分析実行"
          disabled={area.status === AreaStatus.Failure}
          className={`px-5 py-2 text-sm border ${
            area.status === AreaStatus.Success
              ? `border-primary`
              : `border-[#cccccc]`
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onAnalyze(area);
          }}
        />
        <CustomButton
          text="編集"
          className="px-5 py-2 text-sm bg-white text-primary border border-primary"
          onClick={(e) => {
            e.stopPropagation();
            onEdit(area);
          }}
        />
        <CustomButton
          text="削除"
          className="px-5 py-2 text-sm bg-white text-primary border border-primary"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(area);
          }}
        />
      </div>
      <button
        type="button"
        className="absolute p-2.5 top-0 right-0"
        onClick={onClose}
      >
        <IconX />
      </button>
    </div>
  );
};
