import React, { ButtonHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";
import { mergeClassNames } from "../../utils/style";

type ButtonType =
  | { type: "submit"; onClick?: never }
  | {
      type?: Omit<ButtonHTMLAttributes<HTMLButtonElement>["type"], "submit">;
      onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    };

type Props = ButtonType & {
  text: string;
  disabled?: boolean;
  className?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  buttonStyle?: "filled" | "border";
};

export const CustomButton: React.FC<Props> = ({
  text,
  type,
  className,
  disabled,
  buttonStyle = "filled",
  ...props
}: Props) => {
  const formContext = useFormContext();

  return (
    <button
      type={type ?? "button"}
      disabled={
        type === "submit"
          ? !formContext?.formState.isValid ||
            formContext.formState.isSubmitting
          : disabled
      }
      className={mergeClassNames(
        "border rounded px-10 py-3 whitespace-nowrap disabled:cursor-not-allowed",
        buttonStyle === "filled"
          ? "bg-primary text-white disabled:bg-gray-border"
          : "bg-white text-primary border-primary disabled:text-[#C0C7D7] disabled:border-[#C0C7D7]",
        className,
      )}
      {...props}
    >
      {text}
    </button>
  );
};
