import { useState } from "react";

export const useMapDrawCircle = () => {
  const [circle, setCircle] = useState<any>(null);

  const [radius, setRadius] = useState<number>(0.25);
  const [center, setCenter] = useState<number[]>([]);

  const resetAll = () => {
    setCircle(null);
    setRadius(0.25);
    setCenter([]);
  };

  return {
    circle,
    setCircle,
    radius,
    setRadius,
    center,
    setCenter,
    resetAll,
  };
};

export type MapDrawCircleControls = ReturnType<typeof useMapDrawCircle>;
