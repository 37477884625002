import { put } from "typed-redux-saga";
import { analysisActions } from "./analysis";
import { areaActions } from "./area";
import { assetActions } from "./asset";
import { authActions } from "./auth";
import { cartoActions } from "./carto";
import { cityActions } from "./city";
import { datasetActions } from "./dataset";
import { filterActions } from "./filter";
import { historyActions } from "./history";
import { mapActions } from "./map";
import { roadActions } from "./road";
import { tenantActions } from "./tenant";

export function* resetToInitialState() {
  yield* put(analysisActions.resetToInitialState());
  // yield* put(appActions.resetToInitialState());
  yield* put(areaActions.resetToInitialState());
  yield* put(assetActions.resetToInitialState());
  yield* put(authActions.resetToInitialState());
  yield* put(cartoActions.resetToInitialState());
  yield* put(cityActions.resetToInitialState());
  yield* put(datasetActions.resetToInitialState());
  yield* put(filterActions.resetToInitialState());
  yield* put(historyActions.resetToInitialState());
  yield* put(mapActions.resetToInitialState());
  yield* put(roadActions.resetToInitialState());
  yield* put(tenantActions.resetToInitialState());
}
