import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { Reception } from "../../core/api";
import { HistoryReception } from "../history";
import { AnalysisItem, AnalysisRequestBody } from "./types";

const actionCreator = actionCreatorFactory("@@Analysis");

export const analysisActions = {
  setCurrentStep: actionCreator<number>("SET_CURRENT_STEP"),
  resetCurrentStep: actionCreator("RESET_CURRENT_STEP"),
  fetchAnalysisItems: actionCreator.async<{}, AnalysisItem[]>("FETCH_ANALYSIS_ITEMS"),
  setSelectedAnalysisItem: actionCreator<AnalysisItem>("SET_SELECTED_ANALYSIS_ITEM"),
  clearSelectedAnalysisItem: actionCreator("CLEAR_SELECTED_ANALYSIS_ITEM"),
  analyze: actionCreator.async<AnalysisRequestBody & CommonAsyncType<HistoryReception[]>, Reception>("ANALYZE"),
  resetToInitialState: actionCreator("RESET_TO_INITIAL_STATE"),
};
