import { createSelector } from "@reduxjs/toolkit";
import { MAP_STYPE_OPTIONS } from "../../constants/map";
import { StoreState } from "../../store";

const selector = (state: StoreState) => state.map;

export const mapSelectors = {
  mapStyle: createSelector(selector, (state) => state.mapStyle),
  currentMapStyle: createSelector(
    selector,
    (state) =>
      MAP_STYPE_OPTIONS.find((opt) => opt.value === state.mapStyle)?.name ?? "",
  ),
  isDisplayData: createSelector(selector, (state) => state.isDisplayData),
  isDisplayRoadArea: createSelector(
    selector,
    (state) => state.isDisplayRoadArea,
  ),
  mapCenter: createSelector(selector, (state) => state.mapCenter),
  mapZoom: createSelector(selector, (state) => state.mapZoom),
  heatMapDisplay: createSelector(selector, (state) => state.heatMapDisplay),
  colorScale: createSelector(selector, (state) => state.colorScale),
  maxValue: createSelector(selector, (state) => state.maxValue),
};
