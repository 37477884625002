import { IconProps } from "../types";

export default function IconAnalysisExecution({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill="currentColor"
    >
      <path d="M19.435 21.451a1.527 1.527 0 0 1-1.084-.449l-3.866-3.865a7.591 7.591 0 1 1 3.251-3.251l3.866 3.866a1.536 1.536 0 0 1 0 2.169l-1.082 1.082a1.524 1.524 0 0 1-1.085.449m-3.672-5.156 3.645 3.646 1.13-1.082-3.647-3.691a7.632 7.632 0 0 1-1.128 1.127M10.998 4.31a6.088 6.088 0 1 0 6.088 6.088 6.094 6.094 0 0 0-6.088-6.088m-.548 8.671a.748.748 0 0 1-.53-.22l-1.833-1.833a.75.75 0 0 1 1.061-1.061l1.3 1.3 2.956-2.957a.75.75 0 0 1 1.06 1.061l-3.484 3.491a.748.748 0 0 1-.53.22" />
    </svg>
  );
}
