import { ComponentProps, useEffect, useState } from "react";
import { UseFormRegisterReturn, useFormContext } from "react-hook-form";
import IconEye from "../../icons/IconEye";
import { mergeClassNames } from "../../utils/style";
import { RequiredBadge } from "./RequiredBadge";

type Props = Omit<
  ComponentProps<"input">,
  keyof UseFormRegisterReturn | "size"
> & {
  name: string;
  size?: "xss" | "xs" | "sm" | "md" | "lg";
  label?: string;
  disabled?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  hideErrorMessage?: boolean;
  icon?: JSX.Element;
};

export const Input: React.FC<Props> = ({
  name,
  className,
  type,
  size = "lg",
  disabled,
  hideErrorMessage,
  icon,
  ...props
}) => {
  const { register, formState } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const message = formState.errors[name];
    if (message) {
      setError(
        message
          ? typeof message.message === "string"
            ? message.message
            : ""
          : "",
      );
    } else {
      setError("");
    }
  }, [formState]);

  return (
    <div className="relative">
      {(props.label || props.required) && (
        <div className="flex items-center mb-[11px] gap-x-2.5">
          {props.label && <div className="text-[14px] ">{props.label}</div>}
          {props.required && <RequiredBadge />}
        </div>
      )}

      <div className="border border-gray-border overflow-hidden rounded bg-gray-background relative focus-within:border-gray-light">
        <input
          {...register(name)}
          disabled={disabled}
          type={showPassword ? "text" : type}
          className={mergeClassNames(
            "flex items-center placeholder:text-gray-light inset-0 focus:outline-none bg-white disabled:bg-transparent disabled:cursor-not-allowed",
            size === "xss" ? "px-1 w-[56px] text-[12px]" : "",
            size === "xs" ? "px-1 w-[75px] text-[12px]" : "",
            size === "sm" ? "p-1 w-[75px] text-sm" : "",
            size === "md" ? "py-3 pl-4.5 w-full text-sm" : "",
            size === "lg" ? "py-4.5 pl-4.5 w-full text-sm" : "",
            type === "password" ? "pr-14" : "",
            className,
          )}
          {...props}
        />
        {type === "password" && (
          <button
            type="button"
            className="focus:outline-none absolute top-1/2 -translate-y-1/2 right-4.5"
            onClick={() => setShowPassword((previousValue) => !previousValue)}
          >
            <IconEye open={showPassword} className="h-5.5 w-5.5" />
          </button>
        )}

        {icon && (
          <div className="focus:outline-none absolute top-1/2 -translate-y-1/2 right-4.5">
            {icon}
          </div>
        )}
      </div>
      {error && !hideErrorMessage && (
        <p className="text-[12px] text-[#FF0000]">{error}</p>
      )}
    </div>
  );
};
