import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { PageRoutes } from "../../../constants/routes";
import { appActions } from "../../../ducks/app";
import { authActions } from "../../../ducks/auth";
import { CustomButton } from "../../atoms/CustomButton";
import { Input } from "../../atoms/Input";
import FormTemplate from "../../templates/FormTemplate";

export const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { secret_key } = useParams();

  if (!secret_key) return <Navigate replace to={PageRoutes.LOGIN} />;

  return (
    <div className="grid place-items-center w-full mt-24">
      <FormTemplate
        className="flex flex-col w-full max-w-100 gap-y-14"
        schema={z
          .object({
            password: z
              .string()
              .regex(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#%*,\-./;<=>?@^_|]).{8,64}$/,
                {
                  message:
                    "パスワードは大文字、小文字、数字、記号の組合せの8桁以上64桁以下で入力して下さい。 記号は ! # % * , - . / ; < = > ? @ ^ _ | をお使いいただけます。",
                },
              ),
            confirmPassword: z.string(),
          })
          .refine((obj) => obj.password === obj.confirmPassword, {
            message: "パスワードが一致しません",
            path: ["confirmPassword"],
          })}
        onSubmit={({ password }) => {
          dispatch(appActions.showLoading());
          dispatch(
            authActions.resetPassword.started({
              secret_key,
              password,
              onComplete: () => dispatch(appActions.hideLoading()),
              onSuccess: () => {
                dispatch(
                  appActions.showModal.started({
                    title: "パスワードを変更しました。",
                    showXIcon: true,
                    closeButtonLabel: "閉じる",
                    handleClose: () => {
                      dispatch(appActions.closeModal());
                      navigate(PageRoutes.LOGIN);
                    },
                  }),
                );
              },
            }),
          );
        }}
      >
        <span className="text-3xl text-gray-dark text-center">
          パスワード再設定
        </span>

        <div className="flex flex-col gap-y-2.5">
          <div className="mb-[22px]">
            <Input
              name="password"
              type="password"
              placeholder="パスワード"
              maxLength={64}
              className="w-full"
            />
          </div>
          <div className="mb-[2px]">
            <Input
              name="confirmPassword"
              type="password"
              placeholder="パスワード（確認)"
              className="w-full"
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-2 w-80 ml-10">
          <CustomButton type="submit" text="設定" />
        </div>
      </FormTemplate>
    </div>
  );
};
