import { IconProps } from "./types";

export default function IconAdd({ fillColor }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.314"
      height="12.314"
      viewBox="0 0 12.314 12.314"
    >
      <g transform="translate(6.157 0.5) rotate(45)">
        <path
          d="M902.816,300.922l8,8"
          transform="translate(-902.816 -300.922)"
          fill="none"
          stroke={fillColor ?? "#334a7c"}
          strokeWidth="1"
        />
        <path
          d="M910.816,300.922l-8,8"
          transform="translate(-902.816 -300.922)"
          fill="none"
          stroke={fillColor ?? "#334a7c"}
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
