import { Road, RoadStatus } from "../ducks/road";
import { isTrue } from "./array";
import { getMaxLatLngBounds } from "./geojson";

export const displayRoadLineStringOnMap = (
  map: any,
  roads: Road[],
  onClickLayer?: (road: Road, coordinates: [number, number][]) => void,
  onClickRoadLayer?: (event: any) => void,
  selectRoad?: (id: number) => void,
  isAnalysis?: boolean,
  removeOnly?: boolean,
) => {
  if (!map.current) return;

  Object.keys(map.current.style._layers).forEach((layer: any) => {
    if (layer.startsWith("line-roads-")) {
      map.current.removeLayer(layer);
    }
  });

  if (removeOnly) return;

  let allLatitudes: number[] = [];
  let allLongitudes: number[] = [];

  roads.forEach((road) => {
    const sourceId = `source-roads-${road.id}`;
    const lineLayerId = `line-roads-${road.id}`;
    const coordinates: [number, number][] = road.polygon
      .map(({ longitude, latitude }) => {
        if (!longitude || !latitude) return null;
        return [longitude, latitude] as [number, number];
      })
      .filter<[number, number]>(isTrue);

    const latitudes = road.polygon.map((x) => x.latitude);
    const longitudes = road.polygon.map((x) => x.longitude);

    allLatitudes.push(...latitudes);
    allLongitudes.push(...longitudes);

    const draw = () => {
      if (!map.current.getSource(sourceId)) {
        map.current.addSource(sourceId, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: { type: "LineString", coordinates },
            properties: {},
          },
        });
      }
      if (!map.current.getLayer(lineLayerId)) {
        map.current.addLayer(
          {
            id: lineLayerId,
            type: "line",
            source: sourceId,
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color":
                road.status === RoadStatus.Waiting
                  ? "#FE9500"
                  : isAnalysis
                    ? "#5FB955"
                    : "#ACBADD",
              "line-width": {
                stops: [
                  [0, 0],
                  [20, 35],
                ],
                base: 2,
              },
            },
          },
          "admin-0-boundary",
        );
      }

      if (selectRoad) {
        map.current.on("click", lineLayerId, () => selectRoad(road.id));
      }
      if (onClickRoadLayer) {
        map.current.off("click", lineLayerId, onClickRoadLayer);
        map.current.on("click", lineLayerId, onClickRoadLayer);
      }
      map.current.on("click", lineLayerId, (e: any) => {
        if (onClickLayer) onClickLayer(road, coordinates);
      });
    };

    try {
      draw();
    } catch (e) {
      map.current.on("load", () => draw());
    }
  });

  return getMaxLatLngBounds(allLatitudes, allLongitudes, 0.001);
};

export const paintMapRoadLayers = (
  map: any,
  roads: Road[],
  selectedRoads: Road[],
  isAnalysis?: boolean,
) => {
  if (!map.current) return;

  roads.forEach((road) => {
    const isSelected = selectedRoads.find((x) => x.id === road.id);
    const lineLayerId = `line-roads-${road.id}`;

    map.current.setPaintProperty(
      lineLayerId,
      "line-color",
      isSelected
        ? "#1967D2"
        : road.status === RoadStatus.Waiting
          ? "#FE9500"
          : isAnalysis
            ? "#5FB955"
            : "#ACBADD",
    );
    map.current.setPaintProperty(
      lineLayerId,
      "line-width",
      isSelected && !isAnalysis
        ? {
            stops: [
              [0, 0],
              [20, 55],
            ],
            base: 2,
          }
        : {
            stops: [
              [0, 0],
              [20, 35],
            ],
            base: 2,
          },
    );
  });
};
