import { useMemo } from "react";
import { AreaStatus } from "../../ducks/area";
import { mergeClassNames } from "../../utils/style";

type Props = {
  status: AreaStatus;
  isRoad: boolean;
};

export const StatusLabel: React.FC<Props> = ({ status, isRoad }) => {
  const bgColorClass = useMemo(() => {
    switch (status) {
      case AreaStatus.Success:
        return "bg-[#5FB955]";
      case AreaStatus.Approved:
      case AreaStatus.Processing:
        return "bg-[#FE9500]";
      case AreaStatus.Failure:
        return "bg-[#cccccc]";
    }
  }, []);

  const text = useMemo(() => {
    switch (status) {
      case AreaStatus.Success:
        return "生成済み";
      case AreaStatus.Approved:
        return isRoad ? "夜間待ち" : "生成中";
      case AreaStatus.Processing:
        return "生成中";
      case AreaStatus.Failure:
        return "失敗";
    }
  }, []);

  return (
    <span
      className={mergeClassNames(
        "text-[11px] py-0.5 px-2.5 rounded-full text-white whitespace-nowrap",
        bgColorClass,
      )}
    >
      {text}
    </span>
  );
};
