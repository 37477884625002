import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { appActions } from "../ducks/app";
import { AreaCoordinates } from "../ducks/area";
import { EditRoadNetworkMode, Road, roadActions } from "../ducks/road";

export const useCreateRoadNetwork = (handleClose: () => void) => {
  const dispatch = useDispatch();

  const [currentMode, setCurrentMode] = useState<EditRoadNetworkMode>(
    EditRoadNetworkMode.Add,
  );
  const [coordinates, setCoordinates] = useState<AreaCoordinates[]>([]);
  const [roadsToDelete, setRoadsToDelete] = useState<Road[]>([]);

  const disableSubmitButton = useMemo(() => {
    return coordinates.length === 0 && roadsToDelete.length === 0;
  }, [coordinates, roadsToDelete]);

  const handleChangeMode = (name: string, value: string) => {
    // @ts-ignore
    setCurrentMode(name);
    setCoordinates([]);
    setRoadsToDelete([]);
  };

  const handleCallback = (coords: AreaCoordinates[]) => {
    setCoordinates(coords);
  };

  const handleRoadCallback = (roads: Road[]) => {
    setRoadsToDelete(roads);
  };

  const helperText = useMemo(() => {
    switch (currentMode) {
      case EditRoadNetworkMode.Add:
        return "地図上をクリックすると線分を表示します。線分の頂点を編集し、追加したい道路に合わせてください\n※すでにある道路に重複して追加はできません";
      case EditRoadNetworkMode.Delete:
        return "地図上の道路を選択して、右クリックから道路を削除を選択してください";
      default:
        return null;
    }
  }, [currentMode]);

  const onSubmit = () => {
    if (currentMode === EditRoadNetworkMode.Add) {
      dispatch(
        appActions.showModal.started({
          title: "道路の編集内容を保存しますか？",
          subTitle: "道路を編集すると分析結果に影響が出る場合があります",
          showXIcon: true,
          closeButtonLabel: "キャンセル",
          nextButtonLabel: "保存",
          handleClose: () => dispatch(appActions.closeModal()),
          handleNext: () => {
            dispatch(appActions.showLoading());
            dispatch(
              roadActions.createRoad.started({
                area: coordinates,
                onComplete: () => dispatch(appActions.hideLoading()),
                onSuccess: () => {
                  dispatch(appActions.closeModal());
                  handleClose();
                },
              }),
            );
          },
        }),
      );
    } else {
      dispatch(appActions.showLoading());
      if (
        currentMode === EditRoadNetworkMode.Delete &&
        roadsToDelete.length > 0
      ) {
        dispatch(
          roadActions.deleteRoads.started({
            roadIds: roadsToDelete.map((road) => road.id),
            onComplete: () => dispatch(appActions.hideLoading()),
            onSuccess: () => handleClose(),
          }),
        );
      }
    }
  };

  return {
    currentMode,
    coordinates,
    handleChangeMode,
    handleCallback,
    handleRoadCallback,
    disableSubmitButton,
    helperText,
    onSubmit,
  };
};
