import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { appActions } from "./actions";
import { AppState } from "./types";

const initialState: AppState = {
  isLoading: false,
  showModal: false,
  modalObject: null,
  notification: null,
  showNotification: false,
  menus: [],
  sideMenuExpanded: true,
};

export function AppReducer(state = initialState, action: Action): AppState {
  if (isType(action, appActions.showLoading)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (isType(action, appActions.hideLoading)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (isType(action, appActions.showModal.done)) {
    return {
      ...state,
      showModal: true,
      modalObject: action.payload.params,
    };
  }

  if (isType(action, appActions.closeModal)) {
    return {
      ...state,
      showModal: false,
      modalObject: null,
    };
  }

  if (isType(action, appActions.displayNotification)) {
    return {
      ...state,
      notification: action.payload,
      showNotification: true,
    };
  }

  if (isType(action, appActions.resetNotification)) {
    return {
      ...state,
      notification: null,
      showNotification: false,
    };
  }

  if (isType(action, appActions.fetchMenus.done)) {
    return {
      ...state,
      menus: action.payload.result,
    };
  }

  if (isType(action, appActions.setSideMenuExpanded)) {
    return {
      ...state,
      sideMenuExpanded: action.payload,
    };
  }

  if (isType(action, appActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
