export const ROAD_HEAT_MAP_COLOR = [
  "#FEF7C3",
  "#FADD9C",
  "#F5AC5E",
  "#D97435",
  "#A7261B",
  "#6F170F",
];

const DEFAULT_ROAD_RANGE = [1, 40, 80, 120, 160, 200, 240];
export const DEFAULT_ROAD_COLOR_SCALE: { from: number; to: number }[] =
  DEFAULT_ROAD_RANGE.map<{ from: number; to: number }>((num, i) => ({
    from: num,
    to: DEFAULT_ROAD_RANGE[i + 1],
  })).filter((scale) => scale.from !== undefined && scale.to !== undefined);
