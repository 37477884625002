import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { historyActions } from "./actions";
import { HistoryState } from "./types";

const initialState: HistoryState = {
  totalHistories: 0,
  histories: [],
};

export function HistoryReducer(
  state = initialState,
  action: Action,
): HistoryState {
  if (isType(action, historyActions.fetchHistories.done)) {
    return {
      ...state,
      totalHistories: action.payload.result.totalHistories,
      histories: [...state.histories, ...action.payload.result.histories],
    };
  }

  if (isType(action, historyActions.createHistory.done)) {
    return {
      ...state,
      totalHistories: state.totalHistories + 1,
      histories: [],
    };
  }

  if (isType(action, historyActions.deleteHistories.done)) {
    return {
      ...state,
      totalHistories: state.totalHistories - 1,
      histories: state.histories.filter(
        (history) => history.history_id !== action.payload.result.historyId,
      ),
    };
  }

  if (isType(action, historyActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
