import IconDefaultUserProfileImage from "../../icons/Header/IconDefaultUserProfileImage";
import IconLogout from "../../icons/Header/IconLogout";
import { mergeClassNames } from "../../utils/style";
import { useLogin } from "../pages/login/hook";

type Props = {
  empty?: boolean;
};

export const Header: React.FC<Props> = ({ empty }) => {
  const { loggedInUser, handleLogout, serviceName } = useLogin();

  return (
    <div
      className={mergeClassNames(
        "py-2 px-6 flex items-center text-white bg-primary justify-between",
        !!empty && "h-10",
      )}
    >
      {!empty && (
        <>
          <img className="object-cover h-[24px]" src="/logo.png" alt="logo" />
          <div className="flex items-center gap-x-7 justify-end">
            <div className="flex gap-x-1.5 items-center">
              <IconDefaultUserProfileImage />
              <span className="text-sm">{loggedInUser?.name}</span>
            </div>
            <button
              type="button"
              className="flex items-center gap-x-1.5"
              onClick={handleLogout}
            >
              <IconLogout />
              <span className="text-xs">ログアウト</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};
