import { DataDisplayControl } from "../../molecules/map/DataDisplayControl";
import { HeatMapControl } from "../../molecules/map/HeatMapControl";
import { MapTypeControl } from "../../molecules/map/MapTypeControl";

type Props = {
  index: number;
};

export const MapSettings: React.FC<Props> = ({ index }) => {
  return (
    <div id="map-settings" style={{ display: "none" }}>
      <div className="flex m-2">
        <MapTypeControl index={index} />
        <DataDisplayControl index={index} />
        <HeatMapControl index={index} />
      </div>
    </div>
  );
};
