import { VOYAGER } from "@carto/react-basemaps";
import { InitialCarto3State, createCartoSlice } from "@carto/react-redux";
import { Action } from "@reduxjs/toolkit";
// @ts-ignore
import { API_VERSIONS } from "@deck.gl/carto";
import { isType } from "typescript-fsa";
import { cartoActions } from "./actions";
import { CartoData, CartoState } from "./types";

export const cartoInitialState: InitialCarto3State = {
  viewState: {
    latitude: 35.7295,
    longitude: 139.7109,
    zoom: 14,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: VOYAGER,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: "https://gcp-asia-northeast1.api.carto.com",
    accessToken: process.env.REACT_APP_CARTO_ACCESS_TOKEN,
  },
  googleApiKey: "", // only required when using a Google Basemap,
  googleMapId: "", // only required when using a Google Custom Basemap
  accountsUrl: "http://app.carto.com/",
  oauth: {
    domain: "auth.carto.com",
    clientId: process.env.REACT_APP_CARTO_CLIENT_ID ?? "",
    organizationId: process.env.REACT_APP_CARTO_ORGANIZATION_ID ?? "",
    namespace: "http://app.carto.com/",
    scopes: [
      "read:current_user",
      "update:current_user",
      "read:connections",
      "write:connections",
      "read:maps",
      "write:maps",
      "read:account",
      "admin:account",
    ],
    audience: "carto-cloud-native-api",
    authorizeEndPoint: "https://carto.com/oauth2/authorize",
  },
};

export const CartoReducer = createCartoSlice(cartoInitialState);

export function Carto2Reducer(
  state = { carto: [] as CartoData[] },
  action: Action,
): CartoState {
  if (isType(action, cartoActions.fetchCartoData.done)) {
    return {
      ...state,
      carto: action.payload.result.carto,
    };
  }

  if (isType(action, cartoActions.resetToInitialState)) {
    return { carto: [] as CartoData[] };
  }

  return state;
}
