import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../constants/routes";
import { analysisActions, analysisSelectors } from "../../../ducks/analysis";
import {
  AnalysisItem,
  AnalysisPattern,
  AnalysisStep,
} from "../../../ducks/analysis/types";

import {
  DEFAULT_ANALYSIS_FILTER,
  DEFAULT_ANALYSIS_START_DATE,
  DEFAULT_ANALYSIS_TIME,
  DEFAULT_ANALYSIS_TO_DATE,
} from "../../../constants/analysis";
import {
  URL_QUERY_PARAM_AREA,
  URL_QUERY_PARAM_FILTERED_AREA,
  URL_QUERY_PARAM_FILTERED_ROAD,
  URL_QUERY_PARAM_FROM,
  URL_QUERY_PARAM_IS_VISIBLE,
  URL_QUERY_PARAM_ROAD,
  URL_QUERY_PARAM_SUB_AREA,
  URL_QUERY_PARAM_TIME,
  URL_QUERY_PARAM_TO,
  URL_QUERY_PARAM_TYPE,
} from "../../../constants/param";
import { Area, AreaStatus, areaSelectors } from "../../../ducks/area";
import { datasetSelectors } from "../../../ducks/dataset";
import { HistoryReception, historyActions } from "../../../ducks/history";
import { getAnalysisItemIconMini } from "../../../utils/analysis";
import { getMinAndMaxDate } from "../../../utils/date";

export const useAnalysis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [saveSelectionPopupOpen, setSaveSelectionPopupOpen] =
    useState<boolean>(false);
  const [analysisLoadingModal, setAnalysisLoadingModal] =
    useState<boolean>(false);

  const selectedAreas = useSelector(areaSelectors.selectedAreas);
  const selectedSubAreas = useSelector(areaSelectors.selectedSubAreas);
  const selectedRoads = useSelector(areaSelectors.selectedRoads);

  const currentStep = useSelector(analysisSelectors.currentStep);
  const analysisItems = useSelector(analysisSelectors.analysisItems);
  const selectedAnalysisItem = useSelector(
    analysisSelectors.selectedAnalysisItem,
  );
  const analysisSteps = useSelector(analysisSelectors.analysisSteps);

  const datasets = useSelector(datasetSelectors.datasets);

  const SelectedAnalysisItemIcon = useMemo(() => {
    if (!selectedAnalysisItem) return null;
    return getAnalysisItemIconMini(selectedAnalysisItem.type);
  }, [selectedAnalysisItem]);

  const canGoNext = useMemo(() => {
    if (currentStep === 1) return selectedAnalysisItem !== null;
    if (!selectedAnalysisItem) return false;

    if (
      selectedAnalysisItem.min &&
      selectedAreas.length < selectedAnalysisItem.min
    ) {
      return false;
    }

    if (selectedAnalysisItem.pattern === AnalysisPattern.SelectMainAndSubArea) {
      if (location.pathname === PageRoutes.SELECT_ANALYSIS_SUB_AREA) {
        return selectedSubAreas.length > 0;
      }
    }

    if (currentStep === 2) {
      if (selectedAnalysisItem.pattern === AnalysisPattern.SelectRoad) {
        return selectedRoads.length > 0;
      } else {
        return selectedAreas.length > 0;
      }
    }
    if (currentStep === 3) {
      if (selectedAnalysisItem.pattern === AnalysisPattern.SelectAreaAndRoad) {
        return selectedRoads.length > 0;
      } else {
        return selectedAreas.length > 0;
      }
    }
    return currentStep < analysisSteps.length;
  }, [
    currentStep,
    selectedAnalysisItem,
    selectedAreas,
    selectedSubAreas,
    selectedRoads,
    analysisSteps,
    location.pathname,
  ]);

  const canGoBack = useMemo(() => {
    return currentStep > 1;
  }, [currentStep]);

  const handleNext = useCallback(() => {
    if (!canGoNext || !selectedAnalysisItem) return;

    if (currentStep < analysisSteps.length)
      dispatch(analysisActions.setCurrentStep(currentStep + 1));

    if (selectedAnalysisItem.pattern === AnalysisPattern.SelectMainAndSubArea) {
      if (location.pathname === PageRoutes.SELECT_ANALYSIS_SUB_AREA)
        return openSaveSelectionPopup();
      if (location.pathname === PageRoutes.SELECT_ANALYSIS_AREA)
        return navigate(PageRoutes.SELECT_ANALYSIS_SUB_AREA);
    }

    if (analysisSteps[currentStep] === AnalysisStep.SelectArea)
      return navigate(PageRoutes.SELECT_ANALYSIS_AREA);
    if (analysisSteps[currentStep] === AnalysisStep.SelectRoad)
      return navigate(PageRoutes.SELECT_ANALYSIS_ROAD);

    if (currentStep === analysisSteps.length) return openSaveSelectionPopup();
  }, [
    currentStep,
    canGoNext,
    analysisSteps,
    selectedAnalysisItem,
    selectedAreas,
    selectedRoads,
    location.pathname,
  ]);

  const handleBack = useCallback(() => {
    if (!canGoBack || !selectedAnalysisItem) return;

    if (selectedAnalysisItem.pattern === AnalysisPattern.SelectMainAndSubArea) {
      if (location.pathname === PageRoutes.SELECT_ANALYSIS_SUB_AREA) {
        return navigate(PageRoutes.SELECT_ANALYSIS_AREA);
      }
    }

    dispatch(analysisActions.setCurrentStep(currentStep - 1));

    if (analysisSteps[currentStep - 2] === AnalysisStep.SelectArea)
      return navigate(PageRoutes.SELECT_ANALYSIS_AREA);

    if (analysisSteps[currentStep - 2] === AnalysisStep.SelectRoad)
      return navigate(PageRoutes.SELECT_ANALYSIS_ROAD);

    navigate(PageRoutes.ANALYSIS_EXECUTE);
  }, [
    currentStep,
    canGoBack,
    analysisSteps,
    selectedAnalysisItem,
    location.pathname,
  ]);

  const handleSelectAnalysisItem = useCallback((item: AnalysisItem) => {
    dispatch(analysisActions.setSelectedAnalysisItem(item));
  }, []);

  const openSaveSelectionPopup = () => setSaveSelectionPopupOpen(true);
  const closeSaveSelectionPopup = () => setSaveSelectionPopupOpen(false);

  const closeAnalysisLoadingModal = () => setAnalysisLoadingModal(false);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const minMaxDates = useMemo(() => {
    const dates: string[] = [];

    const getStartAndEndDates = (area: Area) => {
      area.dataset.datasets.forEach((dataset) => {
        const currentDataset = datasets.find(
          (d) => d.dataset_id === dataset.dataset_id,
        );

        if (currentDataset) {
          if (!dates.includes(currentDataset.start_date))
            dates.push(currentDataset.start_date);
          if (!dates.includes(currentDataset.end_date))
            dates.push(currentDataset.end_date);
        }
      });
    };

    selectedAreas.forEach(getStartAndEndDates);
    selectedSubAreas.forEach(getStartAndEndDates);
    selectedRoads.forEach(getStartAndEndDates);

    return getMinAndMaxDate(dates.map((date) => new Date(date)));
  }, [datasets, selectedAreas, selectedSubAreas, selectedRoads]);

  const handleClickSaveSelection = (isSave: boolean) => {
    setIsVisible(isSave);

    if (
      selectedAreas.some((area) => area.status !== AreaStatus.Success) ||
      selectedSubAreas.some((area) => area.status !== AreaStatus.Success) ||
      selectedRoads.some((area) => area.status !== AreaStatus.Success)
    )
      return setAnalysisLoadingModal(true);

    if (process.env.NODE_ENV === "development")
      return closeSaveSelectionPopup();

    const index = window.location.href.indexOf("/#/");
    const baseUrl = window.location.href.slice(0, index);

    query.set(
      URL_QUERY_PARAM_TYPE,
      selectedAnalysisItem?.type.toString() ?? "",
    );
    query.set(
      URL_QUERY_PARAM_AREA,
      selectedAreas.map((area) => area.id).join(","),
    );
    query.set(
      URL_QUERY_PARAM_SUB_AREA,
      selectedSubAreas.map((area) => area.id).join(","),
    );
    query.set(
      URL_QUERY_PARAM_ROAD,
      selectedRoads.map((area) => area.id).join(","),
    );
    query.set(URL_QUERY_PARAM_FILTERED_AREA, "");
    query.set(URL_QUERY_PARAM_FILTERED_ROAD, "");
    query.set(
      URL_QUERY_PARAM_FROM,
      minMaxDates[0] ?? DEFAULT_ANALYSIS_START_DATE,
    );
    query.set(URL_QUERY_PARAM_TO, minMaxDates[1] ?? DEFAULT_ANALYSIS_TO_DATE);
    query.set(URL_QUERY_PARAM_TIME, DEFAULT_ANALYSIS_TIME);
    query.set(URL_QUERY_PARAM_IS_VISIBLE, isSave ? "1" : "");

    window.location.href = `${baseUrl}/analyze/#${PageRoutes.ANALYSIS_RESULT}?${query}`;
  };

  const handleCloseAnalysisLoadingModal = (sendEmail: boolean) => {
    closeSaveSelectionPopup();
    closeAnalysisLoadingModal();

    if (!selectedAnalysisItem) return;

    const onSuccess = (receptions: HistoryReception[]) => {
      if (isVisible) {
        dispatch(
          historyActions.createHistory.started({
            type: selectedAnalysisItem.type,
            areas: [
              ...selectedAreas.map((area) => ({
                area_id: area.id,
                checked: true,
                is_subarea: false,
              })),
              ...selectedSubAreas.map((area) => ({
                area_id: area.id,
                checked: true,
                is_subarea: true,
              })),
            ],
            roads: selectedRoads.map((area) => ({
              road_id: area.id,
              checked: true,
            })),
            from: minMaxDates[0] ?? DEFAULT_ANALYSIS_START_DATE,
            to: minMaxDates[1] ?? DEFAULT_ANALYSIS_TO_DATE,
            time: DEFAULT_ANALYSIS_TIME,
            filter: DEFAULT_ANALYSIS_FILTER,
            receptions,
          }),
        );
      }
      dispatch(analysisActions.clearSelectedAnalysisItem());
      navigate(PageRoutes.ANALYSIS_EXECUTE);
    };

    dispatch(
      analysisActions.analyze.started({
        areaIds: [
          ...selectedAreas.map((area) => area.id),
          ...selectedSubAreas.map((area) => area.id),
          ...selectedRoads.map((area) => area.id),
        ],
        batchIds:
          selectedAnalysisItem.analyze_id.length === 0
            ? [100]
            : selectedAnalysisItem.analyze_id,
        filterMask: DEFAULT_ANALYSIS_FILTER,
        startDate: minMaxDates[0] ?? DEFAULT_ANALYSIS_START_DATE,
        endDate: minMaxDates[1] ?? DEFAULT_ANALYSIS_TO_DATE,
        sendEmail,
        isVisible,
        reception_ids: [],
        onSuccess,
      }),
    );

    setIsVisible(false);
  };

  return {
    currentStep,
    canGoBack,
    canGoNext,
    handleNext,
    handleBack,
    analysisItems,
    selectedAnalysisItem,
    analysisLoadingModal,
    SelectedAnalysisItemIcon,
    analysisSteps,
    saveSelectionPopupOpen,
    isVisible,
    setIsVisible,
    handleSelectAnalysisItem,
    openSaveSelectionPopup,
    closeSaveSelectionPopup,
    closeAnalysisLoadingModal,
    handleClickSaveSelection,
    handleCloseAnalysisLoadingModal,
  };
};
