import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../constants/routes";
import { appActions } from "../../../ducks/app";
import { Area, AreaType, areaActions } from "../../../ducks/area";

export const useAnalysisArea = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(areaActions.resetSelectedAreas());
    dispatch(areaActions.resetSelectedSubAreas());
    dispatch(areaActions.resetSelectedRoads());
  }, []);

  const [isModalClose, setIsModalClose] = useState<boolean>(false);
  const [createAreaModalOpen, setCreateAreaModalOpen] =
    useState<boolean>(false);
  const [createAreaNameModalOpen, setCreateAreaNameModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (isModalClose) setIsModalClose(false);
  }, [isModalClose]);

  const [currentSelectedArea, setCurrentSelectedArea] = useState<Area | null>(
    null,
  );

  const onAnalyze = (area: Area) =>
    navigate(
      PageRoutes.ANALYSIS_EXECUTE +
        `?${area.area.type === AreaType.Road ? "road" : "area"}=${area.id}`,
    );

  const onEdit = (area: Area) => {
    setCurrentSelectedArea(area);
    setCreateAreaNameModalOpen(true);
  };

  const onDelete = (area: Area) => {
    dispatch(
      appActions.showModal.started({
        showXIcon: true,
        title: `分析${
          area.area.type === AreaType.Road ? "道路" : "エリア"
        }を\n削除して良いですか？`,
        nextButtonLabel: "削除",
        closeButtonLabel: "キャンセル",
        handleNext: () => {
          dispatch(appActions.showLoading());
          dispatch(
            areaActions.deleteArea.started({
              areaId: area.id,
              type: area.area.type === AreaType.Road ? "road" : "area",
              onComplete: () => dispatch(appActions.hideLoading()),
              onSuccess: () => {
                dispatch(appActions.closeModal());
                setCurrentSelectedArea(null);
                onClose();
              },
            }),
          );
        },
        handleClose: () => dispatch(appActions.closeModal()),
      }),
    );
  };

  const onClose = () => {
    const closeButton = document.querySelector<HTMLButtonElement>(
      ".mapboxgl-popup-close-button",
    );
    closeButton?.click();
  };

  const onClickAdd = () => setCreateAreaModalOpen(true);

  const closeCreateAreaModal = () => {
    dispatch(areaActions.resetSelectedAreas());
    setCreateAreaModalOpen(false);
    setIsModalClose(true);
  };

  const closeCreateAreaNameModal = () => setCreateAreaNameModalOpen(false);

  const onSubmit = (
    selectedArea: Area | null,
    areaName: string,
    datasetIds: number[],
    forMulti: boolean,
  ) => {
    if (selectedArea) {
      dispatch(appActions.showLoading());
      dispatch(
        areaActions.editArea.started({
          areaId: selectedArea.id,
          areaName,
          forMulti,
          area: selectedArea.area,
          onComplete: () => dispatch(appActions.hideLoading()),
          onSuccess: () => {
            closeCreateAreaNameModal();
            setCurrentSelectedArea(null);
            onClose();
          },
        }),
      );
    }
  };

  return {
    onAnalyze,
    onEdit,
    onDelete,
    onClose,
    onClickAdd,
    createAreaModalOpen,
    createAreaNameModalOpen,
    closeCreateAreaModal,
    closeCreateAreaNameModal,
    onSubmit,
    currentSelectedArea,
    setCurrentSelectedArea,
    isModalClose,
  };
};
