import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as CloseIcon } from "../../assets/icons/closebtn.svg";
import { ReactComponent as FailIcon } from "../../assets/icons/fail.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/tick.svg";
import { NotificationItem, appActions } from "../../ducks/app";
import { mergeClassNames } from "../../utils/style";

type Props = {
  notification: NotificationItem;
  visible: boolean;
};

export const NotificationStatus: React.FC<Props> = ({
  notification,
  visible,
}) => {
  const dispatch = useDispatch();

  let bgColor = useMemo(() => {
    return notification.type === "success"
      ? "bg-[#5FB955]"
      : notification.type === "failed"
        ? "bg-[#AA3B13]"
        : "";
  }, [notification.type]);

  const handleClose = () => {
    dispatch(appActions.resetNotification());
  };

  useEffect(() => {
    setTimeout(() => handleClose(), 3000);
  }, [visible]);

  if (!visible) return null;

  return (
    <div
      className={mergeClassNames(
        bgColor,
        "flex items-center fade-in fixed right-[20px] top-[20px] min-w-[312px] h-[64px] pl-[13px] py-[25px] rounded z-[999]",
      )}
    >
      <div className="relative flex items-center w-full justify-between">
        <div className="flex items-center">
          {notification.type === "success" && <SuccessIcon />}
          {notification.type === "failed" && <FailIcon />}
          <div className="text-[14px] font-bold text-white ml-[10px]">
            {notification.text}
          </div>
        </div>

        <CloseIcon
          onClick={handleClose}
          className="absolute -top-[20px] right-[6px] cursor-pointer"
        />
      </div>
    </div>
  );
};
