import { IconProps } from "../types";

export default function IconAreaMovementMini({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16.047"
      viewBox="0 0 16 16.047"
    >
      <path
        id="Path_857"
        data-name="Path 857"
        d="M8,0H.835A.835.835,0,0,0,0,.835V8a.834.834,0,0,0,.835.835H8A.834.834,0,0,0,8.835,8V.835A.835.835,0,0,0,8,0M7.165,7.165H1.67V1.67h5.5ZM16,.835V8a.834.834,0,0,1-.835.835H11.34A.834.834,0,0,1,10.5,8V.835A.835.835,0,0,1,11.34,0h3.826A.835.835,0,0,1,16,.835m0,10.551v3.826a.834.834,0,0,1-.835.835H11.34a.834.834,0,0,1-.835-.835V11.386a.834.834,0,0,1,.835-.835h3.826a.834.834,0,0,1,.835.835m-7.165,0v3.826A.834.834,0,0,1,8,16.047H.835A.834.834,0,0,1,0,15.212V11.386a.834.834,0,0,1,.835-.835H8a.834.834,0,0,1,.835.835"
        fill="#334a7c"
      />
    </svg>
  );
}
