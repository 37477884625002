import IconAreaMovement from "../icons/Analysis/IconAreaMovement";
import IconAreaMovement2 from "../icons/Analysis/IconAreaMovement2";
import IconAreaMovement2Mini from "../icons/Analysis/IconAreaMovement2Mini";
import IconAreaMovementMini from "../icons/Analysis/IconAreaMovementMini";
import IconMovement from "../icons/Analysis/IconMovement";
import IconMovementMini from "../icons/Analysis/IconMovementMini";
import IconResidence from "../icons/Analysis/IconResidence";
import IconResidenceMini from "../icons/Analysis/IconResidenceMini";
import IconRoad from "../icons/Analysis/IconRoad";
import IconRoadMini from "../icons/Analysis/IconRoadMini";
import IconVisitor2 from "../icons/Analysis/IconVIsitor2";
import IconVisitor from "../icons/Analysis/IconVisitor";
import IconVisitor2Mini from "../icons/Analysis/IconVisitor2Mini";
import IconVisitorMini from "../icons/Analysis/IconVisitorMini";
import { IconComponent } from "../icons/types";

export const getAnalysisItemIcon = (type: number) => {
  let icon: IconComponent | null = null;
  switch (type) {
    case 1:
    case 2:
      icon = IconVisitor;
      icon = IconVisitor;
      break;
    case 3:
      icon = IconResidence;
      break;
    case 4:
      icon = IconVisitor2;
      break;
    case 5:
      icon = IconRoad;
      break;
    case 6:
      icon = IconAreaMovement;
      break;
    case 7:
      icon = IconMovement;
      break;
    case 8:
      icon = IconAreaMovement2;
      break;
    default:
      icon = null;
      break;
  }
  return icon;
};

export const getAnalysisItemIconMini = (type: number) => {
  let icon: IconComponent | null = null;
  switch (type) {
    case 1:
    case 2:
      icon = IconVisitorMini;
      icon = IconVisitorMini;
      break;
    case 3:
      icon = IconResidenceMini;
      break;
    case 4:
      icon = IconVisitor2Mini;
      break;
    case 5:
      icon = IconRoadMini;
      break;
    case 6:
      icon = IconAreaMovementMini;
      break;
    case 7:
      icon = IconMovementMini;
      break;
    case 8:
      icon = IconAreaMovement2Mini;
      break;
    default:
      icon = null;
      break;
  }
  return icon;
};
