export interface IAppConfig {
  LocalStorageKey: Record<string, string>;
}

export class AppConfig implements IAppConfig {
  private static _instance: IAppConfig;
  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  readonly LocalStorageKey = {
    accessToken: "t__ur_access_token",
    refreshToken: "t__ur_refresh_token",
    loggedInUser: "t__ur_logged_in_user",
    tenantId: "t__ur_tenant_id",
  };
}
