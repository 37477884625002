import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { City } from "./types";

const actionCreator = actionCreatorFactory("@@City");

export const cityActions = {
  fetchCities: actionCreator.async<CommonAsyncType<City[]> & { keyword: string }, {}>("FETCH_CITIES"),
  fetchTowns: actionCreator.async<CommonAsyncType<City[]> & { keyword: string }, {}>("FETCH_TOWNS"),
  resetToInitialState: actionCreator("RESET_TO_INITIAL_STATE"),
};
