// @ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
// @ts-expect-error
import MapboxCircle from "mapbox-gl-circle";
import { useCallback, useEffect, useRef, useState } from "react";
import { STANDARD_MAP_STYLE } from "../../../constants/map";
import { AreaCoordinates } from "../../../ducks/area";
import { useArea } from "../../../hooks/useArea";
import { useMapBounds } from "../../../hooks/useMapBounds";
import { useMapControl } from "../../../hooks/useMapControl";
import { MapDrawCircleControls } from "../../../hooks/useMapDrawCircle";
import { useMapSetting } from "../../../hooks/useMapSetting";
import { displayAreaPolygonOnMap } from "../../../utils/polygon";
import { MapResetButton } from "../../molecules/map/MapResetButton";
import { MapTypeControl } from "../../molecules/map/MapTypeControl";

type Props = {
  mapDrawCircleControls: MapDrawCircleControls;
  callback(coordinates: AreaCoordinates[]): void;
};

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN;

export const CircleMap: React.FC<Props> = ({
  mapDrawCircleControls,
  callback,
}) => {
  const { circle, center, radius, setCircle, setCenter, resetAll } =
    mapDrawCircleControls;

  const [hasAddedControls, setHasAddedControls] = useState(false);

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const { mapCenter, mapZoom, setOnMapMove } = useMapBounds(map);
  const { mapStyle } = useMapSetting();

  const {
    geocoderControl,
    navigationControl,
    languageControl,
    mapResetButton,
    mapTypeControl2,
  } = useMapControl();

  const { areas, roads } = useArea();

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: STANDARD_MAP_STYLE,
      center: mapCenter,
      zoom: mapZoom,
      attributionControl: false,
    })
      .addControl(new mapboxgl.AttributionControl({ compact: false }))
      .addControl(geocoderControl)
      .addControl(navigationControl, "bottom-right")
      .addControl(mapTypeControl2, "top-right")
      .addControl(languageControl);

    setHasAddedControls(true);

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    setOnMapMove();

    return () => {
      resetAll();
    };
  }, []);

  useEffect(() => {
    if (!map.current) return;

    if (hasAddedControls) map.current.removeControl(mapResetButton);

    map.current.addControl(mapResetButton, "top-right");
  }, [circle]);

  const addCircleToMap = useCallback(
    ({ lngLat }: { lngLat: { lng: number; lat: number } }) => {
      if (circle) circle.remove();

      setCenter([lngLat.lng, lngLat.lat]);
      callback([{ latitude: lngLat.lat, longitude: lngLat.lng }]);

      const circleOptions = {
        draggable: true,
        minRadius: 50,
        radius,
        fillColor: "#1967D2",
        strokeColor: "#1967D2",
        strokeWeight: 2,
        fillOpacity: 0.5,
      };

      const newCircle = new MapboxCircle(lngLat, radius * 1000, circleOptions)
        .on("centerchanged", (event: any) =>
          setCenter(event._currentCenterLngLat),
        )
        .addTo(map.current);
      setCircle(newCircle);
    },
    [circle, radius],
  );

  const removeCircle = useCallback((circle: any) => {
    if (circle) circle.remove();
    setCircle(null);
    setCenter([]);
  }, []);

  useEffect(() => {
    if (!map.current) return;

    map.current.on("click", addCircleToMap);

    return () => {
      map.current?.off("click", addCircleToMap);
    };
  }, [circle]);

  useEffect(() => {
    displayAreaPolygonOnMap(map, areas);
    displayAreaPolygonOnMap(map, roads);

    map.current.on("style.load", () => {
      displayAreaPolygonOnMap(map, areas);
      displayAreaPolygonOnMap(map, roads);
    });
  }, [areas, roads]);

  useEffect(() => {
    if (center.length > 0) {
      addCircleToMap({ lngLat: { lng: center[0], lat: center[1] } });
    }
  }, [radius]);

  useEffect(() => {
    if (!map.current) return;

    map.current.setStyle(mapStyle);
  }, [mapStyle]);

  return (
    <div className="flex flex-1 w-full h-full">
      <div
        ref={mapContainer}
        className="w-full h-full"
        onContextMenu={(e) => e.preventDefault()}
      ></div>
      <MapResetButton onClick={removeCircle} data={circle} />
      <MapTypeControl index={2} />
    </div>
  );
};
