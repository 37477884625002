import React from "react";
import { useSideNavigation } from "../../hooks/useSideNavigation";
import { IconComponent } from "../../icons/types";
import { mergeClassNames } from "../../utils/style";

type Props = {
  shrink?: boolean;
  title: string;
  path: string;
  icon: IconComponent | null;
};

export const SideNavItem: React.FC<Props> = ({
  shrink,
  title,
  path,
  icon: Icon,
}) => {
  const { currentMenu, onClickSideMenu } = useSideNavigation();

  return (
    <button
      type="button"
      onClick={() => onClickSideMenu(path)}
      className={mergeClassNames(
        "flex items-center gap-x-2.5 py-3 px-5 my-1 mx-2",
        currentMenu && currentMenu.path === path
          ? "font-bold text-primary bg-primary-light rounded overflow-hidden"
          : "font-normal",
      )}
    >
      {Icon && (
        <Icon
          className={mergeClassNames(
            "w-6 h-6",
            currentMenu && currentMenu.path === path
              ? "text-primary"
              : "text-black",
          )}
        />
      )}
      {!shrink && <span className="text-sm">{title}</span>}
    </button>
  );
};
