import { IconProps } from "../types";

export default function IconLogout({
  className = "text-white h-3.5 w-3.5",
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      className={className}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.821 3.682V1.773A1.265 1.265 0 0 0 7.566.5h-5.81A1.264 1.264 0 0 0 .5 1.773v11.454A1.263 1.263 0 0 0 1.756 14.5h5.81a1.264 1.264 0 0 0 1.256-1.273v-1.908"
        data-name="Path 274"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.579 4.538 14.501 7.5l-2.922 2.965"
        data-name="Path 275"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.746 7.5h-7.77"
        data-name="Line 573"
      />
    </svg>
  );
}
