import React from "react";
import IconSpinner from "../../icons/IconSpinner";

type Props = {
  className?: string;
  isShown?: boolean;
};

export const LoadingIndicator: React.FC<Props> = ({ className, isShown }) => {
  if (!isShown) return null;

  return (
    <div
      data-testid="loading"
      className={`fixed inset-0 bg-black/20 z-[999] grid place-items-center ${className}`}
    >
      <div className="bg-white flex justify-center items-center w-[440px] max-w-[90vw] h-[440px] rounded-xl">
        <IconSpinner />
      </div>
    </div>
  );
};
