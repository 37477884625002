//@ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React, { useCallback, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import {
  ROAD_NETWORK_MAP_MIN_ZOOM,
  STANDARD_MAP_STYLE,
} from "../../../constants/map";
import { Road } from "../../../ducks/road";
import { useMapBounds } from "../../../hooks/useMapBounds";
import { useMapControl } from "../../../hooks/useMapControl";
import { useMapDraw } from "../../../hooks/useMapDraw";
import { useMapSetting } from "../../../hooks/useMapSetting";
import { useRoad } from "../../../hooks/useRoad";
import { useSideNavigation } from "../../../hooks/useSideNavigation";
import { debounceFunction } from "../../../utils/debounce";
import { MapBounds, getMapBounds } from "../../../utils/geojson";
import {
  displayRoadLineStringOnMap,
  paintMapRoadLayers,
} from "../../../utils/linestring";
import { MapTypeControl } from "../../molecules/map/MapTypeControl";

type Props = {
  callback?: (roads: Road[]) => void;
  isModalClose?: boolean;
};

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN ?? "";

export const RoadNetworkMap: React.FC<Props> = ({ callback, isModalClose }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const { mapCenter, setOnMapMove, moveToMapBounds } = useMapBounds(map, true);
  const { closePopup } = useMapDraw({});
  const {
    roads,
    selectedRoad,
    searchRoad,
    selectRoad,
    setShowDeleteRoadPopup,
  } = useRoad(callback, true);

  const {
    geocoderControl,
    navigationControl,
    languageControl,
    mapTypeControl1,
    mapTypeControl2,
  } = useMapControl(true);
  const { mapStyle } = useMapSetting();

  const { sideMenuExpanded } = useSideNavigation();

  useEffect(() => {
    map.current?.resize();
  }, [sideMenuExpanded]);

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: STANDARD_MAP_STYLE,
        center: mapCenter,
        zoom: ROAD_NETWORK_MAP_MIN_ZOOM,
        minZoom: ROAD_NETWORK_MAP_MIN_ZOOM,
        attributionControl: false,
      });
    }

    map.current
      .addControl(new mapboxgl.AttributionControl({ compact: false }))
      .addControl(geocoderControl)
      .addControl(navigationControl, "bottom-right")
      .addControl(callback ? mapTypeControl2 : mapTypeControl1, "top-right")
      .addControl(languageControl);

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    const searchRoadFn = debounceFunction((bounds, mapBounds) => {
      searchRoad(bounds, mapBounds);
    }, 500);

    map.current.on("load", () => {
      const { _ne, _sw } = map.current.getBounds();
      const bounds: MapBounds = {
        northeast: [_ne.lng, _ne.lat],
        southwest: [_sw.lng, _sw.lat],
      };
      const mapBounds = getMapBounds(bounds);

      searchRoadFn(bounds, mapBounds);
    });

    map.current.on("move", () => {
      if (!mapContainer.current) return;

      closePopup();

      if (map.current.getZoom() < ROAD_NETWORK_MAP_MIN_ZOOM) return;

      const { _ne, _sw } = map.current.getBounds();
      const bounds: MapBounds = {
        northeast: [_ne.lng, _ne.lat],
        southwest: [_sw.lng, _sw.lat],
      };
      const mapBounds = getMapBounds(bounds);

      searchRoadFn(bounds, mapBounds);
    });

    setOnMapMove();
  }, []);

  useEffect(() => {
    if (isModalClose) moveToMapBounds();
  }, [isModalClose]);

  useEffect(() => {
    displayRoadLineStringOnMap(map, roads, (road, coordinates) => {
      if (!callback) return;

      selectRoad(road);
    });

    map.current.on("style.load", () => {
      displayRoadLineStringOnMap(map, roads, (road, coordinates) => {
        if (!callback) return;

        selectRoad(road);
      });
    });
  }, [roads]);

  useEffect(() => {
    if (!map.current) return;

    map.current.setStyle(mapStyle);
  }, [mapStyle]);

  const onContextMenu = useCallback((event: any) => {
    closePopup();

    const popup = document.createElement("div");
    const root = createRoot(popup);
    root.render(
      <div className="py-1 flex flex-col gap-y-6 rounded-lg bg-white shadow-lg">
        <div
          onClick={() => {
            closePopup();
            setShowDeleteRoadPopup(true);
          }}
          className="flex items-center px-5 py-1 text-[14px] cursor-pointer hover:bg-primary-light"
        >
          道路の削除
        </div>
      </div>,
    );
    const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
      .setLngLat(event.lngLat)
      .setDOMContent(popup)
      .addTo(map.current);

    mapboxPopup.on("close", () => {});
  }, []);

  useEffect(() => {
    if (!map.current) return;

    try {
      paintMapRoadLayers(map, roads, selectedRoad ? [selectedRoad] : []);
    } catch (e) {
      map.current.on("load", () => {
        paintMapRoadLayers(map, roads, selectedRoad ? [selectedRoad] : []);
      });
    }

    Object.keys(map.current.style._layers).forEach((layer: any) => {
      if (layer.startsWith("line-roads-")) {
        map.current.off("contextmenu", layer, onContextMenu);

        if (
          selectedRoad &&
          Number(layer.split("line-roads-")[1]) === selectedRoad.id
        ) {
          map.current.on("contextmenu", layer, onContextMenu);
        }
      }
    });
  }, [roads, selectedRoad]);

  return (
    <>
      <div
        ref={mapContainer}
        className="w-full h-full"
        onContextMenu={(e) => e.preventDefault()}
      />
      <MapTypeControl index={callback ? 2 : 1} />
    </>
  );
};
