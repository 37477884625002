import axios, { AxiosResponse, HttpStatusCode } from "axios";
import { AppConfig } from "../../AppConfig";
import { appActions } from "../../ducks/app";
import { authActions } from "../../ducks/auth";
import { APIError } from "../@types";
import {
  AssetsApi,
  CitiesApi,
  HistoriesApi,
  OauthApi,
  PoisApi,
  ReceptionsApi,
  RoadNetworksApi,
  RoadsApi,
  SpotsApi,
  TenantsApi,
  UsersApi,
} from "../api/api";
import { Configuration } from "../api/configuration";
import { LocalStorageManager } from "../storage/LocalStorageManager";
import { ErrorMessageManager } from "./ErrorMessageManager";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_VERSION}`;

const configuration = new Configuration({
  basePath: API_BASE_URL,
});

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

// リクエスト前の処理
axiosInstance.interceptors.request.use((config) => {
  const accessToken = LocalStorageManager.Instance.getObject<string>(
    AppConfig.Instance.LocalStorageKey["accessToken"],
  );
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";

  return config;
});

export const requestSuccess = (config: AxiosResponse<any, any>) => {
  return { ...config, response: { data: config.data } };
};

export const requestFailure = (error: APIError, store: any) => {
  const showErrorAlert = (errorCode: string) => {
    const errorObj: { title: string; message: string } =
      ErrorMessageManager.Instance.getErrorMessage(errorCode);
    store.dispatch(
      appActions.showModal.started({
        title: errorObj.title,
        modalType: "alert",
        detailText: errorObj.message,
        errorStatusCode: errorCode,
        closeButtonLabel: "閉じる",
        handleClose: () => store.dispatch(appActions.closeModal()),
      }),
    );
  };

  if (!error) {
  } else if (error && error.code === "ERR_NETWORK") {
    showErrorAlert("0000-0000");
  } else if (
    error &&
    error.response?.status === HttpStatusCode.ServiceUnavailable
  ) {
    showErrorAlert("9999-9999");
  } else if (
    error &&
    error.response?.status === HttpStatusCode.Unauthorized &&
    error.config?.url?.indexOf("/refresh") === -1
  ) {
    const refreshToken = LocalStorageManager.Instance.getObject<string>(
      AppConfig.Instance.LocalStorageKey["refreshToken"],
    );

    if (!refreshToken) {
      showErrorAlert("0002");

      store.dispatch(authActions.logout.started({}));
      store.dispatch(appActions.hideLoading());
      return Promise.reject({
        error: {
          ...error,
          status: error.response?.status ?? HttpStatusCode.InternalServerError,
        },
      });
    }
  } else {
    showErrorAlert(error.response?.data.error_code ?? "9999-9999");
  }
  return Promise.reject({
    error: {
      ...error,
      status: error.response?.status ?? HttpStatusCode.InternalServerError,
    },
  });
};

const assetsApi = new AssetsApi(configuration, "", axiosInstance);
const authApi = new OauthApi(configuration, "", axiosInstance);
const cityApi = new CitiesApi(configuration, "", axiosInstance);
const historyApi = new HistoriesApi(configuration, "", axiosInstance);
const poisApi = new PoisApi(configuration, "", axiosInstance);
const receptionApi = new ReceptionsApi(configuration, "", axiosInstance);
const roadApi = new RoadsApi(configuration, "", axiosInstance);
const roadNetworkApi = new RoadNetworksApi(configuration, "", axiosInstance);
const spotApi = new SpotsApi(configuration, "", axiosInstance);
const tenantApi = new TenantsApi(configuration, "", axiosInstance);
const userApi = new UsersApi(configuration, "", axiosInstance);

export {
  assetsApi,
  authApi,
  cityApi,
  historyApi,
  poisApi,
  receptionApi,
  roadApi,
  roadNetworkApi,
  spotApi,
  tenantApi,
  userApi,
};
