import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { datasetActions } from "./actions";
import { DatasetState } from "./types";

const initialState: DatasetState = {
  datasets: [],
};

export function DatasetReducer(
  state = initialState,
  action: Action,
): DatasetState {
  if (isType(action, datasetActions.fetchDatasets.done)) {
    return {
      ...state,
      datasets: action.payload.result.datasets,
    };
  }

  if (isType(action, datasetActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
