import { useState } from "react";
import { Area } from "../../../ducks/area";
import { mergeClassNames } from "../../../utils/style";
import { LabelCheckBox } from "../../atoms/LabelCheckBox";

type Props = {
  roads: Area[];
  onSelect: (road: Area, selected: boolean) => void;
  checkDisabled: (road: Area) => boolean;
  currentLength: number;
  maxLength?: number;
};

export const RoadSelectionPopup: React.FC<Props> = ({
  roads,
  onSelect,
  checkDisabled,
  currentLength,
  maxLength,
}) => {
  const [selectedRoads, setSelectedRoads] = useState<Area[]>([]);

  const handleOnChange = (road: Area) => {
    const selected = selectedRoads.map((x) => x.id).includes(road.id);

    onSelect(road, selected);

    if (selected) {
      setSelectedRoads(selectedRoads.filter((x) => x.id !== road.id));
    } else {
      setSelectedRoads([...selectedRoads, road]);
    }
  };

  const _renderRoadItem = (road: Area, border: boolean, key: string) => {
    return (
      <div
        key={key}
        className={mergeClassNames(
          "flex flex-1 w-[220px] justify-between items-center py-1 text-[13px]",
          border && "border-t-2",
        )}
      >
        <div className="max-w-[150px] overflow-ellipsis">
          <span className="text-[13px]">{road.area_name}</span>
          <div className="flex-1 text-[12px] text-[#999999]">
            <span className="whitespace-nowrap text-[#999999]">
              データセット：
            </span>
            {road.dataset.datasets
              .map((dataset) => dataset.dataset_name)
              .join(" / ")}
          </div>
        </div>

        <LabelCheckBox
          label=""
          value={road}
          checked={selectedRoads.map((x) => x.id).includes(road.id)}
          disabled={
            (maxLength &&
              currentLength + selectedRoads.length >= maxLength &&
              !selectedRoads.map((x) => x.id).includes(road.id)) ||
            (selectedRoads.length > 0 &&
              road.dataset.group_id !== selectedRoads[0].dataset.group_id) ||
            checkDisabled(road)
          }
          onChange={(event, value) => handleOnChange(value)}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col max-h-[80vh] overflow-y-auto px-3 py-1 rounded-lg bg-white shadow-lg">
      {roads.map((road, i) =>
        _renderRoadItem(road, i > 0, `road-list-${road.id}-${i}`),
      )}
    </div>
  );
};
