import { MaintenanceNotice } from "../../organisms/MaintenanceNotice";

type Props = {
  title: string;
  message: string;
  startDate: string;
  endDate: string;
  subText: string;
};

export const Maintenance: React.FC<Props> = (props) => {
  return (
    <div className="fixed flex justify-center items-center top-0 bottom-0 left-0 right-0">
      <MaintenanceNotice {...props} />
    </div>
  );
};
