import { IconProps } from "./types";

export default function IconMapMarker({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="13.5"
      viewBox="0 0 11 13.5"
    >
      <path
        id="Path_766"
        data-name="Path 766"
        d="M10.5,5.625A4.972,4.972,0,0,0,5.5.5a4.972,4.972,0,0,0-5,5.125Q.5,8.738,5.5,13,10.506,8.744,10.5,5.625Zm-5,1.746A1.958,1.958,0,1,1,7.459,5.412,1.961,1.961,0,0,1,5.5,7.371Z"
        fill="none"
        stroke="#999"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
}
