import IconChevron from "../../icons/IconChevron";
import IconDoubleChevron from "../../icons/IconDoubleChevron";
import { mergeClassNames } from "../../utils/style";

type Props = {
  page: number;
  totalPage: number;
  onSetPage: (page: number) => void;
  onPrevious: () => void;
  onNext: () => void;
  onFirst: () => void;
  onLast: () => void;
};

export const Pagination: React.FC<Props> = ({
  page,
  totalPage,
  onSetPage,
  onPrevious,
  onNext,
  onFirst,
  onLast,
}) => {
  const _renderPageItem = (
    page: number,
    isCurrentPage: boolean,
    isEmpty?: boolean,
    wide?: boolean,
  ) => {
    return (
      <div
        key={`pagination-${page}`}
        onClick={() => {
          if (!isCurrentPage && !isEmpty && page > 0) onSetPage(page);
        }}
        className={mergeClassNames(
          "text-center text-xs py-1 select-none",
          wide ? "w-[60px]" : "w-[30px]",
          isCurrentPage
            ? "bg-gray-light text-white"
            : "bg-white text-gray-light",
          !isCurrentPage && !isEmpty && "cursor-pointer",
        )}
      >
        {!isEmpty ? page : "..."}
      </div>
    );
  };

  const _renderMultiplePageItems = () => {
    if (totalPage == 0) {
      return <>{_renderPageItem(0, false, true)}</>;
    }

    if (totalPage < 7) {
      return Array.from({ length: totalPage }, (x, i) => i).map((pg) =>
        _renderPageItem(pg + 1, page === pg + 1),
      );
    }

    if ([totalPage - 2, totalPage - 1, totalPage].includes(page)) {
      return (
        <>
          {_renderPageItem(1, false)}
          {_renderPageItem(0, false, true, true)}
          {_renderPageItem(totalPage - 3, false)}
          {_renderPageItem(totalPage - 2, page === totalPage - 2)}
          {_renderPageItem(totalPage - 1, page === totalPage - 1)}
          {_renderPageItem(totalPage, page === totalPage)}
        </>
      );
    }

    if ([1, 2, 3].includes(page)) {
      return (
        <>
          {_renderPageItem(1, page === 1)}
          {_renderPageItem(2, page === 2)}
          {_renderPageItem(3, page === 3)}
          {_renderPageItem(4, false)}
          {_renderPageItem(0, false, true, true)}
          {_renderPageItem(totalPage, false)}
        </>
      );
    }

    return (
      <>
        {_renderPageItem(1, false)}
        {_renderPageItem(0, false, true)}
        {_renderPageItem(page - 1, false)}
        {_renderPageItem(page, true)}
        {_renderPageItem(page + 1, false)}
        {_renderPageItem(0, false, true)}
        {_renderPageItem(totalPage, false)}
      </>
    );
  };

  return (
    <div className="flex border border-[#B5B5B5] divide-x divide-[#B5B5B5] rounded">
      <button
        type="button"
        className="disabled:opacity-30 px-2"
        onClick={onFirst}
        disabled={totalPage === 0 || page === 1}
      >
        <IconDoubleChevron />
      </button>
      <button
        type="button"
        className="disabled:opacity-30 px-2.5"
        onClick={onPrevious}
        disabled={totalPage === 0 || page === 1}
      >
        <IconChevron />
      </button>
      <>{_renderMultiplePageItems()}</>
      <button
        type="button"
        className="disabled:opacity-30 px-2.5"
        onClick={onNext}
        disabled={totalPage === 0 || page === totalPage}
      >
        <IconChevron direction="right" />
      </button>
      <button
        type="button"
        className="disabled:opacity-30 px-2"
        onClick={onLast}
        disabled={totalPage === 0 || page === totalPage}
      >
        <IconDoubleChevron direction="right" />
      </button>
    </div>
  );
};
