import { MapBounds } from "../../utils/geojson";
import { AreaCoordinates } from "../area";

export interface RoadState {
  previousMapBounds: MapBounds | null;
}

export enum RoadStatus {
  Waiting = "waiting",
  Created = "created",
}

export interface Road {
  id: number;
  polygon: AreaCoordinates[];
  status: RoadStatus;
}

export enum EditRoadNetworkMode {
  Add = "道路を追加",
  Delete = "道路を選択して削除",
}

export interface SearchRoadRequestBody {
  bounds: MapBounds | null;
  area: AreaCoordinates[];
}

export interface CreateRoadRequestBody {
  area: AreaCoordinates[];
}

export interface DeleteRoadRequestBody {
  roadIds: number[];
}
