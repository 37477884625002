export const MAP_RESET_BUTTON_ID = "map-reset-btn";
export const MAP_TYPE_CONTROL_ID = "map-type-control-btn";
export const DATA_DISPLAY_CONTROL_ID = "data-display-control-btn";
export const HEAT_MAP_DISPLAY_CONTROL_ID = "heat-map-display-control-btn";

export class MapControlLegend {
  private _id;
  constructor(id: string, index?: number) {
    this._id = index ? `${id}-${index}` : id;
  }

  onAdd() {
    try {
      const div = document.createElement("div");
      const element = document.getElementById(this._id);
      const btn = document.getElementById(`${this._id}-btn`);

      div.className = "mapboxgl-ctrl";
      div.innerHTML = element!.innerHTML;

      div.addEventListener("contextmenu", (e) => e.preventDefault());
      if (btn) div.addEventListener("click", () => btn.click());

      div.style.pointerEvents = "auto";

      return div;
    } catch (e) {
      console.warn(e);
    }
  }

  onRemove() {
    const element = document.getElementById(`${this._id}-content`);

    try {
      element!.parentNode!.parentNode!.removeChild(element!.parentNode!);
    } catch (e) {
      console.warn(e);
    }
  }
}
