import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";

const selector = (state: StoreState) => state.auth;

export const authSelectors = {
  users: createSelector(selector, (state) => state.users),
  isLoggedIn: createSelector(selector, (state) => state.isLoggedIn),
  loggedInUser: createSelector(selector, (state) => state.loggedInUser),
};
