import { IconProps } from "../types";

export default function IconDataDisplayControl({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.879"
      height="10.827"
      viewBox="0 0 15.879 10.827"
    >
      <path
        id="visibility_FILL0_wght400_GRAD0_opsz24"
        d="M47.94-791.339a3.132,3.132,0,0,0,2.3-.947,3.132,3.132,0,0,0,.947-2.3,3.132,3.132,0,0,0-.947-2.3,3.132,3.132,0,0,0-2.3-.947,3.132,3.132,0,0,0-2.3.947,3.132,3.132,0,0,0-.947,2.3,3.132,3.132,0,0,0,.947,2.3A3.132,3.132,0,0,0,47.94-791.339Zm0-1.3a1.88,1.88,0,0,1-1.38-.568,1.88,1.88,0,0,1-.568-1.38,1.879,1.879,0,0,1,.568-1.38,1.879,1.879,0,0,1,1.38-.568,1.879,1.879,0,0,1,1.38.568,1.879,1.879,0,0,1,.568,1.38,1.88,1.88,0,0,1-.568,1.38A1.88,1.88,0,0,1,47.94-792.638Zm0,3.465a8.357,8.357,0,0,1-4.8-1.471A8.36,8.36,0,0,1,40-794.587a8.36,8.36,0,0,1,3.14-3.943A8.356,8.356,0,0,1,47.94-800a8.356,8.356,0,0,1,4.8,1.471,8.36,8.36,0,0,1,3.14,3.943,8.36,8.36,0,0,1-3.14,3.943A8.357,8.357,0,0,1,47.94-789.173ZM47.94-794.587Zm0,3.97a6.889,6.889,0,0,0,3.744-1.074,7.054,7.054,0,0,0,2.607-2.9,7.054,7.054,0,0,0-2.607-2.9,6.889,6.889,0,0,0-3.744-1.074,6.889,6.889,0,0,0-3.744,1.074,7.054,7.054,0,0,0-2.607,2.9,7.054,7.054,0,0,0,2.607,2.9A6.889,6.889,0,0,0,47.94-790.617Z"
        transform="translate(-40 800)"
      />
    </svg>
  );
}
