import { Outlet } from "react-router-dom";
import { Header } from "../organisms/Header";

export const MainTemplate: React.FC = () => {
  return (
    <main className="flex flex-col h-[100svh] bg-gray-background">
      <Header empty />
      <Outlet />
    </main>
  );
};
