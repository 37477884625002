import { useEffect, useMemo, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { Area } from "../../../ducks/area";
import { Dataset } from "../../../ducks/dataset";
import { useArea } from "../../../hooks/useArea";
import { useDataset } from "../../../hooks/useDataset";
import IconAdd from "../../../icons/IconAdd";
import { CustomButton } from "../../atoms/CustomButton";
import { Input } from "../../atoms/Input";
import { LabelCheckBox } from "../../atoms/LabelCheckBox";
import { CustomSelection } from "../../molecules/CustomSelection";
import { AreaPolygonCount } from "../../molecules/area/AreaPolygonCount";
import { DatasetSelection } from "../../molecules/area/DatasetSelection";
import FormTemplate from "../../templates/FormTemplate";

type Props = {
  handleSubmit: (
    areaName: string,
    datasetIds: number[],
    forMulti: boolean,
  ) => void;
  handleClose: () => void;
  isEdit: boolean;
  isRoad: boolean;
  defaultAreaName?: string;
  defaultDatasetIds?: number[];
  selectedArea?: Area | null;
};

export const CreateAreaNameForm: React.FC<Props> = ({
  handleClose,
  handleSubmit,
  isEdit,
  isRoad,
  defaultAreaName,
  defaultDatasetIds,
  selectedArea,
}) => {
  const { multipleAnalysisAreaCount } = useArea();
  const { datasets } = useDataset();
  const [mainDataset, setMainDataset] = useState<Dataset | null>(null);
  const [showDatasetCheckbox, setShowDatasetCheckbox] =
    useState<boolean>(false);
  const [selectedDatasets, setSelectedDatasets] = useState<Dataset[]>([]);
  const [forMulti, setForMulti] = useState<boolean>(
    isEdit ? selectedArea?.for_multi ?? false : false,
  );

  const count = useMemo(() => {
    if (!isEdit && !forMulti) return multipleAnalysisAreaCount;
    if (!isEdit && forMulti) return multipleAnalysisAreaCount + 1;

    if (isEdit && selectedArea?.for_multi && forMulti)
      return multipleAnalysisAreaCount;
    if (isEdit && selectedArea?.for_multi && !forMulti)
      return multipleAnalysisAreaCount - 1;
    if (isEdit && !selectedArea?.for_multi && forMulti)
      return multipleAnalysisAreaCount + 1;
    if (isEdit && !selectedArea?.for_multi && !forMulti)
      return multipleAnalysisAreaCount;

    return multipleAnalysisAreaCount;
  }, [forMulti, multipleAnalysisAreaCount]);

  const schema = z
    .object({
      areaName: z.string().min(1, {
        message: `${isRoad ? "道路" : "エリア"}名を入力してください`,
      }),
      datasetIds: z.array(z.number().positive()),
    })
    .refine((obj) => {
      if (isEdit) return true;

      return obj.datasetIds.length > 0;
    });

  const [methods, setMethods] = useState<UseFormReturn<
    FieldValues,
    any,
    undefined
  > | null>(null);

  useEffect(() => {
    methods?.setValue(
      "datasetIds",
      mainDataset ? [mainDataset.dataset_id] : [],
      {
        shouldValidate: true,
      },
    );
    setSelectedDatasets([]);
  }, [mainDataset]);

  useEffect(() => {
    methods?.setValue(
      "datasetIds",
      mainDataset
        ? [
            mainDataset.dataset_id,
            ...selectedDatasets.map((dataset) => dataset.dataset_id),
          ]
        : [],
      {
        shouldValidate: true,
      },
    );
  }, [selectedDatasets]);

  return (
    <FormTemplate
      schema={schema}
      setMethods={setMethods}
      defaultValues={{
        areaName: defaultAreaName,
        datasetIds: defaultDatasetIds ?? [],
      }}
      onSubmit={({
        areaName,
        datasetIds,
      }: {
        areaName: string;
        datasetIds: number[];
      }) => handleSubmit(areaName, datasetIds, forMulti)}
      className="w-full"
    >
      {!defaultDatasetIds && !(isRoad && isEdit) && (
        <div className="my-[30px]">
          <CustomSelection
            name="datasetName"
            label="データセット"
            required
            placeholder="選択してください"
            onChange={(name, value) => setMainDataset(value)}
            options={datasets.map((dataset, i) => ({
              id: dataset.dataset_id,
              name: dataset.dataset_name,
              value: dataset,
            }))}
          />
          {mainDataset && (
            <div>
              {!showDatasetCheckbox && (
                <button
                  className="flex items-center text-[#004697] text-sm mt-3"
                  onClick={() => setShowDatasetCheckbox(true)}
                >
                  <IconAdd />
                  <span className="pl-2">データセット追加</span>
                </button>
              )}

              {showDatasetCheckbox && (
                <DatasetSelection
                  datasets={datasets.filter(
                    (d) =>
                      d.dataset_id !== mainDataset.dataset_id &&
                      d.group_id === mainDataset.group_id,
                  )}
                  selectedDatasets={selectedDatasets}
                  onChange={(value: any, isSelected: boolean) => {
                    if (isSelected) {
                      setSelectedDatasets(
                        selectedDatasets.filter(
                          (d) => d.dataset_id !== value.dataset_id,
                        ),
                      );
                    } else {
                      setSelectedDatasets([...selectedDatasets, value]);
                    }
                  }}
                />
              )}
            </div>
          )}
        </div>
      )}
      <div>
        <Input
          name="areaName"
          required
          label={isRoad ? "道路名" : "エリア名"}
          placeholder="入力してください"
          className="w-full bg-white"
          defaultValue={defaultAreaName}
        />
      </div>
      {!isRoad && (
        <div className="flex items-start mt-[10px]">
          <LabelCheckBox
            label=""
            value={""}
            checked={forMulti}
            onChange={() => setForMulti((prev) => !prev)}
          />
          <div className="ml-2">
            <div className="text-[13px]">
              2エリア間の行動理解、主エリアから副エリアへの来訪率の
              分析で利用する
            </div>
            <div className="flex justify-start items-center mt-2">
              <AreaPolygonCount count={count} />
              <span className="text-xs ml-2">
                ※100個まで設定することができます。
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-wrap justify-center items-center w-full h-[88px]">
        <CustomButton
          buttonStyle="border"
          type="button"
          text="キャンセル"
          className="flex justify-center items-center w-[180px] h-[40px] mx-[5px] text-black border-gray-light"
          onClick={handleClose}
        />
        <CustomButton
          text="保存"
          type="submit"
          className="flex justify-center items-center w-[180px] h-[40px] mx-[5px] font-medium"
        />
      </div>
    </FormTemplate>
  );
};
