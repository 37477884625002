import { put, select } from "typed-redux-saga";
import { AppConfig } from "../../AppConfig";
import { DEFAULT_LOCATION } from "../../constants/map";
import { LocalStorageManager } from "../../core/storage/LocalStorageManager";
import { StoreState } from "../../store";
import { takeEveryFsa } from "../operations";
import { mapActions } from "./actions";

export function* mapSaga() {
  yield takeEveryFsa(mapActions.resetMapBounds.started, function* (action) {
    const state: StoreState = yield* select();

    const tenantId = LocalStorageManager.Instance.getObject<string>(
      AppConfig.Instance.LocalStorageKey["tenantId"],
    );

    const currentTenant = state.tenant.tenants.find(
      (tenant) => tenant.id === Number(tenantId),
    );

    yield* put(
      mapActions.resetMapBounds.done({
        params: action.payload,
        result: {
          center: [
            currentTenant?.setting.default_location.longitude ??
              DEFAULT_LOCATION.longitude,
            currentTenant?.setting.default_location.latitude ??
              DEFAULT_LOCATION.latitude,
          ],
        },
      }),
    );
  });
}
