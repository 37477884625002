import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { analysisActions } from "./actions";
import { AnalysisState } from "./types";

const initialState: AnalysisState = {
  currentStep: 1,
  analysisItems: [],
  selectedAnalysisItem: null,
};

export function AnalysisReducer(
  state = initialState,
  action: Action,
): AnalysisState {
  if (isType(action, analysisActions.setCurrentStep)) {
    return {
      ...state,
      currentStep: action.payload,
    };
  }

  if (isType(action, analysisActions.resetCurrentStep)) {
    return {
      ...state,
      currentStep: 1,
    };
  }

  if (isType(action, analysisActions.fetchAnalysisItems.done)) {
    return {
      ...state,
      analysisItems: action.payload.result,
    };
  }

  if (isType(action, analysisActions.setSelectedAnalysisItem)) {
    return {
      ...state,
      selectedAnalysisItem: action.payload,
    };
  }

  if (isType(action, analysisActions.clearSelectedAnalysisItem)) {
    return {
      ...state,
      selectedAnalysisItem: null,
    };
  }

  if (isType(action, analysisActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
