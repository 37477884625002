import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { cityActions } from "./actions";
import { CityState } from "./types";

const initialState: CityState = {};

export function CityReducer(state = initialState, action: Action): CityState {
  if (isType(action, cityActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
