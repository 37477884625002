import { put } from "typed-redux-saga";
import { takeEveryFsa } from "../operations";
import { appActions } from "./actions";

export function* appSaga() {
  yield takeEveryFsa(appActions.showModal.started, function* (action) {
    yield* put(
      appActions.showModal.done({
        params: action.payload,
        result: {},
      }),
    );
  });
}
