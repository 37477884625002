import React from "react";
import { AnalysisItem } from "../../../ducks/analysis/types";
import { IconComponent } from "../../../icons/types";
import { mergeClassNames } from "../../../utils/style";

type Props = {
  item: AnalysisItem;
  onClick: (item: AnalysisItem) => void;
  isSelected: boolean;
  icon: IconComponent | null;
};

export const AnalysisItemCard: React.FC<Props> = ({
  item,
  onClick,
  isSelected,
  icon: Icon,
}) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <div
      onClick={handleOnClick}
      className={mergeClassNames(
        "flex flex-col items-center cursor-pointer break-all border gap-y-7 px-7.5 pt-9 pb-7.5 rounded-lg",
        isSelected
          ? "bg-[#E8F0FE] border-primary"
          : "bg-white border-gray-light",
      )}
    >
      <div className="flex flex-col items-center gap-y-4">
        <div
          className={mergeClassNames(
            "flex flex-col gap-y-4 p-4.5 rounded-full",
            isSelected ? "bg-white" : "bg-primary-light",
          )}
        >
          {Icon && <Icon />}
        </div>
        <span
          className={mergeClassNames("font-bold", isSelected && "text-primary")}
        >
          {item.name}
        </span>
      </div>
      <div className="text-[13px]">{item.description}</div>
    </div>
  );
};
