import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { HEAT_MAP_DISPLAY_OPTION } from "../../../constants/map";
import { HeatMapType } from "../../../ducks/map";
import { useMapSetting } from "../../../hooks/useMapSetting";
import IconHeatMapControl from "../../../icons/Map/IconHeatMapControl";
import { HEAT_MAP_DISPLAY_CONTROL_ID } from "../../../utils/map";

type Props = {
  index: number;
};

export const HeatMapControl: React.FC<Props> = ({ index }) => {
  const { heatMapDisplay, setHeatMapDisplay, setIsDisplayData } =
    useMapSetting();

  const handleChange = (option: HeatMapType) => {
    setIsDisplayData(option !== HeatMapType.Hidden);
    setHeatMapDisplay(option);
  };

  return (
    <div
      id={`${HEAT_MAP_DISPLAY_CONTROL_ID}-${index}`}
      style={{ display: "none" }}
    >
      <div
        id={`${HEAT_MAP_DISPLAY_CONTROL_ID}-${index}-content`}
        className="flex items-center cursor-pointer bg-white rounded-[10px] shadow-md text-[12px]"
      >
        <Dropdown>
          <MenuButton
            id={`${HEAT_MAP_DISPLAY_CONTROL_ID}-${index}-btn`}
            sx={{
              fontSize: "12px",
              color: "#000000",
              border: 0,
              padding: "0 12px",
              margin: 0,
            }}
          >
            <div className="flex items-center gap-x-2">
              <IconHeatMapControl />
              <span className="font-normal text-sm">
                {
                  HEAT_MAP_DISPLAY_OPTION.find(
                    (option) => option.value === heatMapDisplay,
                  )?.name
                }
              </span>
            </div>
          </MenuButton>

          <Menu
            id={`${HEAT_MAP_DISPLAY_CONTROL_ID}-menu`}
            anchorEl={document.getElementById(
              `${HEAT_MAP_DISPLAY_CONTROL_ID}-${index}-content`,
            )}
            placement="bottom-end"
            size="sm"
            sx={{ padding: 0, margin: 0, borderRadius: "10px" }}
          >
            <MenuItem
              id={`${HEAT_MAP_DISPLAY_CONTROL_ID}-${index}-menu-item`}
              style={{
                backgroundColor: "transparent",
                minHeight: 0,
                pointerEvents: "none",
                padding: 0,
                margin: 0,
              }}
            ></MenuItem>
            {HEAT_MAP_DISPLAY_OPTION.map((option) => {
              return (
                <MenuItem
                  key={option.id}
                  sx={{ padding: 0, margin: 0, borderRadius: 0 }}
                  onClick={() => handleChange(option.value)}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#E8F0FE")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#ffffff")
                  }
                >
                  <span className="px-4.5">{option.name}</span>
                </MenuItem>
              );
            })}
          </Menu>
        </Dropdown>
      </div>
    </div>
  );
};
