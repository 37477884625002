import { IconProps } from "../types";

export default function IconHeatMapControl({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        id="texture_FILL0_wght400_GRAD0_opsz24"
        d="M121.087-826a1.377,1.377,0,0,1-.689-.4,1.38,1.38,0,0,1-.4-.69L132.893-840a1.407,1.407,0,0,1,.7.4,1.673,1.673,0,0,1,.408.69ZM120-830.9v-2.178L126.913-840h2.175Zm0-5.989v-1.556a1.5,1.5,0,0,1,.456-1.1,1.5,1.5,0,0,1,1.1-.457h1.553ZM130.874-826l3.107-3.111v1.556a1.5,1.5,0,0,1-.456,1.1,1.5,1.5,0,0,1-1.1.457Zm-5.981,0,9.087-9.1v2.178L127.068-826Z"
        transform="translate(-120 840)"
      />
    </svg>
  );
}
