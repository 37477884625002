import { PageRoutes } from "../constants/routes";
import IconAnalysisExecution from "../icons/SideNav/IconAnalysisExecution";
import IconAnalysisHistory from "../icons/SideNav/IconAnalysisHistory";
import IconAreaDefinitionList from "../icons/SideNav/IconAreaDefinitionList";
import IconRoadNetworkDefinition from "../icons/SideNav/IconRoadNetworkDefinition";
import IconUserManagement from "../icons/SideNav/IconUserManagement";
import { IconComponent } from "../icons/types";

export const getSideNavigationIcon = (path: string) => {
  let icon: IconComponent | null = null;
  switch (path) {
    case PageRoutes.ANALYSIS_AREA:
      icon = IconAreaDefinitionList;
      break;
    case PageRoutes.ROAD_NETWORK_DEFINITION:
      icon = IconRoadNetworkDefinition;
      break;
    case PageRoutes.ANALYSIS_EXECUTE:
      icon = IconAnalysisExecution;
      break;
    case PageRoutes.ANALYSIS_HISTORY:
      icon = IconAnalysisHistory;
      break;
    case PageRoutes.USER_MANAGEMENT:
      icon = IconUserManagement;
      break;
    default:
      icon = null;
      break;
  }
  return icon;
};
