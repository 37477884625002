import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../store";

const selector = (state: StoreState) => state.app;

export const appSelectors = {
  isLoading: createSelector(selector, (state) => state.isLoading),
  showModal: createSelector(selector, (state) => state.showModal),
  modalObject: createSelector(selector, (state) => state.modalObject),
  notification: createSelector(selector, (app) => app.notification),
  showNotification: createSelector(selector, (app) => app.showNotification),
  menus: createSelector(selector, (state) => state.menus),
  sideMenuExpanded: createSelector(selector, (state) => state.sideMenuExpanded),
};
