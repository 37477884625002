import {
  MAX_ROAD_SELECTION,
  MAX_SUB_AREA_SELECTION,
} from "../../../constants/area";
import { AnalysisItem, AnalysisPattern } from "../../../ducks/analysis";
import {
  Area,
  AreaSelectCondition,
  AreaStatus,
  AreaType,
} from "../../../ducks/area";
import IconSelectMultiple from "../../../icons/IconSelectMultiple";
import { LabelCheckBox } from "../../atoms/LabelCheckBox";
import { StatusLabel } from "../../atoms/StatusLabel";

type Props = {
  area: Area;
  selectedAreas: Area[];
  selectedSubAreas: Area[];
  selectedAnalysisItem: AnalysisItem;
  onSelectArea: (condition: AreaSelectCondition) => void;
  isRoad?: boolean;
  isSelectSubArea?: boolean;
};

export const AnalysisAreaSelectionItem: React.FC<Props> = ({
  area,
  selectedAreas,
  selectedSubAreas,
  selectedAnalysisItem,
  onSelectArea,
  isRoad,
  isSelectSubArea,
}) => {
  const isForMulti = area.status !== AreaStatus.Failure && area.for_multi;
  const isSelected = !!selectedAreas.find((a) => a.id === area.id);
  const isSelectedSubArea = !!selectedSubAreas.find((a) => a.id === area.id);
  const isMax =
    !!selectedAnalysisItem.max &&
    selectedAreas.length >= selectedAnalysisItem.max;
  const isMaxSubArea = selectedSubAreas.length >= MAX_SUB_AREA_SELECTION;

  const selectedRoads = selectedAreas.filter(
    (a) => a.area.type === AreaType.Road,
  );
  const isMaxRoad = selectedRoads.length >= MAX_ROAD_SELECTION;

  const isSameDatasetGroup =
    selectedAreas.length === 0
      ? true
      : area.dataset.group_id === selectedAreas[0].dataset.group_id;

  const isCheckboxDisabled =
    selectedAnalysisItem.pattern === AnalysisPattern.SelectMainAndSubArea
      ? isSelectSubArea
        ? !isForMulti || (isMaxSubArea && !isSelectedSubArea) || isSelected
        : !isForMulti || (isMax && !isSelected)
      : isRoad
        ? (area.status === AreaStatus.Failure || isMaxRoad) && !isSelected
        : selectedAnalysisItem.is_multi
          ? !isForMulti || (isMax && !isSelected)
          : area.status === AreaStatus.Failure || (isMax && !isSelected);

  const showIsMulti = selectedAnalysisItem.is_multi && area.for_multi === true;

  return (
    <div className="flex border-b py-3 gap-x-4 border-gray-border items-top">
      <LabelCheckBox
        label=""
        value={area}
        checked={isSelected || isSelectedSubArea}
        onChange={() => onSelectArea({ area, removeIfSelected: true })}
        disabled={isCheckboxDisabled || !isSameDatasetGroup}
        secondary={isSelectSubArea}
      />
      <div className="flex flex-1 w-full justify-between items-center py-1 text-[13px]">
        <div className="w-full overflow-ellipsis mr-2">
          <span className="text-[13px]">{area.area_name}</span>
          <div className="flex-1 text-[12px] text-[#999999]">
            <span className="whitespace-nowrap text-[#999999]">
              データセット：
            </span>
            {area.dataset.datasets
              .map((dataset) => dataset.dataset_name)
              .join(" / ")}
          </div>
        </div>

        <div className="text-end w-full overflow-ellipsis">
          <StatusLabel
            status={area.status}
            isRoad={area.area.type === AreaType.Road}
          />
          {showIsMulti && (
            <div className="flex justify-end items-center mt-2">
              <span className="flex justify-center items-center py-0.5 px-1.5 bg-primary-light rounded-full">
                <IconSelectMultiple />
                <span className="text-[11px] leading-[11px] p-0.5 text-primary">
                  複数化
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
