import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { DEFAULT_ROAD_COLOR_SCALE } from "../../constants/carto";
import {
  DEFAULT_LOCATION,
  DEFAULT_ZOOM,
  STANDARD_MAP_STYLE,
} from "../../constants/map";
import { mapActions } from "./actions";
import { HeatMapType, MapState } from "./types";

const initialState: MapState = {
  mapStyle: STANDARD_MAP_STYLE ?? "",
  isDisplayData: true,
  isDisplayRoadArea: true,
  mapCenter: [DEFAULT_LOCATION.longitude, DEFAULT_LOCATION.latitude],
  mapZoom: DEFAULT_ZOOM,
  heatMapDisplay: HeatMapType.Both,
  colorScale: DEFAULT_ROAD_COLOR_SCALE,
  maxValue: 0,
};

export function MapReducer(state = initialState, action: Action): MapState {
  if (isType(action, mapActions.setMapStyle)) {
    return {
      ...state,
      mapStyle: action.payload.style,
    };
  }

  if (isType(action, mapActions.setIsDisplayData)) {
    return {
      ...state,
      isDisplayData: action.payload.isDisplay,
    };
  }

  if (isType(action, mapActions.setIsDisplayRoadArea)) {
    return {
      ...state,
      isDisplayRoadArea: action.payload.isDisplay,
    };
  }

  if (isType(action, mapActions.setMapCenter)) {
    return {
      ...state,
      mapCenter: action.payload,
    };
  }

  if (isType(action, mapActions.setMapZoom)) {
    return {
      ...state,
      mapZoom: action.payload,
    };
  }

  if (isType(action, mapActions.resetMapBounds.done)) {
    return {
      ...state,
      mapCenter: action.payload.result.center,
      mapZoom: DEFAULT_ZOOM,
    };
  }

  if (isType(action, mapActions.setHeatMapDisplay)) {
    return {
      ...state,
      heatMapDisplay: action.payload.type,
    };
  }

  if (isType(action, mapActions.setColorScale)) {
    return {
      ...state,
      colorScale: action.payload.scale,
    };
  }

  if (isType(action, mapActions.setMaxValue)) {
    return {
      ...state,
      maxValue: action.payload.maxValue,
    };
  }

  if (isType(action, mapActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
