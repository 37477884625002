import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useCallback, useEffect, useRef, useState } from "react";
//@ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { createRoot } from "react-dom/client";
import {
  DEFAULT_ZOOM,
  ROAD_NETWORK_MAP_MIN_ZOOM,
} from "../../../constants/map";
import { AreaCoordinates } from "../../../ducks/area";
import { useMapBounds } from "../../../hooks/useMapBounds";
import { useMapControl } from "../../../hooks/useMapControl";
import { useMapDraw } from "../../../hooks/useMapDraw";
import { useMapSetting } from "../../../hooks/useMapSetting";
import { useRoad } from "../../../hooks/useRoad";
import { debounceFunction } from "../../../utils/debounce";
import {
  MapBounds,
  getClosestPolygonEdgeToPoint,
  getMapBounds,
  getMidPoint,
} from "../../../utils/geojson";
import { displayRoadLineStringOnMap } from "../../../utils/linestring";
import {
  displayNewLineOnPolygon,
  hasNewLine,
  newLineCoords,
} from "../../../utils/polygon";
import { MapResetButton } from "../../molecules/map/MapResetButton";
import { MapTypeControl } from "../../molecules/map/MapTypeControl";

type Props = {
  callback: (coordinates: AreaCoordinates[]) => void;
};

mapboxgl.accessToken = process.env.REACT_APP__MAPBOX_ACCESS_TOKEN;

export const LineStringMap: React.FC<Props> = ({ callback }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const { mapCenter, mapZoom, setOnMapMove } = useMapBounds(map, true);

  const [hasAddedControls, setHasAddedControls] = useState(false);

  const { roads, searchRoad } = useRoad();
  const { mapStyle } = useMapSetting();

  const {
    draw,
    modes,
    features,
    onUpdate,
    onDelete,
    onReset,
    removeVertex,
    closePopup,
    setDrawLineString,
  } = useMapDraw({ mode: MapboxDraw.constants.modes.DRAW_LINE_STRING });

  const handleOnUpdate = (e: any) => {
    displayNewLineOnPolygon(map, null);

    onUpdate(e);

    callback?.(
      e.features[0].geometry.coordinates.map((coords: [number, number]) => ({
        latitude: coords[1],
        longitude: coords[0],
      })),
    );
  };

  const handleOnReset = () => {
    onReset();
    callback([]);
    displayNewLineOnPolygon(map, null);
  };

  const {
    geocoderControl,
    navigationControl,
    languageControl,
    mapResetButton,
    mapTypeControl2,
  } = useMapControl(true);

  useEffect(() => {
    geocoderControl.on("result", (e) => {
      setTimeout(() => {
        handleOnReset();
        draw.changeMode(MapboxDraw.constants.modes.DRAW_LINE_STRING);
      }, 100);
    });
  }, []);

  const onDrawResult = useCallback(() => {
    if (draw.getAll().features.length === 0) {
      draw.changeMode(MapboxDraw.constants.modes.DRAW_POLYGON);
    }
  }, []);

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        center: mapCenter,
        zoom: mapZoom === DEFAULT_ZOOM ? ROAD_NETWORK_MAP_MIN_ZOOM : mapZoom,
        minZoom: ROAD_NETWORK_MAP_MIN_ZOOM,
        attributionControl: false,
      }).addControl(new mapboxgl.AttributionControl({ compact: false }));
    }

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    const searchRoadFn = debounceFunction((bounds, mapBounds) => {
      searchRoad(bounds, mapBounds);
    }, 500);

    map.current.on("load", () => {
      const { _ne, _sw } = map.current.getBounds();
      const bounds: MapBounds = {
        northeast: [_ne.lng, _ne.lat],
        southwest: [_sw.lng, _sw.lat],
      };
      const mapBounds = getMapBounds(bounds);

      searchRoadFn(bounds, mapBounds);
    });

    map.current.on("move", () => {
      if (!mapContainer.current) return;

      if (map.current.getZoom() < ROAD_NETWORK_MAP_MIN_ZOOM) return;

      const { _ne, _sw } = map.current.getBounds();
      const bounds: MapBounds = {
        northeast: [_ne.lng, _ne.lat],
        southwest: [_sw.lng, _sw.lat],
      };
      const mapBounds = getMapBounds(bounds);

      searchRoadFn(bounds, mapBounds);
    });

    if (hasAddedControls) {
      map.current.removeControl(geocoderControl);
      map.current.removeControl(navigationControl);
      map.current.removeControl(mapTypeControl2);
      map.current.removeControl(mapResetButton);
      map.current.removeControl(draw);

      map.current.off(MapboxDraw.constants.events.CREATE, handleOnUpdate);
      map.current.off(MapboxDraw.constants.events.UPDATE, handleOnUpdate);
      map.current.off(MapboxDraw.constants.events.DELETE, onDelete);
      map.current.off(MapboxDraw.constants.events.MODE_CHANGE, onDrawResult);
    }

    map.current
      .addControl(geocoderControl)
      .addControl(navigationControl, "bottom-right")
      .addControl(mapTypeControl2, "top-right")
      .addControl(mapResetButton, "top-right")
      .addControl(languageControl)
      .addControl(draw);

    map.current.on(MapboxDraw.constants.events.CREATE, handleOnUpdate);
    map.current.on(MapboxDraw.constants.events.UPDATE, handleOnUpdate);
    map.current.on(MapboxDraw.constants.events.DELETE, onDelete);
    map.current.on(MapboxDraw.constants.events.MODE_CHANGE, onDrawResult);
    draw.changeMode(MapboxDraw.constants.modes.DRAW_LINE_STRING);

    if (map.current && !hasAddedControls) {
      setHasAddedControls(true);
    }

    setOnMapMove();
  }, []);

  useEffect(() => {
    map.current.setStyle(mapStyle);
  }, [mapStyle]);

  useEffect(() => {
    // const originalOnDrag = modes.direct_select.onDrag?.bind(
    //   // @ts-ignore
    //   modes.direct_select,
    // );
    const originalOnClick = modes.direct_select.onClick?.bind(
      //@ts-ignore
      modes.direct_select,
    );
    // modes.direct_select.onDrag = (state, event) => {
    //   if (state.selectedCoordPaths.length === 0) return;
    //   displayNewLineOnPolygon(map, null);
    //   originalOnDrag?.(state, event);
    // };
    modes.direct_select.onClick = (state, event) => {
      try {
        if (state.selectedCoordPaths.length > 0)
          originalOnClick?.(state, event);
      } catch (e) {}

      if (!event.featureTarget) return;

      closePopup();

      const newLineCoordinates = getClosestPolygonEdgeToPoint(
        state.feature.coordinates,
        [event.lngLat.lng, event.lngLat.lat],
      );

      if (event.originalEvent.button === 0) {
        displayNewLineOnPolygon(map, newLineCoordinates);
      }

      if (state.selectedCoordPaths.length > 0) {
        displayNewLineOnPolygon(map, null);
      }

      if (event.originalEvent.button === 2) {
        if (state.selectedCoordPaths.length === 0 && !hasNewLine) return;

        const popup = document.createElement("div");
        const root = createRoot(popup);
        root.render(
          <div className="py-1 flex flex-col gap-y-6 rounded-lg bg-white shadow-lg">
            <div
              onClick={() => {
                closePopup();

                if (state.selectedCoordPaths.length > 0) removeVertex();
                else {
                  const newFeature = setDrawLineString(
                    state.featureId,
                    state.feature.coordinates,
                    newLineCoords,
                  );

                  callback(
                    newFeature.geometry.coordinates.map(
                      ([longitude, latitude]: [number, number]) => ({
                        latitude,
                        longitude,
                      }),
                    ),
                  );
                }

                displayNewLineOnPolygon(map, null);
              }}
              className="flex items-center px-5 py-1 text-[14px] cursor-pointer hover:bg-primary-light"
            >
              {state.selectedCoordPaths.length > 0
                ? "頂点の削除"
                : "頂点を追加"}
            </div>
          </div>,
        );
        const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
          .setLngLat(
            newLineCoords
              ? getMidPoint(newLineCoords)
              : draw.getSelectedPoints().features.length > 0
                ? // @ts-ignore
                  draw.getSelectedPoints().features[0].geometry.coordinates
                : event.lngLat,
          )
          .setDOMContent(popup)
          .addTo(map.current);

        mapboxPopup.on("close", () => {});
      }
    };

    // modes.simple_select.onDrag = () => {};
    modes.simple_select.onClick = (state, event) => {
      closePopup();

      if (!event.featureTarget) return;

      if (event.originalEvent.button === 2) {
        const popup = document.createElement("div");
        const root = createRoot(popup);
        root.render(
          <div className="py-1 flex flex-col gap-y-6 rounded-lg bg-white shadow-lg">
            <div
              onClick={() => {
                closePopup();
                const id = features?.id ?? state.initiallySelectedFeatureIds[0];
                draw.setFeatureProperty(id, "selected", true);

                draw.changeMode(MapboxDraw.constants.modes.DIRECT_SELECT, {
                  featureId: id,
                });
              }}
              className="flex items-center px-5 py-1 text-[14px] cursor-pointer hover:bg-primary-light"
            >
              頂点の編集
            </div>
          </div>,
        );
        // @ts-ignore
        const coordinates = draw.getAll().features[0].geometry.coordinates[0];
        const mapboxPopup = new mapboxgl.Popup({ anchor: "left" })
          .setLngLat(event.lngLat)
          .setDOMContent(popup)
          .addTo(map.current);

        mapboxPopup.on("close", () => {});
      } else {
        draw.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
          featureIds: [features?.id ?? state.initiallySelectedFeatureIds[0]],
        });
      }
    };
  }, [features]);

  useEffect(() => {
    displayRoadLineStringOnMap(map, roads);

    map.current.on("style.load", () => {
      displayRoadLineStringOnMap(map, roads);
    });
  }, [roads]);

  return (
    <div className="flex flex-1 w-full h-full">
      <div
        ref={mapContainer}
        className="w-full h-full"
        onContextMenu={(e) => e.preventDefault()}
      ></div>
      <MapTypeControl index={2} />
      <MapResetButton onClick={handleOnReset} />
    </div>
  );
};
