import { useEffect } from "react";
import Modal from "react-modal";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { EditRoadNetworkMode } from "../../../ducks/road";
import { useCreateRoadNetwork } from "../../../hooks/useCreateRoadNetwork";
import { CustomButton } from "../../atoms/CustomButton";
import { CustomSelection } from "../../molecules/CustomSelection";
import FormTemplate from "../../templates/FormTemplate";
import { LineStringMap } from "../map/LineStringMap";
import { RoadNetworkMap } from "../map/RoadNetworkMap";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const EditRoadNetworkModal: React.FC<Props> = ({
  isOpen,
  handleClose,
}) => {
  const {
    currentMode,
    handleChangeMode,
    handleCallback,
    handleRoadCallback,
    disableSubmitButton,
    helperText,
    onSubmit,
  } = useCreateRoadNetwork(handleClose);

  const modalStyle = {
    content: {
      width: "80%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 20,
      backgroundColor: "transparent",
      border: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 120,
    },
  };

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const handleClickClose = () => {
    handleClose();
    handleChangeMode(EditRoadNetworkMode.Add, EditRoadNetworkMode.Add);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle}
      shouldReturnFocusAfterClose={true}
      portalClassName="alert-modal"
    >
      <div className="w-full h-[700px] bg-white rounded-lg pt-[25px] flex flex-col">
        <div className="flex-auto flex flex-col h-[548px] px-[40px]">
          <div className="flex justify-between items-center">
            <div className="font-bold">道路ネットワークの編集</div>
            <div
              onClick={handleClickClose}
              className="w-[32px] h-[32px] flex justify-end items-center cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex flex-1 mt-[15px]">
            <div className="w-[30%] overflow-y-auto h-[548px]">
              <div className="mb-5">道路の編集方法を選択してください</div>
              <div>
                <FormTemplate onSubmit={onSubmit} className="w-full">
                  <CustomSelection
                    defaultValue={currentMode}
                    placeholder="道路の編集方法を選択してください"
                    onChange={handleChangeMode}
                    options={Object.entries(EditRoadNetworkMode).map(
                      ([type, placeholder], i) => ({
                        id: i + 1,
                        name: placeholder,
                        value: type,
                      }),
                    )}
                  />
                </FormTemplate>
              </div>
              {helperText && (
                <div className="mt-[14px] bg-[#F4F4F4] rounded-[4px] text-[#334A7C] text-[13px] py-[10px] px-[13px]">
                  <p className="whitespace-pre-line">{helperText}</p>
                </div>
              )}
            </div>
            <div className="flex-auto ml-[36px] h-[548px]">
              {currentMode === EditRoadNetworkMode.Add && (
                <LineStringMap callback={handleCallback} />
              )}

              {currentMode === EditRoadNetworkMode.Delete && (
                <RoadNetworkMap callback={handleRoadCallback} />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center w-full h-[88px]">
          <CustomButton
            buttonStyle="border"
            text="キャンセル"
            className="flex justify-center items-center w-[200px] h-[40px] mx-[8px] text-black border-gray-light"
            onClick={handleClickClose}
          />
          <CustomButton
            disabled={disableSubmitButton}
            onClick={onSubmit}
            text="設定"
            className="flex justify-center items-center w-[200px] h-[40px] mx-[8px] font-medium"
          />
        </div>
      </div>
    </Modal>
  );
};
