import { IconProps } from "./types";

export default function IconRemove({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g
        id="Group_5561"
        data-name="Group 5561"
        transform="translate(-377 -639)"
      >
        <g
          id="Ellipse_92"
          data-name="Ellipse 92"
          transform="translate(377 639)"
          fill="#FFFFFF"
          stroke="#707070"
          strokeWidth="1"
        >
          <circle cx="15" cy="15" r="15" stroke="none" />
          <circle cx="15" cy="15" r="14.5" fill="none" />
        </g>
        <line
          id="Line_764"
          data-name="Line 764"
          x2="13"
          transform="translate(385.5 654.5)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
