import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Area,
  AreaSelectCondition,
  AreaType,
  areaActions,
  areaSelectors,
} from "../ducks/area";

let lastClick = 0;

export const useArea = () => {
  const dispatch = useDispatch();
  const areas = useSelector(areaSelectors.areas);
  const roads = useSelector(areaSelectors.roads);
  const roadAreas = useSelector(areaSelectors.roadAreas);
  const roadCoordinates = useSelector(areaSelectors.roadCoordinates);
  const roadNetworks = useSelector(areaSelectors.roadNetworks);
  const allAreas = useSelector(areaSelectors.allAreas);
  const selectedAreas = useSelector(areaSelectors.selectedAreas);
  const selectedSubAreas = useSelector(areaSelectors.selectedSubAreas);
  const selectedRoads = useSelector(areaSelectors.selectedRoads);
  const multipleAnalysisAreaCount = useSelector(
    areaSelectors.multipleAnalysisAreaCount,
  );
  const [showAreaPopup, setShowAreaPopup] = useState<boolean>(false);
  const [showRoadPopup, setShowRoadPopup] = useState<boolean>(false);
  const [areaLngLat, setAreaLngLat] = useState<any>({});
  const [currentSelection, setCurrentSelection] = useState<Area | null>(null);
  const [currentRoadSelections, setCurrentRoadSelections] = useState<Area[]>(
    [],
  );
  const [newArea, setNewArea] = useState<Area | null>(null);

  const handleSetCurrentSelection = (area: Area | null) => {
    if (!area) return setCurrentSelection(area);

    // Avoiding from adding multiple area to a selection when spot are layering on top of each other
    if (lastClick < Date.now() - 200) {
      lastClick = Date.now();
      setCurrentSelection(area);
    }
  };

  const handleSetCurrentRoadSelections = (roads: Area[]) => {
    setCurrentRoadSelections(roads);
  };

  const selectArea = (condition: AreaSelectCondition) => {
    // Avoiding from adding multiple area to a selection when spot are layering on top of each other
    if (lastClick < Date.now() - 200) {
      lastClick = Date.now();
      const { area, removeIfSelected } = condition;
      dispatch(areaActions.selectArea.started({ area, removeIfSelected }));
    }
  };

  const selectSubArea = (condition: AreaSelectCondition) => {
    // Avoiding from adding multiple area to a selection when spot are layering on top of each other
    if (lastClick < Date.now() - 200) {
      lastClick = Date.now();
      const { area, removeIfSelected } = condition;
      dispatch(areaActions.selectSubArea.started({ area, removeIfSelected }));
    }
  };

  const selectOneArea = (area: Area) => {
    // Avoiding from adding multiple area to a selection when spot are layering on top of each other
    if (lastClick < Date.now() - 200) {
      lastClick = Date.now();
      dispatch(areaActions.resetSelectedAreas());
      dispatch(
        areaActions.selectArea.started({ area, removeIfSelected: false }),
      );
    }
  };

  const selectRoad = (condition: AreaSelectCondition) => {
    // Avoiding from adding multiple area to a selection when spot are layering on top of each other
    if (lastClick < Date.now() - 200) {
      lastClick = Date.now();
      const { area, removeIfSelected } = condition;
      dispatch(areaActions.selectRoad.started({ area, removeIfSelected }));
    }
  };

  const selectRoadByRoadNetworkId = (id: number) => {
    const roadsToSelect = roads.filter((road) => road.roadIds.includes(id));
    setCurrentRoadSelections(roadsToSelect);
  };

  const removeArea = (area: Area) => {
    dispatch(areaActions.removeArea.started({ area }));
  };

  const removeSubArea = (area: Area) => {
    dispatch(areaActions.removeSubArea.started({ area }));
  };

  const removeRoad = (area: Area) => {
    dispatch(areaActions.removeRoad.started({ area }));
  };

  const resetSelectedSubAreas = () => {
    dispatch(areaActions.resetSelectedSubAreas());
  };

  const onClickLayer = useCallback((event: any) => {
    setAreaLngLat(event.lngLat);
    setShowAreaPopup(true);
  }, []);

  const onClickRoadLayer = useCallback((event: any) => {
    setAreaLngLat(event.lngLat);
    setShowRoadPopup(true);
  }, []);

  const handleSelectNewArea = ({
    area,
    removeIfSelected,
  }: AreaSelectCondition) => {
    setNewArea(area);
    if (area.area.type === AreaType.Road)
      selectRoad({ area, removeIfSelected });
    else selectArea({ area, removeIfSelected });
  };

  const handleSelectNewSubArea = ({
    area,
    removeIfSelected,
  }: AreaSelectCondition) => {
    setNewArea(area);
    selectSubArea({ area, removeIfSelected });
  };

  useEffect(() => {
    if (showAreaPopup) setShowAreaPopup(false);
  }, [showAreaPopup]);

  useEffect(() => {
    if (showRoadPopup) setShowRoadPopup(false);
  }, [showRoadPopup]);

  return {
    areas,
    roads,
    roadAreas,
    roadCoordinates,
    roadNetworks,
    allAreas,
    selectedAreas,
    selectedSubAreas,
    selectedRoads,
    multipleAnalysisAreaCount,
    selectArea,
    selectSubArea,
    selectOneArea,
    selectRoad,
    selectRoadByRoadNetworkId,
    removeArea,
    removeSubArea,
    removeRoad,
    resetSelectedSubAreas,
    showAreaPopup,
    showRoadPopup,
    areaLngLat,
    currentSelection,
    handleSetCurrentSelection,
    currentRoadSelections,
    handleSetCurrentRoadSelections,
    newArea,
    handleSelectNewArea,
    handleSelectNewSubArea,
    onClickLayer,
    onClickRoadLayer,
  };
};
