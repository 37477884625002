import booleanEqual from "@turf/boolean-equal";
import {
  lineString as turfLineString,
  point as turfPoint,
  polygon as turfPolygon,
} from "@turf/helpers";
import kinks from "@turf/kinks";
import turfMidpoint from "@turf/midpoint";
import pointToLineDistance from "@turf/point-to-line-distance";

export type MapBounds = {
  northeast: [number, number]; // longitude, latitude
  southwest: [number, number]; // longitude, latitude
};

export const getMapBounds = ({ northeast, southwest }: MapBounds) => {
  return [
    { latitude: northeast[1], longitude: northeast[0] }, // North-East
    { latitude: northeast[1], longitude: southwest[0] }, // North-West
    { latitude: southwest[1], longitude: southwest[0] }, // South-West
    { latitude: southwest[1], longitude: northeast[0] }, // South-East
    { latitude: northeast[1], longitude: northeast[0] }, // North-East
  ];
};

export const compareMapBounds = (
  bounds: MapBounds,
  previousMapBounds: MapBounds,
) => {
  return (
    previousMapBounds.northeast[1] > bounds.northeast[1] &&
    previousMapBounds.northeast[0] > bounds.northeast[0] &&
    previousMapBounds.southwest[1] < bounds.southwest[1] &&
    previousMapBounds.southwest[0] < bounds.southwest[0]
  );
};

export const getMaxLatLngBounds = (
  latitudes: number[],
  longitudes: number[],
  margin?: number,
) => {
  if (latitudes.length === 0 || longitudes.length === 0) return;

  const minLat: number = Math.min(...latitudes);
  const minLon: number = Math.min(...longitudes);
  const maxLat: number = Math.max(...latitudes);
  const maxLon: number = Math.max(...longitudes);

  return [
    [minLon - (margin ?? 0), minLat - (margin ?? 0)],
    [maxLon + (margin ?? 0), maxLat + (margin ?? 0)],
  ];
};

export const getMidPoint = (coords: [number, number][]) => {
  const point1 = turfPoint(coords[0]);
  const point2 = turfPoint(coords[1]);

  const midpoint = turfMidpoint(point1, point2);

  return midpoint.geometry.coordinates as [number, number];
};

export const comparePoints = (p1: [number, number], p2: [number, number]) => {
  const point1 = turfPoint(p1);
  const point2 = turfPoint(p2);

  return booleanEqual(point1, point2);
};

export const getClosestPolygonEdgeToPoint = (
  polygon: [number, number][],
  p: [number, number],
): [number, number][] | null => {
  const point = turfPoint(p);
  let closest = 999999;
  let closestLine: [number, number][] | null = null;

  polygon.forEach((poly, i) => {
    const lineString = [
      poly,
      i === polygon.length - 1 ? polygon[0] : polygon[i + 1],
    ];
    const line = turfLineString(lineString);

    const distance = pointToLineDistance(point, line) * 100;
    if (distance < closest) {
      closest = distance;
      closestLine = lineString;
    }
  });

  return closestLine;
};

export const addMidpointToPolygon = (
  polygon: [number, number][],
  line: [number, number][],
  midpoint: [number, number],
) => {
  let newPolygon: [number, number][] = [];

  polygon.forEach((coords, i) => {
    newPolygon.push(coords);
    if (i === polygon.length - 1) {
      if (
        (comparePoints(coords, line[0]) || comparePoints(coords, line[1])) &&
        (comparePoints(polygon[0], line[0]) ||
          comparePoints(polygon[0], line[1]))
      ) {
        newPolygon.push(midpoint);
      }
    } else {
      if (
        (comparePoints(coords, line[0]) || comparePoints(coords, line[1])) &&
        (comparePoints(polygon[i + 1], line[0]) ||
          comparePoints(polygon[i + 1], line[1]))
      ) {
        newPolygon.push(midpoint);
      }
    }
  });
  newPolygon.push(polygon[0]);

  return newPolygon;
};

export const addMidpointToLineString = (
  lineString: [number, number][],
  line: [number, number][],
  midpoint: [number, number],
) => {
  let newLineString: [number, number][] = [];

  lineString.forEach((coords, i) => {
    newLineString.push(coords);
    if (i === lineString.length - 1) {
      if (
        (comparePoints(coords, line[0]) || comparePoints(coords, line[1])) &&
        (comparePoints(lineString[0], line[0]) ||
          comparePoints(lineString[0], line[1]))
      ) {
        newLineString.push(midpoint);
      }
    } else {
      if (
        (comparePoints(coords, line[0]) || comparePoints(coords, line[1])) &&
        (comparePoints(lineString[i + 1], line[0]) ||
          comparePoints(lineString[i + 1], line[1]))
      ) {
        newLineString.push(midpoint);
      }
    }
  });

  return newLineString;
};

// longitude, latitude
export const averageGeolocation = (coords: [number, number][]) => {
  if (coords.length === 1) return coords[0];

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;
  for (let coord of coords) {
    const latitude = (coord[1] * Math.PI) / 180;
    const longitude = (coord[0] * Math.PI) / 180;
    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  const total = coords.length;
  x = x / total;
  y = y / total;
  z = z / total;
  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    lat: (centralLatitude * 180) / Math.PI,
    lng: (centralLongitude * 180) / Math.PI,
  };
};

export const checkSelfIntersection = (
  coordinates: [number, number][], // longitude, latitude
) => {
  const selfIntersection = kinks(turfPolygon([coordinates]));

  return selfIntersection.features.length > 0;
};
