import { HttpStatusCode } from "axios";
import { call, put } from "typed-redux-saga";
import { ApiResponse } from "../../core/@types";
import { Reception } from "../../core/api";
import { spotApi } from "../../core/http/openAPIClient";
import { takeEveryFsa } from "../operations";
import { handleRefreshToken } from "../refreshToken";
import { analysisActions } from "./actions";

export function* analysisSaga() {
  yield takeEveryFsa(analysisActions.analyze.started, function* (action) {
    const {
      areaIds,
      batchIds,
      filterMask,
      startDate,
      endDate,
      sendEmail,
      isVisible,
      reception_ids,
      onComplete,
      onError,
      onSuccess,
    } = action.payload;
    const { response, error }: ApiResponse<Reception> = yield call(() => {
      return spotApi
        .spotanalyze({
          spot_id: areaIds,
          analyze_id: batchIds[0],
          filter_mask: filterMask,
          start_date: startDate,
          end_date: endDate,
          send_email: sendEmail,
          is_visible: isVisible,
        })
        .then((response) => response)
        .catch((error) => error);
    });

    if (response && batchIds.length === 1) {
      onComplete?.();

      yield* put(
        analysisActions.analyze.done({
          params: action.payload,
          result: response.data,
        }),
      );

      return onSuccess?.([
        ...reception_ids,
        {
          analyze_id: batchIds[0],
          reception_id: response.data.reception_id ?? "",
        },
      ]);
    }

    if (!response || error || !response.data.reception_id) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          analysisActions.analyze.started(action.payload),
        );
      } else {
        onComplete?.();
        return onError?.(error);
      }
    }

    yield* put(
      analysisActions.analyze.done({
        params: action.payload,
        result: response.data,
      }),
    );

    yield* put(
      analysisActions.analyze.started({
        ...action.payload,
        batchIds: batchIds.slice(1, batchIds.length),
        reception_ids: [
          ...action.payload.reception_ids,
          {
            analyze_id: batchIds[0],
            reception_id: response.data.reception_id ?? "",
          },
        ],
      }),
    );
  });
}
