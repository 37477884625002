import { applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "typed-redux-saga";
import { AnalysisReducer, analysisSaga } from "./analysis";
import { AppReducer, appSaga } from "./app";
import { AreaReducer, areaSaga } from "./area";
import { AssetReducer, assetSaga } from "./asset";
import { AuthReducer, authSaga } from "./auth";
import { Carto2Reducer, CartoReducer, cartoSaga } from "./carto";
import { CityReducer, citySaga } from "./city";
import { DatasetReducer, datasetSaga } from "./dataset";
import { FilterReducer, filterSaga } from "./filter";
import { HistoryReducer, historySaga } from "./history";
import { MapReducer, mapSaga } from "./map";
import { RoadReducer, roadSaga } from "./road";
import { TenantReducer, tenantSaga } from "./tenant";

const sagaMiddleware = createSagaMiddleware({});
export function runSaga() {
  sagaMiddleware.run(function* () {
    yield all([
      fork(analysisSaga),
      fork(appSaga),
      fork(areaSaga),
      fork(assetSaga),
      fork(authSaga),
      fork(cartoSaga),
      fork(citySaga),
      fork(datasetSaga),
      fork(filterSaga),
      fork(historySaga),
      fork(mapSaga),
      fork(roadSaga),
      fork(tenantSaga),
    ]);
  });
}
export const middlewares = applyMiddleware(sagaMiddleware);

export const reducers = combineReducers({
  analysis: AnalysisReducer,
  app: AppReducer,
  area: AreaReducer,
  asset: AssetReducer,
  auth: AuthReducer,
  carto: CartoReducer,
  carto2: Carto2Reducer,
  city: CityReducer,
  dataset: DatasetReducer,
  filter: FilterReducer,
  history: HistoryReducer,
  map: MapReducer,
  road: RoadReducer,
  tenant: TenantReducer,
});
