import { IconProps } from "./types";

export default function IconSelectMultiple({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path
        id="library_add_check_FILL0_wght400_GRAD0_opsz24"
        d="M86.42-872.77l3.39-3.39-.84-.87-2.55,2.55-1.29-1.26-.84.84ZM83.6-870.4a1.156,1.156,0,0,1-.847-.352,1.156,1.156,0,0,1-.353-.847v-7.2a1.156,1.156,0,0,1,.353-.848A1.156,1.156,0,0,1,83.6-880h7.2a1.156,1.156,0,0,1,.847.352A1.156,1.156,0,0,1,92-878.8v7.2a1.156,1.156,0,0,1-.353.847,1.156,1.156,0,0,1-.847.352Zm0-1.2h7.2v-7.2H83.6ZM81.2-868a1.155,1.155,0,0,1-.848-.353A1.155,1.155,0,0,1,80-869.2v-8.4h1.2v8.4h8.4v1.2Zm2.4-10.8v0Z"
        transform="translate(-80 880)"
        fill="#334a7c"
      />
    </svg>
  );
}
