import { hexToRgb as hexToRgbMui } from "@mui/material";

export const rgbToHex = (rgbColorArray: any[]) =>
  `#${rgbColorArray[0].toString(16).padStart(2, "0")}${rgbColorArray[1]
    .toString(16)
    .padStart(2, "0")}${rgbColorArray[2].toString(16).padStart(2, "0")}`;

export const hexToRgbA = (hex: string, opacity: number = 1) => {
  const rgb = hexToRgbMui(hex);
  return rgb.slice(0, rgb.length - 1) + ", " + opacity + ")";
};

export const hexToRgb = (hex: string, opacity: number = 255) => {
  // Evaluate #ABC
  let result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex);

  if (result) {
    return [
      parseInt(result[1] + result[1], 16),
      parseInt(result[2] + result[2], 16),
      parseInt(result[3] + result[3], 16),
      opacity,
    ];
  }

  // Evaluate #ABCD
  result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex);

  if (result) {
    return [
      parseInt(result[1] + result[1], 16),
      parseInt(result[2] + result[2], 16),
      parseInt(result[3] + result[3], 16),
      parseInt(result[4] + result[4], 16),
    ];
  }

  // Evaluate #ABCDEF
  result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
      opacity,
    ];
  }

  // Evaluate #ABCDEFAF
  result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) return [];

  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
    parseInt(result[4], 16),
  ];
};
