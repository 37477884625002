//@ts-expect-error
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import { useMemo } from "react";
import { ROAD_NETWORK_MAP_MIN_ZOOM } from "../constants/map";
import {
  DATA_DISPLAY_CONTROL_ID,
  HEAT_MAP_DISPLAY_CONTROL_ID,
  MAP_RESET_BUTTON_ID,
  MAP_TYPE_CONTROL_ID,
  MapControlLegend,
} from "../utils/map";

export const useMapControl = (isRoad?: boolean) => {
  const geocoderControl = useMemo(() => {
    return new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      placeholder: "地図を検索",
      marker: false,
      language: "ja",
      worldview: "jp",
      flyTo: isRoad
        ? {
            zoom: ROAD_NETWORK_MAP_MIN_ZOOM,
            maxZoom: ROAD_NETWORK_MAP_MIN_ZOOM,
            minZoom: ROAD_NETWORK_MAP_MIN_ZOOM,
            duration: 1000,
          }
        : {},
    });
  }, []);
  const navigationControl = useMemo(() => {
    return new mapboxgl.NavigationControl({ showCompass: false });
  }, []);
  const geolocateControl = useMemo(() => {
    return new mapboxgl.GeolocateControl({ maxWidth: 300 });
  }, []);
  const languageControl = useMemo(() => {
    return new MapboxLanguage({ defaultLanguage: "ja" });
  }, []);

  const mapResetButton = new MapControlLegend(MAP_RESET_BUTTON_ID);
  const mapTypeControl1 = new MapControlLegend(MAP_TYPE_CONTROL_ID, 1);
  const mapTypeControl2 = new MapControlLegend(MAP_TYPE_CONTROL_ID, 2);
  const dataDisplayControl1 = new MapControlLegend(DATA_DISPLAY_CONTROL_ID, 1);
  const heatMapDisplayControl1 = new MapControlLegend(
    HEAT_MAP_DISPLAY_CONTROL_ID,
    1,
  );
  const dataDisplayControl2 = new MapControlLegend(DATA_DISPLAY_CONTROL_ID, 2);
  const heatMapDisplayControl2 = new MapControlLegend(
    HEAT_MAP_DISPLAY_CONTROL_ID,
    2,
  );

  return {
    geocoderControl,
    navigationControl,
    geolocateControl,
    languageControl,
    mapResetButton,
    mapTypeControl1,
    mapTypeControl2,
    dataDisplayControl1,
    heatMapDisplayControl1,
    dataDisplayControl2,
    heatMapDisplayControl2,
  };
};
