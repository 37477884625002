import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../../ducks/app";
import {
  User,
  UserTenantType,
  UserType,
  authActions,
  authSelectors,
} from "../../../ducks/auth";
import { tenantSelectors } from "../../../ducks/tenant";
import { getUserType } from "../../../utils/user";

export const useUserManagement = () => {
  const dispatch = useDispatch();

  const users = useSelector(authSelectors.users);
  const loggedInUser = useSelector(authSelectors.loggedInUser);

  const tenants = useSelector(tenantSelectors.tenants);

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [showUserModal, setShowUserModal] = useState(false);

  const canAddUser = useMemo(
    () => loggedInUser && loggedInUser.user_type === UserType.Admin,
    [loggedInUser],
  );

  const canEditUser = useMemo(() => {
    return selectedUsers.length === 1;
  }, [loggedInUser, selectedUsers]);

  const canDeleteUser = useMemo(() => {
    return (
      loggedInUser &&
      loggedInUser.user_type === UserType.Admin &&
      selectedUsers.length > 0
    );
  }, [loggedInUser, selectedUsers]);

  const canEditTenant = useMemo(() => {
    const isAdmin = loggedInUser && loggedInUser.user_type === UserType.Admin;

    if (selectedUsers.length === 0) return isAdmin;

    const selectedUserIsAdmin = selectedUsers[0].wheel;

    return isAdmin && !selectedUserIsAdmin;
  }, [loggedInUser, selectedUsers]);

  const onPageChange = (page: number) => {
    setSelectedUsers([]);
  };

  const onAdd = () => {
    if (canAddUser) {
      setSelectedUsers([]);
      setShowUserModal(true);
    }
  };

  const onEdit = () => {
    if (canEditUser) setShowUserModal(true);
  };

  const onDelete = () => {
    if (canDeleteUser)
      dispatch(
        appActions.showModal.started({
          title: "アカウントを\n削除して良いですか？",
          closeButtonLabel: "キャンセル",
          nextButtonLabel: "削除",
          handleClose: () => dispatch(appActions.closeModal()),
          handleNext: () => {
            dispatch(appActions.showLoading());
            dispatch(
              authActions.deleteUsers.started({
                userIds: selectedUsers.map((user) => user.id),
                onComplete: () => dispatch(appActions.hideLoading()),
                onSuccess: () => {
                  dispatch(appActions.closeModal());
                  setSelectedUsers([]);
                },
              }),
            );
          },
        }),
      );
  };

  const onClose = () => setShowUserModal(false);

  const onSelectUser = (user: User) => {
    setSelectedUsers((prev) => {
      const isSelected = prev.find((x) => x.id === user.id);
      return isSelected
        ? prev.filter((x) => x.id !== user.id)
        : [...prev, user];
    });
  };

  const onSelectAll = (users: User[]) => {
    setSelectedUsers((prev) => {
      const isSelected = prev.length > 0 && prev.length === users.length;
      return isSelected ? [] : users;
    });
  };

  const onSubmit = (
    selectedUser: User | null,
    name: string,
    email: string,
    password: string,
    tenants: UserTenantType[],
  ) => {
    dispatch(appActions.showLoading());
    if (selectedUser) {
      dispatch(
        authActions.editUser.started({
          userId: selectedUser.id,
          name,
          email,
          password,
          tenants,
          onComplete: () => dispatch(appActions.hideLoading()),
          onSuccess: () => {
            setSelectedUsers([]);
            onClose();
          },
        }),
      );
    } else {
      dispatch(
        authActions.createUser.started({
          name,
          email,
          password,
          tenants,
          onComplete: () => dispatch(appActions.hideLoading()),
          onSuccess: () => onClose(),
        }),
      );
    }
  };

  const getProjectNames = (user: User) => {
    return user.tenants
      .map((tenant) => tenants.find((t) => t.id === tenant.tenant_id)?.name)
      .filter((tenant) => !!tenant)
      .join(", ");
  };

  const getUserTypes = (user: User) => {
    return user.tenants
      .map((tenant) => getUserType(user, tenant.tenant_id))
      .join(", ");
  };

  return {
    users,
    selectedUsers,
    showUserModal,
    canAddUser,
    canEditUser,
    canDeleteUser,
    canEditTenant,
    onPageChange,
    onAdd,
    onEdit,
    onDelete,
    onClose,
    onSelectUser,
    onSelectAll,
    onSubmit,
    getProjectNames,
    getUserTypes,
  };
};
