import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { UserType, authSelectors } from "../../../ducks/auth";

export const useRoadNetworkDefinition = () => {
  const loggedInUser = useSelector(authSelectors.loggedInUser);

  const canEditRoadNetwork = useMemo(() => {
    return loggedInUser && loggedInUser.user_type !== UserType.User;
  }, [loggedInUser]);

  const [isModalClose, setIsModalClose] = useState<boolean>(false);
  const [createRoadNetworkModalOpen, setCreateRoadNetworkModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (isModalClose) setIsModalClose(false);
  }, [isModalClose]);

  const onClickAdd = () => setCreateRoadNetworkModalOpen(true);
  const onClose = () => {
    setCreateRoadNetworkModalOpen(false);
    setIsModalClose(true);
  };

  return {
    createRoadNetworkModalOpen,
    canEditRoadNetwork,
    onClickAdd,
    onClose,
    isModalClose,
  };
};
