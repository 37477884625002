import {
  User,
  UserAuthorization,
  UserTenantAuthorization,
  UserTenantType,
  UserType,
} from "../ducks/auth";

export const getUserTenantAuthorization = (
  tenants: UserTenantType[],
): UserTenantAuthorization[] => {
  return tenants.map((tenant) => ({
    tenant_id: tenant.tenant_id,
    authorization:
      tenant.user_type === UserType.User
        ? UserAuthorization.User
        : UserAuthorization.Editor,
  }));
};

export const getUserType = (user: User, tenantId: number) => {
  const userAuthorization = user.tenants.find(
    (tenant) => tenant.tenant_id === tenantId,
  );

  return user.wheel
    ? UserType.Admin
    : userAuthorization &&
        userAuthorization.authorization === UserAuthorization.Editor
      ? UserType.Editor
      : UserType.User;
};

export const getUserTenantType = (user: User): UserTenantType[] => {
  return user.tenants.map((tenant) => ({
    tenant_id: tenant.tenant_id,
    user_type: user.wheel
      ? UserType.Admin
      : tenant.authorization === UserAuthorization.Editor
        ? UserType.Editor
        : UserType.User,
  }));
};
