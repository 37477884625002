import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { useEffect } from "react";
import { MAP_STYPE_OPTIONS } from "../../../constants/map";
import { useMapSetting } from "../../../hooks/useMapSetting";
import IconMapTypeControl from "../../../icons/Map/IconMapTypeControl";
import { MAP_TYPE_CONTROL_ID } from "../../../utils/map";

type Props = {
  index: number;
};

export const MapTypeControl: React.FC<Props> = ({ index }) => {
  const { currentMapStyle, setMapStyle } = useMapSetting();

  const handleChange = (style: string) => setMapStyle(style);

  useEffect(() => {
    const labelElement = document.getElementById(
      `${MAP_TYPE_CONTROL_ID}-${index}-label`,
    );

    if (labelElement) labelElement.innerText = currentMapStyle;
  }, [currentMapStyle]);

  return (
    <div id={`${MAP_TYPE_CONTROL_ID}-${index}`} style={{ display: "none" }}>
      <div
        id={`${MAP_TYPE_CONTROL_ID}-${index}-content`}
        className="flex items-center cursor-pointer bg-white rounded-[10px] shadow-md text-[12px]"
      >
        <Dropdown>
          <MenuButton
            id={`${MAP_TYPE_CONTROL_ID}-${index}-btn`}
            sx={{
              fontSize: "12px",
              color: "#000000",
              border: 0,
              padding: "0 12px",
              margin: 0,
            }}
          >
            <div className="flex items-center gap-x-2">
              <IconMapTypeControl />
              <span
                id={`${MAP_TYPE_CONTROL_ID}-${index}-label`}
                className="font-normal text-sm"
              >
                {currentMapStyle}
              </span>
            </div>
          </MenuButton>

          <Menu
            id={`${MAP_TYPE_CONTROL_ID}-${index}-menu`}
            anchorEl={document.getElementById(
              `${MAP_TYPE_CONTROL_ID}-${index}-content`,
            )}
            placement="bottom-end"
            size="sm"
            sx={{ padding: 0, margin: 0, borderRadius: "10px" }}
          >
            <MenuItem
              id={`${MAP_TYPE_CONTROL_ID}-${index}-menu-item`}
              style={{
                backgroundColor: "transparent",
                minHeight: 0,
                pointerEvents: "none",
                padding: 0,
                margin: 0,
              }}
            ></MenuItem>
            {MAP_STYPE_OPTIONS.map((option) => {
              return (
                <MenuItem
                  key={option.id}
                  sx={{ padding: 0, margin: 0, borderRadius: 0 }}
                  onClick={() => handleChange(option.value)}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#E8F0FE")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#ffffff")
                  }
                >
                  <span className="px-4.5">{option.name}</span>
                </MenuItem>
              );
            })}
          </Menu>
        </Dropdown>
      </div>
    </div>
  );
};
