import turfArea from "@turf/area";
import turfCircle from "@turf/circle";
import { point as turfPoint, polygon as turfPolygon } from "@turf/helpers";
import {
  Area,
  AreaCoordinates,
  AreaData,
  AreaStatus,
  AreaType,
} from "../ducks/area";
import { isTrue } from "./array";
import { getMaxLatLngBounds } from "./geojson";

export let hasNewLine = false;
export let newLineCoords: [number, number][] | null = null;

export const displayNewLineOnPolygon = (
  map: any,
  newLineCoordinates: [number, number][] | null,
) => {
  const newLineSourceId = "new-line-source";
  const newLineLineLayerId = "new-line-line-layer";

  if (map.current.getLayer(newLineLineLayerId))
    map.current.removeLayer(newLineLineLayerId);
  if (map.current.getSource(newLineSourceId))
    map.current.removeSource(newLineSourceId);

  if (!newLineCoordinates) {
    hasNewLine = false;
    newLineCoords = null;
    return;
  }

  const coordinates = [...newLineCoordinates, newLineCoordinates[0]];

  map.current.addSource(newLineSourceId, {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {},
      geometry: { type: "LineString", coordinates },
    },
  });

  map.current.addLayer({
    id: newLineLineLayerId,
    type: "line",
    source: newLineSourceId,
    layout: { "line-join": "round", "line-cap": "round" },
    paint: { "line-color": "#000000", "line-width": 2 },
  });

  hasNewLine = true;
  newLineCoords = newLineCoordinates;

  return coordinates;
};

export const displayNewPolygonOnMap = (
  map: any,
  newPolygon: AreaData | null,
  index: number | null = 0,
  hidePolygon?: boolean,
) => {
  const newPolygonSourceId = "new-polygon-source-" + index;
  const newPolygonFillLayerId = "new-polygon-fill-layer-" + index;
  const newPolygonLineLayerId = "new-polygon-line-layer-" + index;
  const newPolygonCircleLayerId = "new-polygon-circle-layer-" + index;
  const newPolygonInnerCircleLayerId =
    "new-polygon-inner-circle-layer-" + index;

  if (map.current.getLayer(newPolygonFillLayerId))
    map.current.removeLayer(newPolygonFillLayerId);
  if (map.current.getLayer(newPolygonLineLayerId))
    map.current.removeLayer(newPolygonLineLayerId);
  if (map.current.getLayer(newPolygonCircleLayerId))
    map.current.removeLayer(newPolygonCircleLayerId);
  if (map.current.getLayer(newPolygonInnerCircleLayerId))
    map.current.removeLayer(newPolygonInnerCircleLayerId);
  if (map.current.getSource(newPolygonSourceId))
    map.current.removeSource(newPolygonSourceId);

  if (!newPolygon) return null;

  let allLatitudes: number[] = [];
  let allLongitudes: number[] = [];

  // @ts-ignore
  if (!newPolygon[0].latitude && !newPolygon[0].longitude) {
    newPolygon = newPolygon as AreaCoordinates[][];

    const polygonCoordinates: [number, number][][] = [];
    newPolygon.forEach((polygon) => {
      const coordinates: [number, number][] = polygon
        .map(({ longitude, latitude }) => {
          if (!longitude || !latitude) return null;
          return [longitude, latitude] as [number, number];
        })
        .filter<[number, number]>(isTrue);

      const latitudes = polygon.map((x) => x.latitude);
      const longitudes = polygon.map((x) => x.longitude);

      allLatitudes.push(...latitudes);
      allLongitudes.push(...longitudes);

      polygonCoordinates.push(coordinates);
    });

    map.current.addSource(newPolygonSourceId, {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: polygonCoordinates.map((x) => [x]),
        },
        properties: {},
      },
    });
  } else {
    newPolygon = newPolygon as AreaCoordinates[];

    const coordinates: [number, number][] = newPolygon
      .map(({ longitude, latitude }) => {
        if (!longitude || !latitude) return null;
        return [longitude, latitude] as [number, number];
      })
      .filter<[number, number]>(isTrue);

    const latitudes = newPolygon.map((x) => x.latitude);
    const longitudes = newPolygon.map((x) => x.longitude);

    allLatitudes.push(...latitudes);
    allLongitudes.push(...longitudes);

    map.current.addSource(newPolygonSourceId, {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: { type: "Polygon", coordinates: [coordinates] },
        properties: {},
      },
    });
  }

  if (!hidePolygon) {
    map.current.addLayer({
      id: newPolygonFillLayerId,
      source: newPolygonSourceId,
      type: "fill",
      paint: { "fill-color": "#1967D2", "fill-opacity": 0.5 },
    });

    map.current.addLayer({
      id: newPolygonLineLayerId,
      type: "line",
      source: newPolygonSourceId,
      paint: { "line-color": "#1967D2", "line-width": 2 },
    });

    map.current.addLayer({
      id: newPolygonCircleLayerId,
      type: "circle",
      source: newPolygonSourceId,
      paint: { "circle-radius": 4, "circle-color": "#1967D2" },
    });

    map.current.addLayer({
      id: newPolygonInnerCircleLayerId,
      type: "circle",
      source: newPolygonSourceId,
      paint: { "circle-radius": 2, "circle-color": "#FFFFFF" },
    });
  }

  return getMaxLatLngBounds(allLatitudes, allLongitudes, 0.001);
};

export const displayNewCircleOnMap = (
  map: any,
  newCircle: AreaCoordinates[] | null,
  index: number | null = 0,
  hideCircle?: boolean,
) => {
  const newCircleSourceId = "new-polygon-source-" + index;
  const newCircleFillLayerId = "new-polygon-fill-layer-" + index;
  const newCircleLineLayerId = "new-polygon-line-layer-" + index;

  if (map.current.getLayer(newCircleFillLayerId))
    map.current.removeLayer(newCircleFillLayerId);
  if (map.current.getLayer(newCircleLineLayerId))
    map.current.removeLayer(newCircleLineLayerId);
  if (map.current.getSource(newCircleSourceId))
    map.current.removeSource(newCircleSourceId);

  if (!newCircle) return null;

  let allLatitudes: number[] = [];
  let allLongitudes: number[] = [];

  const coordinates: [number, number][] = newCircle
    .map(({ longitude, latitude }) => {
      if (!longitude || !latitude) return null;
      return [longitude, latitude] as [number, number];
    })
    .filter<[number, number]>(isTrue);

  const latitudes = newCircle.map((x) => x.latitude);
  const longitudes = newCircle.map((x) => x.longitude);

  allLatitudes.push(...latitudes);
  allLongitudes.push(...longitudes);

  // @ts-ignore
  const circleCenter = turfPoint(coordinates[0]);
  // @ts-ignore
  const circleRadius = newCircle[0].radius ?? 0;
  const circleOptions = {
    steps: 50,
    units: "meters",
  };
  const circleData = turfCircle(
    circleCenter,
    circleRadius,
    // @ts-ignore
    circleOptions,
  );

  map.current.addSource(newCircleSourceId, {
    type: "geojson",
    data: circleData,
  });

  if (!hideCircle) {
    map.current.addLayer(
      {
        id: newCircleFillLayerId,
        type: "fill",
        source: newCircleSourceId,
        paint: {
          "fill-color": "#1967D2",
          "fill-opacity": 0.25,
        },
      },
      "admin-0-boundary",
    );
    map.current.addLayer(
      {
        id: newCircleLineLayerId,
        type: "line",
        source: newCircleSourceId,
        paint: {
          "line-color": "#1967D2",
          "line-width": 2,
        },
      },
      "admin-0-boundary",
    );
  }

  return getMaxLatLngBounds(allLatitudes, allLongitudes, 0.001);
};

export const displayAreaPolygonOnMap = (
  map: any,
  areas: Area[],
  onClickLayer?: (event: any) => void,
  selectArea?: (area: Area) => void,
  removeOnly?: boolean,
) => {
  if (!map.current) return;

  Object.keys(map.current.style._layers).forEach((layer: any) => {
    if (
      (layer.startsWith("fill-") ||
        layer.startsWith("line-") ||
        layer.startsWith("circle-") ||
        layer.startsWith("inner-circle-")) &&
      !layer.startsWith("line-roads")
    ) {
      map.current.removeLayer(layer);
    }
  });

  if (removeOnly) return;

  const findLayer = (
    area: Area,
    type: "fill" | "line" | "circle" | "inner-circle",
  ) => {
    return Object.keys(map.current.style._layers).find((layer: any) =>
      layer.startsWith(`${type}-${area.area.type}-${area.id}`),
    );
  };

  const randomNumber = Math.random();

  let allLatitudes: number[] = [];
  let allLongitudes: number[] = [];

  areas.sort((a, b): any => {
    if (a.area.type === AreaType.Road && b.area.type !== AreaType.Road) {
      return -1;
    } else if (a.area.type !== AreaType.Road && b.area.type === AreaType.Road) {
      return 1;
    } else {
      if (
        a.area.type === AreaType.Polygon ||
        a.area.type === AreaType.Rectangle ||
        a.area.type === AreaType.Circle
      ) {
        return (
          //@ts-ignore
          calculateAreaSize(a.area.data, a.area.type) -
          //@ts-ignore
          calculateAreaSize(b.area.data, b.area.type)
        );
      }

      return 1;
    }
  });

  areas.forEach((area) => {
    const type = area.area.type;
    const isCircle = type === AreaType.Circle;
    const isPolygon = type === AreaType.Polygon || type === AreaType.Rectangle;
    const isRoad = type === AreaType.Road;

    const sourceId = `source-${type}-${area.id}-${randomNumber}`;
    const fillLayerId = `fill-${type}-${area.id}-${randomNumber}`;
    const lineLayerId = `line-${type}-${area.id}-${randomNumber}`;
    const circleLayerId = `circle-${type}-${area.id}-${randomNumber}`;
    const innerCircleLayerId = `inner-circle-${type}-${area.id}-${randomNumber}`;

    let coordinates: [number, number][] | [number, number][][] = [];

    // @ts-ignore
    if (!area.area.data[0].latitude && !area.area.data[0].longitude) {
      const areaData = area.area.data as AreaCoordinates[][];

      areaData.forEach((polygon) => {
        const coords: [number, number][] = polygon
          .map(({ longitude, latitude }) => {
            if (!longitude || !latitude) return null;
            return [longitude, latitude] as [number, number];
          })
          .filter<[number, number]>(isTrue);

        const latitudes = polygon.map((x) => x.latitude);
        const longitudes = polygon.map((x) => x.longitude);

        allLatitudes.push(...latitudes);
        allLongitudes.push(...longitudes);

        // @ts-ignore
        coordinates.push(coords);
      });
    } else {
      const areaData = area.area.data as AreaCoordinates[];

      coordinates = areaData
        .map(({ longitude, latitude }) => {
          if (!longitude || !latitude) return null;
          return [longitude, latitude] as [number, number];
        })
        .filter<[number, number]>(isTrue);

      const latitudes = areaData.map((x) => x.latitude);
      const longitudes = areaData.map((x) => x.longitude);

      allLatitudes.push(...latitudes);
      allLongitudes.push(...longitudes);
    }

    const draw = () => {
      if (!map.current.getSource(sourceId)) {
        if (isCircle) {
          // @ts-ignore
          const circleCenter = turfPoint(coordinates[0]);
          // @ts-ignore
          const circleRadius = area.area.data[0]?.radius ?? 0;
          const circleOptions = {
            steps: 50,
            units: "meters",
          };
          const circleData = turfCircle(
            circleCenter,
            circleRadius,
            // @ts-ignore
            circleOptions,
          );
          map.current.addSource(sourceId, {
            type: "geojson",
            data: circleData,
          });
        } else if (isRoad) {
          const areaData = area.area.data as AreaCoordinates[];
          const roadLineStrings: Record<number, [number, number][]> = {};
          areaData.forEach((data) => {
            if (!data.road_id) return;

            if (!roadLineStrings[data.road_id])
              roadLineStrings[data.road_id] = [];
            roadLineStrings[data.road_id].push([data.longitude, data.latitude]);
          });

          map.current.addSource(sourceId, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "MultiLineString",
                    coordinates: Object.values(roadLineStrings),
                  },
                },
              ],
            },
          });
        } else {
          // @ts-ignore
          if (!area.area.data[0].latitude && !area.area.data[0].longitude) {
            map.current.addSource(sourceId, {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "MultiPolygon",
                  coordinates: coordinates.map((x) => [x]),
                },
                properties: {},
              },
            });
          } else {
            map.current.addSource(sourceId, {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: { type: "Polygon", coordinates: [coordinates] },
                properties: {},
              },
            });
          }
        }
      }

      if (isCircle) {
        if (!findLayer(area, "fill")) {
          map.current.addLayer(
            {
              id: fillLayerId,
              type: "fill",
              source: sourceId,
              paint: {
                "fill-color":
                  area.status === AreaStatus.Success ? "#5FB955" : "#FE9500",
                "fill-opacity": 0.25,
              },
            },
            "admin-0-boundary",
          );
        }
        if (!findLayer(area, "line")) {
          map.current.addLayer(
            {
              id: lineLayerId,
              type: "line",
              source: sourceId,
              paint: {
                "line-color":
                  area.status === AreaStatus.Success ? "#5FB955" : "#FE9500",
                "line-width": 2,
              },
            },
            "admin-0-boundary",
          );
        }
      } else if (isRoad) {
        if (!findLayer(area, "line")) {
          map.current.addLayer(
            {
              id: lineLayerId,
              type: "line",
              source: sourceId,
              layout: {
                "line-cap": "round",
                "line-join": "round",
              },
              paint: {
                "line-color":
                  area.status === AreaStatus.Success ? "#5FB955" : "#FE9500",
                "line-width": {
                  stops: [
                    [0, 0],
                    [20, 95],
                  ],
                  base: 2,
                },
              },
            },
            "admin-0-boundary",
          );
        }
      } else {
        if (!findLayer(area, "fill")) {
          map.current.addLayer(
            {
              id: fillLayerId,
              source: sourceId,
              type: "fill",
              paint: {
                "fill-color":
                  area.status === AreaStatus.Success ? "#5FB955" : "#FE9500",
                "fill-opacity": isPolygon ? 0.5 : 1,
              },
            },
            "admin-0-boundary",
          );
        }
        if (!findLayer(area, "line")) {
          map.current.addLayer(
            {
              id: lineLayerId,
              type: "line",
              source: sourceId,
              paint: {
                "line-color":
                  area.status === AreaStatus.Success ? "#5FB955" : "#FE9500",
                "line-width": 2,
              },
            },
            "admin-0-boundary",
          );
        }
        if (!findLayer(area, "circle")) {
          map.current.addLayer(
            {
              id: circleLayerId,
              type: "circle",
              source: sourceId,
              paint: {
                "circle-radius": 4,
                "circle-color":
                  area.status === AreaStatus.Success ? "#5FB955" : "#FE9500",
              },
            },
            "admin-0-boundary",
          );
        }
        if (!findLayer(area, "inner-circle")) {
          map.current.addLayer(
            {
              id: innerCircleLayerId,
              type: "circle",
              source: sourceId,
              paint: { "circle-radius": 2, "circle-color": "#FFFFFF" },
            },
            "admin-0-boundary",
          );
        }
      }

      if (selectArea) {
        map.current.on("click", isRoad ? lineLayerId : fillLayerId, () =>
          selectArea(area),
        );
      }
      if (onClickLayer) {
        map.current.off(
          "click",
          isRoad ? lineLayerId : fillLayerId,
          onClickLayer,
        );
        map.current.on(
          "click",
          isRoad ? lineLayerId : fillLayerId,
          onClickLayer,
        );
      }
    };

    try {
      draw();
    } catch (e) {
      map.current.on("load", () => draw());
    }
  });

  return getMaxLatLngBounds(allLatitudes, allLongitudes, 0.001);
};

export const displayRoadLineStringOnMap = (
  map: any,
  roadCoordinates: Record<number, AreaCoordinates[]>,
  roadAreas: Record<number, Area[]>,
  onClickLayer?: (event: any) => void,
  selectRoad?: (area: Area) => void,
  removeOnly?: boolean,
) => {
  if (!map.current) return;

  Object.keys(map.current.style._layers).forEach((layer: any) => {
    if (layer.startsWith("line-roads")) {
      map.current.removeLayer(layer);
    }
  });

  if (removeOnly) return;

  const findLayer = (id: number) => {
    return Object.keys(map.current.style._layers).find((layer: any) =>
      layer.startsWith(`line-roads-${id}`),
    );
  };

  const randomNumber = Math.random();

  let allLatitudes: number[] = [];
  let allLongitudes: number[] = [];

  Object.entries(roadCoordinates).forEach(([id, coords]) => {
    const sourceId = `source-roads-${id}-${randomNumber}`;
    const lineLayerId = `line-roads-${id}-${randomNumber}`;

    let coordinates: [number, number][] | [number, number][][] = [];

    coordinates = coords
      .map(({ longitude, latitude }) => {
        if (!longitude || !latitude) return null;
        return [longitude, latitude] as [number, number];
      })
      .filter<[number, number]>(isTrue);

    const latitudes = coords.map((x) => x.latitude);
    const longitudes = coords.map((x) => x.longitude);

    allLatitudes.push(...latitudes);
    allLongitudes.push(...longitudes);

    const draw = () => {
      if (!map.current.getSource(sourceId)) {
        const roadLineStrings: Record<number, [number, number][]> = {};
        coords.forEach((data) => {
          if (!data.road_id) return;

          if (!roadLineStrings[data.road_id])
            roadLineStrings[data.road_id] = [];
          roadLineStrings[data.road_id].push([data.longitude, data.latitude]);
        });

        map.current.addSource(sourceId, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "MultiLineString",
                  coordinates: Object.values(roadLineStrings),
                },
              },
            ],
          },
        });
      }

      if (!findLayer(Number(id))) {
        map.current.addLayer(
          {
            id: lineLayerId,
            type: "line",
            source: sourceId,
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": roadAreas[Number(id)].every(
                (area) => area.status === AreaStatus.Success,
              )
                ? "#5FB955"
                : "#FE9500",
              "line-width": {
                stops: [
                  [0, 0],
                  [20, 95],
                ],
                base: 2,
              },
            },
          },
          "admin-0-boundary",
        );
      }

      if (selectRoad) {
        const areas = roadAreas[Number(id)];
        const area: Area =
          areas.length === 1
            ? areas[0]
            : { ...areas[0], subAreas: [...areas.slice(1)] };
        map.current.on("click", lineLayerId, () => selectRoad(area));
      }
      if (onClickLayer) {
        map.current.off("click", lineLayerId, onClickLayer);
        map.current.on("click", lineLayerId, onClickLayer);
      }
    };

    try {
      draw();
    } catch (e) {
      map.current.on("load", () => draw());
    }
  });

  return getMaxLatLngBounds(allLatitudes, allLongitudes, 0.001);
};

export const paintMapLayers = (
  map: any,
  areas: Area[],
  selectedAreas: Area[],
  selectedSubAreas: Area[],
) => {
  if (!map.current) return;

  areas.forEach((area) => {
    const type = area.area.type;
    const isCircle = type === AreaType.Circle;
    const isPolygon = type === AreaType.Polygon || type === AreaType.Rectangle;
    const isRoad = type === AreaType.Road;
    const isSelected = selectedAreas.find((x) => x.id === area.id);
    const hasSubAreaSelected = area.subAreas.find((x) =>
      selectedAreas.map((a) => a.id).includes(x.id),
    );

    const isSelectedSubArea = selectedSubAreas.find((x) => x.id === area.id);
    const hasSubAreaSelectedAsSubArea = area.subAreas.find((x) =>
      selectedSubAreas.map((a) => a.id).includes(x.id),
    );
    const fillLayerId = Object.keys(map.current.style._layers).find(
      (layer: any) => layer.startsWith(`fill-${type}-${area.id}`),
    );
    const lineLayerId = Object.keys(map.current.style._layers).find(
      (layer: any) => layer.startsWith(`line-${type}-${area.id}`),
    );
    const circleLayerId = Object.keys(map.current.style._layers).find(
      (layer: any) => layer.startsWith(`circle-${type}-${area.id}`),
    );

    if (!isRoad) {
      map.current.setPaintProperty(
        fillLayerId,
        "fill-color",
        isSelected || hasSubAreaSelected
          ? "#1967D2"
          : isSelectedSubArea || hasSubAreaSelectedAsSubArea
            ? "#000000"
            : area.status === AreaStatus.Success
              ? "#5FB955"
              : "#FE9500",
      );
    }
    if (isCircle) {
      map.current.setPaintProperty(
        lineLayerId,
        "line-color",
        isSelected || hasSubAreaSelected
          ? "#1967D2"
          : isSelectedSubArea || hasSubAreaSelectedAsSubArea
            ? "#000000"
            : area.status === AreaStatus.Success
              ? "#5FB955"
              : "#FE9500",
      );
    } else {
      map.current?.setPaintProperty(
        lineLayerId,
        "line-color",
        isSelected || hasSubAreaSelected
          ? "#1967D2"
          : isSelectedSubArea || hasSubAreaSelectedAsSubArea
            ? "#000000"
            : area.status === AreaStatus.Success
              ? "#5FB955"
              : "#FE9500",
      );

      if (isPolygon) {
        map.current?.setPaintProperty(
          circleLayerId,
          "circle-color",
          isSelected || hasSubAreaSelected
            ? "#1967D2"
            : isSelectedSubArea || hasSubAreaSelectedAsSubArea
              ? "#000000"
              : area.status === AreaStatus.Success
                ? "#5FB955"
                : "#FE9500",
        );
      }
    }
  });
};

export const paintMapRoadLayers = (
  map: any,
  areas: Area[],
  selectedAreas: Area[],
) => {
  if (!map.current) return;

  const selectedRoadIds = selectedAreas.flatMap((area) => {
    let roadIds = area.roadIds;

    area.subAreas.forEach((subArea) => {
      roadIds = [...roadIds, ...subArea.roadIds];
    });

    return roadIds;
  });
  areas.forEach((area) => {
    area.roadIds.forEach((id) => {
      const isSelected = selectedRoadIds.includes(id);
      const lineLayerId = Object.keys(map.current.style._layers).find(
        (layer: any) => layer.startsWith(`line-roads-${id}`),
      );

      map.current?.setPaintProperty(
        lineLayerId,
        "line-color",
        isSelected
          ? "#1967D2"
          : area.status === AreaStatus.Success
            ? "#5FB955"
            : "#FE9500",
      );
    });
  });
};

export const calculateAreaSize = (
  area: AreaCoordinates[],
  areaType: AreaType,
) => {
  const isPolygon =
    areaType === AreaType.Polygon || areaType === AreaType.Rectangle;
  const isCircle = areaType === AreaType.Circle;

  if (isPolygon) {
    try {
      const polygon = turfPolygon([
        area.map((item) => [item.longitude, item.latitude]),
      ]);

      return turfArea(polygon);
    } catch (e) {
      return 999999;
    }
  }

  if (isCircle) {
    const circle = turfCircle(
      [area[0].longitude, area[0].latitude],
      area[0].radius ?? 1,
    );
    return turfArea(circle);
  }
};
