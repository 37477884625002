import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { User, UserTenantType, UserType } from "../../ducks/auth";
import { tenantSelectors } from "../../ducks/tenant";
import IconAdd from "../../icons/IconAdd";
import IconRemove from "../../icons/IconRemove";
import { mergeClassNames } from "../../utils/style";
import { getUserTenantType } from "../../utils/user";
import { CustomSelection } from "./CustomSelection";

type Props = {
  onChangeTenants: (tenants: UserTenantType[]) => void;
  canEditTenant: boolean;
  selectedUser?: User;
};

export const TenantSelection: React.FC<Props> = ({
  onChangeTenants,
  canEditTenant,
  selectedUser,
}) => {
  const tenants = useSelector(tenantSelectors.tenants);

  const [selectedTenants, setSelectedTenants] = useState<
    Partial<UserTenantType & { id: string }>[]
  >(
    selectedUser
      ? getUserTenantType(selectedUser).map((user) => ({
          ...user,
          id: Math.random().toString(),
        }))
      : [{ id: Math.random().toString() }],
  );

  useEffect(() => {
    // @ts-ignore
    onChangeTenants(selectedTenants);
  }, [selectedTenants]);

  const tenantOptions = useMemo(() => {
    const selectedTenantIds = selectedTenants.map((t) => t.tenant_id);
    return tenants
      .filter((tenant) => !selectedTenantIds.includes(tenant.id))
      .map((tenant, i) => ({
        id: i + 1,
        name: tenant.name,
        value: tenant.id,
      }));
  }, [selectedTenants]);

  const disableAddTenant = useMemo(() => {
    return !canEditTenant || selectedTenants.length >= tenants.length;
  }, [selectedTenants]);

  const onAddTenant = () => {
    if (disableAddTenant) return;

    setSelectedTenants((prev) => [...prev, { id: Math.random().toString() }]);
  };

  const onRemoveTenant = (id: string) => {
    if (!canEditTenant) return;

    setSelectedTenants((prev) => prev.filter((x) => x.id !== id));
  };

  const _renderTenantSelection = (index: number, id: string) => {
    return (
      <div
        key={`tenant-selection-${id}-${index}`}
        className="flex gap-x-5 my-5"
      >
        <div className="w-full">
          <CustomSelection
            required
            // name={`tenants[${index}].tenant_id`}
            label="プロジェクト名"
            placeholder="プロジェクト名を選択してください"
            disabled={!canEditTenant}
            defaultValue={
              tenants.find(
                (tenant) => tenant.id === selectedTenants[index].tenant_id,
              )?.name
            }
            onChange={(name, value) => {
              setSelectedTenants((prev) =>
                prev.map((tenant) =>
                  tenant.id === id ? { ...tenant, tenant_id: value } : tenant,
                ),
              );
            }}
            options={tenantOptions}
          />
        </div>

        <div className="w-full">
          <CustomSelection
            required
            // name={`tenants[${index}].user_type`}
            label="ユーザータイプ"
            placeholder="ユーザータイプを選択してください"
            disabled={!canEditTenant}
            defaultValue={selectedTenants[index].user_type}
            onChange={(name, value) => {
              setSelectedTenants((prev) =>
                prev.map((tenant) =>
                  tenant.id === id ? { ...tenant, user_type: value } : tenant,
                ),
              );
            }}
            options={Object.entries(UserType).map(([type, placeholder], i) => ({
              id: i + 1,
              name: placeholder,
              value: placeholder,
            }))}
          />
        </div>

        <div
          onClick={() => onRemoveTenant(id)}
          className={mergeClassNames(
            "w-[75px] flex flex-col justify-end mb-[10px]",
            selectedTenants.length > 1 && "cursor-pointer",
            selectedTenants.length > 1 &&
              !canEditTenant &&
              "cursor-not-allowed",
          )}
        >
          {selectedTenants.length > 1 &&
            (!selectedUser || !selectedUser.wheel) && <IconRemove />}
        </div>
      </div>
    );
  };

  return (
    <div>
      {selectedTenants.map((tenant, i) =>
        _renderTenantSelection(i, tenant.id ?? Math.random().toString()),
      )}

      <div
        onClick={onAddTenant}
        className={mergeClassNames(
          "mt-[24px] flex items-center",
          disableAddTenant
            ? "cursor-not-allowed"
            : "cursor-pointer hover:opacity-80",
        )}
      >
        <IconAdd fillColor={disableAddTenant ? "#707070" : "#334a7c"} />
        <span
          className={mergeClassNames(
            "text-[14px] ml-[4px]",
            disableAddTenant ? "text-[#707070]" : "text-[#334a7c]",
          )}
        >
          プロジェクトの追加
        </span>
      </div>
    </div>
  );
};
