import { IconProps } from "../types";

export default function IconAreaMovement2Mini({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_859"
        data-name="Path 859"
        d="M15.165,3.583H10.626V.835A.834.834,0,0,0,9.791,0H.835A.834.834,0,0,0,0,.835V9.791a.834.834,0,0,0,.835.835H3.583v4.539A.834.834,0,0,0,4.417,16H15.165A.834.834,0,0,0,16,15.165V4.417a.834.834,0,0,0-.835-.835M1.67,8.956V1.67H8.956V3.583H4.417a.834.834,0,0,0-.835.835V8.956Zm7.286,0h-3.7v-3.7h3.7ZM14.33,14.33H5.252v-3.7H9.791a.834.834,0,0,0,.835-.835V5.252h3.7Z"
        fill="#334a7c"
      />
    </svg>
  );
}
