import { IconProps } from "../types";

export default function IconResidence({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="33.179"
      viewBox="0 0 36 33.179"
    >
      <path
        id="Path_808"
        data-name="Path 808"
        d="M18.146,26.88H6.38V14.131H32.307a1.667,1.667,0,0,0,1.291-.6,1.565,1.565,0,0,0,.321-1.357L31.364,1.249A1.645,1.645,0,0,0,29.752,0H4.207A1.645,1.645,0,0,0,2.595,1.249L.04,12.171a1.576,1.576,0,0,0,.321,1.357,1.672,1.672,0,0,0,1.291.6H3.074V28.485a1.629,1.629,0,0,0,1.652,1.607h13.42a1.606,1.606,0,1,0,0-3.212M5.526,3.21H28.433l1.8,7.71H3.723ZM29.058,16.32a6.8,6.8,0,0,0-6.942,6.912q0,4.2,6.942,9.947,6.95-5.741,6.942-9.947a6.8,6.8,0,0,0-6.942-6.912m0,9.224a2.6,2.6,0,1,1,2.674-2.6,2.639,2.639,0,0,1-2.674,2.6"
        transform="translate(0)"
      />
    </svg>
  );
}
