import React from "react";
import { ReactComponent as CheckboxDisable } from "../../assets/icons/disable-checkbox.svg";

export const DisableCheckbox: React.FC = () => {
  return (
    <div className="block pb-[1px] w-[24px] cursor-not-allowed">
      <div className="flex justify-center">
        <CheckboxDisable />
      </div>
    </div>
  );
};
