import actionCreatorFactory from "typescript-fsa";
import { CommonAsyncType } from "../../core/@types";
import { Roadnetwork } from "../../core/api";
import { MapBounds } from "../../utils/geojson";
import { CreateRoadRequestBody, DeleteRoadRequestBody, Road, SearchRoadRequestBody } from "./types";

const actionCreator = actionCreatorFactory("@@Road");

export const roadActions = {
  fetchRoads: actionCreator.async<CommonAsyncType<Roadnetwork[]> & SearchRoadRequestBody, { roads: Road[] }>("FETCH_ROADS"),
  createRoad: actionCreator.async<CommonAsyncType & CreateRoadRequestBody, {}>("CREATE_ROAD"),
  deleteRoads: actionCreator.async<CommonAsyncType & DeleteRoadRequestBody, {}>("DELETE_ROADS"),
  setPreviousMapBounds: actionCreator<MapBounds | null>("SET_PREVIOUS_MAP_BOUNDS"),
  resetToInitialState: actionCreator("RESET_TO_INITIAL_STATE"),
};
