import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { appActions } from "../ducks/app";
import { AreaCoordinates } from "../ducks/area";
import { Road, RoadStatus, roadActions } from "../ducks/road";
import { MapBounds } from "../utils/geojson";

let lastClick = 0;

export const useRoad = (
  callback?: (roads: Road[]) => void,
  hideSelectedRoads?: boolean,
) => {
  const dispatch = useDispatch();

  const [roads, setRoads] = useState<Road[]>([]);
  const [selectedRoad, setSelectedRoad] = useState<Road | null>(null);
  const [selectedRoads, setSelectedRoads] = useState<Road[]>([]);
  const [showDeleteRoadPopup, setShowDeleteRoadPopup] = useState(false);

  useEffect(() => {
    callback?.(selectedRoads);
  }, [selectedRoads]);

  useEffect(() => {
    if (hideSelectedRoads) {
      setRoads(
        roads.filter((road) => {
          return !selectedRoads.map((r) => r.id).includes(road.id);
        }),
      );
    } else {
      setRoads([
        ...roads,
        ...selectedRoads.filter(
          (road) => !roads.map((r) => r.id).includes(road.id),
        ),
      ]);
    }
  }, [roads.length]);

  useEffect(() => {
    if (showDeleteRoadPopup) {
      removeRoadFromDisplay();
      setShowDeleteRoadPopup(false);
    }
  }, [showDeleteRoadPopup]);

  const searchRoad = (
    bounds: MapBounds | null,
    mapBounds: AreaCoordinates[],
    mode: "display" | "select" = "display",
  ) => {
    dispatch(appActions.showLoading());
    dispatch(
      roadActions.fetchRoads.started({
        bounds,
        area: mapBounds,
        onComplete: () => dispatch(appActions.hideLoading()),
        onSuccess: (roads) => {
          const newRoads = roads.map((road) => ({
            id: road.id ?? 0,
            polygon:
              road.polygon?.map((polygon) => ({
                latitude: polygon.latitude ?? 0,
                longitude: polygon.longitude ?? 0,
              })) ?? [],
            status: (road.status as RoadStatus) ?? RoadStatus.Waiting,
          }));

          if (mode === "display") setRoads(newRoads);

          if (mode === "select")
            setSelectedRoads((prev) => [
              ...prev,
              ...newRoads.filter(
                (road) => !prev.map((r) => r.id).includes(road.id),
              ),
            ]);
        },
      }),
    );
  };

  const selectRoad = (road: Road) => {
    setSelectedRoad(road);
  };

  const selectOrRemoveRoad = (road: Road) => {
    // Avoiding from adding multiple area to a selection when spot are layering on top of each other
    if (lastClick < Date.now() - 200) {
      lastClick = Date.now();

      setSelectedRoads((prev) => {
        if (prev.map((r) => r.id).includes(road.id))
          return prev.filter((r) => r.id !== road.id);
        return [...prev, road];
      });
    }
  };

  const removeSelectedRoad = (road: Road) => {
    setSelectedRoads((prev) => prev.filter((r) => r.id !== road.id));
  };

  const removeRoadFromDisplay = () => {
    if (selectedRoad) {
      dispatch(
        appActions.showModal.started({
          title: "道路を削除して良いですか？",
          subTitle:
            "この道路で定義していた分析エリア・道路が使用できなくなります",
          showXIcon: true,
          closeButtonLabel: "キャンセル",
          nextButtonLabel: "削除",
          handleNext: () => {
            setRoads(roads.filter((road) => road.id !== selectedRoad.id));
            setSelectedRoads([...selectedRoads, selectedRoad]);
            setSelectedRoad(null);
            dispatch(appActions.closeModal());
          },
          handleClose: () => dispatch(appActions.closeModal()),
        }),
      );
    }
  };

  const resetSelectedRoads = () => {
    setSelectedRoad(null);
    setSelectedRoads([]);
  };

  const resetAll = () => {
    setRoads([]);
    setSelectedRoad(null);
    setSelectedRoads([]);
  };

  return {
    roads,
    selectedRoad,
    selectedRoads,
    searchRoad,
    selectRoad,
    selectOrRemoveRoad,
    removeSelectedRoad,
    resetAll,
    removeRoadFromDisplay,
    setShowDeleteRoadPopup,
    resetSelectedRoads,
  };
};

export type RoadControls = ReturnType<typeof useRoad>;
