import { Action } from "@reduxjs/toolkit";
import { isType } from "typescript-fsa";
import { AppConfig } from "../../AppConfig";
import { LocalStorageManager } from "../../core/storage/LocalStorageManager";
import { getUserType } from "../../utils/user";
import { authActions } from "./actions";
import { AuthState } from "./types";

const initialState: AuthState = {
  users: [],
  isLoggedIn: false,
  loggedInUser: null,
};

export function AuthReducer(state = initialState, action: Action): AuthState {
  if (isType(action, authActions.fetchUsers.done)) {
    const loggedInUserId = LocalStorageManager.Instance.getObject(
      AppConfig.Instance.LocalStorageKey["loggedInUser"],
    );
    const tenantId = LocalStorageManager.Instance.getObject(
      AppConfig.Instance.LocalStorageKey["tenantId"],
    );

    const users = action.payload.result.users.map((user) => {
      const user_type = getUserType(user, Number(tenantId));

      return { ...user, user_type };
    });

    const loggedInUser =
      users.find((user) => user.id === loggedInUserId) ?? null;

    return {
      ...state,
      users,
      loggedInUser,
    };
  }

  if (isType(action, authActions.login.done)) {
    return {
      ...state,
      isLoggedIn: true,
    };
  }

  if (isType(action, authActions.setToken.done)) {
    return {
      ...state,
    };
  }

  if (isType(action, authActions.logout.done)) {
    return {
      ...state,
      isLoggedIn: false,
      loggedInUser: null,
    };
  }

  if (isType(action, authActions.forgetPassword.done)) {
    return {
      ...state,
    };
  }

  if (isType(action, authActions.resetPassword.done)) {
    return {
      ...state,
    };
  }

  if (isType(action, authActions.createUser.done)) {
    return {
      ...state,
    };
  }

  if (isType(action, authActions.editUser.done)) {
    return {
      ...state,
    };
  }

  if (isType(action, authActions.deleteUsers.done)) {
    return {
      ...state,
    };
  }

  if (isType(action, authActions.resetToInitialState)) {
    return initialState;
  }

  return state;
}
