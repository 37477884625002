import MapboxDraw from "@mapbox/mapbox-gl-draw";
//@ts-expect-error
import DrawRectangle from "mapbox-gl-draw-rectangle-restrict-area";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  DRAWING_CONTROL_STYLE_PRIMARY,
  DRAWING_CONTROL_STYLE_SECONDARY,
} from "../constants/map";
import { appActions } from "../ducks/app";
import {
  addMidpointToLineString,
  addMidpointToPolygon,
  getMidPoint,
} from "../utils/geojson";

type Props = {
  mode: string;
  style: "primary" | "secondary";
};

let lastClick = 0;

export const useMapDraw = ({
  mode = MapboxDraw.constants.modes.DRAW_POLYGON,
  style = "primary",
}: Partial<Props>) => {
  const dispatch = useDispatch();

  const [features, setFeatures] = useState<{
    id: string;
    type: string;
    properties: any;
    geometry: {
      coordinates: [number, number][];
      type: string;
    };
  } | null>(null);

  const draw = useMemo(() => {
    return new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      styles:
        style === "primary"
          ? DRAWING_CONTROL_STYLE_PRIMARY
          : DRAWING_CONTROL_STYLE_SECONDARY,
      controls: {
        polygon: true,
        line_string: true,
        trash: true,
      },
      // @ts-ignore
      modes: Object.assign(MapboxDraw.modes, {
        draw_rectangle: DrawRectangle,
      }),
    });
  }, []);

  const modes = MapboxDraw.modes;

  const onUpdate = useCallback((e: any) => {
    setFeatures(e.features[0]);
  }, []);

  const onDelete = useCallback((e: any) => {
    setFeatures(null);
  }, []);

  const onReset = () => {
    draw.deleteAll();
    draw.changeMode(mode);

    setFeatures(null);
  };

  const onInvalidDrawing = (onClose: () => void) => {
    dispatch(
      appActions.showModal.started({
        title: "線分を交差したポリゴンは作成できません",
        closeButtonLabel: "閉じる",
        handleClose: () => {
          onClose();
          dispatch(appActions.closeModal());
        },
      }),
    );
  };

  const stopDrawing = () => {
    draw.deleteAll();
    draw.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT);

    setFeatures(null);
  };

  const removeVertex = (index?: number) => {
    const trashBtn = document.getElementsByClassName(
      "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash",
    )[index ?? 0] as HTMLElement;

    trashBtn.click();
  };

  const closePopup = () => {
    const closeButton = document.querySelector<HTMLButtonElement>(
      ".mapboxgl-popup-close-button",
    );
    closeButton?.click();
  };

  const setDrawPolygon = (
    id: string,
    coordinates: [number, number][][],
    newLineCoordinates: [number, number][] | null,
  ) => {
    // Avoiding from adding multiple area to a selection when spot are layering on top of each other
    if (lastClick < Date.now() - 200) {
      lastClick = Date.now();
      let newCoords: [number, number][] = [];
      let midpoint: [number, number] | null = null;

      if (newLineCoordinates) {
        midpoint = getMidPoint(newLineCoordinates) as [number, number];
        newCoords = addMidpointToPolygon(
          coordinates[0],
          newLineCoordinates,
          midpoint,
        );
      } else {
        newCoords = coordinates[0];
      }

      const newFeature: any = {
        id,
        type: "Feature",
        properties: { new: true, selected: true },
        geometry: { type: "Polygon", coordinates: [newCoords] },
      };

      setFeatures(newFeature);

      draw.set({
        type: "FeatureCollection",
        features: [newFeature],
      });

      draw.changeMode(MapboxDraw.constants.modes.DIRECT_SELECT, {
        featureId: id,
      });

      return newFeature;
    }
  };

  const setDrawLineString = useCallback(
    (
      id: string,
      coordinates: [number, number][],
      newLineCoordinates: [number, number][] | null,
    ) => {
      let newCoords: [number, number][] = [];
      let midpoint: [number, number] | null = null;

      if (newLineCoordinates) {
        midpoint = getMidPoint(newLineCoordinates) as [number, number];
        newCoords = addMidpointToLineString(
          coordinates,
          newLineCoordinates,
          midpoint,
        );
      } else {
        newCoords = [...coordinates];
      }

      const newFeature: any = {
        id,
        type: "Feature",
        properties: { new: true, selected: true },
        geometry: { type: "LineString", coordinates: newCoords },
      };

      setFeatures(newFeature);

      draw.set({
        type: "FeatureCollection",
        features: [newFeature],
      });

      return newFeature;
    },
    [],
  );

  // useEffect(() => {
  //   const onKeyUp = (event: KeyboardEvent) => {
  //     if (event.key === "Escape" || event.key === "Backspace") {
  //       onReset();
  //     }
  //   };

  //   document.addEventListener("keyup", onKeyUp);

  //   return () => {
  //     document.removeEventListener("keyup", onKeyUp);
  //   };
  // }, []);

  return {
    draw,
    modes,
    features,
    onUpdate,
    onDelete,
    onReset,
    onInvalidDrawing,
    stopDrawing,
    closePopup,
    removeVertex,
    setDrawPolygon,
    setDrawLineString,
  };
};
