import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ColorScale,
  HeatMapType,
  mapActions,
  mapSelectors,
} from "../ducks/map";

export type MapSetting = ReturnType<typeof useMapSetting>;

export const useMapSetting = () => {
  const dispatch = useDispatch();
  const mapStyle = useSelector(mapSelectors.mapStyle);
  const currentMapStyle = useSelector(mapSelectors.currentMapStyle);
  const isDisplayData = useSelector(mapSelectors.isDisplayData);
  const isDisplayRoadArea = useSelector(mapSelectors.isDisplayRoadArea);
  const heatMapDisplay = useSelector(mapSelectors.heatMapDisplay);
  const colorScale = useSelector(mapSelectors.colorScale);
  const maxValue = useSelector(mapSelectors.maxValue);

  const setMapStyle = (style: string) => {
    dispatch(mapActions.setMapStyle({ style }));
  };

  const setIsDisplayData = (isDisplay: boolean) => {
    dispatch(mapActions.setIsDisplayData({ isDisplay }));
  };

  const setIsDisplayRoadArea = (isDisplay: boolean) => {
    dispatch(mapActions.setIsDisplayRoadArea({ isDisplay }));
  };

  const setHeatMapDisplay = (type: HeatMapType) => {
    dispatch(mapActions.setHeatMapDisplay({ type }));
  };

  const setColorScale = (scale: ColorScale[]) => {
    dispatch(mapActions.setColorScale({ scale }));
  };

  const setMaxValue = (maxValue: number) => {
    dispatch(mapActions.setMaxValue({ maxValue }));
  };

  useEffect(() => {
    setColorScale([
      {
        from: 1,
        to: Math.round(maxValue / 5 / 6),
      },
      {
        from: Math.round(maxValue / 5 / 6),
        to: Math.round((maxValue / 5 / 6) * 2),
      },
      {
        from: Math.round((maxValue / 5 / 6) * 2),
        to: Math.round((maxValue / 5 / 6) * 3),
      },
      {
        from: Math.round((maxValue / 5 / 6) * 3),
        to: Math.round((maxValue / 5 / 6) * 4),
      },
      {
        from: Math.round((maxValue / 5 / 6) * 4),
        to: Math.round(maxValue / 6),
      },
      {
        from: Math.round(maxValue / 6),
        to: maxValue,
      },
    ]);
  }, [maxValue]);

  return {
    mapStyle,
    currentMapStyle,
    setMapStyle,
    isDisplayData,
    setIsDisplayData,
    isDisplayRoadArea,
    setIsDisplayRoadArea,
    heatMapDisplay,
    setHeatMapDisplay,
    colorScale,
    setColorScale,
    maxValue,
    setMaxValue,
  };
};
