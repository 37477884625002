import { HttpStatusCode } from "axios";
import { call, put } from "typed-redux-saga";
import { ApiResponse } from "../../core/@types";
import { City, Town } from "../../core/api";
import { cityApi } from "../../core/http/openAPIClient";
import { takeEveryFsa } from "../operations";
import { handleRefreshToken } from "../refreshToken";
import { cityActions } from "./actions";

export function* citySaga() {
  yield takeEveryFsa(cityActions.fetchCities.started, function* (action) {
    const { keyword, onComplete, onError, onSuccess } = action.payload;
    const { response, error }: ApiResponse<City[]> = yield call(() => {
      return cityApi
        .searchcity({ keyword })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          cityActions.fetchCities.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }
    yield* put(
      cityActions.fetchCities.done({
        params: action.payload,
        result: {},
      }),
    );

    const result = response.data.map((city) => ({
      city_id: city.city_id?.toString() ?? "",
      city_name: city.city_name ?? "",
      data:
        city.data?.map((data) => {
          if (!data.latitude && !data.longitude) {
            // @ts-ignore
            return data.flatMap((x) => x);
          }

          return {
            latitude: data.latitude ?? 0,
            longitude: data.longitude ?? 0,
          };
        }) ?? [],
    }));

    onSuccess?.(result);
  });

  yield takeEveryFsa(cityActions.fetchTowns.started, function* (action) {
    const { keyword, onComplete, onError, onSuccess } = action.payload;
    const { response, error }: ApiResponse<Town[]> = yield call(() => {
      return cityApi
        .searchtown({ keyword })
        .then((response) => response)
        .catch((error) => error);
    });

    onComplete?.();

    if (!response || error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        return yield* handleRefreshToken(
          cityActions.fetchTowns.started(action.payload),
        );
      } else {
        return onError?.(error);
      }
    }
    yield* put(
      cityActions.fetchTowns.done({
        params: action.payload,
        result: {},
      }),
    );

    const result = response.data.map((town) => ({
      city_id: town.town_id?.toString() ?? "",
      city_name: town.town_name ?? "",
      data:
        town.data?.map((data) => {
          if (!data.latitude && !data.longitude) {
            // @ts-ignore
            return data.flatMap((x) => x);
          }

          return {
            latitude: data.latitude ?? 0,
            longitude: data.longitude ?? 0,
          };
        }) ?? [],
    }));

    onSuccess?.(result);
  });
}
